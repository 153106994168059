import { EuiTextArea } from "@elastic/eui";




export const TextAreaField = ({ fieldSchema, error, fieldController }) => {

 
    return (
      <>
        <EuiTextArea
          fullWidth
          name={fieldSchema.id}
          id={fieldSchema.id}
          value={fieldController.value || ''}
          onChange={(e) => fieldController.onChange(e.target.value)}
          isInvalid={!!error}
          compressed
        />       
        </>
    );
  };