import { EuiAvatar, EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiPageHeader, EuiPageHeaderSection, EuiSkeletonCircle, EuiSkeletonText, EuiSkeletonTitle, EuiSpacer, EuiText } from '@elastic/eui'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import WorkspaceApi from 'api/workspace-api';
import { TextField } from 'packages/fields/text-field/text-field';
import i18n from './EditWorkspaceForm.i18n';
import { TextArea } from 'packages/fields/text-area/text-area';
import { ColorPicker } from 'packages/fields/color-picker/color-picker';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMatch } from 'react-router-dom';


import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';

import ButtonDeleteWorkspace from './components/button-delete-workspace';




function EditWorkspaceForm() {

  let match = useMatch("/workspace/:workspace/:page");

  const hash_id = match.params.workspace;
  const [updating, setUpdating] = useState(false)
  const [, setValidationText] = useState(i18n.label_required)
  const [idws, setIdws] = useState()
  const [name, setName] = useState('')
  const [color, setColor] = useState('#BD10E0')


  const { data, isLoading } = useQuery(["GetWorkspaceByhash", hash_id], () => WorkspaceApi.GetWorkspaceByhash(hash_id));
  const { control, handleSubmit, clearErrors, setError, formState: { errors }, reset } = useForm();

  const queryClient = useQueryClient()
  const navigator = useAppRouteNavigator();



  const update = async (data) => {

    const response = await WorkspaceApi.UpdateWorkspace(data)

    if (response) {

      setUpdating(false);
      setName(data.name);
      setColor(data.color);
      toast.success(i18n.updated_title_succes);
      queryClient.invalidateQueries(['GetListWorkspace']);
      return true;

    }
    else {

      setUpdating(false);
      return false;
    }

  }


  const onSubmit = async (data) => {

    setUpdating(true)
    clearErrors('name')
    setValidationText(i18n.label_required);
    data.id = idws;
    data.hash_id = hash_id;
    data.state = true;



    if (name === data.name) {
      update(data)
      // updateHeaderProps({
      //   title: data.name,
      //   color: data.color,
      // })

    }
    else {

      const duplicate = await WorkspaceApi.CheckWorkspaceDuplicate(data.name);

      if (!duplicate.data) {
        update(data)
        // updateHeaderProps({
        //   title: data.name,
        //   color: data.color,
        // })



      } else {
        setError('name', { message: data.name + ' ya existe, intenta otra nombre.' })
        setValidationText(i18n.workspace_duplicate)
        setUpdating(false);
      }

    }
  }

  useEffect(() => {

    // loadingHeaderProps(true)


    if (data) {
      if (data.status) {
        reset(data.data)
        setName(data.data.name);
        setIdws(data.data.id);
        setColor(data.data.color);


      } else {
        navigator.GotoForbidden();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash_id, data]);

  const titleWorkspace = (
    <div>
      <dl>
        <dt className="ws-title">
          <EuiSkeletonText lines={1} size="m" isLoading={isLoading} contentAriaLabel="Espacio de trabajo">
            <EuiText size="m"><h4>{name}</h4></EuiText>
          </EuiSkeletonText>
        </dt>
        <dd className="ws-subtitle-small">Administración del espacio de trabajo</dd>
      </dl>
    </div>);


  return (
    <Fragment>

      <EuiPageHeader bottomBorder>
        <EuiPageHeaderSection>
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem grow={false}>
              <EuiSkeletonCircle size="l" isLoading={isLoading} contentAriaLabel="Avatar">
                <EuiAvatar size="l" initialsLength={2} name={name.toUpperCase()} color={color} />
              </EuiSkeletonCircle>
            </EuiFlexItem>
            <EuiFlexItem>{titleWorkspace}</EuiFlexItem>
          </EuiFlexGroup>



        </EuiPageHeaderSection>
        <EuiPageHeaderSection>

        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiSpacer />
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <EuiSkeletonTitle size="xs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <EuiFormRow label={i18n.workspace_name} isInvalid={errors.name ? true : false} error={errors.name && errors.name?.message} helpText="Escriba un nombre único para su espacio de trabajo" >
                <TextField
                  field={field}
                  maxLength={60}
                  error={fieldState.error} />
              </EuiFormRow>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="description"
            control={control}
            rules={{ required: false }}
            defaultValue=""
            render={({ field, fieldState }) =>
              <EuiFormRow label={i18n.workspace_description} helpText={i18n.workspace_name_helptext}>
                <TextArea
                  field={field}
                  error={fieldState.error} />
              </EuiFormRow>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="color"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <EuiFormRow label={i18n.workspace_color} error={fieldState.error} helpText={i18n.workspace_name_helptext}>
                <ColorPicker
                  field={field}
                  error={fieldState.error} />
              </EuiFormRow>
            }
          />
        </EuiSkeletonTitle>

        <EuiSpacer />
        <EuiFlexGroup>
          {!isLoading &&
            (<EuiFlexItem grow={false}>
              <EuiButton fill type="submit" size="s" isLoading={updating}>
                {i18n.save_modal}
              </EuiButton>
            </EuiFlexItem>
            )
          }
        </EuiFlexGroup>
      </EuiForm>

      <EuiHorizontalRule/>
      <EuiSkeletonTitle size="xs" isLoading={isLoading} contentAriaLabel="">
      <ButtonDeleteWorkspace/>
      </EuiSkeletonTitle>


    </Fragment>
  )
}

export default EditWorkspaceForm
