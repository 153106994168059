
import RegexParser from 'regex-parser';



const useRegexValidation = (validationRules) => {



    const patter = validationRules?.pattern || '';
    const type_validation = validationRules?.type || '';
    const custom_message = validationRules?.message || '';

    const validators = {
        email: (val) => RegexParser(patter).test(val),
        only_number: (val) => RegexParser(patter).test(val),
        only_letter: (val) => RegexParser(patter).test(val),
        only_uppercase: (val) => RegexParser(patter).test(val),
        range_char: (val) => val.length >= validationRules.min_length && val.length <= validationRules.max_length,
        range_number: (val) => {
            const numericValue = parseFloat(val);
            return !isNaN(numericValue) && numericValue >= validationRules.min && numericValue <= validationRules.max;
        },
    };

    const validate = (_value) => {
        const validator = validators[type_validation];

        if (validator && !validator(_value)) {

            return custom_message;

        } else {


            return null;
        }
    };



    return { validate };
};

export default useRegexValidation;