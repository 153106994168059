import { useQuery } from "@tanstack/react-query";
import SubscriptionApi from "api/subscription-api";

export enum LimitType {
    WORKSPACE = "workspace",
    APP = "app"
}


export default function useSuscriptionLimit(type_limit) {
    return useQuery(["GetLimitWorkspace"], () => SubscriptionApi.GetLimit(type_limit));
}