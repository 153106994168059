import hide from './hide';
import show from './show';
import cascade from './cascade';
import required from './required';

export const conditionsMet = (rule, data, formId) => {
  let met = true;
  for (const condition of rule.conditions) {
    const currFieldValue = (data[condition.form || formId] ?? {})[
      condition.field
    ];
    if (condition.values.indexOf(currFieldValue) < 0) {
      met = false;
      break;
    }
  }

  return met;
};

const rules = { hide, show, required, cascade };

export default rules;
