import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes'
import { setlang, i18n } from './i18n/i18n'
import { EuiContext, } from '@elastic/eui';
import ThemeSelector from 'theme/Theme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'



function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  useEffect(() => {

    setlang()
    

  }, [])
  
  

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeSelector>
        <EuiContext i18n={i18n}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </EuiContext>
      </ThemeSelector>
    </QueryClientProvider>  
  )
}

export default App
