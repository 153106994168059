import { EuiButtonIcon, EuiFlexGrid, EuiFlexItem, EuiIcon, EuiPanel } from "@elastic/eui";


function IconList({ onChangeIcon }) {


    const list_icons = ['bell', 'beaker', 'article', 'brush', 'bug', 'clock', 'cheer', 'broom', 'copy', 'faceSad', 'faceHappy']


    return (
        <>
            <EuiPanel paddingSize="m">
                <EuiFlexGrid columns={4} gutterSize="s">
                    {list_icons.map((icon, index) => (
                        <EuiFlexItem key={"list-icon-" + index}>
                            <EuiButtonIcon
                                iconType={icon}
                                color="text"
                                aria-label={'Seleccionar icono'}
                                onClick={() => { onChangeIcon(icon) }}
                            />
                        </EuiFlexItem>
                    ))}


                </EuiFlexGrid>
            </EuiPanel>
        </>
    )
}

export default IconList