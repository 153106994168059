//MOMENT FORMAT
export const FormatDate = [
    {
        value : "DD/MM/YYYY",
        text : "31/12/2023 ( Dia /Mes /Año )"
    },
    {
        value : "MM/DD/YYYY",
        text : "31/02/2023 ( Mes /Dia /Año )"
    },    
];

export const FormatTimeDay = 
[
    {
        value : "12",
        text : "4:20 PM"
    },
    {
        value : "24",
        text : "16:20"
    }
    
];

