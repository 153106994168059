import React from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,   
    EuiFieldText,
    EuiButtonIcon,
  } from '@elastic/eui';
  

function ItemBuilder() {
  return (
    <EuiFlexGroup style={{ maxWidth: 800 }}  >
    <EuiFlexItem>
      
        <EuiFieldText compressed  />
      
    </EuiFlexItem>
    <EuiFlexItem>
    
        <EuiFieldText compressed  />
    
    </EuiFlexItem>
    <EuiFlexItem>
        <EuiFieldText compressed  />
    
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
          display="base"
          iconType="trash"
          aria-label="Delete"
          color="danger"
        />
    
    </EuiFlexItem>
  </EuiFlexGroup>
  );
}

export default ItemBuilder;
