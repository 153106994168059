import { EuiListGroup, EuiListGroupItem } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { NavLink,useMatch } from "react-router-dom";


function SideNavWorkspace() {

    let match = useMatch("/workspace/:workspace/:page");   
    const hash_id = match.params.workspace;



    return (
        <>
            <EuiListGroup size="m" maxWidth={600} >
                <NavLink to={`/workspace/${hash_id}/edit`}>
                {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-edit'}
                            iconType={ICON_GALLERY.INFO_CIRCLE}
                            label={'Espacio de Trabajo'}
                            onClick={() => { }}
                            isActive={isActive}
                        />

                        )}
                </NavLink>

                <NavLink to={`/workspace/${hash_id}/apps`}>
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-apps'}
                            iconType="gear"
                            onClick={() => { }}
                            label={'Apps'}
                            isActive={isActive}

                        />
                    )}
                </NavLink>

                <NavLink to={`/workspace/${hash_id}/portal`}>
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-portal'}
                            iconType={'globe'}
                            onClick={() => { }}
                            label={'Portal'}
                        />
                    )}
                </NavLink>
                <NavLink to={`/workspace/${hash_id}/users`}>
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-users'}
                            iconType={ICON_GALLERY.USERS}
                            onClick={() => { }}
                            label={'Usuarios'}
                        />
                    )}
                </NavLink>
                <NavLink to={`/workspace/${hash_id}/backups`}>
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-backups'}
                            iconType={'database'}
                            onClick={() => { }}
                            label={'Copias de seguridad'}
                        />
                    )}
                </NavLink>
                <NavLink to={`/workspace/${hash_id}/activities`}>
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'workspace-activities'}
                            iconType={'clock'}
                            onClick={() => { }}
                            label={'Actividades'}
                        />
                    )}
                </NavLink>
            </EuiListGroup></>
    )
}

export default SideNavWorkspace;