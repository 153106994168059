
import AvatarRenderer from "./avatar/avatar";
import RichTextRenderer from "./rich-text-editor/rich-text-editor";
import TextRenderer from "./text/text";
import DateRenderer from "./date/date";
import CreateAtRenderer from "./create_at/create_at";
import NumberRenderer from "./number/number";
import ComboBoxRenderer from "./combo-box/combo-box";
import CheckBoxRenderer from "./checkbox/checkbox";
import FileRenderer from "./file/file";
import SignatureRenderer from "./signature/signature";
import EmailRenderer from "./email/email";
import UrlRenderer from "./url/url";
import RatingRenderer from "./rating/rating";
import ConsecutiveRenderer from "./consecutive/consecutive";
import ForeignKeyRenderer from "./foreign-key";



export const CellFieldType = {
    'id': TextRenderer,
    'title': TextRenderer,
    'avatar': AvatarRenderer,
    'richtext': RichTextRenderer,
    'geolocalization': TextRenderer,
    'create_at': CreateAtRenderer,
    'last_update': TextRenderer,
    'create_by': TextRenderer,
    'text': TextRenderer,
    'longtext': TextRenderer,
    'date': DateRenderer,
    'numeric': NumberRenderer,
    'select': ComboBoxRenderer,
    'cascade': ComboBoxRenderer,
    'checkbox': CheckBoxRenderer,
    'options': ComboBoxRenderer,
    'file': FileRenderer,
    'signature': SignatureRenderer,
    'email': EmailRenderer,
    'url': UrlRenderer,
    'rating': RatingRenderer,
    'consecutive': ConsecutiveRenderer,
    'gallery': FileRenderer,
    'foreign_key': ForeignKeyRenderer,
    'association_field':TextRenderer

};

