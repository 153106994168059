import ApiClient from "api/api.client";



export const QUERY_KEYS_RECORDS = {
    GET_RECORDS_BY_VIEW: "getrecordsbyview",
    GET_RECORD_BY_ID_BY_KEY: "getrecordbyid",

}

// async function GetRecordsByDataGridQuery(view_id) {

//     const response = await ApiClient.HttpGet(`records/${view_id}/datagrid`);
//     return response.data;
// }

// function GetRecordsByDataGrid(view_id) {
//     return useQuery([QUERY_KEYS_RECORDS.GET_RECORDS_BY_VIEW, view_id], () => GetRecordsByDataGridQuery(view_id));
// }


// async function GetValueByRecordIdByKey(collection,id,key) {

//     const response = await ApiClient.HttpGet(`records/${collection}/records/${id}/${key}/value`);
//     return response.data;
// }

// function GetRecordByIdByKey(collection,id,key) {
//     return useQuery([QUERY_KEYS_RECORDS.GET_RECORD_BY_ID_BY_KEY,[collection,id,key]], () => GetValueByRecordIdByKey(collection,id,key));
// }



// export const RecordsApiQuery = {
//     //GetRecordsByDataGrid,
//     GetRecordByIdByKey

// }


async function AddRecord(element) 
{
    const response = await ApiClient.HttpPost("records/add", element);
    return response.data;
}




export const RecordsApiCommand = {
    AddRecord,
    

}