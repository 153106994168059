import { euiPaletteColorBlindBehindText } from "@elastic/eui";

// export const PALLETE_COLOR_ = ['#8D91C7','#fb5779','#F94144','#ff7511','#E76F51','#ffd100','#ace60f','#19db7e','#00d4c8','#48dafd','#0064fb','#277DA1','#ff4ba6','#caf7e3','#fde8cd','#ff8585','#bbf1fa','#F7AEF8','#A5A5A5','#F2F2F2'];
export const PALLETE_COLOR_WORKSPACE = ['#4C9AFF','#00B8D9','#36B37E','#FF991F','#6554C0','#48DAFD','#00D4C8','#19DB7E','#FFC400','#8D91C7','#B3D4FF','#B3F5FF','#CAF7E3','#FFF0B3','#EAE6FF'];
export const PALLETE_COLOR_OPTIONS = ['#FAE2E3','#FCE0EA','#F2E2F4','#EAE5F5','#E4E7F4','#DBE8FE','#D1EBF9','#C6EFF4','#D4ECEA','#D7EDD8','#DDEDCA','#FEE3C1','#FEE1D8','#F3E4E0','#E3E8EC'];
export const PALLETE_COLOR_AVATAR = ['#70D6FF','#3363FF','#DD7DF9','#9D4EDD','#FF70A6','#FA0082','#FF7D1A','#FFBE86','#45F0DF','#1EE9B6','#FFDE54','#6C757D','#1D1929','#F42829','#AF3800'];

export const PALLETE_COLOR_PICKER =() => {
    const visColorsBehindText = euiPaletteColorBlindBehindText({sortBy: 'natural'});
       const colors = [];
    for (let i = 0; i <= 9; i++) {
        colors.push(visColorsBehindText[i]);
    }

    return colors;
        
}