import { EuiAccordion, EuiIcon, EuiLink, EuiPanel, EuiText, EuiTextColor } from "@elastic/eui";
import { nanoid } from "nanoid";

function QuickMessageAccordion({ title, description, paddingSize, link_url = null, link_text = null, style , initialIsOpen=false}) {

    const QuickMessageAccordionid = nanoid();

    return (
        <EuiPanel color='transparent' paddingSize={paddingSize || 's'} style={style} >
            <EuiAccordion
                id={QuickMessageAccordionid}
                initialIsOpen={initialIsOpen}
                buttonContent={<>
                    <EuiIcon style={{ marginTop: '-3px' }}
                        type="questionInCircle" />
                    <EuiTextColor >
                        {title}
                    </EuiTextColor></>}
                arrowDisplay="none"
                extraAction={
                    link_url ?
                        <EuiText size='xs'>
                            <EuiLink external target="_blank" href={link_url}>{link_text}</EuiLink>
                        </EuiText> : null}
            >
                {description && (<EuiPanel color="primary">
                    <p>{description}</p>
                </EuiPanel>)}
            </EuiAccordion>
        </EuiPanel>
    )
}


export default QuickMessageAccordion