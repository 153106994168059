import { EuiCheckbox } from "@elastic/eui"
import { useState } from "react";

export const CheckboxField = ({ fieldSchema, error, fieldController }) => {

    const [checked, setChecked] = useState(false);

    const onChange = () => {
        setChecked(!checked);
        fieldController.onChange(!checked);
    }

    return (
        <>
        <EuiCheckbox
            id={fieldSchema.id + ''}
            label={fieldSchema.name}
            checked={checked}
            onChange={onChange}
            compressed

        />
        </>

    )
}
