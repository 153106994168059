import { EuiProvider, EuiThemeColorMode, euiStylisPrefixer, } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import createCache from '@emotion/cache';
import { OverridesTheme } from './overide-theme';
import { useGlobalStore } from 'store/global-store';



const ThemeSelector: React.FC = ({ children }) => {


  // const euiCache = createCache({key: 'one-space'});  
  // euiCache.compat = true;

  // const { euiTheme } = useEuiTheme();

  const euiCache = createCache({
    key: 'eui',
    stylisPlugins: [euiStylisPrefixer],
    container: document.querySelector('meta[name="eui-style-insert"]'),
  });
  euiCache.compat = true;

  const currentTheme = useGlobalStore(state => state.theme);
  const [colorMode, setColorMode] = useState<EuiThemeColorMode>(currentTheme as EuiThemeColorMode)

  useEffect(() => {

    require(`assets/eui/dist/overlay-${currentTheme}.css`);
    require(`assets/eui/dist/eui_theme_${currentTheme}.css`);
    setColorMode(currentTheme as EuiThemeColorMode)

  }, [currentTheme])


  return (
    <>
      <EuiProvider colorMode={colorMode} cache={euiCache} modify={OverridesTheme} >
        {children}
      </EuiProvider>
    </>
  )
};

export default ThemeSelector;