import React, { useEffect, useState } from 'react'
import { EuiDragDropContext, euiDragDropReorder, EuiDraggable, EuiDroppable, EuiPanel } from '@elastic/eui';
import RowFieldDrop from 'packages/column-manager/components/row-field-drop';
import { useGlobalStore } from 'store/global-store';
import { useInvalidateQuery } from 'api/invalidate-querys/invalidate-current-views';
import { CollectionApiQuery } from 'api/collections/query-api';
import { CollectionApiCommand } from 'api/collections/command-api';
import ProgreessWhitPanel from 'components/progress/progress-whit-panel';
import { useGlobalCallOutNotificationStore } from 'store/global-callout-notification-store';




function ColumnListManager({ collection }) {


    
    
    const currentTheme = useGlobalStore(state => state.theme);
    const [isDragging, setIsDragging] = useState(false);
    const showNotificationError = useGlobalCallOutNotificationStore(state => state.showNotificationError);

    const [rowsFields, setRowsFields] = useState([]);
    const isDraggingOverClass = currentTheme === 'dark' ? 'isDraging-hover-dark' : 'isDraging-hover-ligth ';
    const { InvalidateCurrentView } = useInvalidateQuery();
    

    const { data, isLoading } = CollectionApiQuery.GetAttributesByCollection(collection);


    const onRemoveField = (value) => console.log(value)

    const onDragStart = () => setIsDragging(true);
    const onDragEnd = ({ source, destination }) => {

        if (source && destination) {

            const items = euiDragDropReorder(rowsFields, source.index, destination.index);
            setRowsFields(items);
            updateOrder(items);
            setIsDragging(false);
        }
    };

    const updateOrder = async (items) => {

        const update_order =
        {
            keys_id: items.map(({ id }) => parseInt(id))
        }
        const response = await CollectionApiCommand.UpdateOrderAttributes(update_order);

        if (response.data){
            InvalidateCurrentView()
        }
        else
            showNotificationError('Ha ocurrido algo inesperado', 'No fue posible orderar los campos.')


    }


    useEffect(() => 
    {
        if (data){
            setRowsFields(data.data);
        }

    }, [data]);

    if (isLoading)
        return (<ProgreessWhitPanel />)



    return (
        <>
            <EuiPanel color="subdued" paddingSize='xs'>
                <EuiDragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
                    <EuiDroppable isDropDisabled={false} droppableId="DROPPABLE_AREA" spacing="s" className={isDragging ? isDraggingOverClass : ''} >
                        {!isLoading && rowsFields.map((item, index) => (
                            <EuiDraggable isDragDisabled={false} spacing="m" key={item.key} index={index} draggableId={'key-' + item.id}>
                                {(provided, state) =>
                                (
                                    <RowFieldDrop
                                        {...provided}
                                        title={item.name}
                                        is_predefined={item.is_predefined}
                                        idx={item.key}
                                        icon={item.data_type}
                                        visible={item.status}
                                        id={item.id}
                                        isDragging={state.isDragging}
                                        remove={onRemoveField}
                                        data={item}
                                    />

                                )}
                            </EuiDraggable>
                        )
                        )}
                    </EuiDroppable>
                </EuiDragDropContext>
            </EuiPanel>
        </>
    )

}

export default ColumnListManager