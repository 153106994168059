
export const KEYS_DATE_FORMAT_ISO = 'YYYY-MM-DD';

export const DateHelper ={

    getIsoDate: (date)=>
    {
        return date.toISOString().split('T')[0];
    }
    ///con la fecha y horA actual en formato ISO
    ,    
}