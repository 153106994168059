import React, { useState } from 'react'
import { EuiSwitch } from "@elastic/eui"
import { useInvalidateQuery } from 'api/invalidate-querys/invalidate-current-views';
import { CollectionApiCommand } from 'api/collections/command-api';

function SwitchStatusAttribute({ status, id, onChange }) {

  const { InvalidateCurrentView } = useInvalidateQuery();

  const [checked, setChecked] = useState(status);

  const onChangeStatus = async (e) => 
  {
    setChecked(e.target.checked);
    onChange(e.target.checked);
    await CollectionApiCommand.UpdateStatusAttributes({ attribute_id: id, status: e.target.checked });
    InvalidateCurrentView();
  };




  return (
    <EuiSwitch
      showLabel={false}
      label="Autoscaling"
      checked={checked}
      onChange={onChangeStatus}
      compressed
    />
  )
}

export default SwitchStatusAttribute