import {create} from 'zustand';
import { persist,createJSONStorage  } from 'zustand/middleware'


const useVisibilityStore = create(persist((set) =>({
    
    visibilityState: {},
    setVisibilityState: (componentName, isVisible) =>
        set((state) => ({
            visibilityState: {
                ...state.visibilityState,
                [componentName]: isVisible,
            },
        })),
    toggleVisibilityState: (componentName) =>   
        set((state) => ({
            visibilityState: 
            {
                ...state.visibilityState,
                [componentName]: !state.visibilityState[componentName],
            }
            
        }))
    }),
    
{    name: 'componentVisibilityStates',
    storage: createJSONStorage(() => localStorage),
}));

export default useVisibilityStore;