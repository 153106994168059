import { useQuery } from "@tanstack/react-query";
import ApiClient from "./api.client";


export const QUERY_KEYS_VIEW_BY_ATTRIBUTES={
    GET_ATTRIBUTES_BY_VIEW: "GetAttributesQuery",
}



async function UpdateOrderAttributesByView(view_order)
{
    const response = await ApiClient.HttpPost("view/attributes/order", view_order);
    return response.data;
}


async function UpdateVisibilityAttributesByView(attribute_visibility)
{
    const response = await ApiClient.HttpPost("view/attributes/visibility", attribute_visibility);
    return response.data;
}


async function GetAttributesByViewQuery(view)
{
      const response = await ApiClient.HttpGet(`view/${view}/attributes`);	
      return response.data; 
  
}


function GetAttributesByView(view_id) 
{
      return useQuery([QUERY_KEYS_VIEW_BY_ATTRIBUTES.GET_ATTRIBUTES_BY_VIEW, view_id], () => GetAttributesByViewQuery(view_id));
}


export const ViewAttributeApiCommand = {
    UpdateOrderAttributesByView ,
    UpdateVisibilityAttributesByView,  
   
}


export const ViewAttributeApiQuery = {
    GetAttributesByView
}
