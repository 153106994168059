import { EuiFieldText } from '@elastic/eui';
import { useEffect, useState } from 'react';

const LatitudeField = ({ fieldSchema, error, fieldController }) => {


    const [latitude, setLatitude] = useState(0);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setLoading(true)
        if (navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(function (position) {
                var latitud = position.coords.latitude;
                setLatitude(latitud);
                fieldController.onChange(latitud)
                setLoading(false)
            });
        }
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <EuiFieldText
                fullWidth
                disabled
                isLoading={loading}
                name={'latitude'}
                id={'latitude'}
                value={fieldController.value || latitude}
                onChange={(e) => fieldController.onChange(e.target.value)}
                isInvalid={!!error}
                compressed
                prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
                append={fieldSchema.props?.append && fieldSchema.props?.append}
            />
        </>
    );
};

export default LatitudeField;