import { create } from 'zustand';



export const useDataGridStore = create(
    (set) => ({
        isGlobalStateChange: false,
        updateGrid: () => set(state => ({ isGlobalStateChange:!state.isGlobalStateChange})),
}));

