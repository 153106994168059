import LabelRequiredBetaBadge from "./label-required-beta-badge-"
import LabelDescription from "./label-description"

function Label({ schema_field }) {
  if (schema_field.data_type === 'checkbox')
    return null;

  return (
    <>
      {schema_field.props?.is_required && <LabelRequiredBetaBadge />}
      {schema_field.props?.description && <LabelDescription description={schema_field.props?.description} />}
      {schema_field.name}
    </>
  )
}

export default Label