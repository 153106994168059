import { EuiIcon,EuiButton, EuiEmptyPrompt, EuiButtonEmpty, EuiTitle, EuiLink, EuiModal, EuiPortal, EuiOverlayMask} from '@elastic/eui'
import React, { Fragment,  useState } from 'react'
import UpgradeIco from 'assets/svg/ico-upgrade-plan.svg'
import { useNavigate } from 'react-router'





const  NotifyUpgrade=({showModal})=> {

    const navigate = useNavigate();
    
    const [isModalVisible, setIsModalVisible] = useState(showModal);  

    const upgradepage=()=>{
        navigate("/pages/upgrade")
    }


      const content=(
      <EuiEmptyPrompt
          icon={<EuiIcon type={UpgradeIco} size="original"></EuiIcon>}
          title={<h2>Puedes hacer mas con OneSpace!</h2>}
          layout="vertical"
          hasBorder
          body={
            <p>
              Inicia el periodo de pruebas o compra un plan empresarial
            </p>
          }
          actions={[
            <EuiButton color="primary" fill>
      Actualiza tu plan
            </EuiButton>,
            <EuiButtonEmpty onClick={upgradepage}>Inicia prueba de 8 dias</EuiButtonEmpty>,
          ]}
          footer={
            <>
              <EuiTitle size="xxs">
                <h3>Necesitas soporte?</h3>
              </EuiTitle>
              <EuiLink href="#" target="_blank">
                Nuestra documentacion
              </EuiLink>
            </>
          }
        />
      )

   let portal=(
    <EuiPortal>
        <EuiOverlayMask>
        <EuiModal onClose={()=>{setIsModalVisible(false)}}>
        {content}
        </EuiModal>
    </EuiOverlayMask>
    </EuiPortal>
   );
      

    return (
        <>
        {isModalVisible ? portal : !showModal ? content : null       }
      </>
        
      )


}


export default NotifyUpgrade
