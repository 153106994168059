import { EuiButtonIcon, EuiFieldText, EuiColorPicker, EuiFlexGroup, EuiFlexItem, EuiPanel, useColorPickerState, EuiButtonEmpty, EuiIcon, euiPaletteColorBlindBehindText, EuiFormRow, EuiPopover, EuiButton, EuiSplitPanel, EuiToolTip } from '@elastic/eui'
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import { PALLETE_COLOR_PICKER } from 'core/Colors';
import LabelWithHelpIconToolTip from 'packages/fields/label/label-help-icon-tooltip';
import React, { useState } from 'react'



function ItemOption({ item, OnUpdate, OnRemove, showColorPicker, showEditorValues, currentList }) {

  const index = item?.id;
  const retornarValorAleatorio = () => Math.floor(Math.random() * 10);
  const visColorsBehindText = euiPaletteColorBlindBehindText();
  const [color, setColor] = useColorPickerState(item?.color || visColorsBehindText[retornarValorAleatorio()]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isInvalidMessage,setIsInvalidMessage] = useState('');
  const [listOptions, setListOptions] = useState(currentList);
  const [value, setvalue] = useState(item?.label || '');
  const [showPopover, setShowPopover] = useState(false);
  const [currentItem, setCurrentItem] = useState(item || { id: '', label: '', value: '', color: '' });

  const closePopover = () => setShowPopover(false);
  const clickOnremove = () => OnRemove(index)

  const OnChangueInput = ((value, is_enter) => 
  {
    setvalue(value);
    setIsInvalid(false);

    if (value.trim() !== "") 
    {
 
        const isDuplicate = currentList.some((el) => 
        el.value.toLowerCase() === value.trim().toLowerCase() && el.id !== index);

        if (isDuplicate) {
          setIsInvalid(true);
          setIsInvalidMessage('La opción ya existe');
          return;
        }
        const item = { id: index, label: value, value: value, color: color };
        setCurrentItem(item);
        setListOptions([...listOptions, item]);
        OnUpdate(item, is_enter);
    
    }

  });

  const onKeyDownHandler = (event) => {

    const keyCode = event.keyCode || event.which;
    
    if (keyCode === 8 || keyCode === 192) return;//backspace and ñ por alguna razon
    if (keyCode === 110 || keyCode === 107 || keyCode === 186) event.preventDefault();

    const isSpecialCharacter = /[^\w\s]/.test(String.fromCharCode(keyCode));

    if (isSpecialCharacter)
        event.preventDefault();

    if (keyCode === 13) {
      event.preventDefault();
      OnChangueInput(event.target.value, true);
    }

  };



  const OnChangeColor = (text, { hex, isValid }) => {
    const item = { id: index, label: value, value: value, color: text }
    setColor(text, { hex, isValid });
    OnUpdate(item, false)
  }




  const handleEditValueChange = (event) => {
    const keyCode = event.keyCode || event.which;

    const clonedItem = { ...currentItem };
    clonedItem.value = event.target.value;
    setCurrentItem(clonedItem);

    if (keyCode === 13) {
      event.preventDefault();
      OnUpdate(clonedItem, true);

    } else {
      OnUpdate(clonedItem, false);
    }



  }


  const buttonEditValues = (<>
    <EuiButtonEmpty
      role="button"
      size="xs"
      iconType="arrowDown"
      iconSide="right"
      css={{
        background: '#fbfcfd',
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 9,
        borderRadius: '6px 0 0 6px'
      }}
      aria-label="Editar opción"
      aria-labelledby='Editar'
      onClick={() => setShowPopover(!showPopover)}
    >
      <EuiToolTip content={'' + currentItem.value} position="top">
      <EuiIcon aria-labelledby='Editar' aria-label="Editar valor de la opción" type="documentEdit" />
      </EuiToolTip>
    </EuiButtonEmpty>
  </>);

  const LabelEditValue = (
    <LabelWithHelpIconToolTip
      label={"Valor"}
      extraHelpText="Este es valor que se almacena, puede cambiarlo a continuación, con limite de 60 caracteres"
    />)

  const popOverEditValues = (
    <EuiPopover
      button={buttonEditValues}
      isOpen={showPopover}
      closePopover={closePopover}>
      <EuiSplitPanel.Outer style={{ maxWidth: 200 }} grow={false} color='transparent' >
        <EuiSplitPanel.Inner paddingSize='none'>
          <EuiButtonIcon iconType='cross' aria-labelledby='Close' onClick={closePopover} style={{ position: 'absolute', top: 10, right: 10 }} />
        </EuiSplitPanel.Inner>
        <EuiSplitPanel.Inner grow={false} color="transparent" paddingSize='none'>
          <EuiFormRow label={"Etiqueta"} display='rowCompressed' helpText="Este es el texto que se mostrará">
            <EuiFieldText disabled={true} name="label" compressed value={currentItem.label} />
          </EuiFormRow>
          <EuiFormRow label={LabelEditValue} display='rowCompressed' helpText="Este es el valor que se almacenará">
            <EuiFieldText name="value" title='máximo 60 caracteres' maxLength={60} spellCheck={false} compressed value={currentItem.value} onChange={handleEditValueChange} />
          </EuiFormRow>
          <EuiButton onClick={closePopover} size='s' fullWidth>Guardar</EuiButton>
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>
    </EuiPopover>
  )



  return (
    <EuiFlexGroup alignItems="center" gutterSize="xs">
      <EuiFlexItem grow={false}>
        <EuiPanel
          color="plain"
          paddingSize="none"
          hasShadow={false}
          hasBorder={true}
          aria-label="Drag Handle"
        >
          {showColorPicker &&
            <EuiColorPicker
              id={'color-' + index}
              color={color}
              onChange={OnChangeColor}
              swatches={PALLETE_COLOR_PICKER()}
              button={
                <EuiButtonIcon
                  iconType='stopFilled'
                  aria-label="brush"
                  iconSize='m'
                  style={{ color: color }} />
              }
            />
          }
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem >
        <EuiFormRow
          style={{ marginTop: 0 }}
          hasEmptyLabelSpace
          isInvalid={isInvalid}
          error={isInvalidMessage}
          display='rowCompressed'
        >


          <EuiFieldText
            autoFocus
            title='Presione Enter para agregar un opción y máximo 200 caracteres'
            style={{ minWidth: 230 }}
            prepend={showEditorValues ? popOverEditValues : null}
            placeholder="Escriba una opción"
            onKeyDown={onKeyDownHandler}
            onChange={(e) => { OnChangueInput(e.target.value, false) }}
            value={value}
            compressed
            isInvalid={isInvalid}
            maxLength={200}



          />

        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          iconType={ICON_GALLERY.DELETE}
          aria-label="delete"
          onClick={() => { clickOnremove() }}
          color="accent" />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default ItemOption