import TextField from './text/text-field';
import { TextAreaField } from './text-area/text-area-field';
import { DateField } from './date/date';
import { NumberField } from './number/number';
import { ComboBoxField } from './combo-box/combo-box';
import { CheckboxField } from './checkbox/checkbox';
import { UploadField } from './upload/upload';
import { SignatureField } from './signature/signature';
import { UrlField } from './url/url';
import { OptionField } from './option/option';
import RatingField from './rating/rating';
import { UploadAvatarField } from './upload/upload-avatar';
import CascadeField from './cascade/cascade';
import ConsecutiveField from './consecutive/consecutive';
import LatitudeField from './geolocalization/latitude';
import LongitudeField from './geolocalization/longitude';
import IdField from './id/id';
import LastUpdateField from './last_update/last-update';
import CreateByField from './create-by/create-by';
import CreateAtField from './create_at/create-at';
import GeoLocalizationField from './geolocalization/geolocalization';
import RichTextField from './rich-text-editor/rich-text-editor';
import { UserField } from './user/user';
import ForeignKeyField from './foreign-key';

export const FieldTypes = {
  'text': TextField,
  'longtext': TextAreaField,
  'date': DateField,
  'age': DateField,
  'numeric': NumberField,
  'select': ComboBoxField,
  'checkbox': CheckboxField,
  'file': UploadField,
  'gallery': UploadField,
  'signature': SignatureField,
  'email': TextField,
  'url': UrlField,
  'options': OptionField,
  'rating': RatingField,
  'avatar': UploadAvatarField,
  'cascade': CascadeField,
  'consecutive': ConsecutiveField,
  'latitude': LatitudeField,
  'longitude': LongitudeField,
  'id': IdField,
  'title':TextField,
  'last_update': LastUpdateField,
  'create_by': CreateByField,
  'create_at': CreateAtField,
  'geolocalization': GeoLocalizationField,
  'richtext':RichTextField,
  'user': UserField,
  'foreign_key': ForeignKeyField,
  'association_field':TextField
}

