import { useQuery } from "@tanstack/react-query";
import ApiClient from "./api.client";


export const QUERY_KEYS_FIELDS = {
    GET_FIELD_BY_KEY: "GetFieldByKey",
    GET_FIELD_BY_FOREIGN_KEY: "GetFieldByForeignKey",
}


async function GeDataFromField(collection,key) {
    const response = await ApiClient.HttpGet(`fields/${collection}/field/${key}`);
    return response.data;
}

function GetDataFromFieldQuery(collection,key) {
    return useQuery([QUERY_KEYS_FIELDS.GET_FIELD_BY_KEY, [collection,key]], () => GeDataFromField(collection,key),
    {
        enabled: true
    });
}


async function GeDataFromForeignField(collection) {
    const response = await ApiClient.HttpGet(`fields/${collection}/foreign-field`);
    return response.data;
}

function GeDataFromForeignFieldQuery(collection,key) {
    return useQuery([QUERY_KEYS_FIELDS.GET_FIELD_BY_FOREIGN_KEY, [collection]], () => GeDataFromForeignField(collection),
    {
        enabled: true
    });
}



async function CheckFieldBeUnique(request) 
{
    const response = await ApiClient.HttpPost("fields/check-unique-value", request);
    return response.data;
}




export const FieldApiQuery   = {
    GetDataFromFieldQuery,
    GeDataFromForeignFieldQuery,
    CheckFieldBeUnique
}





async function UpdateFieldByKey(element) 
{
    const response = await ApiClient.HttpPost("fields/update", element);
    return response.data;
}



export const FieldApiCommand = {
    UpdateFieldByKey
}