import { EuiFormRow, EuiIcon, EuiSwitch, EuiText, EuiToolTip } from "@elastic/eui";
import { useState } from "react";


function FieldUser({onChangeUserOptions}) {

    const emptyUserOptions = {
        multiple: false,
        notify:false
    }

    const [enableMultipleUser, setenableMultipleUser] = useState(false);
    const [enableNotification, setenableNotification] = useState(false);
    const [userOptions, setUserOptions] = useState(emptyUserOptions);

    

    const onChange = (type,value) => {

        
        const update_options = {
            ...userOptions,
            [type]: value
        }
        
        if(type === 'multiple')
            setenableMultipleUser(value)
        else
            setenableNotification(value)

                
            setUserOptions(update_options)
            onChangeUserOptions(update_options)

    }
    return (
        <>
            <EuiFormRow
                label={
                    <EuiText color="subdued" size="s">
                        Habilitar seleccion de varios usuarios
                    </EuiText>
                }
                fullWidth
                display="rowCompressed"
                labelAppend={
                    <EuiSwitch
                        showLabel={false}
                        label="default value"
                        checked={enableMultipleUser}
                        compressed
                        onChange={() => {
                            onChange("multiple",!enableMultipleUser)
                        }}
                    />
                }
            >
                <></>
            </EuiFormRow>
            <EuiFormRow
                label={
                    <EuiText color="subdued" size="s">
                        Notificar cuando se asignen
                        <EuiToolTip content="Cuando se habilita, se notificará al correo del usuario que se le asignó a un registro.">
                                  <>
                                    <EuiIcon type="iInCircle" />
                                  </>
                                </EuiToolTip>
                    </EuiText>
                }
                fullWidth
                display="rowCompressed"
                labelAppend={
                    <EuiSwitch
                        showLabel={false}
                        label="default value"
                        checked={enableNotification}
                        compressed
                        onChange={() => {
                            onChange("notify",!enableNotification)
                        }}
                    />
                }
            >
                <></>
            </EuiFormRow>
        </>
    );
}

export default FieldUser;