import { EuiSelect } from '@elastic/eui';


export const Select = ({field,error,...others}) => {
    
  
const { name,value } = field;
  
return (
    <EuiSelect
      name={name}
      value={value||''}
      onChange={(e) => field.onChange(e.target.value)}
      isInvalid={!!error}
      compressed={true}   
      fullWidth={false}
      prepend={null}
      append={null}
      autoComplete='off'
      options={null}
      {...others}
    />
)
};