import { EuiButton,   EuiPageHeader,  EuiPageSidebar, EuiPanel, EuiResizableContainer } from "@elastic/eui"
import CollectionHeaderPage from "./components/header"
import Navigator from "packages/navigator/navigator"
import InlineForm from "packages/form/inline"
import useScreenSize from "hooks/useScreenSize"
import { useEffect, useState } from "react"


function CollectionPage() {

    const { windowDimention } = useScreenSize()

    const [fixHeight, setFixHeight] = useState('89.5vh')

    useEffect(() => {

        if (windowDimention.winHeight > 900) {
            setFixHeight('90.5vh')

        } else {
            setFixHeight('90.5vh')
        }


    }, [windowDimention])



    return (
        <>
            <CollectionHeaderPage />

            <EuiResizableContainer
                direction={'horizontal'}
                style={{ height: '100%' }}
            >
                {(EuiResizablePanel, EuiResizableButton) => (
                    <>
                        <EuiResizablePanel
                            mode={[
                                'collapsible',
                                {
                                    className: 'panel-toggle',
                                    position: 'middle',
                                },
                            ]}
                            initialSize={17}
                            minSize="10%"
                            color='plain' hasBorder={false}
                            paddingSize="none"


                        >
                            <EuiPageSidebar paddingSize="none" style={{ backgroundColor: "#FFF" }}>
                                <Navigator />
                            </EuiPageSidebar>
                        </EuiResizablePanel>
                        <EuiResizableButton indicator="border" />
                        <EuiResizablePanel
                            mode="main"
                            initialSize={100}
                            minSize="50px"
                            paddingSize="none"
                            hasBorder={false}
                            grow={true}
                            wrapperPadding="none"
                            color="plain"
                        >

                            <EuiPanel paddingSize="s" hasBorder={false} hasShadow={false} style={{ maxHeight:'89.5vh'}} >
                                <EuiPageHeader
                                    bottomBorder
                                    pageTitle="Datos de la vivienda y del la bra de ciudad"
                                    iconType="documentEdit"
                                    description="This description should be describing the current page as depicted by the page title. It will never extend beneath the right side content."
                                    rightSideItems={[
                                        <EuiButton size="s" fill>Editar</EuiButton>,
                                        ,
                                    ]}
                                />
                                <EuiPanel paddingSize="s" hasBorder={false} hasShadow={false} color="plain" style={{ maxHeight: fixHeight , marginBottom:'10px'}}>
                                    <InlineForm />

                                </EuiPanel>


                            </EuiPanel>

                        </EuiResizablePanel>
                    </>
                )}
            </EuiResizableContainer>

        </>
    )
}

export default CollectionPage