import { EuiDataGridToolbarControl, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiListGroup, EuiListGroupItem, EuiNotificationBadge, EuiPopover, EuiPopoverTitle, EuiToolTip } from "@elastic/eui"
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { useAppColumnManager } from "hooks/useAppColumnManager";
import ColumnsByView from "packages/column-selector/columns-by-view"
import { useState } from "react";


function DataGridToolBarColumnSelector({ totalColumns }) {

    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const columnManager = useAppColumnManager();

    const buttonColumnSelector = (
        <EuiDataGridToolbarControl
            iconType="tableDensityExpanded"
            iconSize="m"
            size="s"
            onClick={() => { setShowColumnSelector(true) }}
        >
            Columnas <EuiNotificationBadge color="subdued">{totalColumns}</EuiNotificationBadge>
        </EuiDataGridToolbarControl>        
    )

    const onchangeOrderColumns = () => {

        setShowColumnSelector(false)
    }

    const iconHelp = (
        <EuiToolTip content='Te brinda la opción de mostrar u ocultar columnas, además de permitirte cambiar su orden' title="Personalización de las columnas" position="right" >
            <EuiIcon type={'questionInCircle'} />
        </EuiToolTip>
    )

    return (
        <EuiPopover
            id={'id-popover-open-columns'}
            button={buttonColumnSelector}
            isOpen={showColumnSelector}
            closePopover={() => { setShowColumnSelector(false) }}
            panelPaddingSize="none"
            hasDragDrop
        >
            <EuiPopoverTitle style={{ textAlign: 'center', padding: '10px' }} >Personalización de las columnas {iconHelp}</EuiPopoverTitle>
            {showColumnSelector &&
                (
                    <>
                        <EuiFlexGroup direction='column' gutterSize="none">
                            <EuiFlexItem className="eui-yScroll" style={{ maxHeight: 300 }}>
                                <ColumnsByView onChange={onchangeOrderColumns} />
                                <EuiHorizontalRule margin="none" />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiListGroup>
                                    <EuiListGroupItem
                                        id="configuration-columns-3"
                                        iconType={ICON_GALLERY.COLUM_SIDEBAR}
                                        label="Configurar columnas"
                                        onClick={() => { columnManager.Show(); setShowColumnSelector(false); }}
                                        size='s'
                                    />
                                </EuiListGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                )

            }



        </EuiPopover>
    )
}

export default DataGridToolBarColumnSelector