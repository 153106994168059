import { useRef, useState } from 'react';
import { EuiConfirmModal } from '@elastic/eui';
import { FilePond, registerPlugin } from 'react-filepond';
import locale_es_co from 'filepond/locale/es-es.js';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import cleanFileNameHelper from './component/file-name.helper';
import { useFilePondServer } from './component/useFilePondServer';


registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginGetFile,
    FilePondPluginFileRename
)



export const UploadField = ({ fieldSchema, error, fieldController }) => {



    let modalConfirmDelete;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleConfirmationRef = useRef(null);
    const fileSizeInMb = fieldSchema.upload_props?.max_file_size * 1048576;

    //TODO: FALTA OBTENER LOS DATOS CUANDO VENGAN DEL SERVIDOR
    //const [files, setFiles] = useState([
    // {
    //     source: "https://21532fff-3b92-4cde-86c6-ea5b082fef29.s3.us-west-1.amazonaws.com/d07ab372-cebc-4430-8eff-4efae1062b89.webp?X-Amz-Expires=600&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA34ROKANWCSTAJORH%2F20231211%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20231211T190439Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b88e0854027915aa422dff3bde810359f0ac0fd014b9a2cca87002308babbb5b2",
    //     options: { type: "local" }
    // },
    // {
    //     source: "https://21532fff-3b92-4cde-86c6-ea5b082fef29.s3.us-west-1.amazonaws.com/d07ab372-cebc-4430-8eff-4efae1062b89.webp?X-Amz-Expires=600&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA34ROKANWCSTAJORH%2F20231211%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20231211T190439Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b88e0854027915aa422dff3bde810359f0ac0fd014b9a2cca87002308babbb5b3",
    //     options: { type: "local" }
    // },
    // {
    //     source: "https://21532fff-3b92-4cde-86c6-ea5b082fef29.s3.us-west-1.amazonaws.com/d07ab372-cebc-4430-8eff-4efae1062b89.webp?X-Amz-Expires=600&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA34ROKANWCSTAJORH%2F20231211%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20231211T190439Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b88e0854027915aa422dff3bde810359f0ac0fd014b9a2cca87002308babbb5b5",
    //     options: { type: "local" }
    // }
    //]);

    const { files,
        setFiles,
        loadFile,
        processFile,
        revertFile,
        removeFile, } = useFilePondServer('storage/upload',[]);


    const onBeforeDeleteFile = (item) => {
        return new Promise((resolve) => {
            setIsModalVisible(true);
            handleConfirmationRef.current = (confirmed) => {

                setIsModalVisible(false)
                resolve(confirmed);
            };


        });
    }

    const onchange = (files) => {

        const list_files = files.map(file => file.filename);
        setFiles(files)
        fieldController.onChange(list_files);
    }


    if (isModalVisible) {
        modalConfirmDelete = (
            <EuiConfirmModal
                title="¿Esta seguro de Eliminar?"
                onCancel={() => { handleConfirmationRef.current(false) }}
                onConfirm={() => { handleConfirmationRef.current(true) }}
                cancelButtonText="Cancelar"
                confirmButtonText="Si"
                defaultFocusedButton="confirm"
                buttonColor='danger'
                titleProps={{
                    size: 'xs'
                }}

            >
            </EuiConfirmModal>
        );
    }




    return (
        <>
            {modalConfirmDelete}
            <FilePond
                name={fieldSchema.id + ''}
                credits={false}
                files={files}
                onupdatefiles={onchange}
                labelButtonDownloadItem="Descargar"
                beforeRemoveFile={onBeforeDeleteFile}
                allowDownloadByUrl={true}
                allowFileTypeValidation={fieldSchema.upload_props?.allowed_extensions > 0}
                allowMultiple={fieldSchema.upload_props.max_files > 1}
                maxFiles={5}
                maxTotalFileSize={fileSizeInMb}
                acceptedFileTypes={fieldSchema.upload_props?.allowed_extensions}
                fileRenameFunction=
                {
                    (file) => {
                        const cleanName = cleanFileNameHelper(file.basename, file.extension);                       
                        return cleanName;
                    }
                }
                server={{
                    load: loadFile,
                    process: processFile,
                    revert: revertFile,
                    remove: removeFile
                }}             

                {...locale_es_co}
            />

        </>


    )
}
