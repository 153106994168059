import { useQueryClient } from "@tanstack/react-query";
import { useAppParameters } from "hooks/useAppParameters";
import { DATAGRID_QUERY_KEYS } from "api/datagrid-api";
import { QUERY_KEYS_COLLECTION } from "api/collections/query-api";
import { QUERY_KEYS_VIEW_BY_ATTRIBUTES } from "../view-attributes-api";
import useSchemasFormStore from "packages/form/state/schemas-store";
import { useDataGridStore } from "packages/datagrid/store/use-datagrid-store";


export function useInvalidateQuery() 
{
    const { view_type, view_id, collection } = useAppParameters();
    const queryClient = useQueryClient();
    const updateGrid = useDataGridStore(state => state.updateGrid);
    const fetch = useSchemasFormStore((state) => state.fetch);


    const InvalidateCurrentView = async () => {
        switch (view_type) {
            case "database":
                queryClient.invalidateQueries([QUERY_KEYS_VIEW_BY_ATTRIBUTES.GET_ATTRIBUTES_BY_VIEW, view_id])//view atributes
                queryClient.invalidateQueries([QUERY_KEYS_COLLECTION.GET_ATTRIBUTES_BY_COLLECTION, collection]) //atrtibutes
                queryClient.invalidateQueries([DATAGRID_QUERY_KEYS.GET_VIEW_BY_HASH])//datagrid
                await fetch(collection);//refresh forms-schema
                updateGrid();
                break;

            default:
                break;

        }

    }

    return { InvalidateCurrentView }


}


