import { Fragment } from "react";
import { CellFieldType } from "./field-type";




const RenderCellComponent = ({ value, row, column, onUpdateValue }) => {

    

    const data_type = column.data_type;
    
    const RenderComponent = CellFieldType[data_type];

    const OnChangeValue = (value) => {
        // console.log("value", value)
        onUpdateValue(value)
    }

    return (<Fragment>
        <RenderComponent
            value={value}
            row={row}
            column={column}
            onChangeValue={OnChangeValue}
        />
    </Fragment>);

};

export default RenderCellComponent;