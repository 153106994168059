import { EuiBadge } from "@elastic/eui";



const ComboBoxRenderer = ({ value, row, column }) => {

    const options = value ? value.split(',') : [];
    const response_options = column.options && JSON.parse(column.options);
    const props = JSON.parse(column.props) || {};
    const enable_colores = props.enable_color_picker;

    const getColor = (response, enableColorPicker) => {
        
        if (enableColorPicker) {
            const color_response = response_options.find(obj => obj.value === response);
            return color_response ? color_response.color : 'hollow';

        } else { return 'hollow'; }

    };

    return (<>{
        options.map((valor, index) => (
            <EuiBadge color={getColor(valor, enable_colores)} key={index}>{valor}</EuiBadge>
        ))}
    </>)
}

export default ComboBoxRenderer;