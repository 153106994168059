import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiPanel } from "@elastic/eui"
import { QUERY_KEYS_ATTRIBUTE, AttributeApiCommand, AttributeApiQuery } from "api/attributes-api";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { useInvalidateQuery } from "api/invalidate-querys/invalidate-current-views";
import NotifySucces from "components/notifications/notify-success";
import { PATTERN_VALIDATOR } from "core/Patterns";
import { useEffect, useState } from "react";
import { useGlobalCallOutNotificationStore } from "store/global-callout-notification-store";



function TitleFieldEditForm({ data, collection }) {

    const EmptyNumericProps = {
        allow_thousands_separator: false,
        decimal_scale: 0,
        mask: '',
        format: '',
        allow_negative: false,
        max_length: '12',
        type: 'number',
        prefix: '',
    };

    const emptyUploadProps = {
        max_files: 1,
        max_file_size: 5,
        allowed_extensions: ['image/*']
    }

    const [value, setvalue] = useState(data.name || "");
    const [onSuccess, setOnSuccess] = useState(false);
    const invalidateQueries = useInvalidateQuery();
    const { InvalidateQuery: InvalidateQueryByKey } = useInvalidateQueryByKey([QUERY_KEYS_ATTRIBUTE.GET_ATTRIBUTE_BY_KEY]);
    const showNotificationError = useGlobalCallOutNotificationStore((state) => state.showNotificationError);
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const IsNotSpecialCharactersInputName = (value) => {
        const response = PATTERN_VALIDATOR.NOT_SPECIAL_CHARACTERS(String(value).toLowerCase());

        if (response) {
            setErrorMessage('No se permiten caracteres especiales : @#$%^&*()_+\-=[\]{};¨');
            setIsInvalid(true);
            return false;
        } else {
            setIsInvalid(false);
            return true;
        }
    };

    const ValidateIsDuplicate = async (name) => {

        if (name === data.name) {
            return true;
        }

        const check = { name: name.trim(), collection_id: collection };
        const response = await AttributeApiQuery.BeUniqueAttributeName(check);

        if (response.data) {
            return false;

        } else {
            return true;
        }

    };
    const OnUpdate = async () => {

        setIsLoading(true)
        if (!value) {
            setIsInvalid(true)
            setIsLoading(false)
            return;
        }




        if (IsNotSpecialCharactersInputName(value)) {

            const is_not_duplicate = await ValidateIsDuplicate(value);

            if (is_not_duplicate) {


                const update_title = data;
                update_title.name = value;
                update_title.rules = null;
                update_title.numeric_props = EmptyNumericProps;
                update_title.upload_props = emptyUploadProps;

                let field = { AttributeItem: update_title };

                const response = await AttributeApiCommand.UpdateAttribute(field);

                if (response.data) {
                    setOnSuccess(true);
                    invalidateQueries.InvalidateCurrentView();
                    InvalidateQueryByKey();

                } else {
                    showNotificationError('No se actualizo', 'Ocurrio un algo inesperado', response.message);

                }
            } else {
                setErrorMessage('Ya existe una columna con este nombre');
                setIsInvalid(true);
            }
        }

        setIsLoading(false)
    }

    const onChange = (e) => {
        setvalue(e.target.value);
    }

    useEffect(() => {
        setvalue(data.name);
    }, [data])

    return (
        <>
            <EuiPanel hasShadow={false}>
                {onSuccess && (
                    <NotifySucces
                        title={'Columna actualizada'}
                        description={''} conffeti={undefined} />
                )}
                {!onSuccess && (
                    <EuiForm component="form"  >
                        <EuiFormRow label="Columna de titulo de la base de datos" isInvalid={isInvalid} helpText="Escribe solo textos y números" error={errorMessage} >
                            <EuiFieldText
                                name="update_title"
                                isInvalid={isInvalid}
                                placeholder=""
                                value={value}
                                compressed
                                fullWidth
                                onChange={onChange}
                            />
                        </EuiFormRow>
                        <EuiButton isLoading={isLoading} onClick={OnUpdate} fill fullWidth>Aplicar cambios</EuiButton>

                    </EuiForm>
                )}
            </EuiPanel>
        </>
    )

}

export default TitleFieldEditForm