import {
    EuiContextMenu,
    EuiTextTruncate,
    EuiEmptyPrompt,
} from '@elastic/eui';
import { CollectionApiQuery } from 'api/collections/query-api';
import ProgressPanel from 'components/progress/progress-whit-panel';
import { useAppParameters } from 'hooks/useAppParameters';
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';
import CreateCollection from 'pages/collection/create-collection';
import Title from './title';



function DataGridExplorerDataBasePanel() {

    const { collection, view_id, view_name } = useAppParameters();
    const { data, isLoading = true } = CollectionApiQuery.GetCollectionNavigator(collection);
    const navigator = useAppRouteNavigator();




    if (isLoading) {
        return <ProgressPanel paddingSize='m' />
    }



    const panels = [
        {
            id: 0,
            title:
                <Title
                    data={data && data}

                />
            ,
            items: []
        },

        {
            id: 1,
            title: 'Creación de tabla',
            content: <CreateCollection />,
        }
    ];


    const views = data?.data?.views || [];

    
 

    if (views.length > 0) {
        views.forEach((view, i) => {
            panels[0].items.push(
                {
                    name: (
                        <EuiTextTruncate
                            key={i}
                            text={view.view_name || ''}
                        />
                    ),
                    icon: view.view_icon,
                    onClick: () => {
                        navigator.GotoViewWithName(view.collection_id, view.view_hash_id, 'database', view.view_name)
                    },
                    toolTipContent:`Puedes registrar ${view.multiple_records ? 'varios registros' : 'un registro'}  de ${view.view_name || ''}` ,
                    toolTipProps: {
                        title: `${view.multiple_records ? 'Entradas multiples' : 'Entrada única'}`,
                        position: 'right',
                    },
                },
                {
                    isSeparator: true,
                    key: `sep-${i}`,
                }
            );
        });
    } else {

        panels[0].items.push(

            {
                name: 'No hay tablas',
                renderItem: () => (
                    <EuiEmptyPrompt
                        iconType="tableDensityNormal"
                        iconColor="default"
                        title={<h2>No hay tablas adicionales</h2>}
                        titleSize="xs"
                        body={<p>Agrega una nueva tabla para extender la base de datos</p>}
                        actions={[]}
                    />
                ),
            },
            {
                isSeparator: true,
                key: `sep-empty`,
            },
        );
    }

    panels[0].items.push(
        {
            name: 'Agregar una nueva tabla',
            icon: 'plusInCircle',
            panel: 1,
        }
    );



    return (

        <EuiContextMenu
            initialPanelId={0}
            panels={panels}

        />



    );
};

export default DataGridExplorerDataBasePanel;