export const GLOBLAL_KEY_NANO_ID_LENGT = 15;

const PORT = "44375";

export const GLOBAL_KEY_BASE_URL = "https://onespace.arcoirisapp.co/api/v1/";
//export const GLOBAL_KEY_BASE_URL =  "https://localhost:" + PORT + "/v1/"; 


/* keys for local storage */
export const GLOBAL_KEY_EMOJI_SET = "native";

/* keys for local storage */
export const DEBUG_FECTH = false;
export const KEY_GOOGLE_MAPS = 'AIzaSyC9uREjkAynY9GeM2zUoP40-Wz2XETjM-I';
export const ERROR_MESSAJE_DETAIL = 'La tarea no se pudo completar, intenta nuevamente o comunicate el centro de ayuda.'
export const KEY_URL_SUPPORT = 'https://www.arcoirisap.co'



