import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiPortal,  EuiSkeletonText } from "@elastic/eui"
import { AttributeApiQuery } from "api/attributes-api";
import FormFieldCreator from "packages/field-creator"
import { useState } from "react";


function ModalFieldEditor({ collection, isOpen, closeModal, columToEdit }) {

    const [showModal, setShowModal] = useState(isOpen);
    const {data, isLoading} = AttributeApiQuery.GetAttributeByKey(collection,columToEdit);

    const onClose = () => {
        setShowModal(false);
        closeModal();
    }

    
    return (
        <>
            {showModal && 
            (
                <>
                <EuiSkeletonText lines={3} size="m" isLoading={isLoading} contentAriaLabel="Loading field" >
                    <EuiPortal>
                        <EuiModal onClose={onClose}  initialFocus="[name=name-field-creator]" >
                            <EuiModalHeader>
                                <EuiModalHeaderTitle>Edición de columna</EuiModalHeaderTitle>
                            </EuiModalHeader>
                            <FormFieldCreator
                                collection={collection}
                                compressed={false}
                                action='update'
                                data={data && data.data}
                            />
                        </EuiModal>
                    </EuiPortal>
                    </EuiSkeletonText>
                </>
            )}
        </>

    )
}

export default ModalFieldEditor