import { EuiDataGridToolbarControl, EuiPopover } from "@elastic/eui";


function DataGridToolBarDownload() {
    return (
        <EuiPopover
            id={'id-popover-download'}
            button={
                <EuiDataGridToolbarControl
                    iconType="download"
                    size="s"
                    onClick={() => { }}
                >
                    Descargar
                </EuiDataGridToolbarControl>
            }
            isOpen={false}
            closePopover={() => { }}
            panelPaddingSize="none"
        >

        </EuiPopover>
    )
}

export default DataGridToolBarDownload