import React, { useEffect, useState } from 'react';
import {
    EuiButtonGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiSuperSelect,
    EuiSwitch,
} from '@elastic/eui';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import { useGlobalConfiguration } from 'hooks/useGlobalConfiguration';



function FieldNumericFormatSelector({ OnChangeFieldFormatSelector, data }) {


    console.log("data", data)

    const { separatorThousands } = useGlobalConfiguration();

    const toggleButtonsFormat = [
        {
            id: 'number',
            label: 'Numerico',
            iconType: ICON_GALLERY.NUMERIC
        },

        {
            id: 'money',
            label: 'Moneda',
            iconType: ICON_GALLERY.MONEY
        },
        {
            id: 'percent',
            label: 'Porcentaje',
            iconType: ICON_GALLERY.PERCENT
        },

    ];

    let numberDecimals = [{
        value: 'none',
        inputDisplay: 'Ninguno',
    }];

    let numberDigits = [
        {
            value: '1',
            inputDisplay: "1 (0)",
        },
        {
            value: '2',
            inputDisplay: "2 (00)",
        },
        {
            value: '3',
            inputDisplay: "3 (000)",
        },
        {
            value: '4',
            inputDisplay: "4 (0" + separatorThousands + "000)",
        },
        {
            value: '5',
            inputDisplay: "5 (00" + separatorThousands + "000)",
        }, {
            value: '6',
            inputDisplay: "6 (000" + separatorThousands + "000)",
        }, {
            value: '7',
            inputDisplay: "7 (0" + separatorThousands + "000" + separatorThousands + "000)",
        }, {
            value: '8',
            inputDisplay: "8 (00" + separatorThousands + "000" + separatorThousands + "000)",
        }, {
            value: '9',
            inputDisplay: "9 (000" + separatorThousands + "000" + separatorThousands + "000)",
        },
        {
            value: '10',
            inputDisplay: "10 (0" + separatorThousands + "000" + separatorThousands + "000" + separatorThousands + "000)",
        },
        {
            value: '11',
            inputDisplay: "11 (00" + separatorThousands + "000" + separatorThousands + "000" + separatorThousands + "000)",
        },
        {
            value: '12',
            inputDisplay: "12 (000" + separatorThousands + "000" + separatorThousands + "000" + separatorThousands + "000)",
        },
    ]

    let values = '';

    for (let i = 0; i < 4; i++) {
        values += '0';
        const indice = i + 1;
        numberDecimals.push(
            {
                value: values,
                inputDisplay: indice + ' (' + values + ')',
            }
        );
    }

    const emptyFormat = {
        type: 'number',
        allow_thousands_separator: false,
        decimal_scale: 0,
        mask: '',
        format: '',
        allow_negative: false,
        max_length: '12',
        prefix: '',
    };

    const [typeFormat, settypeFormat] = useState(data?.type || 'number');
    const [numberDecimail, setNumberDecimal] = useState(data?.decimal_scale || numberDecimals[0].value);
    const [numberDigit, setNumberDigit] = useState(numberDigits[11].value);
    const [checked, setChecked] = useState(data?.allow_thousands_separator || false);//allow_separator_thousands
    const [enableAllowSeparatorThousands, setEnableAllowSeparatorThousands] = useState(true);
    const [allow_negative, setAllow_negative] = useState(data?.allow_negative || false);
    const [format, setFormat] = useState(emptyFormat)


    const onChangeNumberDecimal = (value) => {

        const total_decimals = value === 'none' ? 0 : value.length
        const update_format = {
            ...format,
            decimal_scale: total_decimals
        }
        setAndUpdateFormat(update_format)
        setNumberDecimal(value);

    };



    const onChangeNumberDigit = (value) => {
        const update_format = {
            ...format,
            max_length: value
        }


        if (["1", "2", "3"].includes(value)) {
            setChecked(false)
            setEnableAllowSeparatorThousands(false)
        } else {

            setEnableAllowSeparatorThousands(true)
        }

        setAndUpdateFormat(update_format)
        setNumberDigit(value);
    }


    const onChangeAllow_negative = (e) => {
        const update_format = {
            ...format,
            allow_negative: e.target.checked
        }
        setAndUpdateFormat(update_format)
        setAllow_negative(e.target.checked);

    };

    const onChange = (e) => {
        const update_format = {
            ...format,
            allow_thousands_separator: e.target.checked
        }
        setAndUpdateFormat(update_format)
        setChecked(e.target.checked);

    };

    const onChangetypeFormat = option => {

        const update_format = {
            ...format,
            type: option
        }
        setAndUpdateFormat(update_format)
        settypeFormat(option);

    };

    const setAndUpdateFormat = update_format => {

        setFormat(value => ({
            ...value,
            ...update_format
        }))

        OnChangeFieldFormatSelector(update_format)
    }


    useEffect(() => {
        if (data) 
        {
            const total_decimal = data?.decimal_scale || 0
            const total_decimal_scale = total_decimal === 0 ? 'none' : total_decimal.toString().padEnd(total_decimal + 1, '0').slice(1);
            setNumberDecimal(total_decimal_scale);

            const total_number_digit = data?.max_length || 12
            setNumberDigit(numberDigits[total_number_digit - 1].value);


        }
    }, [])

    return (
        <div key='field-format-selector' style={{ maxWidth: '520px' }}>
            <EuiFlexGroup >
                <EuiFlexItem>
                    <EuiFormRow
                        id="formato"
                        label='Formato'
                        fullWidth
                        display="rowCompressed"
                    >
                        <EuiButtonGroup
                            legend="Formato"
                            className="eui-displayInlineBlock"
                            options={toggleButtonsFormat}
                            idSelected={typeFormat}
                            color='primary'
                            onChange={(id) => onChangetypeFormat(id)}
                            buttonSize="compressed"
                            isIconOnly
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow
                        label=''
                        id="separadormiles"
                        fullWidth
                        display="rowCompressed"
                        hasEmptyLabelSpace={true}
                    >
                        <><EuiSwitch
                            labelProps={{
                                style: {
                                    whiteSpace: 'nowrap',
                                },
                            }}
                            id='thousand'
                            label="Mostrar separador de miles"
                            checked={checked}
                            onChange={(e) => onChange(e)}
                            compressed
                            disabled={!enableAllowSeparatorThousands}

                        />
                            <EuiSwitch
                                labelProps={{
                                    style: {
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                                id='allownegative'
                                label="Permitir valores negativos"
                                checked={allow_negative}
                                onChange={(e) => onChangeAllow_negative(e)}
                                compressed
                            />
                        </>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="m" />
            <EuiFlexGroup >
                <EuiFlexItem>
                    <EuiFormRow
                        id="numerodigitos"
                        label='Número de digitos'
                        display="rowCompressed"                    >
                        <EuiSuperSelect
                            fullWidth
                            style={{ width: '100%' }}
                            options={numberDigits}
                            valueOfSelected={numberDigit}
                            onChange={(value) => onChangeNumberDigit(value)}
                            compressed
                        />
                    </EuiFormRow>

                </EuiFlexItem>



                <EuiFlexItem>
                    <EuiFormRow
                        id="numerodedecimales"
                        label='Número de decimales'
                        fullWidth
                        display="rowCompressed"
                    >
                        <EuiSuperSelect
                            options={numberDecimals}
                            valueOfSelected={numberDecimail}
                            onChange={(value) => onChangeNumberDecimal(value)}
                            compressed
                        />
                    </EuiFormRow>

                </EuiFlexItem>

            </EuiFlexGroup>
        </div>

    )
}

export default FieldNumericFormatSelector

