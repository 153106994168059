import ApiClient from "./api.client";


async function GetConfiguration(option)
{
    const response = await ApiClient.HttpGet(`configuration/get/${option}`);	  
    return response.data;    
  
}

async function UpdateConfiguration(parameters)
{
    const response = await ApiClient.HttpPost("configuration/update/",parameters);  
    return response.data;   
}


const ConfigurationApi = {
    GetConfiguration,
    UpdateConfiguration
}
    

export default ConfigurationApi;