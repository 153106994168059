import { EuiButtonIcon, EuiHorizontalRule, EuiListGroup, EuiListGroupItem, EuiPopover, EuiToolTip } from "@elastic/eui";
import DangerButton from "components/buttons/danger-button";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { useAppColumnManager } from "hooks/useAppColumnManager";
import { useState } from "react";




function DataGridToolBarConfiguration() {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const closePopover = () => setIsPopoverOpen(false);
    const columnManager = useAppColumnManager();

    const openConfiguration = () => {
        setIsPopoverOpen(true)
    }

    const buttonConfiguration = (
        <>
            <EuiToolTip title="Configurar la base de datos"
                content="Administrar la configuración de la base de datos actual" position='left' >
                <EuiButtonIcon
                    iconType={'gear'}
                    onClick={openConfiguration}
                    aria-label="opciones de la configuración"
                    id='view-configuration-collection'
                    color='text'
                    display='base'
                    style={{ marginLeft: 5 }}

                />
            </EuiToolTip>
        </>

    );


    return (
        <EuiPopover
            button={buttonConfiguration}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="xs">
            <EuiListGroup maxWidth={260}>
                <EuiListGroupItem
                    id="configuration-columns-3"
                    iconType={ICON_GALLERY.COLUM_SIDEBAR}
                    label="Configurar columnas"
                    onClick={() => {
                        columnManager.Show();
                        closePopover();
                    }}
                    size='s'
                />
                <EuiListGroupItem
                    id="rules-columns-4"
                    iconType={ICON_GALLERY.ROBOT}
                    label="Automatización"
                    onClick={() => { }}
                    size='s'

                />
                <EuiListGroupItem
                    id="user-permissions-4"
                    iconType="user"
                    label="Permisos y accesos"
                    onClick={() => { }}
                    size='s'

                />
                <EuiListGroupItem
                    id="proteted-view-5"
                    iconType="lock"
                    label="Protejer vista"
                    onClick={() => { }}
                    size='s'

                />
                <EuiHorizontalRule margin="xs" />
                <DangerButton text="Eliminar base de datos" color="danger" onClick={() => { }} />
            </EuiListGroup>
        </EuiPopover>



    )
}

export default DataGridToolBarConfiguration
