import { EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiListGroup, EuiListGroupItem, EuiTextColor, EuiTitle, EuiToolTip, useEuiTheme, useGeneratedHtmlId } from '@elastic/eui'
import React, { useState } from 'react'
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';
import AppNavButtomAddComponent from './nav-app-add-view/Index';
import EmojiMart from 'components/emoji/emoji';
import { useGlobalStore } from 'store/global-store';
import { css } from '@emotion/react';
import { useAppParameters } from 'hooks/useAppParameters';



function AppNavItemApp({ app, iscollapse }) {


  const idHtml = useGeneratedHtmlId();

  const navigator = useAppRouteNavigator();
  const { euiTheme } = useEuiTheme();
  const currentTheme = useGlobalStore((state) => state.theme);
  const [showAddButton, setShowAddButton] = useState(false);

  const { collection: current_collection_id, app: current_app_id } = useAppParameters();

  const current_url = current_collection_id ? current_collection_id : current_app_id;
  const current_url_to_compare = current_collection_id ? app.collection_id : app.hash_id;

  const styleOnChangeHover = css`
  text-decoration: none !important;
  background-color: ${current_url === current_url_to_compare ? currentTheme === 'light' ? euiTheme.colors.emptyShade : euiTheme.colors.lightShade : null};
  border-radius: 5px;
  /* border: ${current_url === current_url_to_compare ? '1px solid #D3DAE6' : null};*/
  &:hover {
    background-color: ${currentTheme === 'light' ? euiTheme.colors.emptyShade : euiTheme.colors.lightShade};
    border-radius: 5px;  
  }
`;

  const apps_views = app.views.filter(view => view.hash_id !== app.default_view);


  const openView = (view_has_id) => {

    navigator.GotoView(app.collection_id, view_has_id, app.default_view_type);


  }


  const label_app = app.status ? app.name : <EuiTextColor color="warning"><b>{app.name}</b></EuiTextColor>
  const label_app_title = app.status ? app.name : app.name + ' (inactiva)';


  const buttonApp = (
    <>
      <EuiFlexGroup gutterSize="xs" alignItems="center" responsive={false} wrap>
        <EuiFlexItem grow={2}>
          <EuiToolTip content={label_app_title} position="right" display="block" >
            <div style={{ paddingTop: 2, paddingLeft: iscollapse ? 13 : 14 }}>
              <EmojiMart id={app.status ? app.emoji : 'warning'} onClick={() => openView(app.default_view)} />
            </div>
          </EuiToolTip>
        </EuiFlexItem>
        <EuiFlexItem style={{ maxWidth: 120 }}>
          {!iscollapse ? (
            <EuiTitle size="xxs">
              <h4 className="truncate-145" onClick={() => openView(app.default_view)} style={{ fontWeight: 500, paddingTop: 5 }} title={label_app_title}>{iscollapse ? '' : label_app}</h4>
            </EuiTitle>
          ) : (null)}

        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );


  return (
    <EuiAccordion
      id={idHtml}
      key={idHtml}
      arrowDisplay="none"
      buttonClassName='remove-underline'
      style={{ borderRadius: 8 }}
      buttonContent={buttonApp}
      paddingSize="none"
      css={styleOnChangeHover}
      onMouseOver={() => setShowAddButton(true)}
      onMouseOut={() => setShowAddButton(false)}
      extraAction={!iscollapse && <AppNavButtomAddComponent apphash={app.hash_id} showAddButton={showAddButton} />}

    >
      { }
      {apps_views && (
        <EuiListGroup gutterSize='none' flush  >
          {apps_views.map(item => {
            return (
              item.view_type !== 'database' && (
                <>
                  <EuiListGroupItem
                    key={'item-' + item.hash_id}
                    style={{ marginLeft: iscollapse ? 8 : 8, marginRight: 9 }}
                    onClick={() => openView(item.hash_id)}
                    iconType={item.icon}
                    iconProps={{
                      css: { marginRight: 8 }
                    }}
                    css={styleOnChangeHover}
                    label={item.name}
                    showToolTip={iscollapse ? true : false}
                    extraAction={{
                      color: 'text',
                      onClick: () => { },
                      iconType: 'starEmpty',
                      iconSize: 's',
                      'aria-label': 'Favorite',
                      alwaysShow: false,
                    }}

                  /></>
              )

            )
          })
          }
        </EuiListGroup>

      )}

    </EuiAccordion>
  )
}

export default AppNavItemApp