import React from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from '@elastic/eui'
import IconFieldType from 'components/icons/icons-fields-type';



function Options({ type, label, description }) {


  return (
      <EuiFlexGroup gutterSize='none' alignItems='center' responsive={false} justifyContent="flexStart">
        <EuiFlexItem grow={1}>
          <IconFieldType iconType={type} color='subdued' />
        </EuiFlexItem>
        <EuiFlexItem grow={9}>
        <EuiToolTip content={description} position="right">
          <EuiText color="subdued" size="xs"><b>{label}</b></EuiText>
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
      )
}

export default Options