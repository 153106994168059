import { EuiCheckbox } from "@elastic/eui"

const CheckBoxRenderer = ({ value, row, column }) => {

    
    return (
        <EuiCheckbox
            disabled
            id={'checkbox-' + row.id}
            checked={value}
            onChange={() => { }}
            compressed
        />)
}

export default CheckBoxRenderer