import { EuiListGroup, EuiListGroupItem, EuiToolTip, useEuiTheme } from '@elastic/eui'
import { css } from '@emotion/react';
import { ICON_GALLERY } from 'components/icons/icons-gallery'
import React from 'react'
import { useGlobalStore } from 'store/global-store';
import { KEYS_STORE } from 'store/keys-store';
import useVisibilityStore from 'store/visibility-store';


function AppSideNavTop({ iscollapse, navigate }) {

  const { euiTheme } = useEuiTheme();
  const currentTheme = useGlobalStore((state) => state.theme);
  const { setComponentVisibility, componentVisibility } = useVisibilityStore();


  const showPanelNotification = () => {
    const componentName = KEYS_STORE.VISIBILITY_COMPONENT.NOTIFICATION_PANEL;
    const isVisible = !componentVisibility[componentName];
    setComponentVisibility(componentName, isVisible);

  }

  const styleOnChangeHover = css`
  &:hover {
    background-color: ${currentTheme === 'light' ? euiTheme.colors.emptyShade : euiTheme.colors.lightShade};
  }
`;

  return (
    <EuiListGroup flush={false} bordered={false} gutterSize="none">
      <EuiToolTip content={iscollapse ? 'Pagina Principal' : ''} position="right" display="block" id='tooltip-home' >
        <EuiListGroupItem
          id="home-1"
          iconType={ICON_GALLERY.HOME}
          iconProps={{ size: 'm' }}
          aria-label='home'
          label={!iscollapse ? 'Pagina Principal' : ''}
          onClick={() => { navigate("/home") }}
          css={styleOnChangeHover}


        />
      </EuiToolTip>
      <EuiToolTip content={iscollapse ? 'Notificaciónes' : ''} position="right" display="block" id='tooltip-notification' >
        <EuiListGroupItem
          id="notification-icon-1"
          className="remove-text-decoration"
          iconType="bell"
          aria-label='-'
          label={!iscollapse ? 'Notificaciónes' : ''}
          onClick={showPanelNotification}
          css={styleOnChangeHover}

        />
      </EuiToolTip>
      <EuiToolTip content={iscollapse ? 'Mis tareas' : ''} position="right" display="block" id='tooltip-task'>
        <EuiListGroupItem
          id="my-task-1"
          aria-label='-'
          className="remove-text-decoration"
          iconType="check"
          label={!iscollapse ? 'Mis tareas' : ''}
          onClick={() => { }}
          css={styleOnChangeHover}

        />
      </EuiToolTip>
      <EuiToolTip content={iscollapse ? 'Favoritos' : ''} position="right" display="block" id='tooltip-portal' >
        <EuiListGroupItem
          id="portal"
          aria-label='-'
          className="remove-text-decoration"
          iconType={ICON_GALLERY.FAVORITE}
          label={!iscollapse ? 'Favoritos' : ''}
          onClick={() => { }}
          css={styleOnChangeHover}

        />
      </EuiToolTip>
      <EuiToolTip content={iscollapse ? 'Mis tableros de control' : ''} position="right" display="block" id='tooltip-dashboard' >
        <EuiListGroupItem
          id="portal"
          aria-label='-'
          className="remove-text-decoration"
          iconType={ICON_GALLERY.DASHBOARD}
          label={!iscollapse ? 'Tableros' : ''}
          onClick={() => { }}
          css={styleOnChangeHover}

        />
      </EuiToolTip>


    </EuiListGroup>
  )
}

export default AppSideNavTop