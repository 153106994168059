
import ApiClient from "./api.client";


async function UploadBrandingLogo(file){
      
  const response = await ApiClient.HttpUpload("storage/upload/logo",file,{});  
  return response.data; 

}


async function UploadBrandingLogin(file){
      
  const response = await ApiClient.HttpUpload("storage/upload/login",file,{});  
  return response.data; 

}

async function GetSubDomainImage(){

  const response = await ApiClient.HttpGet("subscription/subdomain");  
  return response.data;  
}



async function GetHasBranding(){  
 
  const response = await ApiClient.HttpGet("subscription/branding");  
  return response.data; 
}


async function GetLimit(type){  
 
  const response = await ApiClient.HttpGet(`subscription/limit?type=${type}`);  
  return response.data; 

}

async function GetBrandingLogo(){  
 
  const response = await ApiClient.HttpPost("subscription/logo");  
  return response.data; 

}

const SubscriptionApi = { 
  UploadBrandingLogo,
  UploadBrandingLogin,
  GetSubDomainImage,
  GetHasBranding,
  GetLimit,
  GetBrandingLogo 
};

export default SubscriptionApi;
