import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiPortal,  EuiSkeletonText } from "@elastic/eui"
import { AttributeApiQuery } from "api/attributes-api";
import { useState } from "react";
import TitleFieldEditForm from "./title-field-form";


function ModalTitleFieldEditor({ collection, isOpen, closeModal, columToEdit }) {

    const [showModal, setShowModal] = useState(isOpen);
    const {data, isLoading} = AttributeApiQuery.GetAttributeByKey(collection,columToEdit);

    const onClose = () => {
        setShowModal(false);
        closeModal();
    }

    
    return (
        <>
            {showModal && 
            (
                <>
                <EuiSkeletonText lines={3} size="m" isLoading={isLoading} contentAriaLabel="Loading field">
                    <EuiPortal>
                        <EuiModal onClose={onClose} initialFocus="[name=update_title]" >
                            <EuiModalHeader>
                                <EuiModalHeaderTitle>Edición de columna</EuiModalHeaderTitle>
                            </EuiModalHeader>
                            <TitleFieldEditForm data={data && data.data} collection={collection}/>
                        </EuiModal>
                    </EuiPortal>
                    </EuiSkeletonText>
                </>
            )}
        </>

    )
}

export default ModalTitleFieldEditor