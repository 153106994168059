import { EuiPagination, EuiPanel } from "@elastic/eui";



function DataGridPagination({limit, pagination, onChangePage}) {


    return (
        pagination.total > limit && (
        <EuiPanel borderRadius="m" paddingSize="none" color="subdued" className="data-grid-floating-pagination ">
            <EuiPagination
                aria-label="pagination data grid"
                pageCount={Math.ceil(pagination.total / pagination.pageSize)}
                activePage={pagination.pageIndex}
                onPageClick={(activePage) => onChangePage(activePage)}
                compressed
                color="subdued"

            />
        </EuiPanel>
        )
    )
}


export default DataGridPagination