import { EuiI18n } from "@elastic/eui"

const button_save = (<EuiI18n token="global.save" default="Guardar" />)
const company_name = (<EuiI18n token="company.label.name" default="Nombre de la organización" />)
const label_required=(<EuiI18n token="global.required" default="El nombre de la organización es requerido" />)
const company_email = (<EuiI18n token="company.label.email" default="Correo electrónico" />)
const company_country = (<EuiI18n token="company.label.country" default="Pais" />)
const company_postal_code = (<EuiI18n token="company.label.postalcode" default="Código Postal" />)
const company_address = (<EuiI18n token="company.label.address" default="Dirección" />)
const company_phone = (<EuiI18n token="company.label.phone" default="Telefono" />)
const company_delete_title = (<EuiI18n token="company.label.delete" default="Borrar cuenta" />)
const company_warning_title = (<EuiI18n token="company.label.warning" default="Esta opción elimina definitivamente tu cuenta y no hay forma de recuperar la información. " />)
const company_warning = (<EuiI18n token="company.title.warning" default="Procede con cuidado! " />)
const title_succes = (<EuiI18n token="global.save.change" default="Se actualizo la información " />)

const i18n ={ label_required,button_save,title_succes, company_name, company_email, company_country, company_postal_code, company_address, company_phone, company_delete_title, company_warning_title, company_warning }

export default i18n;
