import React, { useState } from 'react'
import { EuiButtonIcon, EuiCollapsibleNavGroup, EuiFlexGroup, EuiFlexItem, EuiToolTip } from '@elastic/eui'
import { useNavigate } from 'react-router-dom'
import { useGlobalStore } from 'store/global-store';
import { AuthManager } from 'api/auth-api';


function AppNavFooter({ iscollapse, onCollapse }) {

  const setTheme = useGlobalStore((state) => state.setTheme);
  const currentTheme = useGlobalStore((state) => state.theme);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate()

  const changecollapse = () => {
    onCollapse();
  }

  const closeSession = async () => {
    setIsLoading(true);
    await AuthManager.CloseSession();

    navigate('/Auth');
  }

  return (
    <EuiCollapsibleNavGroup>
      <EuiFlexGroup responsive={false} gutterSize="s" alignItems="flexStart">
        <EuiFlexItem >
          <EuiToolTip content={'Colapsar barra de navegación'} position="right" display="block" >
            <EuiButtonIcon color='text' iconType={iscollapse ? "arrowRight" : "arrowLeft"} aria-label="collap" onClick={changecollapse} />
          </EuiToolTip>
        </EuiFlexItem>

        {!iscollapse && (
          <>
            <EuiFlexItem >
              <EuiToolTip content={'Cambiar modo oscuro'} position="right" display="block" >
                <EuiButtonIcon
                  iconType={currentTheme === 'dark' ? "sun" : "moon"}
                  color={currentTheme === 'dark' ? "warning" : "text"}
                  aria-label="sun"

                  onClick={() => { setTheme(currentTheme === 'light' ? 'dark' : 'light') }}
                />
              </EuiToolTip>

            </EuiFlexItem>
            <EuiFlexItem>
              <EuiToolTip content={'Soporte tecnico'} position="right" display="block" >
                <EuiButtonIcon
                  iconType="help"
                  aria-label="Delete"
                  color='text'
                />
              </EuiToolTip>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiToolTip content={'Configuración de la suscripción'} position="right" display="block" >
                <EuiButtonIcon iconType="gear" aria-label="Lens" color='text' onClick={() => { navigate("/subscription/organization") }} />
              </EuiToolTip>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiToolTip content={'Mi cuenta'} position="right" display="block" >
                <EuiButtonIcon iconType='user' aria-label="Lens" color='text' />
              </EuiToolTip>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiToolTip content={'Cerrar sesion'} position="right" display="block" >
                <EuiButtonIcon iconType='exit' isLoading={isLoading} aria-label="Lens" color='text' onClick={closeSession} />
              </EuiToolTip>
            </EuiFlexItem>
          </>
        )}




      </EuiFlexGroup>
    </EuiCollapsibleNavGroup>

  )
}

export default AppNavFooter