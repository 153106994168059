
export const getSubDomain=()=>{
    var subdomain;
    if(window.location.host.split('.').length===2)
            subdomain=window.location.host.split('.')[0]
    else
        subdomain="none";

    return subdomain;

}

/**
 * Replace Current pathname V for D for detail view toolbar
 * @returns 
 */
export const getUrlDetailPage=()=>{
    const currentUrl = window.location.pathname;
    const app= currentUrl.split('/')[2];
    const collection = currentUrl.split('/')[3];
    const view_type= currentUrl.split('/')[5];
    const hash = currentUrl.split('/')[6];
    const newUrl = `/app/${app}/${collection}/d/${view_type}/${hash}/detail`;
       return newUrl;

}


export const URL = {
    getCurrent(){
        const currentUrl = window.location.pathname;
        const app= currentUrl.split('/')[2];
        const collection = currentUrl.split('/')[3];
        const toolbar = currentUrl.split('/')[4];
        const view_type= currentUrl.split('/')[5];
        const hash = currentUrl.split('/')[6];
        const newUrl = `/app/${app}/${collection}/${toolbar}/${view_type}/${hash}`;
        return newUrl;
    }
}
