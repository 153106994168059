import ApiClient from "./api.client";

export const QUERY_KEYS_WORKSPACE={
  GET_WORKSPACE_APPS : "GetWorkSpaceApps",  
  GET_LIST_WORKSPACE : "GetListWorkspace",
}




async function Create(workspace)
{
      const response = await ApiClient.HttpPost("workspace/create",workspace);  
      return response.data;   
}
    
async function CheckWorkspaceDuplicate(name)
{
  const response = await ApiClient.HttpGet(`workspace/${name}/validate`);  
  return response.data;

}

async function GetList(){
  
  const response = await ApiClient.HttpGet("workspace/list");  
  return response.data;
}


async function GetWorkspaceByhash(hash_id){
  const response = await ApiClient.HttpGet("workspace/"+hash_id);  
  return response.data;     
  
}

async function GetWorkSpaceApps(workspace){

  const response = await ApiClient.HttpGet(`workspace/${workspace}/apps`);  
  return response.data; 

}



async function UpdateWorkspace(workspace){
  
  const response = await ApiClient.HttpPost("workspace/update",workspace);  
  return response.data; 
}

async function DeleteWorkspace(workspace){
  
  const response = await ApiClient.HttpPost(`workspace/delete`,workspace);	  
  return response.data; 

}




const WorkspaceApi = { Create,GetWorkSpaceApps,CheckWorkspaceDuplicate ,GetList,GetWorkspaceByhash ,UpdateWorkspace,DeleteWorkspace} 

export default WorkspaceApi;

