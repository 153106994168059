import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";



function formatTextToURL(string) {
  let clear_string = string.replace(/\s+/g, '_');
  return decodeURIComponent(clear_string);
}

export const useAppRouteNavigator = () => {

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const GotoSettings = app => {

    navigate(`/app/${app}/s/configuration`)
  }

  const GotoWorkSpaceSettings = workspace => {

    navigate(`workspace/${workspace}/edit`)
  }



  const GotoHome = () => {
    navigate(`/home`)
  }

  const GotoForbidden = app => {

    navigate(`/home/forbidden`)
  }



  const GotoView = (collection, default_view, default_view_type) => {

    if (default_view_type === 'database')
      navigate(`/collection/${collection}/${default_view_type}/${default_view}/`);

    if (default_view_type === 'turbo-form')
          navigate(`/collection/${collection}/turbo-form`);

  }

  const GotoViewWithName = (collection, view, view_type, view_name) => {

    const name_view = formatTextToURL(view_name);
    navigate(`/collection/${collection}/${view_type}/${view}/${name_view}`);

  }


  const GotoViewDetails = (collection, record) => {
    const newUrl = `/collection/${collection}/details/${record}`;
    navigate(newUrl)
  }

  const CloseSession = () => {
    localStorage.setItem("user-profile", "undefined")
    localStorage.setItem("user-token", "undefined")
    queryClient.clear();
    navigate('/Auth')
  }



  const navigator = {
    GotoHome,
    GotoSettings,
    GotoWorkSpaceSettings,
    GotoView,
    GotoViewWithName,
    GotoViewDetails,
    GotoForbidden,
    CloseSession,
  }

  return navigator;

};