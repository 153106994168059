import { EuiBottomBar } from '@elastic/eui'
import React from 'react'


/** bottom sticky bar, theme:  light dark transparent */
function BottomBar({ children, ...rest}) {


  const style_transparent = {background:'#fff',boxShadow:'none'};
  const style_light = {background:'#fff',boxShadow:'none'};
  const style_dark = {background:'#000',boxShadow:'none'};
  const style_default = {};

  const styleTheme = {
    'ligth':style_light,
    'transparent':style_transparent,
    'dark':style_dark,
    'default':style_default
  }

  ///const style = styleTheme[theme || 'default'];

  return (
    <EuiBottomBar position="sticky" bottom={4} style={styleTheme['transparent']} paddingSize="none" {...rest}>
      {children}
    </EuiBottomBar>
  )
}

export default BottomBar