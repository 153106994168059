import { EuiFieldText } from '@elastic/eui';
import { useEffect, useState } from 'react';

const LongitudeField = ({ fieldSchema, error, fieldController }) => {
    
    const [longitude, setlongitude] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        if (navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(function (position) {
                var longitude = position.coords.longitude;
                setlongitude(longitude);
                fieldController.onChange(longitude)
                setLoading(false)
            });
        }

        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <EuiFieldText
                fullWidth
                disabled
                isLoading={loading}
                name={'longitude'}
                id={'longitude'}
                value={fieldController.value || longitude}
                onChange={(e) => fieldController.onChange(e.target.value)}
                isInvalid={!!error}
                compressed
                prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
                append={fieldSchema.props?.append && fieldSchema.props?.append}
            />
        </>
    );
};

export default LongitudeField;