import React from 'react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { GLOBAL_KEY_EMOJI_SET } from 'core/Constants'
import { emoji_es_co } from 'i18n/emoji-es-Co'

function EmojimartPicker({ ...props }) {
  return (
    <Picker
      i18n={emoji_es_co}
      data={data}
      theme="light"
      emojiSize={24}
      
      emojiButtonRadius='6px'
      emojiButtonColors={
        [
          'rgba(155,223,88,.7)',
          'rgba(149,211,254,.7)',
          'rgba(247,233,34,.7)',
          'rgba(238,166,252,.7)',
          'rgba(255,213,143,.7)',
          'rgba(211,209,255,.7)',
        ]
      }
      set={GLOBAL_KEY_EMOJI_SET}

      {...props}
    />
  )
}

export default EmojimartPicker