import RegexParser from 'regex-parser';


const fetch = (data) =>
  new Promise((resolve, reject) => {
    // if (!data.firstName || !data.lastName) {
    //   reject(new Error('Not all information provided'));
    // }   
   
    setTimeout(() => resolve(false), 800);

  });

const useValidationRules = ({ is_required, enable_is_unique, validation_rules }) => {

  const type_validation = validation_rules?.type || '';
  const validation = { required: is_required };

  //if (enable_is_unique)
    //validation.validate = { isUnique: async (value) => await fetch(value) || 'El valor debe ser único' }


  const patter = validation_rules?.pattern || '';
  const custom_message = validation_rules?.message || '';


  switch (type_validation) {
    case 'regular_expression':
    case 'email':
    case 'only_number':
    case 'only_letter':
    case 'only_uppercase':
      const parcer = RegexParser(patter);
      validation.pattern = { value: parcer, message: custom_message }
      break;

    case 'range_char':
      validation.maxLength = { value: validation_rules?.max_length, message: custom_message }
      validation.minLength = { value: validation_rules?.min_length, message: custom_message }
      break;

    case 'range_number':
      validation.max = { value: validation_rules?.max, message: custom_message }
      validation.min = { value: validation_rules?.min, message: custom_message }
      break;

    // case 'unique'://TODO not implemented
    //   validation.validate = { isUnique: 'El valor debe ser único' || 'El valor debe ser único' ,message:" El valor debe ser único" }


    default:

  }


  return validation;

};

export default useValidationRules;