
import { useGlobalConfiguration } from "hooks/useGlobalConfiguration";
import moment from "moment";


const CreateAtRenderer = ({ value, row,column } ) => {

    const  { dateFormat} = useGlobalConfiguration();

    const dateFomated =  value ?  moment(value).format(dateFormat+', h:mm:ss a') : '';
    
    return (<>{dateFomated}</>)
}

export default CreateAtRenderer;