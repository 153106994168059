import { EuiPageSection } from '@elastic/eui';
import React from 'react';
import {Outlet} from 'react-router-dom'
import AppFormsToolBar from './toolbar';


function AppPageContainerForms() {
  return(
    <EuiPageSection>
         <AppFormsToolBar/>
                    
              <Outlet/>
    </EuiPageSection>)
}

export default AppPageContainerForms;
