import { EuiAvatar, EuiPopover, EuiSkeletonCircle } from "@elastic/eui"
import { ImagesApiQuery } from "api/image-api";
import { useState } from "react"


const AvatarRenderer = ({ value, row, column }) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const avatar_url = value ? value[0] : null;
    const avatar_title = row?.title || '?';
    const { data, isLoading } = ImagesApiQuery.GetAvatarQuery(avatar_url, isPopoverOpen)

    const closePopover = () => {
        setIsPopoverOpen(false);
    }

    const buttonAvatar = (
        <div style={{ cursor: 'pointer' }} >
            <EuiAvatar title="Ver imagen" name={avatar_title} size="s" onClick={() => setIsPopoverOpen(!isPopoverOpen)} />
        </div>
    )

   
    return (<>
   
        <EuiPopover
            button={buttonAvatar}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
        >
            <div style={{ textAlign: 'center' }} >
                <EuiSkeletonCircle
                    size="xl"
                    isLoading={isLoading}
                    style={{ textAlign: 'center' }}
                    contentAriaLabel="Avatar"
                >
                    {data && <EuiAvatar size="xl" name={avatar_title} imageUrl={avatar_url && data.data || ''} />}
                </EuiSkeletonCircle>
            </div>
        </EuiPopover>
    </>)
}

export default AvatarRenderer