import { EuiEmptyPrompt, EuiLink, EuiPanel, EuiTitle } from "@elastic/eui"

function DataGridEmptyColumns() {
    return (
        <EuiPanel borderRadius="m" paddingSize="none" color="subdued" className="data-grid-floating-empty">
            <EuiEmptyPrompt
                iconType="plusInCircleFilled"
                iconColor="primary"
                title={<h2>Agrega un nuevo registro</h2>}
                titleSize="xs"
                body={<p>Puedes agregar un nuevo registro en esta base de datos</p>}
                actions={<></>}
                footer={
                    <>
                        <EuiTitle size="xxs">
                            <h3>Guia de aprendizaje</h3>
                        </EuiTitle>
                        <EuiLink href="#" target="_blank">
                            Documentacion
                        </EuiLink>
                    </>
                }
            />
        </EuiPanel>

    )
}

export default DataGridEmptyColumns