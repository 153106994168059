
import React, { useState, useEffect } from 'react';
import { EuiAvatar, EuiBreadcrumbs, EuiButtonIcon, EuiHeaderSection, EuiHeaderSectionItem, EuiIcon, EuiSkeletonRectangle } from '@elastic/eui';
import EmojiMart from 'components/emoji/emoji';
import { useAppParameters } from 'hooks/useAppParameters';
import { CollectionApiQuery } from 'api/collections/query-api';
import { ICON_GALLERY } from 'components/icons/icons-gallery';



function BreadcrumbsCollectionDetails() {


    const [emoji, setemoji] = useState('pinata')
    const [collectionName, setcollectionName] = useState('')
    const { collection, record } = useAppParameters();
    const { data, isLoading } = CollectionApiQuery.GetCollectionByHash(collection);

    const backButton = (<EuiButtonIcon display="base" iconType={ICON_GALLERY.ARROW_LEFT} aria-label='---' color='text' style={{ borderRadius: '50%' }} />);
    const recordTitle =(<EuiAvatar size="s" name={record} />)

    const breadcrumbs = [
       
        {
            text: collectionName,
            href: '#',
        }, {
            text: 'Detalle del registro',           
        },
        {
            text:recordTitle

        },
        
    ];

    useEffect(() => {

        if (data) {
            setemoji(data.data.icon)
            setcollectionName(data.data.name)
        }

    }, [collection, data])




    return (
        <>
            <div style={{ width: '350px' }}>
                <EuiSkeletonRectangle
                    width="100%"
                    height={20}
                    borderRadius="m"
                    isLoading={isLoading}
                    contentAriaLabel="Loading configuration"
                ><EuiHeaderSection>
                       
                        <EuiHeaderSectionItem>{backButton}</EuiHeaderSectionItem>
                        <EuiHeaderSectionItem><EuiIcon type='empty' /> </EuiHeaderSectionItem>
                        <EuiHeaderSectionItem><EmojiMart id={emoji} /></EuiHeaderSectionItem>
                        <EuiHeaderSectionItem>
                            <EuiBreadcrumbs
                                max={6}
                                breadcrumbs={breadcrumbs}
                                aria-label=" miga de pan de la navegación"
                            />
                        </EuiHeaderSectionItem>
                    </EuiHeaderSection>
                </EuiSkeletonRectangle>
            </div>
        </>
    )
}

export default BreadcrumbsCollectionDetails