

import { EuiAccordion, EuiPanel, EuiSpacer } from '@elastic/eui'
import { nanoid } from 'nanoid'
import React from 'react'

function SectionComponent({ title, initialIsOpen, customStyle={}, compressed=false, children }) {

    const hasBorder = compressed ? false : true;
    const hasShadow = compressed ? false : true;
    const paddingSize = compressed ? 'none' : 's';
    const nanoId = nanoid();
    
    return (
        <>
            <EuiPanel className="eui-yScroll" hasBorder={hasBorder} hasShadow={hasShadow} paddingSize={paddingSize} style={customStyle}>
                <EuiAccordion
                    id={nanoId}
                    buttonContent={title}
                    initialIsOpen={initialIsOpen}
                    >
                    <EuiSpacer size="s" />
                    <EuiPanel  hasShadow={false} color="subdued">
                        {children}
                    </EuiPanel>
                </EuiAccordion>
            </EuiPanel>
        </>
    )
}

export default SectionComponent