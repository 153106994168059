import { conditionsMet } from '.';

const func = (formId, fieldId, rule, data, mutableSchema) => {
  const field = (mutableSchema || []).find((f) => f.display_id === fieldId);
  if (!field) return;
  const x = conditionsMet(rule, data, formId);
  console.log(x, mutableSchema);
  field.hidden = x;
};

export default func;
