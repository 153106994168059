
//function add maxlength if decimal scale
// maxlength (000) [3] + decimal scale [3] = 000.000 maxlength = 6

function useMaxLength(decimal_scale, max_length, decimal_separator, allow_thousands_separator) {

    

    const sumPoints = () => 
    {
        if (max_length > 0 && max_length < 3) {
            return max_length;
        } else if (max_length >= 3 && max_length <= 6) { 
            return allow_thousands_separator ? max_length + 1 : max_length;
        } else if (max_length > 6 && max_length <= 9) {
            return allow_thousands_separator ? max_length + 2 : max_length;
        } else if (max_length > 9 && max_length <= 12) {
            return allow_thousands_separator ? max_length + 3 : max_length;
        }
    };

    var maxLengthLimit = sumPoints();
    var maxLength = decimal_scale > 0 ? max_length + maxLengthLimit + decimal_scale : maxLengthLimit;

    /**
    * Validates if the given value is within the maximum length limit.
    *
    * @param {string} value - The value to be validated.
    * @return {boolean} True if the value is within the maximum length limit, false otherwise.
    */
    const validateMaxLength = (value) => {

        if (decimal_scale === 0)
            return true

        const numeric_part = value.split(decimal_separator)[0];
        return numeric_part.length <= maxLengthLimit;

    }



    return {
        maxLength,
        validateMaxLength
    }

}





export default useMaxLength;
