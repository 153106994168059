
import React from 'react'
import data from '@emoji-mart/data'
import { GLOBAL_KEY_EMOJI_SET } from 'core/Constants'
import { init } from 'emoji-mart'

function EmojiMart({ ...props }) {

  init({ data })
  
    // @ts-ignore
    return (<em-emoji  set={GLOBAL_KEY_EMOJI_SET} size='1.4em' {...props} fallback=':pinata:'></em-emoji>)
}

export default EmojiMart