import { EuiButton, EuiCallOut, EuiFlexGrid, EuiForm, EuiSkeletonText, EuiSpacer } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import useSchemasFormStore from './state/schemas-store';
import DynamicField from './dynamic-field';
import { CollectionApiQuery } from 'api/collections/query-api';


export const ScobarForm = ({ formId, data: formData, onChange, onSubmit, showSubmitButton,formAction,style, }) => {


  const [loading, setLoading] = useState(true);
  const [dismissed, setDismissed] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const [messageInitial, setMessageInitial] = useState(null);
  const {data: collection} = CollectionApiQuery.GetCollectionByHash(formId)
 


  const { schema, fetch } = useSchemasFormStore(
    (state) => ({
      fetch: state.fetch,
      schema: state.schemas[formId],
    }),
    shallow
  );



useEffect(() => {
  
  if(collection?.data.on_start_form_message)
  {
    setShowInitialMessage(collection.data.on_start_form_message.status)
    setMessageInitial(collection.data.on_start_form_message)
  }

},[collection])


  useEffect(() => {
    const func = async () => {
      setLoading(true);

      if (!schema){
        await fetch(formId);

      }

      setLoading(false);

      

    };

    func();

  }, [fetch, formId, schema]);

  const { control, watch, handleSubmit,setError, clearErrors } = useForm({
    shouldUnregister: true,
    defaultValues: formData[formId],
  });

  useEffect(() => {

    const subscription = watch((value, { name }) => {
      if (name && onChange) 
      {
        onChange({ ...formData, [formId]: value });
      }
    });

    return () => subscription.unsubscribe();

  }, [formId, formData, watch, onChange]);


  return (
    <EuiSkeletonText lines={9} size="m" isLoading={loading} contentAriaLabel="Cargando formulario">
      <EuiForm id={'form-' + formId} component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" key={'form-key-' + formId} style={style}>

        {!dismissed && showInitialMessage && <EuiCallOut
          size="s"
          heading='h5'          
          title={messageInitial.title}
          iconType="pin"
          style={{ borderRadius: 5 }}
          color={ messageInitial.color}
          onDismiss={() => {
            setDismissed(true);
          }}
        >
          <p>{messageInitial.content}</p>
        </EuiCallOut>
        }
        <EuiSpacer size="s" />
        <EuiFlexGrid columns={1} direction="row" gutterSize="m">
          {(schema || []).map((f) => (

            <DynamicField
              key={f.id}
              watch={watch}
              control={control}
              fieldId={f.id}
              formId={formId}
              data={formData}
              formAction={formAction}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
        </EuiFlexGrid>
        {showSubmitButton && <EuiButton id={'submit-' + formId} type='submit'>Guardar</EuiButton>}
      </EuiForm>
    </EuiSkeletonText>
  );
};
