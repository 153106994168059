import { EuiAvatar, EuiButtonIcon,  EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTextTruncate } from "@elastic/eui"



function ForeignKeyRenderer({ value, row, column }) {

    const onChange = (e) => {

        console.log(e.target.value)
    }

    
    console.log("value", JSON.parse(value))

    return (<>
        <EuiPanel 
        grow={false} 
        hasBorder={true} 
        hasShadow={false} 
        paddingSize="xs"        
        borderRadius="m"
        color="subdued"
        style={{maxWidth: 250}}
        >
            <EuiFlexGroup gutterSize="xs" >
                <EuiFlexItem grow={1}><EuiAvatar name={JSON.parse(value).label || '?'} size="s" /></EuiFlexItem>
                <EuiFlexItem grow={8}><EuiTextTruncate  text={JSON.parse(value).label} /></EuiFlexItem>
                <EuiFlexItem grow={1}><EuiButtonIcon iconType={'popout'} color="text" aria-labelledby="Abrir" /></EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>

    </>)
}

export default ForeignKeyRenderer