import { EuiIcon, EuiToolTip } from '@elastic/eui'
import React from 'react'

function LabelWithHelpIconToolTip({ label, extraHelpText }) {
  return (
    <EuiToolTip content={extraHelpText}  >
      <>
      <EuiIcon type="questionInCircle"  color="subdued" /><span>{label}</span>
      </>
      </EuiToolTip>
  )
}

export default LabelWithHelpIconToolTip