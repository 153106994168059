import { useState } from "react";
import { ScobarForm } from "..";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { DATAGRID_QUERY_KEYS } from "api/datagrid-api";
import { useAppParameters } from "hooks/useAppParameters";
import { RecordsApiCommand } from "api/collections/records-api";




function InlineForm() {

    const [showModal, setShowModal] = useState(false);
    const [showModalSucces, setShowModalSucces] = useState(false);
    const [isLoadingSavingChanges, setIsLoadingSavingChanges] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setCurrentData] = useState({});
    const {collection } = useAppParameters();

    const { InvalidateQuery } = useInvalidateQueryByKey([DATAGRID_QUERY_KEYS.GET_VIEW_BY_HASH]);


    const closeModal = () => setShowModal(false);
    const closeSuccessModal = () => setShowModalSucces(false);
    const onFormChange = (data) => setCurrentData(data);


    const [openTourAddRecord, setOpenTourAddRecord] = useState(true);
    const onSubmit = async (data) => {


        setIsLoadingSavingChanges(true);
        setShowError(false);


        const new_record = {
            hash_id: collection,
            data: data,
        }

        const response = await RecordsApiCommand.AddRecord(new_record);

        if (response.status) {

            setIsLoadingSavingChanges(false);
            setShowModal(false);
            setShowModalSucces(true);
            setCurrentData({});
            InvalidateQuery();

        } else {
            setIsLoadingSavingChanges(false);
            setShowError(true);
            setErrorMessage(response.message);
            setShowModal(false);

        }



    }





    return (


        <ScobarForm
            formId={collection}
            data={data}
            onChange={onFormChange}
            onSubmit={onSubmit}
            showSubmitButton={false}
            style={{ width: 450 }}
            formAction='create'
        />


    )
}


export default InlineForm


