import { EuiI18n, useEuiI18n } from "@elastic/eui"

const useI18n_already_exist = () => useEuiI18n('form.field.name.already.exist', 'El nombre para la nueva columna ya existe, intenta otro nombre. ')
const useI18n_special_characters_not_allowed = () => (useEuiI18n("form.field.name.invalid.characters", "Por favor no usar el caracter "))
const useI18n_placeholder = (character) => useEuiI18n("form.field.placeholder", "Escribe hasta {character} caracteres", { character })
const useI18n_help_link_helptext = () => (useEuiI18n("form.field.link.helptext", "Texto del enlace"))
const useI18n_help_link_url = () => (useEuiI18n("form.field.link.url", "Dirección web o URL"))

const help_text_placeholder = (<EuiI18n token="form.field.help.helptext" default="Escribe hasta 100 caracteres" />)

const button_create = (<EuiI18n token="form.button.create" default="Crear columna" />)
const create_again_field = (<EuiI18n token="form.button.add.again" default="Agregar otra columna " />)
const input_name =  (<EuiI18n token="form.field.name" default="Nombre de la columna" />)
const useI18n_input_name_required =()=> useEuiI18n('form.field.required', 'Requerido')
const name_already_exists = (<EuiI18n token="form.field.name.already.exist'" default="Este nombre ya existe en esta app, intenta otro nombre" />)

const input_type = (<EuiI18n token="form.field.type" default="Tipo de columna" />)
const input_default_value = (<EuiI18n token="global.default_value" default="Valor por defecto" />)
const input_default_value_help = (<EuiI18n token="form.field.default_value.help" default="Valor por defecto" />)
const input_options = (<EuiI18n token="form.field.options" default="Opciones de respuesta" />)
const success_create_field = (<EuiI18n token="form.field.success.create" default="Columna creada con éxito" />)
const help_text = (<EuiI18n token="form.field.help" default="Texto de ayuda" />)

const help_link = (<EuiI18n token="form.field.link.help" default="Link de ayuda" />)


const label_unique = (<EuiI18n token="form.field.unique" default="No acepta respuestas duplicadas" />)
const label_unique_tooltip = (<EuiI18n token="form.field.unique.tooltip" default="Si se activa, esta columna no tendrá respuestas duplicadas" />)
const label_panel_configuration = (<EuiI18n token="form.panel.configuration" default="Configuración general" />)
const label_way_cant_change_type = (<EuiI18n token="form.field.way.cant.change.type" default="¿Por que no puedo cambiar el tipo?" />)
const label_way_cant_change_type_tooltip = (<EuiI18n token="form.field.way.cant.change.type.tooltip" default="Al cambiar una columna existente de un tipo a otro, necesitamos saber cómo migrar los datos existentes, puedes hacerlo en el modulo de transformación de datos." />)
const save_changes = (<EuiI18n token="global.save.change" default="Aplicar cambios" />)
const label_enable_options_colors = (<EuiI18n token="form.field.enable.options.colors" default="Permitir opciones con colores" />)


const i18n = {
    button_create,
    create_again_field,
    input_name,
    name_already_exists,
    input_type,
    input_default_value,
    input_default_value_help,
    input_options,
    success_create_field,
    help_text,
    help_text_placeholder,
    help_link,
    label_unique,
    label_unique_tooltip,
    label_panel_configuration,
    save_changes,
    label_way_cant_change_type,
    label_way_cant_change_type_tooltip,
    label_enable_options_colors,
    useI18n_already_exist,
    useI18n_special_characters_not_allowed,
    useI18n_placeholder,
    useI18n_help_link_helptext,
    useI18n_help_link_url,
    useI18n_input_name_required,
}

export default i18n;
