import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

function AnimatePanelWrapper({ children }) {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key="panel-wrapper-animate"
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}>
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default AnimatePanelWrapper