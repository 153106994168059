import { EuiIcon, EuiToolTip } from "@elastic/eui";



function QuickMessageTooltip({ text, titleToolTip, descriptionToolTip, position }) {


    return (
        <>
            <EuiToolTip title={titleToolTip || ''} content={descriptionToolTip || ''} position={position || 'top'}  >
                <>
                    <EuiIcon type="questionInCircle" color="subdued" /><span>{text || ''}</span>
                </>
            </EuiToolTip>

        </>
    )
}

export default QuickMessageTooltip