import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'

///pasar a json storage por que no se necesita mucho acciones en este caso
export const useGlobalStore = create(persist(
       (set) => ({
              app: '',
              workspace: 0,
              theme: 'light',
              collection: '',
              collection_name: '',
              session: '',
              setApp: (app) => set(state => ({ app: app })),
              setWorkSpace: (workspace) => set(state => ({ workspace: workspace })),
              setTheme: (theme) => set(state => ({ theme: theme })),
              setCollection: (collection,name) => set(state => ({ collection: collection , collection_name: name})),              
       })
       ,
       { name: 'onespace-store', storage: createJSONStorage(() => localStorage) }
));



