import { EuiButton, EuiCallOut, EuiFieldText, EuiFormRow, EuiHorizontalRule, EuiInputPopover } from "@elastic/eui"
import { useState } from "react"
import useRegexValidation from "../hooks/validation-rules";
import { useAppParameters } from "hooks/useAppParameters";
import { useGlobalCallOutNotificationStore } from "store/global-callout-notification-store";
import { FieldApiCommand, FieldApiQuery } from "api/field-api";
import { ShowWhen } from "components/conditional-render/show-when";





const TextCellEditor = ({ value, row, column, UpdateCellValue }) => {

    const [editValue, setEditValue] = useState(value)
    const [initialValue,] = useState(value)
    const [isSaving, setIsSaving] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [showCoincidenceValidation, setShowCoincidenceValidation] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { collection } = useAppParameters();
    const props = JSON.parse(column.props) || {};
    const is_unique = props.enable_is_unique;

    const showNotificationError = useGlobalCallOutNotificationStore(state => state.showNotificationError);

    const { validate } = useRegexValidation(JSON.parse(column.validations_rules));

  
    const onTextChange = (event) =>{
        setIsInvalid(false)
        setShowCoincidenceValidation(false)
        setIsPopoverOpen(false)
        setEditValue(event.target.value)
    }

    const checkUniqueValue = async (value) => {

        setIsSaving(true)

        if (value.trim() === initialValue) return false

        const response = await FieldApiQuery.CheckFieldBeUnique({ field: column.id, collection: column.collection_id, value: value });

        console.log("response", response)
        if (response.data) 
        {
            setIsSaving(false)
            return response.data;
        }

    }

    const saveCellTextEditor = async () => {
        setIsInvalid(false)
        setShowCoincidenceValidation(false)
        setIsPopoverOpen(false)

        if (editValue.length === 0) {
            setIsInvalid(true)
            setErrorMessage("El campo no puede estar vacío")
            return
        }

        const is_valid = validate(editValue);


        if (is_valid !== null) {
            setIsInvalid(true)
            setErrorMessage(is_valid)
            return
        }

        const is_valid_unique = is_unique ? await checkUniqueValue(editValue) : false;

        if (is_valid_unique) {
            setIsInvalid(true)
            setShowCoincidenceValidation(true)
            setIsPopoverOpen(true)
            return
        }

        if (editValue) {
            setIsSaving(true)
            const update = { id: row.id, value: editValue.trim(), collection: collection, column: column.id, type: column.data_type }
            const response = await FieldApiCommand.UpdateFieldByKey(update);

            if (response.status) {
                UpdateCellValue(editValue)

            } else {

                setEditValue(value)
                showNotificationError("No fue posible actualizar", "ha ocurrido algo inesperado ", response.message)
            }

            setIsSaving(false)

        } else {
            setEditValue(value)
        }

    }


    return (
        <>
            <EuiFormRow
                hasChildLabel={false}
                isInvalid={isInvalid}
                error={errorMessage}
            >
                <EuiInputPopover
                    isOpen={isPopoverOpen}
                    closePopover={() => setIsPopoverOpen(false)}
                    closeOnScroll={true}
                    fullWidth
                    input={
                        <EuiFieldText
                            fullWidth
                            id={"cell-text-editor-" + column.id}
                            compressed
                            isInvalid={isInvalid}
                            placeholder=""
                            isLoading={isSaving}
                            value={editValue}
                            onChange={(event) => onTextChange(event)}
                            prepend={props?.prepend && props?.prepend}
                            append={props?.append && props?.append}

                        />
                    }
                >
                    <ShowWhen condition={showCoincidenceValidation}>
                        <>
                            <EuiCallOut title={
                                <span dangerouslySetInnerHTML={
                                    { __html: `Ya existe un registro con el valor de: <b>${editValue}</b>` }
                                } />
                            }
                                color="warning" iconType="warning" size='s'>
                            </EuiCallOut>
                        </>
                    </ShowWhen>
                </EuiInputPopover>
            </EuiFormRow>
            <EuiHorizontalRule margin="xs" />
            <EuiButton onClick={saveCellTextEditor} isLoading={isSaving} fullWidth size="s" fill>Guardar</EuiButton>

        </>
    )
}
export default TextCellEditor