import { EuiFieldNumber } from '@elastic/eui';
import { useGlobalConfiguration } from 'hooks/useGlobalConfiguration';
import { NumericFormat } from 'react-number-format';
import useMaxLength from './max-length.hook';

export const NumberField = ({ fieldSchema, error, fieldController }) => {

  const { separatorThousands, separatorDecimals } = useGlobalConfiguration();
  const decimal_scale = fieldSchema.numeric_props.decimal_scale;
  const max_length = fieldSchema.numeric_props.max_length;
  const allow_thousands_separator = fieldSchema.numeric_props.allow_thousands_separator;
  
  const { maxLength,validateMaxLength } = useMaxLength(decimal_scale,max_length,separatorDecimals,allow_thousands_separator);

  
  return (
    <>
      <NumericFormat
        fixedDecimalScale
        fullWidth
       // value={fieldController.value || ''}
        customInput={EuiFieldNumber}
        isInvalid={!!error}
        thousandSeparator={fieldSchema.numeric_props.allow_thousands_separator && separatorThousands}
        decimalSeparator={separatorDecimals}
        decimalScale={fieldSchema.numeric_props.decimal_scale}
        isAllowed={({ formattedValue }) => validateMaxLength(formattedValue)}
        maxLength={maxLength}
        compressed
        allowNegative={fieldSchema.numeric_props.allow_negative}
        prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
        append={fieldSchema.props?.append && fieldSchema.props?.append}
        onValueChange={(values) => {
          const { floatValue } = values;
          
          fieldController.onChange(floatValue);
        }}
      />
    </>
  );

};
