import { EuiSplitPanel } from '@elastic/eui';
import { useAppParameters } from 'hooks/useAppParameters';
import AppDataGrid from 'packages/datagrid/Index';

import DataGridTopToolBar from 'packages/datagrid/toolbar/top-bar';




const DataBaseView = () => {


  const { collection } = useAppParameters();
  console.log("renderiza view")


  return (
    <>

      <EuiSplitPanel.Outer direction="row" hasShadow={false} hasBorder={false} style={{ height: '100%' }}>
        <EuiSplitPanel.Inner
          color="subdued"
          css={{ blockSize: 'auto', inlineSize: '85%' }}
          style={{ blockSize: 'auto', inlineSize: '85%' }}
          paddingSize='none'
        >
          <EuiSplitPanel.Outer hasShadow={false} hasBorder={false} borderRadius='none' style={{ height: '99%' }} >
            <EuiSplitPanel.Inner paddingSize='none' grow={false} >
              <DataGridTopToolBar collection={collection} />
            </EuiSplitPanel.Inner>
            {/* <EuiSplitPanel.Inner paddingSize='m' grow={true} style={{ maxHeight: 100 }} > 
             <div>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiStat title="1" description="Total 1" />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiStat title="10" description="Indicador 2" titleColor="subdued" />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiStat title="100" description="Sumatoria " titleColor="primary" />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiStat
                          title="1,000"
                          description="Promedio"
                          titleColor="success"
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiStat
                          title="10,000"
                          description="Conteo"
                          titleColor="danger"
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiStat
                          title="100,000"
                          description="distintos"
                          titleColor="accent"
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div></EuiSplitPanel.Inner> */}
            <EuiSplitPanel.Inner paddingSize="none" style={{ height: '99%', overflow: 'hidden' }} >
              <AppDataGrid />
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>


    </>
  );
}


export default DataBaseView

