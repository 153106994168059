import {
  EuiButtonIcon,
  EuiDataGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSkeletonCircle,
  EuiSkeletonText,
  EuiToolTip,
} 
from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import _ from 'lodash';


import { Fragment, useCallback, useEffect, useState, Suspense, createContext, } from "react";
import AddQuickColumn from "./add-column/add-quick-column";
import RenderCellComponent from "./cell-renderers/render-cell-component";
import { useAppRouteNavigator } from "hooks/useAppRouteNavigator";
import { useAppParameters } from "hooks/useAppParameters";
import ModalFieldEditor from "packages/field-creator/editor-field/modal";
import ModalTitleFieldEditor from "packages/field-creator/editor-field/title-field";
import IconDataGridFieldType from "components/icons/icon-datagrid-field-type";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { DATAGRID_QUERY_KEYS, DataGridViewApiQuery } from "api/datagrid-api";
import DataGridToolBarColumnSelector from "./column-selector";
import FilterInTable from "packages/filter/components/FilterInTable";
import DataGridToolBarConfiguration from "./configuration";
import DataGridToolBarTotalRecords from "./toolbar/total-records";
import DataGridToolBarDownload from "./toolbar/download";
import DataGridToolBarRefresh from "./toolbar/refresh";
import { useDataGridStore } from "./store/use-datagrid-store";
import DataGridEmptyRows from "./empty-messajes/empty-columns";
import DataGridEmptyColumns from "./empty-messajes/empty-columns";
import DataGridPagination from "./pagination";




const checkIfCellIsExpandable = (data_type) => {

  if (data_type === "file") return true
  return false
}


const DataContext = createContext([]);


function AppDataGrid() {



  const exclude_columns = ['id', 'create_at', 'last_update', 'create_by', 'geolocalization', 'avatar'];
  const [pagination, setPagination] = useState(
    {
      pageIndex: 0,
      pageSize: 50,
      total: 0,


    });


  const [showQuickEditColumns, setShowQuickEditColumns] = useState(false);
  const [showQuickEditTitle, setShowQuickEditTitle] = useState(false);
  const [, setOpenResume] = useState(false);
  const [columnToEdit, setColumnToEdit] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const { collection, view_id } = useAppParameters();
  const [showGrid, setShowGrid] = useState(false);
  const navigator = useAppRouteNavigator();
  const { InvalidateQuery } = useInvalidateQueryByKey([DATAGRID_QUERY_KEYS.GET_RECORDS_BY_VIEW]);
  const isGlobalStateChange = useDataGridStore(state => state.isGlobalStateChange);
  const [isChangingPage, setIsChangingPage] = useState(false);



  const setPageIndex = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    []
  );

  const setPageTotal = useCallback(
    (total) => setPagination((pagination) => ({ ...pagination, total })),
    [])



  const TitleColumnActions = (col) => [
    {
      iconType: 'pencil',
      label: 'Editar columna',
      size: 'xs',
      onClick: () => {
        setShowQuickEditTitle(true)
        setColumnToEdit(col.id)

      },
    },
  ];




  const CustomColumnActions = (col) => [
    {
      iconType: 'copy',
      label: 'Duplicar columna',
      size: 'xs',
      onClick: () => { },
    },
    {
      iconType: 'pencil',
      label: 'Editar columna',
      size: 'xs',
      onClick: () => {
        setShowQuickEditColumns(true)
        setColumnToEdit(col.id)
      },
    },
  ];


  const ColumnActions = (data_type, col) => {

    return exclude_columns.includes(data_type) ? null : {
      showHide: {
        label: 'Ocultar',
        onClick: () => { setShowQuickEditColumns(true) },
      },
      showMoveLeft: {
        label: 'Mover hacia la izquierda',
        onClick: () => { console.log("Left") },
      },
      showMoveRight: {
        label: 'Mover hacia la derecha',
        onClick: () => { console.log("Right") },
      },
      additional: data_type === "title" ? TitleColumnActions(col) : CustomColumnActions(col)


    }
  }

  const getSizeColumn = (data_type) => {

    if (data_type === "avatar") return 38
    if (data_type === "id") return 55

    return null;
  }

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);

  const ProcessColumns = useCallback((columns) => {

    return columns.map((col) => {

      Object.keys(col).forEach((key) => {
        const display_text = col.displayAsText;
        const data_type = col.data_type;
        col.initialWidth = getSizeColumn(data_type)
        col.display = (<>
          <IconDataGridFieldType
            typeIcon={data_type} />
          {data_type === "avatar" ? '' : display_text}
        </>
        );
        col.isExpandable = checkIfCellIsExpandable(data_type);
        col.actions = ColumnActions(data_type, col);

      });

      return col;
    }
    );
  }, [Columns])







  // const { isLoading } = useQuery([DATAGRID_QUERY_KEYS.GET_RECORDS_BY_VIEW, view_id], () => getViewByHashQuery(view_id), {
  //   onSuccess: (data) => {
  //     if (data) {
  //       setVisibleColumns(data.data.columns.map(({ id }) => id));
  //       setColumns(ProcessColumns(data.data.columns));
  //       setRows(data.data.rows);
  //     }
  //   },
  // });





  // const { data, refetch } = DataGridViewApiQuery.GetRecordsByDataGrid(parameters)


  const onChangePage = (pageIndex) => {

    fechGrid(
      {
        view_id: view_id,
        page_size: pagination.pageSize,
        page_index: pageIndex,
        order_by: "",
        filter_by: "",
        filter_template: ""

      }, true
    )

  }





  // useEffect(() => {

  //   setShowGrid(false)

  // }, [view_id])

  const fechGrid = async (parameters, is_change_page, force = false) => {

    if (is_change_page)
        setIsChangingPage(true)

    const response = await DataGridViewApiQuery.GetRecordsByDataGridQuery(parameters)
    const page_index = parameters.page_index;

    setVisibleColumns(response.data.columns.map(({ id }) => id));
    setColumns(ProcessColumns(response.data.columns));
    setRows(response.data.rows);
    setPageIndex(page_index)
    setPageTotal(response.data.total)
    setIsChangingPage(false)
    
    if(force)
        setShowGrid(true)
  }


  useEffect(() => {

    setShowGrid(false)

    const parameters = {
      view_id: view_id,
      page_size: pagination.pageSize,
      page_index: pagination.pageIndex,
      order_by: "",
      filter_by: "",
      filter_template: ""
    };

    fechGrid(parameters, false,true)
    console.log("view_id", view_id)



    return () => {
      setShowGrid(true)
    }

  }, [view_id])



  useEffect(() => 
    {
    const parameters =
    {
      view_id: view_id,
      page_size: pagination.pageSize,
      page_index: pagination.pageIndex,
      order_by: "",
      filter_by: "",
      filter_template: ""
    };

    fechGrid(parameters, false)

    return () => {
      setShowGrid(true)
    }
  }, [isGlobalStateChange])


  const onCloseEditColumns = () => {
    setShowQuickEditColumns(false)
  }

  const onCloseEditTitle = () => {
    setShowQuickEditTitle(false)
  }


  const onRefreshGrid = () => {
    InvalidateQuery()
    setIsRefreshing(true)
    setTimeout(() => {
      setIsRefreshing(false)
      setLastRefresh(new Date())
    }, 2000)

  }

  // const updateValueCell = (value, colIndex, rowIndex) => {
  //   // Clona solo la fila específica que se está actualizando

  //   setRows(prevRows => {
  //     const updatedRows = [...prevRows]; // Clonar el array de filas

  //     // Clonar la fila específica y actualizar el valor de la celda
  //     const updatedRow = { ...updatedRows[rowIndex] };
  //     updatedRow[colIndex] = value;

  //     // Reemplazar la fila original con la fila actualizada
  //     updatedRows[rowIndex] = updatedRow;

  //     return updatedRows; // Devolver el nuevo estado actualizado
  //   });

  //   //console.log("updatedRow", updatedRow)

  //   console.log("value", value, "colIndex", colIndex, "rowIndex", rowIndex)

  //   // const update= Rows[rowIndex][colIndex] = value;

  //   // setRows(updatedRow);


  //   console.log("evento cuando se actualiza una celda")

  //   // Actualiza el valor de la celda específica en la fila clonada
  //   // updatedRow[colIndex] = RenderCellComponent({
  //   //   value,
  //   //   row: updatedRow,
  //   //   column: columns.find(col => col.id === colIndex),
  //   //   onUpdateValue: newValue => updateValueCell(newValue, colIndex, rowIndex),
  //   // });

  //   // // Crea una nueva matriz de filas con la fila actualizada
  //   // const updatedRows = [...rowsFormated];
  //   // updatedRows[rowIndex] = updatedRow;

  //   // // Actualiza el estado de rowsFormated con la nueva matriz
  //   // setRowsFormated(updatedRows);
  // }









  //usememo


  // const setColumns = ProcessColumns(columns);



  // const FlyoutRowCell = (rowIndex) => {
  //   let flyout;
  //   const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  //   if (isFlyoutOpen) {
  //     const rowData = rows[rowIndex.rowIndex];

  //     const details = Object.entries(rowData).map(([key, value]) => {
  //       return (
  //         <Fragment>
  //           <EuiDescriptionListTitle>{key}</EuiDescriptionListTitle>
  //           <EuiDescriptionListDescription>{value}</EuiDescriptionListDescription>
  //         </Fragment>
  //       );
  //     });

  //     flyout = (
  //       <EuiPortal>
  //         <EuiFlyout ownFocus onClose={() => setIsFlyoutOpen(!isFlyoutOpen)}>
  //           <EuiFlyoutHeader hasBorder>
  //             <EuiTitle size="m">
  //               <h2>{rowData.name}</h2>
  //             </EuiTitle>
  //           </EuiFlyoutHeader>
  //           <EuiFlyoutBody>
  //             <EuiDescriptionList >{details}</EuiDescriptionList>
  //           </EuiFlyoutBody>
  //         </EuiFlyout>
  //       </EuiPortal>
  //     );
  //   }

  //   return (
  //     <Fragment>
  //       <EuiButtonIcon
  //         color="text"
  //         iconType="eye"
  //         iconSize="s"
  //         aria-label="View details"
  //         onClick={() => setIsFlyoutOpen(!isFlyoutOpen)}
  //       />
  //       {flyout}
  //     </Fragment>
  //   );
  // };


  const renderCellValue = ({ rowIndex, columnId }) => {

    if (isChangingPage)
      return <EuiSkeletonText lines={1} />

    const columns = Columns;
    const rows = Rows;
    const columnFiltered = columns.find(col => col.id === columnId);
    const row = rows[rowIndex];
    const currentValue = row ? row[columnId] || '' : '';

    const handleUpdateValue = (value) => {


    };

    return RenderCellComponent({ value: currentValue, row, column: columnFiltered, onUpdateValue: handleUpdateValue });
  }


  if (!showGrid) {
    return <EuiProgress size="xs" color="primary" position="static" />
  }

  const emptyColumns = (showGrid && Columns.length <= 2 && pagination.total === 0 && (<DataGridEmptyColumns />))
  const emptyRows = (showGrid && Columns.length ==3 && pagination.total === 0 && (<DataGridEmptyRows />))
  



  console.log("renderiza grid")


  return (
    <>

      {isRefreshing && <EuiProgress size="xs" color="primary" position="static" />}

      {/* <EuiHeader position="static">
        <EuiHeaderSectionItem>
          Inicio
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <EuiHeaderLinks aria-label="App navigation links example">

            <>
              <EuiHeaderLink isActive onClick={() => { }}>
                Docs
              </EuiHeaderLink>

              <EuiHeaderLink onClick={() => { }}>Code</EuiHeaderLink>

              <EuiHeaderLink iconType="help" onClick={() => { }}>
                Help
              </EuiHeaderLink>
            </>

          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeader> */}

      {showGrid && (
        <Suspense fallback={<EuiProgress size="xs" color="primary" position="static" />}>
          <DataContext.Provider value={[]}>
            <EuiDataGrid
              style={{ minWidth: 0, maxHeight: '99vh' }}
              id={"data-grid-" + collection}
              key={"data-grid-key-" + collection}
              aria-label="records-data-grid"
              className='eui-scrollBar'
              columns={Columns}

              columnVisibility={{
                visibleColumns: visibleColumns,
                setVisibleColumns: setVisibleColumns,
              }}

              virtualizationOptions={{
                estimatedRowHeight: 210,
              }}
              leadingControlColumns={[
                {
                  id: 'selection',
                  width: 80,
                  headerCellRender: () => <>

                    <AddQuickColumn showBeacon={Columns.length <= 2} />

                  </>,
                  headerCellProps: { className: 'eui-textCenter' },
                  rowCellRender: ({ rowIndex, columnId }) =>
                    <div>
                      <EuiFlexGroup gutterSize="xs">
                        <EuiFlexItem>

                          <EuiSkeletonCircle
                            size="s"
                            isLoading={isChangingPage}
                            contentAriaLabel="open record"
                          >
                            <EuiToolTip content="Abrir" >
                              <EuiButtonIcon
                                className="border-radius-50"
                                aria-labelledby="Abrir registro"
                                color="text"
                                display="base"
                                iconType={'popout'}
                                aria-label={''}
                                onClick={() => { navigator.GotoViewDetails(collection) }}
                              />
                            </EuiToolTip>
                          </EuiSkeletonCircle>

                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiSkeletonCircle
                            size="s"
                            isLoading={isChangingPage}
                            contentAriaLabel="open record"
                          >
                            <EuiToolTip content="Resumen" >
                              <EuiButtonIcon color="text" display="base" aria-labelledby="Resumen" onClick={() => { setOpenResume(true) }} iconType={ICON_GALLERY.OPEN_RESUMEN} aria-label={''} />
                            </EuiToolTip>
                          </EuiSkeletonCircle>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </div>
                  ,
                  footerCellRender: () => null,
                  footerCellProps: { className: 'eui-textCenter' },
                },
              ]}
              rowCount={Rows.length}
              rowHeightsOptions={
                {
                  defaultHeight: 'auto' // all rows will automatically adjust the height except rows defined in 'rowHeights'
                }
              }

              toolbarVisibility={{
                showKeyboardShortcuts: false,
                showColumnSelector: false,
                additionalControls: {
                  left: {
                    prepend: (
                      <>
                        <DataGridToolBarConfiguration />
                        <DataGridToolBarTotalRecords
                          totalRecords={pagination.total} />
                        <DataGridToolBarColumnSelector
                          totalColumns={Columns.length} />

                      </>

                    ),
                    append: (

                      <Fragment>

                        <FilterInTable />
                      </Fragment>
                    ),
                  },
                  right: (

                    <Fragment>
                      <DataGridToolBarDownload />
                      <DataGridToolBarRefresh onRefreshGrid={onRefreshGrid} lastRefresh={lastRefresh} />
                    </Fragment>
                  ),
                },
              }}

              renderCellValue={renderCellValue}

            />
          </DataContext.Provider>

          <DataGridPagination limit={49} pagination={pagination} onChangePage={onChangePage} />
          {emptyRows}
          {emptyColumns}

        </Suspense>
      )}


      {/* <ResumeRecords visible={true}/>  */}
      {showQuickEditColumns &&
        <ModalFieldEditor
          collection={collection}
          closeModal={onCloseEditColumns}
          isOpen={showQuickEditColumns}
          columToEdit={columnToEdit}

        />
      }
      {
        showQuickEditTitle &&
        <ModalTitleFieldEditor
          collection={collection}
          closeModal={onCloseEditTitle}
          isOpen={showQuickEditTitle}
          columToEdit={columnToEdit}
        />
      }
    </>
  );
}

export default AppDataGrid;