import React, { useState } from 'react'
import { EuiDragDropContext, euiDragDropCopy, euiDragDropReorder, EuiFlexGroup, EuiFlexItem, EuiPanel, htmlIdGenerator } from '@elastic/eui'
import Navigator from 'packages/navigator/navigator'
import FormContainer from './form-container';
import NavFieldsContainer from './nav-fields/nav-container';
import { FieldTypes } from 'packages/field-creator/components/field-selector/types/fields.type.json';
import CalloutInformation from 'components/notifications/callout/callout-information';


const makeId = htmlIdGenerator();


const itemElementsFields = FieldTypes.map((item, index) => {
    return {
        icon: item.value,
        content: item.label,
        id: makeId(),
    };
});



function FormDesinger() {

    const [isItemRemovable, setIsItemRemovable] = useState(false);
    const [list1, setList1] = useState(itemElementsFields);
    const [list2, setList2] = useState([]);
    const lists = { DROPPABLE_AREA_COPY_1: list1, DROPPABLE_AREA_COPY_2: list2 };
    const actions = {
        DROPPABLE_AREA_COPY_1: setList1,
        DROPPABLE_AREA_COPY_2: setList2,
    };
    const remove = (droppableId, index) => {
        const list = Array.from(lists[droppableId]);
        list.splice(index, 1);

        actions[droppableId](list);
    };
    const onDragUpdate = ({ source, destination }) => {
        const shouldRemove =
            !destination && source.droppableId === 'DROPPABLE_AREA_COPY_2';
        setIsItemRemovable(shouldRemove);
    };
    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                const items = euiDragDropReorder(
                    lists[destination.droppableId],
                    source.index,
                    destination.index
                );

                actions[destination.droppableId](items);
            } else {
                const sourceId = source.droppableId;
                const destinationId = destination.droppableId;
                const result = euiDragDropCopy(
                    lists[sourceId],
                    lists[destinationId],
                    source,
                    destination,
                    {
                        property: 'id',
                        modifier: makeId,
                    }
                );

                actions[sourceId](result[sourceId]);
                actions[destinationId](result[destinationId]);
            }
        } else if (!destination && source.droppableId === 'DROPPABLE_AREA_COPY_2') {
            remove(source.droppableId, source.index);
        }
    };


    return (
        <>        <EuiDragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}><div>
                    <EuiPanel paddingSize='none' style={{ maxWidth: 257 }}>
                        <Navigator />
                    </EuiPanel>
                </div>
                </EuiFlexItem>
                <EuiFlexItem>
                    <FormContainer isItemRemovable={isItemRemovable} remove={remove} list2={list2} />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ maxWidth: 257 }}>
                    {list1.length && <NavFieldsContainer list1={list1} />}
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiDragDropContext>
            <CalloutInformation title='Diseñador de formularios' messaje={(
                <>
                    <p>Permiten gestionar los datos de tu aplicacion,
                        aprende como <a href='arcoirisapp.co/help/'>aqui</a>.</p>
                </>
            )} />
        </>

    )
}

export default FormDesinger