import React from 'react'
import { EuiIcon, EuiToken } from '@elastic/eui'
import {
    Icon123, Type, Calendar4Week, Check2Square, CheckCircle, FileArrowUp,
    CurrencyDollar, PersonCircle, PersonCheck, SortNumericDown,  Window, CalendarDate,
    TextParagraph,
    GeoAlt,
    ListNested,
    MenuButtonWide,
    FileRichtext,
    InfoSquare,QrCodeScan, PlayBtn, RecordCircle, Geo,Clock

} from 'react-bootstrap-icons';


const IcoUIComponents = {
    text: Type,
    date: Calendar4Week,
    longtext: TextParagraph,
    numeric: Icon123,
    select: CheckCircle,
    linktoapp: Window,
    linktodb: 'database',
    checkbox: Check2Square,
    time: Clock,
    money: CurrencyDollar,
    user: PersonCheck,
    file: FileArrowUp,
    consecutive: SortNumericDown,
    id: 'tokenKey',
    avatar: PersonCircle,
    age: CalendarDate,
    email: 'email',
    rating: 'starEmpty',
    searchplace: GeoAlt,
    cascade: ListNested,
    country_state_city: MenuButtonWide,
    url: 'link',
    richtext: FileRichtext,
    callout: InfoSquare,
    qr:QrCodeScan,
    button:PlayBtn,
    signature:'documentEdit',
    options:RecordCircle,
    latitude:Geo,
    longitude:Geo,
    create_at:Calendar4Week,
    last_update:Calendar4Week,
    create_by:Type,
    geolocalization:Geo,
    title :'tokenParameter',
    gallery:'image',
    foreign_key:'tokenRepo'

}

const predefined_columns = ['id', 'title', 'create_at', 'last_update', 'create_by', 'geolocalization','foreign_key' ];

function IconDataGridFieldType({ typeIcon, ...rest }) {

  
    const Icon = IcoUIComponents[typeIcon];

    if(predefined_columns.includes(typeIcon))
    {
        const title = typeIcon==='id' ? 'Identificador único' : 'Columna predefinida de la base de datos';
        return <EuiToken  size='s' className="eui-alignMiddle" title={title} iconType={Icon} style={{ marginRight: 5 }} {...rest} />
        

    }else
    {
        
        return <EuiIcon size='m' className="eui-alignMiddle" type={Icon} style={{ marginRight: 5 , marginLeft :(typeIcon==='avatar' ? 5 : 0) }} {...rest} />
    }
    
}

export default IconDataGridFieldType