import { KEYS_LOCALSTORE } from "core/Keys";
import { LocalStoreHelper } from "core/LocalStore";

export interface Configuration {
    separatorThousands: string;
    separatorDecimals: string;
    dateFormat: string;
    lang: string;
}

/**
 * Retrieves the configuration settings from LocalStoreHelper.
 * @returns The configuration settings.
 */
export const useGlobalConfiguration = (): Configuration => {

    const settings = LocalStoreHelper.get(KEYS_LOCALSTORE.KEY_ACCOUNT_SETTINGS);

    return {
        separatorThousands: settings.thousand_separator,
        separatorDecimals: settings.decimal_separator,
        dateFormat : settings.date_format,
        lang: settings.lang        
    }
}