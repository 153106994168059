
import { clearToken, setToken } from "core/Token";
import ApiClient from "./api.client";
import { LocalStoreHelper } from "core/LocalStore";
import { KEYS_LOCALSTORE } from "core/Keys";
import { GLOBAL_KEY_BASE_URL } from "core/Constants";


export const AuthManager = {

  async Login(credentials) {

    const response = await ApiClient.HttpPost("auth/login", credentials);

    if (response.data.status) {
      setToken(KEYS_LOCALSTORE.KEY_TOKEN, response.data.data.token)
      LocalStoreHelper.set(KEYS_LOCALSTORE.KEY_USERPROFILE, response.data.data.user);
      LocalStoreHelper.set(KEYS_LOCALSTORE.KEY_ACCOUNT_SETTINGS, response.data.data.settings);

    }

    return response.data.status;

  },
  async RecoveryPassWord(username) {


    const response = await ApiClient.HttpGet(`auth/password/${username}/forgot`);
    return response.data;

  },
  async CheckToken(token) {

    try {
      const response = await fetch(GLOBAL_KEY_BASE_URL + "session/status", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(token)
      });

      const data = await response.json();


      return data.status;
    } catch (error) {

      return false;
    }



  },
  async GetBrandingLogin(subdomain) {

    const response = await ApiClient.HttpGet(`auth/subdomain/${subdomain}`);
    return response.data;


  },
  async CloseSession() {

 
    const close= {
      token:localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN),
      method :'web'
    }
    const response = await ApiClient.HttpPost(`auth/logout`,close);
    if (response)
      clearToken();
  },



}



