import {  EuiSelect } from "@elastic/eui";
import { JsonHelper } from "core/Json";
import { useState } from "react";


const CascadeField = ({ fieldSchema, error, fieldController, watch ,isLoading}) => {

    const [selectedOptions, setSelectedOptions] = useState('');
    const optionConvert = JsonHelper.ToJson(fieldSchema.options); 

    const options = optionConvert.map((o) => ({ value: o.label, text: o.value }));

    if(options.length > 0)
            options.unshift({ value: '', text: '' });


    const onChange = (e) => {
        const response = e.target.value
        setSelectedOptions(response)
        fieldController.onChange(response);
        
    }

    return (
        <>
            <EuiSelect
                    compressed
                    fullWidth
                    id={fieldSchema.id + ''}
                    options={options}
                    value={selectedOptions}
                    onChange={(e) => onChange(e)}
                    aria-label="Cascade"
                />

            {/* <EuiComboBox
                fullWidth
                isLoading={isLoading}
                placeholder="seleccione una opción"
                id={fieldSchema.id + ''}
                singleSelection={JSON.parse(fieldSchema.props?.multiple_selection) ? false : { asPlainText: true }}
                selectedOptions={selectedOptions}
                options={options}
                onChange={(e) => onChange(e)}
                isInvalid={!!error}
                compressed
                isClearable={false}
                prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
                append={fieldSchema.props?.append && fieldSchema.props?.append}

            /> */}
        </>
    )
}

export default CascadeField;