import { EuiButtonIcon, EuiPopover, EuiPopoverTitle, EuiToolTip,EuiNotificationBadge} from "@elastic/eui"
import { useState } from "react";
import DataGridExplorerDataBasePanel from "./panel-navigator";




function DataGridExloreDatabase() {


    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const buttonExlore = (
        <EuiToolTip title="Explorador de base de datos" content="Muestra las bases de datos y sus tablas relacionadas">
            
            <EuiButtonIcon
                aria-label='breadcrumbs'
                aria-labelledby='breadcrumbs'
                iconType="database"
                display="base"
                style={{ borderRadius: '50%' }}               
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}              
            />
            
        </EuiToolTip>);

    const closePopover = () => setIsPopoverOpen(false);


    return (
        <>
            <EuiPopover
                button={buttonExlore}
                isOpen={isPopoverOpen}
                closePopover={closePopover}
                panelPaddingSize="none"
                hasArrow={true}
            >
                <EuiPopoverTitle style={{ textAlign: 'center', padding: '8px' }} >Explorador de base de datos </EuiPopoverTitle>
                <DataGridExplorerDataBasePanel />
            </EuiPopover>
        </>
    )
}

export default DataGridExloreDatabase