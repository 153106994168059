import { EuiCallOut, EuiFieldText, EuiInputPopover } from '@elastic/eui';
import { FieldApiQuery } from 'api/field-api';
import { ShowWhen } from 'components/conditional-render/show-when';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

const TextField = ({ fieldSchema, error, fieldController, setError, clearErrors }) => {

  const enable_is_unique = fieldSchema.props?.enable_is_unique;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showCoincidenceValidation, setShowCoincidenceValidation] = useState(false);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);

  const [value, setValue] = useState(fieldController.value || '');
  const [valueDbounce] = useDebounce(value, 100);

 

  const onChangeUniqueValue = async () => {

    if (value.trim() !== '') {

      const idfield = fieldSchema.id + ''
      setError(idfield)
      setIsPopoverOpen(false)
      setIsLoadingValidation(true)

      const response = await FieldApiQuery.CheckFieldBeUnique({ field: fieldSchema.key, collection: fieldSchema.collection_id, value: valueDbounce });


      if (response.data) {
        setIsPopoverOpen(true)
        setShowCoincidenceValidation(true)
        setIsLoadingValidation(false)
        setError(idfield)

      } else {
        setShowCoincidenceValidation(false)
        clearErrors(idfield)
        setIsLoadingValidation(false)
        fieldController.onChange(valueDbounce)
      }

    } else {
      setValue('')
    }

  }

  
  const handleChange = (e) => {
    setValue(e.target.value);
  };


  return (
    <>
      <ShowWhen condition={!enable_is_unique}>
        <EuiFieldText
          fullWidth
          name={fieldSchema.id + ''}
          id={'text-field-' + fieldSchema.id}
          value={fieldController.value || ''}
          onChange={(e) => fieldController.onChange(e.target.value)}
          isInvalid={!!error}
          compressed
          prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
          append={fieldSchema.props?.append && fieldSchema.props?.append}
        />
      </ShowWhen>

      <ShowWhen condition={enable_is_unique}>
        <EuiInputPopover
          isOpen={isPopoverOpen}
          closePopover={() => setIsPopoverOpen(false)}
          closeOnScroll={true}
          fullWidth
          input={
            <EuiFieldText
              fullWidth
              name={fieldSchema.id + ''}
              id={'text-field-' + fieldSchema.id}
              value={value}
              onChange={handleChange}
              onBlur={onChangeUniqueValue}
              isInvalid={!!error || showCoincidenceValidation}
              compressed
              isLoading={isLoadingValidation}
              prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
              append={fieldSchema.props?.append && fieldSchema.props?.append}
              maxLength={fieldSchema.props?.max_length}
            />
          }
        >
          <ShowWhen condition={showCoincidenceValidation}>
            <>
              <EuiCallOut title={
                <span dangerouslySetInnerHTML={
                  { __html: `Ya existe un registro con el valor de: <b>${value}</b>` }
                } />
              }
              color="warning" iconType="warning" size='s'>
              </EuiCallOut>
            </>
          </ShowWhen>
        </EuiInputPopover>

      </ShowWhen>
    </>
  );
};

export default TextField;