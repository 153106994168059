import { EuiButton, EuiPortal } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { useState } from "react";
import ModalConfirmDelete from "./modal-confirm-delete";
import DangerButton from "components/buttons/danger-button";



function ButtonDeleteApp({ app }) {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const openModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };




    return (
        <>
            <DangerButton text={'Borrar app'} size="s" fullWidth={false} iconType={ICON_GALLERY.DELETE} onClick={openModal} />
            {isModalVisible && 
            <EuiPortal>
            <ModalConfirmDelete closeModal={closeModal} />
            </EuiPortal>
            }
        </>
    )
}

export default ButtonDeleteApp