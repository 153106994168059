import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui';
import React from 'react';

export default function AndOr() {
  return (
    <EuiFlexGroup alignItems="center" gutterSize="none">
    <EuiFlexItem><EuiHorizontalRule margin='none' /></EuiFlexItem>
    <EuiFlexItem grow={false}>Y/O</EuiFlexItem>
    <EuiFlexItem><EuiHorizontalRule/></EuiFlexItem>
  </EuiFlexGroup>
  );
}
