import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiForm, EuiI18n, EuiSpacer, } from '@elastic/eui'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { ColorPicker } from 'packages/fields/color-picker/color-picker';
import { TextField } from 'packages/fields/text-field/text-field';
import { TextArea } from 'packages/fields/text-area/text-area';
import { RowSection } from 'packages/fields/row-section';
import WorkspaceApi from 'api/workspace-api';
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalStore } from 'store/global-store';
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';

const i18n_workspace_name = (<EuiI18n token="workspace.name" default="Nombre" />)
const i18n_save_modal = (<EuiI18n token="global.create" default="Crear" />)
const i18n_cancel_modal = (<EuiI18n token="global.cancel" default="Cerrar" />)
const i18n_label_required = (<EuiI18n token="global.required" default="Esta información es requerida" />)
const i18n_workspace_duplicate = (<EuiI18n token="workspace.duplicate" default="Ya existe un espacio de trabajo con ese nombre." />)
const i18n_workspace_name_helptext = (<EuiI18n token="workspace.name.helptext" default="Identifique a una área de la compañia o equipo de trabajo" />)
const i18n_workspace_description = (<EuiI18n token="workspace.description" default="Describa su espacio de trabajo" />)


function AddWorkspaceForm({ closeModal }) {

  const { control, clearErrors, setError,handleSubmit} = useForm();
  const [validationText, setValidationText] = useState(i18n_label_required)
  const [Loading, setLoading] = useState(false)
  const setWorkspace = useGlobalStore(state => state.setWorkSpace);
  const queryClient = useQueryClient()
  const navigator = useAppRouteNavigator();
  

  const onSubmit = async (data) => {

    setLoading(true);
    clearErrors('name')
    setValidationText(i18n_label_required);

    data.creation_user = '';
    data.hash_id = '';


    const duplicate = await WorkspaceApi.CheckWorkspaceDuplicate(data.name);

    if (!duplicate.data) {

      const response = await WorkspaceApi.Create(data)
      
      if (response.status) 
      {
        
        setLoading(false);
        queryClient.invalidateQueries(["GetListWorkspace"])
        setWorkspace(response.data)
        closeModal(true);
        navigator.GotoHome();

      } else {
        setLoading(false);
      }

    } else {
      setError('name', { message: data.name + ' ya existe, intenta otro nombre.' })
      setValidationText(i18n_workspace_duplicate)
      setLoading(false);

    }

  }




  return (
    <Fragment>

      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate  >
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) =>
            <RowSection label={i18n_workspace_name} 
            display='row' 
            extraHelpText={"Este nombre debe ser único en su espacio de trabajo"}
             error={fieldState.error}
             errorText={validationText} 
             helpText={i18n_workspace_name_helptext} >
              <TextField
                field={field}
                maxLength={60}
                error={fieldState.error} />            
            </RowSection>
          }
        />

        <Controller
          name="description"
          control={control}
          rules={{ required: false }}
          render={({ field, fieldState }) =>
            <RowSection label={i18n_workspace_description} display='row' extraHelpText={null} error={fieldState.error} errorText={i18n_label_required} helpText={i18n_workspace_description} >
              <TextArea
                field={field}
                error={fieldState.error} />
            </RowSection>

          }
        />

        <Controller
          name="color"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) =>
            <RowSection label={<EuiI18n token="workspace.color"  default="Color" />} display='row'  extraHelpText={null} error={fieldState.error} helpText="Seleccione un color que identifique a su espacio de trabajo" errorText={i18n_label_required}>
              <ColorPicker
                field={field}
                error={fieldState.error} />
            </RowSection>
          }
        />


        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButtonEmpty  size='s' color="text" onClick={() => closeModal(false)}>{i18n_cancel_modal}</EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton size='s' fill type="submit" isLoading={Loading}>
              {i18n_save_modal}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </Fragment>
  )
}

export default AddWorkspaceForm
