import { Controller } from 'react-hook-form';
import useRules from 'packages/form/hooks/useRules/useRules';
import useSchemasFormStore from 'packages/form/state/schemas-store';
import { FieldTypes } from 'packages/form/components/fields';
import useValidationRules from './hooks/validationRules';
import FormRowSection from './components/fields/form-row-section';
import { useState } from 'react';

const DynamicField = ({ fieldId, formId, data, control, watch , formAction,setError ,clearErrors }) => {


  const schema_field = useSchemasFormStore((state) =>
    (state.schemas[formId] || {}).find((f) => f.id === fieldId)
  );
  const [loading, setLoading] = useState(false);


  useRules({ formId, schema_field, data , setLoading });


  const FieldTypesRender = FieldTypes[schema_field.data_type];

  const is_required_message = schema_field.props?.is_required_message;
  const is_required = schema_field.props?.is_required;
  const enable_is_unique = schema_field.props?.enable_is_unique;
  const validation_rules = useValidationRules({ is_required, enable_is_unique, validation_rules: schema_field.validations_rules });


  const ResponseMesageError = (response) => {

    const type = response?.type
    switch (type) {
      case 'max':
      case 'min':
      case 'minLength':
      case 'maxLength':
      case 'pattern':
        return response.message;

      case 'required':
        return is_required_message ? is_required_message : response?.message

      default:
        return response?.message;
    }


  }

  return(
  <Controller
      name={schema_field.key}
      rules={validation_rules}
      control={control}
      defaultValue={''}
      render={({ field: FieldController, fieldState }) =>
      (
        <FormRowSection
          schema_field={schema_field}
          display={'row'}
          formAction={formAction}
          error={fieldState.error}
          errorText={ResponseMesageError(fieldState.error)}
        >
          <FieldTypesRender
            fieldSchema={schema_field}
            error={fieldState.error}
            fieldController={FieldController}
            isLoading={loading}
            setError={setError}
            clearErrors={clearErrors}
          />
        </FormRowSection>
      )}
    />)



};

export default DynamicField;
