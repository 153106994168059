import { EuiAccordion, EuiGlobalToastList, EuiPanel, EuiText } from '@elastic/eui'
import { Fragment, useEffect, useState } from 'react';
import { useGlobalCallOutNotificationStore } from 'store/global-callout-notification-store';
import { nanoid } from 'nanoid';
import useConnectionStore from 'store/use-conection-store';


function GlobalNotificationOffline() {


    const [Toast, setToast] = useState([]);
    const [description, setDescription] = useState('');
    const [tecnical_error, setTecnicalError] = useState(null);
    const [isShow, setIsShow] = useState(false);

    const toasts = useGlobalCallOutNotificationStore((state) => state.toast);
    const isConnected = useConnectionStore(state => state.isConnected);
    const clearAllToasts = useGlobalCallOutNotificationStore((state) => state.clearAllToasts);
    const id_accordion = 'error-accordion' + nanoid();

    const title_technical_details = (
        <EuiText style={{ paddingTop: 5 }} size='s'><h5>Detalle técnico</h5></EuiText>
    )

    const template = (
        <Fragment>
            {description}
            <EuiAccordion id={id_accordion} buttonContent={title_technical_details} arrowDisplay='none'>
                <EuiPanel color="subdued">
                    {tecnical_error}
                </EuiPanel>
            </EuiAccordion>
        </Fragment>
    )


    const CloseAllToast = () => {
        clearAllToasts();
    }


    useEffect(() => {

       
         if (!isConnected) 
            {
                setIsShow(true);
                setDescription("La red no se encuentra disponible")
                setTecnicalError("La red no se encuentra disponible")
                setToast([{
                    id: 'notification-' + nanoid(),
                    title: "Conexión perdida",
                    color: "danger",
                    text:"La red no se encuentra disponible",
                    iconType: "warning",

                }])
            }
      
        // eslint-disable-next-line
    }, [isConnected])



    return (
        <>
            {isShow &&
                <EuiGlobalToastList
                    toasts={Toast}
                    toastLifeTimeMs={900000}
                    dismissToast={CloseAllToast} />}
        </>
    )
}

export default GlobalNotificationOffline
