import { EuiButtonEmpty, EuiListGroup, EuiListGroupItem, EuiPopover, EuiToolTip } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { useState } from "react";

function DataGridTools() {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const buttonTools = (<EuiButtonEmpty
        iconType={'wrench'}
        iconSide="left"
        onClick={onButtonClick}
        size="s"
        color="text"
    >Herramientas</EuiButtonEmpty>);

    return (
        <EuiPopover
            button={buttonTools}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
            ownFocus={false}

        >

            <EuiListGroup maxWidth={288} style={{ minWidth: 180 }}>




                <EuiToolTip content="Crea alertas de los cambios de forma automatica" position='right' display="block">
                    <EuiListGroupItem
                        id="2-alert-configuration"
                        iconType="bell"
                        label="Notificaciónes"
                        onClick={() => { }}
                        size='s'


                    />
                </EuiToolTip>

                <EuiListGroupItem
                    id="3-add-to-favorites"
                    iconType={'color'}
                    label="Colores de filas"
                    onClick={() => { }}
                    size='s'


                />

                <EuiToolTip content="Revisar el historial de los cambios realizados" delay='long' position='right' display="block">
                    <EuiListGroupItem
                        id="4-show-history"
                        iconType={ICON_GALLERY.CLOCK}
                        label="Actividad"
                        onClick={() => { }}
                        size='s'

                    />
                </EuiToolTip>

                <EuiListGroupItem
                    id="5-share-view"
                    iconType="link"
                    label="Compartir"
                    onClick={() => { }}
                    size='s'

                />

                <EuiListGroupItem
                    id="public-form-view"
                    iconType="popout"
                    label="Publicar formulario"
                    onClick={() => { }}
                    size='s'

                />
                <EuiListGroupItem
                    id="6-export-view"
                    iconType={ICON_GALLERY.EXPORT}
                    label="Exportar"
                    onClick={() => { }}
                    size='s'
                />
                <EuiListGroupItem
                    id="7-import-view"
                    iconType={ICON_GALLERY.IMPORT}
                    label="Importar"
                    onClick={() => { }}
                    size='s'
                />


            </EuiListGroup>



        </EuiPopover>
    )
}

export default DataGridTools;