import React, { Suspense } from "react";
import { EuiPortal, EuiProgress } from '@elastic/eui';
import { isValidToken } from './core/Token'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import MainLayout from './layouts'
//import Subscription from './pages/subscription/Index'
import Auth from './pages/auth/index'
import Home from './pages/home/index'
//import WorkspaceSetting from './pages/workspace/settings'
import AppPageContainer from './pages/apps/AppContainer'
import AppPageContainerForms from './pages/apps/forms/AppContainerForms'
import AppPageForms from './pages/apps/forms/'
import AppPageFormsPhase from './pages/apps/forms/phase'
import AppConfiguration from './pages/apps/settings/Index'

//import ViewDetails from "pages/view-details/data-app";//era la pagina de detalle 
import ColumnManager from "pages/sidebars/column-manager";
import CollectionPage from "pages/collection";
import SuscriptionPageContainer from "pages/subscription/suscription-page-container";
import SubscriptionCompanyPage from "pages/subscription/Company";
import SuscriptionConfigurationPage from "pages/subscription/Configuration";
import SuscriptionBrandingPage from "pages/subscription/Branding";
import WorkSpacePageContainer from "pages/workspace/workspace-page-container";
import EditWorkspaceForm from "pages/workspace/edit/EditWorkspaceForm";
import WorkSpaceAppPage from "pages/workspace/apps";
import WorkSpacePortalPage from "pages/workspace/portal";
import WorkSpaceUsersPage from "pages/workspace/users";
import WorkSpaceBackupsPage from "pages/workspace/backups";
import WorkSpaceActivitiesPage from "pages/workspace/activities";
import DataBaseViewContainer from "pages/views/database/database-view-container";
import TurboFormContainer from "pages/views/turbo-form/turbo-form-container";




function PrivateRoute({ children }) {
  let location = useLocation();

  if (!isValidToken())
    return <Navigate to="/auth" state={{ path: location.pathname }} />;



  return children;
}

const LoadingPage = (
  <div style={{ position: 'absolute', zIndex: '5' }}>
    <EuiPortal>
      <EuiProgress size="xs" color='primary' position="fixed" />
    </EuiPortal>
  </div>)



export const RoutesApp = [
  {
    path: "/",
    element: <PrivateRoute><MainLayout /></PrivateRoute>,
    children: [
      { path: "home", element: <Home /> },
      { path: "home/:notify", element: <Home /> },
      {
        path: "subscription", element: <SuscriptionPageContainer />,
        children: [
          {
            path: "organization",
            element: <SubscriptionCompanyPage />,
          },
          {
            path: "configuration",
            element: <SuscriptionConfigurationPage />
          },
          {
            path: "branding",
            element: <SuscriptionBrandingPage />
          }
        ],
      },
      {
        path: "workspace/", element: <WorkSpacePageContainer />,
        children: [
          {
            path: ":workspace/edit",
            element: <EditWorkspaceForm />,
          },
          {
            path: ":workspace/apps",
            element: <WorkSpaceAppPage />
          },
          {
            path: ":workspace/portal",
            element: <WorkSpacePortalPage />
          },
          {
            path: ":workspace/users",
            element: <WorkSpaceUsersPage />
          },
          {
            path: ":workspace/backups",
            element: <WorkSpaceBackupsPage />
          },
          {
            path: ":workspace/activities",
            element: <WorkSpaceActivitiesPage />
          }

        ],
      },
      {
        path: "app/:app/:toolbar", element: <AppPageContainer />,
        children: [
          {
            path: "form",
            element: <AppPageContainerForms />,
            children: [
              { index: true, element: <AppPageForms /> },
              { path: "phase", element: <AppPageFormsPhase /> },
            ]
          },
          { path: "configuration", element: <AppConfiguration /> },
        ],
      },
      {
        path: "collection/:collection/:view_type/:view",
        element: <DataBaseViewContainer />,
        errorElement: <h1>Error</h1>,
        children: [{ path: "column-manager", element: <EuiPortal><ColumnManager /></EuiPortal> }],

      },
      {
        path: "collection/:collection/:view_type/:view/:name_view",
        element: <DataBaseViewContainer />,
        errorElement: <h1>Error</h1>,
        children: [{path: "column-manager",element: <EuiPortal><ColumnManager /></EuiPortal>}],

      },
      { path: "collection/:collection/details/:record", element: <CollectionPage /> },
      {
        path: "collection/:collection/turbo-form/",
        element: <TurboFormContainer />       

      },
    ],
  },
  { path: "/auth", element: <Auth /> }
];


const AppRoutes = () => {
  let pages = useRoutes(RoutesApp);
  return (<Suspense fallback={LoadingPage}>{pages}</Suspense>)
};

export default AppRoutes;


