import { EuiPanel, EuiProgress } from "@elastic/eui";


function  ProgressPanel({...props}) {
    return (
        <EuiPanel color="subdued" paddingSize='xs' {...props}>
            <EuiProgress size="xs" color="primary"  />
        </EuiPanel>
    )
}
export default ProgressPanel;