import { useQueryClient } from "@tanstack/react-query";



export function useInvalidateQueryByKey(keys) {
    const queryClient = useQueryClient();


    const InvalidateQuery = async () => {

        for (const key of keys) {
                queryClient.invalidateQueries([key])
        }
    }

    return { InvalidateQuery }


}