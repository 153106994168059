import { EuiSplitPanel } from "@elastic/eui"




function TurboFormPage() {
    return (
        <EuiSplitPanel.Outer direction="row" hasShadow={false} hasBorder={false} style={{ height: '100%' }}>
            <EuiSplitPanel.Inner
                color="subdued"
                css={{ blockSize: 'auto', inlineSize: '85%' }}
                style={{ blockSize: 'auto', inlineSize: '85%' }}
                paddingSize='none'
            >
                <EuiSplitPanel.Outer hasShadow={false} hasBorder={false} borderRadius='none' style={{ height: '99%' }} >
                    <EuiSplitPanel.Inner paddingSize='none' grow={false} >

                    </EuiSplitPanel.Inner>

                    <EuiSplitPanel.Inner paddingSize="none" style={{ height: '99%', overflow: 'hidden' }} >

                    </EuiSplitPanel.Inner>
                </EuiSplitPanel.Outer>
            </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
    )
}
export default TurboFormPage