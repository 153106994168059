import React, { useEffect, useState } from 'react';
import { EuiDragDropContext, EuiDraggable, EuiDroppable, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, euiDragDropReorder, EuiButtonEmpty, EuiSpacer, EuiAccordion, EuiNotificationBadge, } from '@elastic/eui';
import { useGlobalStore } from 'store/global-store';
import AnimatePanelWrapper from 'components/animation/animate-panel-wrapper';
import { nanoid } from 'nanoid';
import { GLOBLAL_KEY_NANO_ID_LENGT } from 'core/Constants';
import ItemCascadeOption from './field-item-cascade';



function FieldRowCascade({ GetOptions, CurrentItem, onRemove, onReorder, resetOptions, data }) {

    const currentTheme = useGlobalStore(state => state.theme);
    const isDraggingOverClass = currentTheme === 'dark' ? 'euiDroppable--isDraggingOver dark' : 'euiDroppable--isDraggingOver light';
    const id_cascade = 'accordion-cascade-' + nanoid(GLOBLAL_KEY_NANO_ID_LENGT);
    const [list, setList] = useState([{ id: nanoid(GLOBLAL_KEY_NANO_ID_LENGT), value: '', label: '', color: '', dependent: '' }]);
    const [totalItems, setTotalItems] = useState(0);
    const [data_field,] = useState(data?.options ? JSON.parse(data?.options) : []);


    const onDragEnd = ({ source, destination }) => {


        if (source && destination) {
            let order = [...list];
            const ordered = euiDragDropReorder(order, source.index, destination.index);
            setList(ordered);
            onReorder(ordered)

        }

    };


    const addItemOption = () => {
        let add = [...list];
        add.push({ id: nanoid(GLOBLAL_KEY_NANO_ID_LENGT), value: '', label: '', color: '', dependent: '' })

        setList(add)
        GetOptions(add)
    }


    const updateItemOption = (item, is_enter) => {

        let newList = list.map(el =>
        (
            el.id === item.id ? { ...el, value: item.value, label: item.label, color: item.color, dependent: CurrentItem.value } : el
        ))

        if (is_enter) {
            const isBlankValueExists = list.some((el) => el.value === '');
            if (!isBlankValueExists)
                newList.push({ id: nanoid(GLOBLAL_KEY_NANO_ID_LENGT), value: '', label: '', color: '', dependent: '' })
        }

        setTotalItems(newList.length - 1)
        setList(newList)
        GetOptions(newList)

    }


    const removeItemOption = (index) => {


        var array = [...list];
        const length = array.length - 1;
        const removed = array.filter(el => el.id !== index);
        setList(removed)
        onRemove(index);
        setTotalItems(length)

    }




    useEffect(() => {

        if (resetOptions > 1) {
            setList([])
            setList([{ id: nanoid(GLOBLAL_KEY_NANO_ID_LENGT), value: '', label: '', color: '', dependent: '' }])
            setTotalItems(0)
        }


        if (data_field.length > 0) {
            const options_of_items = data_field.filter(option => option.dependent.trim() == CurrentItem.value.trim())
            setList(options_of_items)
            setTotalItems(options_of_items.length)
        }

    }, [resetOptions])


    return (
        <>
            <EuiPanel hasShadow={false} paddingSize='s' hasBorder={true}  >
                <EuiAccordion
                    id={id_cascade}
                    paddingSize='m'
                    buttonContent={'' + CurrentItem.value}
                    extraAction={
                        <EuiNotificationBadge
                            color='subdued'
                            title='Numero de Opciones'
                        >{totalItems}
                        </EuiNotificationBadge>}
                >

                    <EuiPanel hasShadow={false} color='subdued' className="eui-scrollBar" >
                        <EuiDragDropContext onDragEnd={onDragEnd}>
                            <EuiDroppable
                                droppableId="DROPPABLE_AREA_2"
                                spacing="none"
                                className={isDraggingOverClass}
                            >
                                {list.length > 0 && list.map((content, idx) => (
                                    <AnimatePanelWrapper key={'option-' + content.id}>
                                        <EuiDraggable
                                            className="eui-scrollBar"
                                            spacing="m"
                                            key={content.id}
                                            index={idx}
                                            draggableId={content.id}
                                            hasInteractiveChildren={true}
                                        >
                                            {(provided) => (
                                                <EuiPanel className="eui-scrollBar"
                                                    color='subdued'
                                                    paddingSize="none"
                                                    key={'option-' + content.id}
                                                    hasShadow={false}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <EuiFlexGroup alignItems="center" gutterSize="none">
                                                        <EuiFlexItem grow={false}>
                                                            <EuiPanel
                                                                color="transparent"
                                                                paddingSize="none"
                                                                aria-label="Drag Handle"
                                                            >
                                                                <EuiIcon type="grab" />
                                                            </EuiPanel>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem >
                                                            <ItemCascadeOption
                                                                index={content.id}
                                                                OnUpdate={updateItemOption}
                                                                dependentItem={CurrentItem.value}
                                                                OnRemove={removeItemOption}
                                                                item={list[idx]}
                                                                currentList={list} />
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                </EuiPanel>
                                            )}
                                        </EuiDraggable>
                                    </AnimatePanelWrapper>
                                ))}
                            </EuiDroppable>
                        </EuiDragDropContext>
                        {list.length === 0 &&
                            <EuiPanel
                                color="subdued"
                                style={{ position: 'sticky', bottom: 0 }}
                                paddingSize="s">
                                <EuiButtonEmpty
                                    size="xs"
                                    iconType="plusInCircle"
                                    style={{ width: '100%' }}
                                    onClick={() => { addItemOption() }}
                                >
                                    Agregar una opción de respuesta
                                </EuiButtonEmpty>
                            </EuiPanel>
                        }
                    </EuiPanel>
                </EuiAccordion>
            </EuiPanel>
            <EuiSpacer size='xs' />
        </>
    )
}

export default FieldRowCascade

