export const TimeZoneOptions = [
    {
        value : "E. South America Standard Time",
        text : "(GMT-03:00) Brasilia"
    },
    {
        value : "Argentina Standard Time",
        text : "(GMT-03:00) Buenos Aires"
    },
    {
        value : "SA Eastern Standard Time",
        text : "(GMT-03:00) Georgetown"
    },
    {
        value : "Montevideo Standard Time",
        text : "(GMT-03:00) Montevideo"
    },
    {
        value : "Atlantic Standard Time",
        text : "(GMT-04:00) Atlantic Time (Canada)"
    },
    {
        value : "SA Western Standard Time",
        text : "(GMT-04:00) La Paz"
    },
    {
        value : "Central Brazilian Standard Time",
        text : "(GMT-04:00) Manaus"
    },
    {
        value : "Pacific SA Standard Time",
        text : "(GMT-04:00) Santiago"
    },
    {
        value : "Venezuela Standard Time",
        text : "(GMT-04:30) Caracas"
    },
    {
        value : "SA Pacific Standard Time",
        text : "(GMT-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    {
        value : "Eastern Standard Time",
        text : "(GMT-05:00) Eastern Time (US & Canada)"
    },
    {
        value : "US Eastern Standard Time",
        text : "(GMT-05:00) Indiana (East)"
    },
    {
        value : "Central America Standard Time",
        text : "(GMT-06:00) Central America"
    },
    {
        value : "Central Standard Time",
        text : "(GMT-06:00) Central Time (US & Canada)"
    },
    {
        value : "Central Standard Time (Mexico)",
        text : "(GMT-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        value : "US Mountain Standard Time",
        text : "(GMT-07:00) Arizona"
    },
    {
        value : "Mountain Standard Time (Mexico)",
        text : "(GMT-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        value : "Mountain Standard Time",
        text : "(GMT-07:00) Mountain Time (US & Canada)"
    },
    {
        value : "Pacific Standard Time",
        text : "(GMT-08:00) Pacific Time (US & Canada)"
    },
    {
        value : "Pacific Standard Time (Mexico)",
        text : "(GMT-08:00) Tijuana, Baja California"
    }
    ];