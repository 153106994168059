import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiPanel, EuiRadioGroup, EuiSpacer, } from "@elastic/eui"
import { CollectionApiCommand } from "api/collections/command-api";
import { QUERY_KEYS_COLLECTION } from "api/collections/query-api";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import IconPicker from "components/icon-picker";
import NotifySucces from "components/notifications/notify-success";
import QuickMessageTooltip from "help/quick-messaje-tooltip";
import { useAppParameters } from "hooks/useAppParameters";
import { RowSection } from "packages/fields/row-section";
import { TextField } from "packages/fields/text-field/text-field";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";


function CreateCollection() {

    const { control, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [icon, setIcon] = useState('index');
    const [relation_type, setRelationType] = useState('OneToOne');
    const { collection } = useAppParameters();

    const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_COLLECTION.GET_COLLECTION_NAVIGATOR, collection]);


    const onClickIcon = (icon) => setIcon(icon)
    const radiosOptions = [
        {
            id: 'OneToOne',
            label:
                'Entrada única',
        },
        {
            id: 'OneToMany',
            label: 'Entradas multiples',
        },
    ];


    const onSubmit = async (data) => {

        setIsLoading(true)
        const payload = {

            collection_id: collection,
            collection_foreign_name: data.name,
            collection_foreign_description: '',
            collection_foreign_type: relation_type,
            collection_foreign_icon: icon,
        }


        const response = await CollectionApiCommand.CreateSubCollection(payload);

        if (response.status) {
            InvalidateQuery()
            setIsSuccess(true)
            setIsLoading(false)
        }

    }

    return (
        <>
            <EuiPanel paddingSize="m"  >

                {isSuccess && <NotifySucces conffeti={false} title={'Tabla creada con exito'} description={'Quedo asociada a la dase de datos y puedes editarla'} />}
                {!isSuccess && (
                    <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate >
                        <>
                            <EuiFlexGroup gutterSize='xs' direction="column">
                                <EuiFlexItem>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) =>
                                            <RowSection
                                                labelAppend={
                                                    <QuickMessageTooltip text={''} position="right" titleToolTip="Tabla de datos" descriptionToolTip="Esta tabla permite que puedas extender la información de la dase de datos principal y se asocia automaticamente" />
                                                }
                                                label={"Nombre de la tabla"} display="row" extraHelpText={null} error={fieldState.error} errorText={''} helpText={'Escribe el nombre de la tabla y un icono'} >
                                                <TextField
                                                    fullWidth
                                                    field={field}
                                                    maxLength={200}
                                                    error={fieldState.error}
                                                    prepend={<IconPicker onChange={onClickIcon}
                                                    />

                                                    }
                                                />
                                            </RowSection>
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <Controller
                                        name="relation_type"
                                        control={control}
                                        render={({ field, fieldState }) =>
                                            <RowSection hasEmptyLabelSpace={true} label='Número de entradas' error={fieldState.error} display={'row'} errorText={fieldState.error} extraHelpText={null} helpText={'Selecciona si quieres que puedas registrar un registro o varios'}>
                                                <>
                                                    <EuiRadioGroup
                                                        options={radiosOptions}
                                                        idSelected={relation_type}
                                                        onChange={(id) => setRelationType(id)}
                                                        name="radio group"
                                                        compressed

                                                    />
                                                </>
                                            </RowSection>
                                        }
                                    />
                                </EuiFlexItem>

                            </EuiFlexGroup>

                        </>
                        <EuiSpacer />
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiButton fill size="s" type="submit" isLoading={isLoading}>
                                    Crear
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiForm>
                )}

            </EuiPanel>
        </>
    )

}

export default CreateCollection

