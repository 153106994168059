
import {
  ChatLeftDots,
  Sliders,
  Images,
  House,
  Trash,
  Printer,
  FilePerson,
  ThreeDotsVertical,
  PencilSquare,
  InfoCircle,
  Clock,
  ArrowLeftCircle,
  ArrowLeft,
  CodeSquare,
  CodeSlash,
  LayoutTextSidebarReverse,
  ArrowDownUp,
  Star,
  Upload,
  Download,
  TextIndentLeft,
  CalendarDate,
  Speedometer2,
  Asterisk,
  WifiOff,
  CurrencyDollar,
  Percent,
  Icon123,
  RocketTakeoff,
  Magic,
  ExclamationCircle,
  Search,
  Kanban,
  Robot,
  LayoutThreeColumns,
  RCircle,
  People,
  Tablet,
  Key
  

} from 'react-bootstrap-icons';



export const ICON_GALLERY = {

  KEY: Key,
  MOVIL: Tablet,
  USERS: People,
  COMMENTS: ChatLeftDots,
  DELETE: Trash,
  HOME: House,
  MORE: ThreeDotsVertical,
  PRINTER: Printer,
  IMAGE_GALERY: Images,
  SLIDERS: Sliders,
  RESUME: FilePerson,
  PENCIL1: PencilSquare,
  TRASH: Trash,
  INFO_CIRCLE: InfoCircle,
  CLOCK: Clock,
  ARROW_LEFT_CIRCLE: ArrowLeftCircle,
  ARROW_LEFT: ArrowLeft,
  CODE_SQUARE: CodeSquare,
  CODE_SLASH: CodeSlash,
  COLUM_SIDEBAR: LayoutTextSidebarReverse,
  IMPORT_EXPORT: ArrowDownUp,
  IMPORT: Upload,
  EXPORT: Download,
  FAVORITE: Star,
  ADD_SECCTION: TextIndentLeft,
  AGE: CalendarDate,
  DASHBOARD: Speedometer2,
  REQUIRE: Asterisk,
  DISCONECT: WifiOff,
  MONEY: CurrencyDollar,
  PERCENT: Percent,
  NUMERIC: Icon123,
  APPEMPTY:RocketTakeoff,
  MAGIC:Magic,
  ERROR:ExclamationCircle,
  SEARCH:Search,
  KANBAN:Kanban,
  OPEN_RESUMEN:LayoutTextSidebarReverse,
  ROBOT:Robot,
  LAYOUT:LayoutThreeColumns,
  REQUIRED:RCircle,



}



