/* keys for local storage */
const KEY_TOKEN = "user-token";
const KEY_USERPROFILE = "user-profile";
const KEY_ACCOUNT_SETTINGS = "account-settings";
const KEY_CURRENT_APP_SETTINGS = "current-app-settings";
const KEY_CURRENT_DATABASE_ROWS = "current-database-rows";
const KEY_ONESPACE_STORE = "onespace-store";

export const KEYS_LOCALSTORE = 
{
   KEY_TOKEN:KEY_TOKEN,
   KEY_USERPROFILE:KEY_USERPROFILE,
   KEY_ACCOUNT_SETTINGS:KEY_ACCOUNT_SETTINGS,
   KEY_CURRENT_APP_SETTINGS:KEY_CURRENT_APP_SETTINGS,
   KEY_CURRENT_DATABASE_ROWS:KEY_CURRENT_DATABASE_ROWS,
   KEY_ONESPACE_STORE:KEY_ONESPACE_STORE
}

export const KEYS_GLOBAL ={
   KEY_SIDE_NAV_DB: "side-nav-db-",
}




