import { EuiBreadcrumbs, EuiCode, EuiComment, EuiCommentList,  EuiIcon, EuiPortal,  EuiText } from "@elastic/eui";
import CustomFlyout from "components/flyout";
import { KEYS_STORE } from "store/keys-store";
import useVisibilityStore from "store/visibility-store";



function NotificationPanel() {

    const isVisible =false // useVisibilityStore((state) => state.componentVisibility[KEYS_STORE.VISIBILITY_COMPONENT.NOTIFICATION_PANEL]);


    const breadcrumbs = [
        {
            text: <EuiIcon type="bell" />,

        },
        {
            text: "Notificaciones",

        },
        {
            text: "Bandeja de entrada",

        },

    ];

    const body = (
        <>
            <EuiCommentList aria-label="An example with different timeline icons">
                <EuiComment
                    username="andred"
                    timelineAvatarAriaLabel="Andre Diaz"
                    event="is using a default avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The avatar initials is generated from the <EuiCode>username</EuiCode>{' '}
                            prop.
                        </p>
                    </EuiText>
                </EuiComment>

                <EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment><EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment><EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment><EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment><EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment><EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment>

                <EuiComment
                    username="cat"
                    timelineAvatarAriaLabel="Beatiful cat"
                    event="is using a custom avatar"
                    timelineAvatar="bell"
                >
                    <EuiText size="s">
                        <p>
                            The <EuiCode>timelineAvatar</EuiCode> is using a custom{' '}
                            <strong>EuiAvatar</strong>.
                        </p>
                    </EuiText>
                </EuiComment>
            </EuiCommentList>
        </>
    );


    return (
        <EuiPortal>
            {isVisible && 
            <CustomFlyout
                headerContent={
                    <EuiBreadcrumbs
                        max={4}
                        breadcrumbs={breadcrumbs}
                        aria-label="lista de notificaciones"
                    />}
                bodyContent={body}
                ownFocus={false} />}
        </EuiPortal>

    )

}

export default NotificationPanel
