const i18nMaping = {
  es: {
    'euiColumnSelector.button': 'Columnas',
    'euiTablePagination.rowsPerPage': 'Registros por pagina',
    'euiColumnSelector.search': 'Buscar',
    'euiColumnSelector.searchcolumns': 'Buscar',
    'euiColumnSelector.selectAll': 'Mostrar todas',
    'euiColumnSelector.hideAll': 'Ocultar todas',
    'euiColumnActions.hideColumn': 'Ocultar',
    'euiColumnActions.moveLeft': 'Mover a la izquierda',
    'euiColumnActions.moveRight': 'Mover a la derecha',
    'euiColumnSelector.buttonActiveSingular': '{numberOfHiddenFields} columna oculta',
    'euiColumnSelector.buttonActivePlural': '{numberOfHiddenFields} columnas ocultas',
    'euiTablePagination.rowsPerPageOption': '{rowsPerPage} registros',
    'euiForm.addressFormErrors': 'Ha ocurrio algo inesperado y no fue posible completar la operación.',
    'euiComboBoxOptionsList.noMatchingOptions': 'No se encontraron resultados',
    'euiComboBoxOptionsList.allOptionsSelected': 'Ha seleccionado todas las opciones disponibles',
    'euiTourStep.endTour': 'Terminar el tour',
    'euiTourFooter.closeTour': 'Cerrar',
    'euiTourStep.skipTour': 'Omitir el tour',
    'euiTourStep.closeTour': 'Ocultar',
    'euiComboBoxOptionsList.noAvailableOptions': 'No hay opciones disponibles',
    'euiComboBoxOptionsList.alreadyAdded': 'Ya fue agregado',
    'euiComboBoxOptionsList.createCustomOption': ' Agregar {searchValue} ',
    'euiComboBoxOptionsList.loadingOptions': 'Cargando opciones',
    'euiDisplaySelector.resetButtonText': 'Restablecer',
    'euiDisplaySelector.densityLabel': 'Densidad',
    'euiDisplaySelector.labelCompact': 'Compacto',
    'euiDisplaySelector.labelExpanded': 'Expandido',
    'euiDisplaySelector.rowHeightLabel': 'Altura de fila',
    'euiDisplaySelector.labelSingle': 'Sencilla',
    'euiDisplaySelector.labelAuto': 'Auto',
    'euiDisplaySelector.labelCustom': 'Personalizado',
    'euiDisplaySelector.lineCountLabel': 'Lineas',
    'euiFullscreenSelector.fullscreenButton': 'Pantalla completa',
    'euiFullscreenSelector.fullscreenButtonActive': 'Salir de pantalla completa',
    'euiDisplaySelector.buttonText': 'Personalizar la vista',
    'euiTablePagination.allRows': 'Todos los registros',
    'euiPagination.of': 'de',
    'euiPagination.pageOfTotalCompressed': '{page} de {total}',
    'euiPaginationButtonArrow.nextPage': 'Pagina siguiente',
    'euiPaginationButtonArrow.lastPage': 'Ultima pagina',
    'euiPaginationButtonArrow.previousPage': 'Pagina anterior',
    'euiPaginationButtonArrow.firstPage': 'Primera pagina',
    'euiColorPicker.transparent': 'Transparente',

  },
  en: {
    'terms': 'Terms of service | arcoirisapp ',
    'global.cancel': 'Cancel',
    'global.create': 'Add',
    'global.close': 'Close',
    'global.save': 'Save',
    'global.save.change': 'Save changes',
    'global.update': 'Update',
    'global.duplicate': 'A name already exists, try again',
    'global.name': 'A name already exists, try again',
    'global.description': 'A name already exists, try again',
    'global.configuration': 'Configuration',
    'global.required': 'Is required',
    'global.readonly': 'Is read only',
    'global.server.error.title': 'Something unexpected happened',
    'global.server.error.detail': 'the task could not be completed,try again or contact the help center.',
    'global.server.error': 'An unexpected event has occurred and the task could not be completed',
    'global.server.disconect': 'The connection to the server has been lost, try refreshing the page',
    'global.notify.title.success': 'It was done successfully',
    'global.notify.title.alert': 'Something important',
    'global.notify.title.error': 'An error has occurred',
    'global.notify.title.upgrade': 'Upgrade plan',
    'global.notify.title.upgrade.description': '',
    'global.notify.button.upgrade': 'change plan',
    'global.notify.title.forbidden': 'Acces forbidden',
    'global.industry.options': 'Agency (Advertising/ Marketing),Consulting,E-commerce / Retail,Finance / Accounting,Health Care,Media / Entertainment,Non-profit,Education,Goverment,Software,IT,Real Estate',
    'global.role.options': 'Business Owner / CEO,Customer Service,Design,Engineering, IT &amp; DevOps,Finance &amp; Accounting,HR &amp; Recruiting,Marketing,Operations,Project / Product Management,Sales',
    'login.title.welcome': 'Welcome back',
    'login.subtitle': 'Select an option to continue',
    'login.with.email': 'Log in with Email',
    'login.with.sso': 'Log in with SSO',
    'login.with.fogot': 'Reset password',
    'login.return': 'Login options',
    'login.callout.invalid.title': 'wrong username or password.',
    'login.callout.error.text': 'Please verify  email or password',
    'login.username': 'Email address.',
    'login.username.help': 'Write your email address.',
    'login.username.placeholder': 'email@domain.any',
    'login.username.error': 'Please enter a valid email',
    'login.password': 'Password',
    'login.password.help': 'Write your password',
    'login.password.error': 'Please enter a valid password',
    'login.submit': 'Log in',
    'forgot.callout.error.text': 'Your account could not be found.',
    'forgot.callout.error.subtext': 'Verify that the email is valid',
    'forgot.callout.error.title': 'Please enter a valid email',
    'forgot.callout.success.text': 'We will send instructions to the emai with recovery information.',
    'forgot.label.email': 'Email address.',
    'forgot.label.email.help': 'Write your email address.',
    'forgot.label.email.required': 'Write a valid email.',
    'forgot.label.email.placeholder': 'email@domain.any',
    'forgot.button.submit.text': 'Send new password',
    'company.menu.data': 'Company data',
    'company.menu.configuration': 'Company configuration',
    'company.menu.branding': 'Branding',
    'company.menu.statistics': 'Branding',
    'company.menu.subtitle': 'Subscription settings',
    'company.label.name': 'Name',
    'company.label.email': 'Email',
    'company.label.phone': 'Phone',
    'company.label.address': 'Address',
    'company.label.country': 'Country',
    'company.label.postalcode': 'Postal Code',
    'company.label.delete': 'Delete account',
    'company.label.warning': 'This option permanently deletes your account and there is no way to recover the information',
    'company.title.warning': 'Proceed with caution!',
    'company.configuration.title.success': 'Configuration updated',
    'company.configuration.title.timezone': 'Timezone',
    'company.configuration.title.formatdate': 'Date format',
    'company.configuration.title.formatnumber': 'Format of the numbers',
    'company.configuration.title.lang': 'Platform language',
    'company.brandig.logo': 'Logo',
    'company.brandig.logo.subtitle': 'Images of 200 x 100 and no larger than 1 MB are accepted ',
    'company.brandig.logo.input': 'Upload your logo',
    'company.brandig.login.subtitle': 'Images of 1200 x 850 and no larger than 4 MB are accepted ',
    'company.brandig.login.input': 'Upload your image',
    'company.brandig.title.success': 'It was successfully updated, the changes will be reflected in the next session start ',
    'company.brandig.error.title': 'Image could not be uploaded',
    'company.brandig.error.validate.logo': 'Validate that the size of the logo is 200 x 45 (px) and no larger than 1MB.',
    'company.brandig.error.validate.login': 'Validate that the size of the authentication image is 1200 x 800 (px) and no larger than 2MB',
    'company.brandig.loginimage': 'Authentication Image',
    'workspace.create.button.text': 'create',
    'workspace.create.title': 'Create new WorkSpace',
    'workspace.name': 'Name WorkSpace',
    'workspace.color': 'Color',
    'workspace.name.helptext': 'Identify an area of ​​the company or work team',
    'workspace.loading.messaje': 'loading WorkSpaces',
    'workspace.default': 'My first WorkSpace',
    'workspace.description': 'Description WorkSpace',
    'workspace.create.succes': 'Now you can see the new space in the list',
    'workspace.create.error': '-------------------------------t',
    'workspace.list.title': 'Workspaces',
    'workspace.duplicate': 'A workspace with that name already exists',
    'workspace.empty': 'No Workspaces available',
    'workspace.update': 'Workspaces update',

    'space.create.title': 'Create new App',
    'space.create.name.helptext': 'Enter the name of the App',
    'space.create.name': 'Name',
    'space.create.emoji': 'Select emoji for the App',
    'space.create.succes': 'Now you can see the new module in the side bar',

    'sidebar.icon.space.tooltip': 'Workspace',
    'sidebar.icon.gohome.tooltip': 'Go to home page',
    'sidebar.icon.config.tooltip': 'Configuration suscription',
    'sidebar.icon.close.tooltip': 'Hide sidebar',
    'sidebar.icon.notification.tooltip': 'Notification panel',
    'sidebar.icon.addmodule.tooltip': 'Add module',
    'sidebar.icon.search': 'Search',
    'sidebar.icon.user.tooltip': 'My Profile',
    'profile.menu.label.configuration': 'My configuration',
    'profile.menu.label.logout': 'Logout',
    'view.add.title': 'Add view data',
    'view.add.save': 'Create view',
    'view.add.helptext': 'Enter the name of the view',
    'view.add.title.card': 'Cards view',
    'view.add.title.table': 'Table view',
    'view.add.title.gantt': 'Gantt view',
    'view.add.title.dashboard': 'Dashboard view',
    'view.add.title.report': 'Report view',
    'view.add.title.page': 'Web Page view',
    'view.add.title.kanban': 'Kanban view',
    'view.add.title.block': 'Blocks view',

    'form.editor.sidebar.column.available': 'Available fields',
    'form.editor.sidebar.column.add': 'Add field',
    'form.editor.sidebar.column.rule.title': 'Manage columns rules and conditions',

    'form.field.name': 'Name field',
    'form.field.placeholder': 'Write up to 255 characters',
    'form.field.type': 'Type field',
    'form.field.required': 'This field is required',
    'form.field.name.already.exist': 'This field name already exists in this app',
    'form.field.name.duplicate.response': 'This field does not accept duplicate responses',
    'form.field.name.invalid.characters': 'Please do not use the character ',
    'form.field.options': 'Answer options',
    'form.field.default.value': 'Default value',
    'form.field.default.value.helptext': 'Enter the default value',
    'form.field.help': 'Help text',
    'form.field.help.helptext': 'Enter the help text',
    'form.field.help.url': 'Enter Url',
    'form.field.link.help': 'Help link',
    'form.field.link.helptext': 'Insert an external or internal link to complement the help of this field',
    'form.button.create': 'Create',
    'form.button.add.again': 'Create another field',
    'form.field.success.create': 'Field created successfully',
    'form.field.way.cant.change.type': 'You can not change the type of this field',
    'form.field.way.cant.change.type.tooltip': 'You can not change the type of this field',
    'form.field.enable.options.colors': 'Enable colors',
    'form.validator.option.none': 'None',
    'form.validator.option.email': 'Email',
    'form.validator.option.email.tooltip': 'Validate Email',
    'form.validator.option.rangeChar': 'Limit character',
    'form.validator.option.rangeChar.tooltip': 'Limit number of the caracters',
    'form.validator.option.custom.tooltip': 'You can use your own validation rule using a custom regular expression.',
    'form.validator.option.custom': 'Regular Expression',
    'form.validator.option.rangeNumber.tooltip': 'Number range',
    'form.validator.option.rangeNumber': 'Set a minimum and maximum value'





  },
};

const getCurrentlang = () => {
  if (localStorage.getItem("current-lang") === 'undefined' || localStorage.getItem("current-lang") === "")
    return "es";
  else
    return localStorage.getItem("current-lang");
};

const setlang = () => {
  var userLang = navigator.language || window.navigator['userLanguage'];
  localStorage.setItem("current-lang", userLang);
};



const i18n = {
  mapping: i18nMaping[getCurrentlang()]
};

export { i18n, setlang, getCurrentlang };