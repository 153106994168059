import { EuiAccordion,  EuiBetaBadge,  EuiButton,  EuiButtonEmpty,  EuiCallOut,  EuiComboBox,  EuiFieldText,  EuiFlexGroup,  EuiFlexItem,  EuiForm,  EuiFormRow,  EuiHorizontalRule,  EuiIcon,  EuiInputPopover,  EuiLink,  EuiPanel,  EuiSpacer,  EuiSwitch,  EuiText,  EuiToolTip,  useEuiTheme } from "@elastic/eui";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PATTERN_VALIDATOR } from "core/Patterns";
import SuperSelectWithColorOptions from "packages/field-creator/components/super-select-with-color-options";
import FieldSelector from "packages/field-creator/components/field-selector";
import FieldNumericFormatSelector from "packages/field-creator/components/field-format-selector";
import FieldValidator from "packages/field-creator/components/field-validator";
import AnimatePanelWrapper from "components/animation/animate-panel-wrapper";
import { ShowWhen } from "components/conditional-render/show-when";
import BottomBar from "components/bottom-bar";
import NotifySucces from "components/notifications/notify-success";
import { QUERY_KEYS_ATTRIBUTE, AttributeApiCommand, AttributeApiQuery } from "api/attributes-api";
import FieldValidatorUpload from "./components/field-validator-upload";
import SectionComponent from "./components/section-component";
import { useGlobalStore } from 'store/global-store';
import FieldConsecutive from "./components/field-consecutive";
import { useInvalidateQuery } from "api/invalidate-querys/invalidate-current-views";
import FieldUser from "./components/field-user";
import i18n from "./index.i18n";
import FieldCascadeOptions from "./components/field-cascade/field-cascade";
import CalloutError from "components/notifications/callout/callout-error";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import FieldGalleryUpload from "./components/field-gallery/field-gallery";






function FormFieldCreator({ action, data, compressed, collection }) 
{

  let parameters = useParams();
  const currentTheme = useGlobalStore((state) => state.theme);
  const view_id = parameters.view;
  const optionsBydefult = data?.options && ["select", "checklist", "cascade"].includes(data.data_type) ? JSON.parse(data?.options) : [{ label: "", value: "" }];
  const  consecutiveDefault = data?.options && (JSON.parse(data?.options)  || [{ sufix: "", prefix: "" }])
  const emptyValidatorRules = data?.validations_rules || {
    type: 'none',
    min: 0,
    max: 0,
    min_length: 0,
    max_length: 0,
    pattern: '',
    validate: '',
    message: ''    
  };



  const emptyFormat = data?.numeric_props || {
    allow_thousands_separator: false,
    decimal_scale: 0,
    mask: '',
    format: '',
    allow_negative: false,
    max_length: '12',
    type: 'number',
    prefix: '',
  };

  const emptyUploadProps = data?.upload_props || {
    max_files: 1,
    max_file_size: 5,
    allowed_extensions: ['image/*']
  }


  const euiThemeContext = useEuiTheme();
  const euiTheme = euiThemeContext.euiTheme;
  const theme = useGlobalStore(state => state.theme);

  const [typeFieldValue, setTypeFieldValue] = useState(data?.data_type || "");
  const [inputNameValue, setinputNameValue] = useState(data?.name || "");
  const [onSuccess, setonSuccess] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isRequired, setisRequired] = useState(data?.props.is_required || false);
  const [isRequiredMessage, setisRequiredMessage] = useState(data?.props.is_required_message || "Esta columna es requerida");
  const [invalidNameField, setInvalidNameField] = useState(false);
  const [invalidNameFieldError, setInvalidNameFieldError] = useState("");
  const [enableSensibleData, setEnableSensibleData] = useState(data?.is_pii || false);
  const [enableIsUnique, setenableIsUnique] = useState(data?.props?.enable_is_unique || false);
  const [enableHelpText, setenableHelpText] = useState(data?.props.enable_help_text || false);
  const [helpText, sethelpText] = useState(data?.props.help_text || "");
  const [enableHelpLink, setenableHelpLink] = useState(data?.props.enable_help_link || false);
  const [enableDefaultValue, setenableDefaultValue] = useState(data?.props.enable_default_value || false);
  const [enableColorPicker, setenableColorPicker] = useState(data?.props.enable_color_picker || false);
  const [enableEditorOptions, setenableEditorOptions] = useState(data?.props.enable_editor_options || false);
  const [enableAddOptions, setenableAddOptions] = useState(data?.props.enable_add_options || false);
  const [enableAppendPrepend, setenableAppendPrepend] = useState(data?.props.enable_append_prepend || false);
  const [helpLinkText, sethelpLinkText] = useState(data?.props.help_link_text || "");
  const [helpLinkUrl, sethelpLinkUrl] = useState(data?.props.help_link_url || "");
  const [options, setOptions] = useState((data?.options && JSON.parse(data?.options)) || null);
  const [uploadOptions, setUploadOptions] = useState(emptyUploadProps);
  const [multipleSelection, setMultipleSelection] = useState(data?.props.multiple_selection || false);
  const [defaultValue, setdefaultValue] = useState(data?.props.default_value || "");
  const [append, setappend] = useState(data?.props.append || "");
  const [prepend, setprepend] = useState(data?.props.prepend || "");
  const [numericProps, setNumericProps] = useState(emptyFormat);

  const [textPropsValidations, setTextPropsValidations] = useState(emptyValidatorRules);


  const [isReadOnly, setIsReadOnly] = useState(data?.is_read_only || false)
  const [isVisibleInForm, setVisibleInForm] = useState(data?.is_visible_in_form || true)
  const [loading, setLoading] = useState(false);
  const [listofOptionsDefaultValue, setlistofOptionsDefaultValue] = useState(optionsBydefult);
  const [listofOptionsDefaultValueSelected, setlistofOptionsDefaultValueSelected] = useState(optionsBydefult);
  const [rulesfields, setrulesfields] = useState([]);



  const i18n_already_exist = i18n.useI18n_already_exist();
  const i18n_invalid_caracter = i18n.useI18n_special_characters_not_allowed();
  const I18n_help_link_url = i18n.useI18n_help_link_url();
  const I18n_help_link_helptext = i18n.useI18n_help_link_helptext()
  const I18n_placeholder_90 = i18n.useI18n_placeholder(90)
  const I18n_placeholder_50 = i18n.useI18n_placeholder(50)

  const invalidateQueries = useInvalidateQuery();
  const { InvalidateQuery: InvalidateQueryByKey } = useInvalidateQueryByKey([QUERY_KEYS_ATTRIBUTE.GET_ATTRIBUTE_BY_KEY]);

  const resetValues = () => {

    setisRequired(false)
    setenableIsUnique(false)
    setenableHelpText(false)
    setenableAppendPrepend(false)
    sethelpText("")
    setenableHelpLink(false)
    sethelpLinkText("")
    sethelpLinkUrl("")
    setenableDefaultValue(false)
    setenableColorPicker(false)
    setdefaultValue("")
    setenableAddOptions(false)
    setOptions(null)
    setMultipleSelection(false)
    setinputNameValue("")
    setTypeFieldValue("")
    setVisibleInForm(false)
    setlistofOptionsDefaultValue(optionsBydefult)
    setlistofOptionsDefaultValueSelected(optionsBydefult)
    setappend("")
    setprepend("")
    setTextPropsValidations(null)
    setNumericProps(format => ({
      ...format,
      ...emptyFormat
    }))
    setTextPropsValidations(validations => ({
      ...validations,
      ...emptyValidatorRules
    }))

  };



  const onSubmit = async (form_data) => {

    setLoading(true);
    setErrorSubmit(false);

   

    if (validateForm()) {


      if (IsNotSpecialCharactersInputName(inputNameValue)) {


        if (typeFieldValue) {
          const is_not_duplicate = await ValidateIsDuplicate(inputNameValue);
          const inSchema = true;
          const key = data?.key || '';
          const id = data?.id || 0;
          const order = data?.sort || 0;



          if (is_not_duplicate) {
            let field =
            {
              AttributeItem:
              {
                key: key,
                id: id,
                sort: order,
                name: inputNameValue,
                data_type: typeFieldValue,
                collection_id: collection,
                view_hash_id: view_id,
                options: JSON.stringify(options),
                is_predefined: false,
                status: true,
                in_schema: inSchema,
                is_read_only: isReadOnly,
                is_pii: enableSensibleData,
                is_visible_in_form: isVisibleInForm,
                is_visible_in_list: true,
                props: {
                  is_required: isRequired,
                  is_required_message: isRequiredMessage,
                  enable_is_unique: enableIsUnique,
                  enable_help_link: enableHelpLink,
                  enable_help_text: enableHelpText,
                  enable_append_prepend: enableAppendPrepend,
                  enable_add_options: enableAddOptions,
                  enable_color_picker: enableColorPicker,
                  append: append,
                  prepend: prepend,
                  help_link_text: helpLinkText,
                  help_link_url: helpLinkUrl,
                  help_text: helpText,
                  default_value: defaultValue,
                  multiple_selection: multipleSelection,
                  enable_editor_options: enableEditorOptions,
                  enable_default_value: enableDefaultValue,

                },
                validations_rules: textPropsValidations,
                upload_props: uploadOptions,
                numeric_props: numericProps,
                rules: rulesfields

              }
            };

            let response;

            if (action === 'update')
              response = await AttributeApiCommand.UpdateAttribute(field);
            else
              response = await AttributeApiCommand.CreateAttribute(field);


            if (response.data) {

              setonSuccess(response)
              resetValues()

              setTimeout(() => 
              {
                invalidateQueries.InvalidateCurrentView();
                InvalidateQueryByKey();
              }, action === 'update' ? 0 : 800);



            } else {
              setonSuccess(false);
              setErrorSubmit(true);
              seterrorMessage(response.message);

            }
          }
        }
      }
    }
    setLoading(false);
  };

  const onChangeDefaultValues = (item) => {


    if (item.length > 0) {

      setdefaultValue(item[0].value);
      setlistofOptionsDefaultValueSelected(item);
    } else {
      setdefaultValue('');
      setlistofOptionsDefaultValueSelected([]);
    }

  };

  const OnChangeSelectTypeField = (value) => {

    setTextPropsValidations(validatorRules => ({
      ...emptyValidatorRules
    }))

    setTypeFieldValue(value)

    if (value === 'options' || value === 'cascade') {
      setenableColorPicker(false)
      setMultipleSelection(false)
      setenableAddOptions(false)
      setenableEditorOptions(false)
      setrulesfields([]);

    }
  }

  const onChangeFieldValidator = (value) => {


    if (!value.message) {
      if (value.type === 'email')
        value.message = 'Escribe un correo electrónico válido';

      if (value.type === 'range_number')
        value.message = `Escribe rango de número válido`;

      if (value.type === 'range_char')
        value.message = `Escribe un rango de caracteres válido`;


    }

    setTextPropsValidations(validations => ({
      ...validations,
      ...value
    }));


  };

  const onChangeFieldFormatSelector = (format_updated) => {

    if (format_updated.type === 'money') {

      format_updated.prefix = '$';
      console.log("prefix", format_updated.prefix)
    }


    setNumericProps(format => ({
      ...format,
      ...format_updated
    }))


    switch (format_updated.type) {
      case 'percent':
        setenableAppendPrepend(true)
        setappend("%")
        setprepend('')
        break;

      case 'money':
        setenableAppendPrepend(true)
        setprepend("$")
        setappend('')
        break;

      default:
        setenableAppendPrepend(false)
        setappend('')
        setprepend('')
        break;
    }


  }



  const onChangeFieldConsecutive = (consecutive_options) => {
    console.log("onChangeFieldConsecutive", consecutive_options)
    setOptions(consecutive_options)

  }

  const onChangeUserOptions = (user_options) => {
    setOptions(user_options)
  }

  const onChangeFieldValidatorUploadFile = (upload_options) => {   
    setUploadOptions(upload_options)
  }

  const getSelectOptions = (options) => {
    var array = [...options];

    const removeEmptysOptions = array.filter(
      (el) => Object.keys(el.value).length !== 0
    );

    setOptions(removeEmptysOptions);

    let listofOptionsDefaultValue = removeEmptysOptions.map((el) => {
      return {
        label: el.value,
        value: el.id,
      };
    });

    setlistofOptionsDefaultValue(listofOptionsDefaultValue);


  };


  const getSelectOptionsCascade = (optionsFromCascade) => {
    console.log("getSelectOptionsCascade", optionsFromCascade)
    setOptions(optionsFromCascade)


  };

  const getRulesFromOptions = (rules) => {
    console.log("getRulesFromOptions", rules)
    setrulesfields(rules)
  }




  const resetForm = () => setonSuccess(false);

  const ValidateInputName = (value) => {
    setinputNameValue(value)
    IsNotSpecialCharactersInputName(value)

  };

  const validateForm = () => {


    setInvalidNameField(false);
    setInvalidNameFieldError("");

    if (typeFieldValue === 'select') {
      if (options === null) {

        setInvalidNameField(true);
        setInvalidNameFieldError('Es necesario agregar al menos una opción');
        return false;
      }
    }

    if (inputNameValue.length === 0) {
      setInvalidNameField(true);
      setInvalidNameFieldError('Es necesario un nombre');

      return false;

    }


    return true


  };

  const IsNotSpecialCharactersInputName = (value) => {
    const response = PATTERN_VALIDATOR.NOT_SPECIAL_CHARACTERS(String(value).toLowerCase());

    if (response) {
      const last_char = value.substr(value.length - 1);
      setInvalidNameField(true);
      const message = i18n_invalid_caracter + last_char;
      setInvalidNameFieldError(message);
      return false;
    } else {
      setInvalidNameField(false);
      setInvalidNameFieldError("");
      return true;
    }
  };

  const ValidateIsDuplicate = async (name) => {

    if (action === 'update' && inputNameValue === data.name) {
      return true;
    }

    const check = { name: name.trim(), collection_id: collection };
    const response = await AttributeApiQuery.BeUniqueAttributeName(check);

    if (response.data) {
      setInvalidNameField(true);
      setInvalidNameFieldError(i18n_already_exist);
      return false;

    } else {
      setInvalidNameField(false);
      setInvalidNameFieldError("");
      return true;
    }

  };


  const onChangeEditValuesOptions = () => {
    setenableEditorOptions(!enableEditorOptions)
    if (enableAddOptions)
      setenableAddOptions(!enableAddOptions)
  }

  const label_enable_is_unique = (<>{i18n.label_unique}<EuiToolTip content={i18n.label_unique_tooltip}><><EuiIcon type="iInCircle" /></></EuiToolTip></>);

  const label_form_row = (<>{" "}{isRequired && <EuiBetaBadge label="R" title='Requerido' size='s' className='required-icon-form' />} {i18n.input_name}</>);

  const label_sensible_data = (
    <>Contiene información sensible
      <EuiToolTip content="Cuando se habilita no se realizara auditoria, se almacena encriptado con fines de cumplimiento de normas y protección de datos.">
        <><EuiIcon type="iInCircle" /></>
      </EuiToolTip>
    </>
  );


  const way_can_edit = (
    <>
      <EuiToolTip content={i18n.label_way_cant_change_type_tooltip}>
        <EuiText size="s" style={{ fontSize: "smaller" }}>
          <p>{i18n.label_way_cant_change_type}</p>
        </EuiText>
      </EuiToolTip>
    </>
  );





  const SectionFormatSelector = useMemo(() => { return <FieldNumericFormatSelector OnChangeFieldFormatSelector={onChangeFieldFormatSelector} data={data && (data.numeric_props || {})} /> }, [])
  const SectionValidatorRules = useMemo(() => { return <FieldValidator TypeFielSelected={typeFieldValue} OnChangeFieldValidator={onChangeFieldValidator} SetData={data?.validations_rules || []} /> }, [typeFieldValue])
  const SectionSelectWithColorOptions = useMemo(() => { return <SuperSelectWithColorOptions data={data && JSON.parse(data?.options)} GetOptions={getSelectOptions} showColorPicker={enableColorPicker} showEditorValues={enableEditorOptions} /> }, [enableColorPicker, enableEditorOptions])
  const SectionCascadeOptions = useMemo(() => {
    return <FieldCascadeOptions
      OnUpdateOptions={getSelectOptionsCascade}
      OnUpdateRules={getRulesFromOptions}
      data={data && data} />
  }, [])
  const SectionValidatorUploadFile = useMemo(() => { return <FieldValidatorUpload OnChangFieldValidatorUpload={onChangeFieldValidatorUploadFile} data={data && data} /> }, []);
  const SectionFieldConsecutive = useMemo(() => { return <FieldConsecutive onChangeConsecutiveOptions={onChangeFieldConsecutive} SetOptions={consecutiveDefault} /> }, []);
  const SectionFieldUser = useMemo(() => { return <FieldUser onChangeUserOptions={onChangeUserOptions} /> }, []);
  const SectionGallery = useMemo(() => { return <FieldGalleryUpload OnChangFieldValidatorUpload={onChangeFieldValidatorUploadFile} data={data && data} /> }, []);



  useEffect(() => {


    if (data) {

      setTypeFieldValue(data.data_type)
      setinputNameValue(data.name)

      if (data.data_type === "select" || data.data_type === "checklist") {
        if (data.props?.default_value) {
          const default_value = data?.props?.default_value;
          const selectedValue = optionsBydefult?.find(item => item.value === default_value)
          setlistofOptionsDefaultValueSelected([selectedValue])
        }
      }

      if (data.data_type === "cascade") {
        setrulesfields(data?.rules)
        setOptions(JSON.parse(data?.options))
      }
     

    }

  }, [data])


  

  return (
    <>
      {errorSubmit &&
        <CalloutError
          error={errorMessage}
          title="No se creo la columna"
          description="Ha ocurrido algo inesperado y no fue posible crear la columna, intentalo nuevamente o comunicate con el administrador de la plataforma."
        />}
      <EuiFlexGroup gutterSize="none" direction="column" style={{ minWidth: '300px' }}>
        <EuiFlexItem>
          {onSuccess && (
            <NotifySucces
              title={action === 'create' ? i18n.success_create_field : 'Columna actualizada'}
              description={action === 'create' ? 'Ahora puede verla en listado de columnas' : ''} conffeti={undefined} />
          )}
          {onSuccess && (
            !compressed && action === 'create' && (
              <EuiButtonEmpty
                iconType="plusInCircle"
                onClick={() => {
                  resetForm();
                }}
              >
                {i18n.create_again_field}
              </EuiButtonEmpty>
            )

          )}
        </EuiFlexItem>
        <EuiFlexItem>
          {!onSuccess ? (
            <>
              <EuiPanel hasShadow={false}>
                <EuiForm
                  component="form"
                  autoComplete="off"
                  noValidate
                  fullWidth
                >
                  <EuiFlexGroup direction="column" style={{ position: 'sticky', top: 3, zIndex: 1, backgroundColor: currentTheme === 'dark' ? '#1a1c21' : '#fff' }}>
                    <EuiFlexItem>
                      <EuiFormRow
                        label={i18n.input_type}
                        helpText={action === "create" ? "Selecciona el tipo de columna que deseas crear" : way_can_edit}
                        display="rowCompressed"
                        fullWidth
                        labelAppend={
                          <EuiText size="xs">
                            <EuiLink
                              external={true}
                              type="button"
                              target="_blank"
                              href="https://help.onespace.co/es/atributos"
                            >Guia de columnas</EuiLink>
                          </EuiText>
                        }
                      >
                        <FieldSelector fullWidth
                          name="type"
                          disabled={action === "create" ? false : true}
                          compressed
                          onChange={(value) => OnChangeSelectTypeField(value)}
                          valueOfSelected={typeFieldValue}
                          isInvalid={
                            action === "create"
                              ? typeFieldValue
                                ? false
                                : true
                              : false
                          }
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem >
                      <ShowWhen condition={typeFieldValue} >
                        <EuiFormRow
                          label={label_form_row}
                          display="rowCompressed"
                          fullWidth
                          helpText={enableHelpText && helpText}
                          labelAppend={
                            enableHelpLink && (
                              <EuiText size="xs">
                                <EuiLink
                                  color="subdued"
                                  type="button"
                                  target="_blank"
                                  external={true}
                                  href={helpLinkUrl}
                                >
                                  {helpLinkText}
                                </EuiLink>
                              </EuiText>
                            )
                          }
                        >
                          <EuiInputPopover
                            input={
                              <EuiFieldText
                                name="name-field-creator"
                                onChange={(e) =>
                                  ValidateInputName(e.target.value)
                                }
                                fullWidth
                                value={inputNameValue}
                                compressed
                                placeholder={i18n.useI18n_placeholder(255)}
                                isInvalid={invalidNameField}
                                maxLength={255}
                                isLoading={loading}
                                append={append && append}
                                prepend={prepend && prepend}
                                spellCheck={true}
                              />
                            }
                            fullWidth
                            panelPaddingSize="none"
                            isOpen={invalidNameField}
                            closePopover={() => {
                              setInvalidNameField(false);
                            }}
                          >
                            <EuiCallOut
                              title={invalidNameFieldError}
                              size="s"
                              color="danger"
                              iconType="alert"
                            ></EuiCallOut>
                          </EuiInputPopover>
                        </EuiFormRow>

                      </ShowWhen>
                    </EuiFlexItem>
                  </EuiFlexGroup>

                  <ShowWhen condition={typeFieldValue !== ''}>
                    <EuiSpacer size="m" />
                  </ShowWhen>

                  <ShowWhen condition={typeFieldValue === "select" || typeFieldValue === "options"} >

                    <SectionComponent
                      compressed={compressed}
                      customStyle={{ maxHeight: '60vh' }}
                      title={i18n.input_options}
                      initialIsOpen={(["select", "options", "checklist"].includes(typeFieldValue))}>

                      {SectionSelectWithColorOptions}

                      <EuiHorizontalRule margin="s" />
                      <ShowWhen condition={typeFieldValue === "select"} >
                        <EuiAccordion
                          id={'advance-option-panel-1'}
                          buttonContent="Opciones avanzadas"
                        >
                          <EuiFlexGroup>
                            <EuiFlexItem>
                              <EuiPanel paddingSize="m" color="plain">
                                <EuiFormRow
                                  label={
                                    <EuiText color="subdued" size="s">
                                      {i18n.label_enable_options_colors}
                                    </EuiText>
                                  }
                                  fullWidth
                                  display="rowCompressed"
                                  labelAppend={
                                    <EuiSwitch
                                      showLabel={false}
                                      label="options colors"
                                      checked={enableColorPicker}
                                      compressed
                                      onChange={() =>
                                        setenableColorPicker(!enableColorPicker)
                                      }
                                    />
                                  }
                                >
                                  <></>
                                </EuiFormRow>
                                <EuiFormRow
                                  label={
                                    <EuiText color="subdued" size="s">
                                      Permitir selección multiple
                                    </EuiText>
                                  }
                                  fullWidth
                                  display="rowCompressed"
                                  labelAppend={
                                    <EuiSwitch
                                      showLabel={false}
                                      label="select multiple"
                                      checked={multipleSelection}
                                      compressed
                                      onChange={() =>
                                        setMultipleSelection(!multipleSelection)
                                      }
                                    />
                                  }
                                >
                                  <></>
                                </EuiFormRow>
                                <EuiFormRow
                                  label={
                                    <EuiText color="subdued" size="s">
                                      Permitir agregar opciones
                                    </EuiText>
                                  }
                                  fullWidth
                                  display="rowCompressed"
                                  labelAppend={
                                    <EuiSwitch
                                      showLabel={false}
                                      label="is required"
                                      checked={enableAddOptions}
                                      compressed
                                      onChange={() => {

                                        if (!enableEditorOptions)
                                          setenableAddOptions(!enableAddOptions)
                                      }
                                      }
                                    />
                                  }
                                >
                                  <></>
                                </EuiFormRow>
                                <EuiFormRow
                                  label={
                                    <EuiText color="subdued" size="s">
                                      Editar valores de las opciones
                                    </EuiText>
                                  }
                                  fullWidth
                                  display="rowCompressed"
                                  labelAppend={
                                    <EuiSwitch
                                      showLabel={false}
                                      label="editar valores"
                                      checked={enableEditorOptions}
                                      compressed
                                      onChange={onChangeEditValuesOptions}

                                    />
                                  }
                                >
                                  <></>
                                </EuiFormRow>
                              </EuiPanel>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiAccordion>
                      </ShowWhen>
                    </SectionComponent>

                  </ShowWhen>



                  <ShowWhen condition={typeFieldValue === 'cascade'} >
                    <SectionComponent compressed={compressed} title="Lista dependiente" customStyle={{ overflowY: 'scroll', maxHeight: '60vh' }} initialIsOpen={true} >
                      {SectionCascadeOptions}
                    </SectionComponent>
                  </ShowWhen>

                  <ShowWhen condition={typeFieldValue === 'user'}>
                    <SectionComponent compressed={compressed} title="Usuarios" initialIsOpen={typeFieldValue === 'user'} >
                      {SectionFieldUser}
                    </SectionComponent>
                  </ShowWhen>


                  <ShowWhen condition={typeFieldValue === "file"}>
                    <AnimatePanelWrapper>
                      <SectionComponent compressed={compressed} title="Archivo adjunto" initialIsOpen={typeFieldValue === "file"}>
                        {SectionValidatorUploadFile}
                      </SectionComponent>
                    </AnimatePanelWrapper>
                  </ShowWhen>


                  <ShowWhen condition={typeFieldValue === "gallery"}>
                    <AnimatePanelWrapper>
                      <SectionComponent compressed={compressed} title="Galleria de imagenes" initialIsOpen={typeFieldValue === "gallery"}>
                        {SectionGallery}
                      </SectionComponent>
                    </AnimatePanelWrapper>
                  </ShowWhen>

                  

                  <ShowWhen condition={typeFieldValue === "consecutive"}>
                    <AnimatePanelWrapper>
                      <SectionComponent compressed={compressed} title="Consecutivo" initialIsOpen={typeFieldValue === "consecutive"}>
                        {SectionFieldConsecutive}
                      </SectionComponent>
                    </AnimatePanelWrapper>
                  </ShowWhen>



                  <ShowWhen condition={typeFieldValue === "numeric"}>
                    <SectionComponent compressed={compressed} title="Formato numérico" initialIsOpen={false} >
                      {SectionFormatSelector}
                    </SectionComponent>
                  </ShowWhen>

                  <ShowWhen condition={typeFieldValue !== ''}>
                    <EuiSpacer size="s" />
                  </ShowWhen>

                  {/* <ShowWhen condition={typeFieldValue !== "callout"}> */}
                  <ShowWhen condition={!(["callout", "avatar"].includes(typeFieldValue)) && typeFieldValue}>
                    <SectionComponent compressed={compressed} customStyle={{ maxHeight: '60vh' }} title={i18n.label_panel_configuration} initialIsOpen={false} >

                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            {'Requerido'}
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="is required"
                            checked={isRequired}
                            compressed
                            onChange={() => setisRequired(!isRequired)}
                          />
                        }
                      ><>
                          {isRequired ? (
                            <AnimatePanelWrapper>
                              <EuiFieldText
                                fullWidth
                                placeholder={'Escribe un mensaje de requerido'}
                                name="isRequiredMessage-1"
                                compressed
                                value={isRequiredMessage}
                                isInvalid={
                                  isRequired
                                    ? isRequiredMessage.length === 0
                                    : false
                                }
                                onChange={(e) => setisRequiredMessage(e.target.value)}
                                maxLength={90}
                                spellCheck={true}

                              />
                            </AnimatePanelWrapper>
                          ) : null}
                        </>
                      </EuiFormRow>
                      {(["select"].includes(typeFieldValue)) && (
                        <EuiFormRow
                          label={
                            <EuiText color="subdued" size="s">
                              {i18n.input_default_value}
                            </EuiText>
                          }
                          fullWidth
                          display="rowCompressed"
                          labelAppend={
                            <EuiSwitch
                              showLabel={false}
                              label="default value"
                              checked={enableDefaultValue}
                              compressed
                              onChange={() => {
                                setenableDefaultValue(!enableDefaultValue);
                                setdefaultValue("");
                              }}
                            />
                          }
                        >
                          <>
                            {enableDefaultValue ? (
                              <AnimatePanelWrapper>
                                {typeFieldValue === "select" ||
                                  typeFieldValue === "checklist" ? (
                                  <EuiComboBox
                                    compressed
                                    fullWidth
                                    isClearable
                                    aria-label="select a option"
                                    placeholder="Selecciona una opción"
                                    singleSelection={{ asPlainText: true }}
                                    options={
                                      listofOptionsDefaultValue &&
                                      (listofOptionsDefaultValue.length === 0
                                        ? optionsBydefult
                                        : listofOptionsDefaultValue)
                                    }
                                    isInvalid={
                                      listofOptionsDefaultValue
                                        ? listofOptionsDefaultValue.length === 0
                                        : true
                                    }
                                    selectedOptions={listofOptionsDefaultValueSelected}
                                    onChange={onChangeDefaultValues}
                                  />
                                ) : (
                                  <EuiFieldText
                                    fullWidth
                                    name="defaulValue"
                                    compressed
                                    placeholder={I18n_placeholder_50}
                                    value={defaultValue}
                                    isInvalid={
                                      enableDefaultValue
                                        ? defaultValue.length === 0
                                        : false
                                    }
                                    onChange={(e) =>
                                      setdefaultValue(e.target.value)
                                    }
                                    maxLength={255}
                                  />
                                )}
                              </AnimatePanelWrapper>
                            ) : null}
                          </>
                        </EuiFormRow>
                      )
                      }

                      {(["text"].includes(typeFieldValue)) && (
                        <EuiFormRow
                          label={
                            <EuiText color="subdued" size="s">
                              Etiquetas antes y despues
                            </EuiText>
                          }
                          fullWidth
                          display="rowCompressed"
                          labelAppend={
                            <EuiSwitch
                              showLabel={false}
                              label="appendPrepend"
                              checked={enableAppendPrepend}
                              compressed
                              onChange={() => {
                                setenableAppendPrepend(!enableAppendPrepend);
                                setprepend("");
                                setappend("");
                              }}
                            />
                          }
                        >
                          <>
                            {enableAppendPrepend ? (
                              <AnimatePanelWrapper>
                                <EuiFlexGroup>
                                  <EuiFlexItem>
                                    <EuiFieldText
                                      name="prepend"
                                      compressed
                                      placeholder="Texto antes"
                                      value={prepend}
                                      onChange={(e) =>
                                        setprepend(e.target.value)
                                      }
                                      maxLength={10}

                                    />
                                  </EuiFlexItem>
                                  <EuiFlexItem>
                                    <EuiFieldText
                                      name="append"
                                      compressed
                                      placeholder="Texto despues"
                                      value={append}
                                      onChange={(e) =>
                                        setappend(e.target.value)
                                      }
                                      maxLength={10}

                                    />
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </AnimatePanelWrapper>
                            ) : null}
                          </>
                        </EuiFormRow>
                      )}

                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            {i18n.help_text}
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="help text"
                            checked={enableHelpText}
                            compressed
                            onChange={(e) => setenableHelpText(!enableHelpText)}
                          />
                        }
                      >
                        <>
                          {enableHelpText ? (
                            <AnimatePanelWrapper>
                              <EuiFieldText
                                fullWidth
                                placeholder={I18n_placeholder_90}
                                name="helptext"
                                compressed
                                value={helpText}
                                isInvalid={
                                  enableHelpText
                                    ? helpText.length === 0
                                    : false
                                }
                                onChange={(e) => sethelpText(e.target.value)}
                                maxLength={90}
                                spellCheck={true}
                                style={{ maxWidth: '99%' }}
                              />
                            </AnimatePanelWrapper>
                          ) : null}
                        </>
                      </EuiFormRow>
                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            {i18n.help_link}
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="help link"
                            checked={enableHelpLink}
                            compressed
                            onChange={() => {
                              setenableHelpLink(!enableHelpLink);
                              sethelpLinkText("")
                              sethelpLinkUrl("")
                            }}
                          />
                        }
                      >
                        <>
                          {enableHelpLink ? (
                            <AnimatePanelWrapper>
                              <EuiFlexGroup>
                                <EuiFlexItem grow={3}>
                                  <EuiFieldText
                                    name="helplink"
                                    compressed
                                    value={helpLinkText}
                                    placeholder={I18n_help_link_helptext}
                                    onChange={(e) =>
                                      sethelpLinkText(e.target.value)
                                    }
                                    isInvalid={
                                      enableHelpLink
                                        ? helpLinkText.length === 0
                                        : false
                                    }
                                    maxLength={25}
                                  />
                                </EuiFlexItem>
                                <EuiFlexItem grow={7}>
                                  <EuiFieldText
                                    name="helplink"
                                    compressed
                                    prepend="https://"
                                    value={helpLinkUrl}
                                    placeholder={I18n_help_link_url}
                                    onChange={(e) =>
                                      sethelpLinkUrl(e.target.value)
                                    }
                                    isInvalid={
                                      enableHelpLink
                                        ? helpLinkUrl.length === 0
                                        : false
                                    }
                                    maxLength={200}
                                  />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </AnimatePanelWrapper>
                          ) : null}
                        </>
                      </EuiFormRow>

                      <EuiHorizontalRule margin="m" />

                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            Solo lectura
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="is read only data"
                            checked={isReadOnly}
                            compressed
                            onChange={() => setIsReadOnly(!isReadOnly)}
                          />
                        }
                      >
                        <></>
                      </EuiFormRow>
                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            Visible en el formulario
                            <EuiToolTip content="Cuando se deshabilita esta columna se ocultará en el formulario de crear o editar un registro.">
                              <>
                                <EuiIcon type="iInCircle" />
                              </>
                            </EuiToolTip>
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="is visible in form"
                            checked={isVisibleInForm}
                            compressed
                            onChange={() => setVisibleInForm(!isVisibleInForm)}
                          />
                        }
                      >
                        <></>
                      </EuiFormRow>
                      <EuiFormRow
                        label={
                          <EuiText color="subdued" size="s">
                            {label_sensible_data}
                          </EuiText>
                        }
                        fullWidth
                        display="rowCompressed"
                        labelAppend={
                          <EuiSwitch
                            showLabel={false}
                            label="is sensible data"
                            checked={enableSensibleData}
                            compressed
                            onChange={() => setEnableSensibleData(!enableSensibleData)}
                          />
                        }
                      >
                        <></>
                      </EuiFormRow>

                      {(["text"].includes(typeFieldValue)) && (
                        <EuiFormRow
                          label={
                            <EuiText color="subdued" size="s">
                              {label_enable_is_unique}
                            </EuiText>
                          }
                          fullWidth
                          display="rowCompressed"
                          labelAppend={
                            <>
                              <EuiSwitch
                                showLabel={false}
                                label="is unique"
                                checked={enableIsUnique}
                                compressed
                                onChange={() =>
                                  setenableIsUnique(!enableIsUnique)
                                }
                              />
                            </>
                          }
                        >
                          <></>
                        </EuiFormRow>
                      )}


                    </SectionComponent>
                  </ShowWhen>

                  <ShowWhen condition={typeFieldValue !== ''}>
                    <EuiSpacer size="s" />
                  </ShowWhen>

                  <ShowWhen condition={(["numeric", "longtext", "text", "email"].includes(typeFieldValue))}>
                    <AnimatePanelWrapper>
                      <SectionComponent compressed={compressed} title='Validación' initialIsOpen={typeFieldValue === "email" ? true : false} >
                        {SectionValidatorRules}

                      </SectionComponent>
                    </AnimatePanelWrapper>
                  </ShowWhen>

                  <ShowWhen condition={typeFieldValue !== ''}>
                    <EuiSpacer />
                  </ShowWhen>
                  <BottomBar>
                    <EuiButton onClick={onSubmit}
                      css={{
                        backgroundColor: euiTheme.colors.primary,
                        fontSize: '1.0000rem',
                        lineHeight: '1.4286rem',
                        color: (theme === "light" ? "#fff" : "#000"),
                      }}
                      isLoading={loading}
                      color="primary"
                      fullWidth>
                      {action === "create"
                        ? i18n.button_create
                        : i18n.save_changes}
                    </EuiButton>
                  </BottomBar>
                </EuiForm>
              </EuiPanel>
            </>
          ) : null}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default FormFieldCreator;
