import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'
import NewApp from 'pages/apps/new/NewApp'
import React from 'react'

function AppSideNavAddApp({ iscollapse }) {
  return (
    <EuiFlexGroup justifyContent={!iscollapse ? 'spaceBetween' : 'spaceAround'} gutterSize="none" style={{ paddingRight: iscollapse ? 2 : 0 }} >

      {!iscollapse && (
        <EuiFlexItem grow={false}>
          <EuiTitle size="xxs"><h1>Apps</h1></EuiTitle>
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false}>
        <NewApp />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default AppSideNavAddApp