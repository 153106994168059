import { EuiAccordion, EuiGlobalToastList, EuiPanel, EuiText } from '@elastic/eui'
import { Fragment, useEffect, useState } from 'react';
import { useGlobalCallOutNotificationStore } from 'store/global-callout-notification-store';
import { nanoid } from 'nanoid';


function GlobalNotificationCallout() {


    const [Toast, setToast] = useState([]);
    const [description, setDescription] = useState('');
    const [tecnical_error, setTecnicalError] = useState(null);

    const toasts = useGlobalCallOutNotificationStore((state) => state.toast);
    const isShow = useGlobalCallOutNotificationStore((state) => state.isShowGlobalNotification);
    const clearAllToasts = useGlobalCallOutNotificationStore((state) => state.clearAllToasts);
    const id_accordion = 'error-accordion' + nanoid();

    const title_technical_details = (
        <EuiText style={{ paddingTop: 5 }} size='s'><h5>Detalle técnico</h5></EuiText>
    )

    const template = (
        <Fragment>
            {description}
            <EuiAccordion id={id_accordion} buttonContent={title_technical_details} arrowDisplay='none'>
                <EuiPanel color="subdued">
                    {tecnical_error}
                </EuiPanel>
            </EuiAccordion>
        </Fragment>
    )


    const CloseAllToast = () => {
        clearAllToasts();
    }


    useEffect(() => {

        if (toasts.length > 0) {
            setDescription(toasts[0].text)
            setTecnicalError(toasts[0].tecnical_error)
            setToast([{
                id: 'notification-' + nanoid(),
                title: toasts[0].title,
                color: toasts[0].color,
                text: toasts[0].tecnical_error ? template : description,
                iconType: toasts[0].icon,

            }])
        }
        // eslint-disable-next-line
    }, [toasts])



    return (
        <>
            {isShow &&
                <EuiGlobalToastList
                    toasts={Toast}
                    toastLifeTimeMs={900000}
                    dismissToast={CloseAllToast} />}
        </>
    )
}

export default GlobalNotificationCallout
