import { ICON_GALLERY } from 'components/icons/icons-gallery';
import { create } from 'zustand';



// export const useGlobalCallOutNotificationStore = create(
//        (set) => ({
//               isShowGlobalNotification: false,
//               toast: [],
//               showError: (title, message) => set(state => (
//                      {
//                             toast: [
//                                    ...state.toast, {
//                                           title: title,
//                                           text: message !== undefined ? message : 'No fue posible realizar la operación, intentalo nuevamente o comunicate con el administrador de la plataforma.',
//                                           color: 'danger',
//                                           id: nanoid(),
//                                           iconType: 'error'
//                                    }
//                             ],
//                             isShowGlobalNotification: true
//                      }
//               )),
//               setShowNotification: (status) => set(state => ({ isShowGlobalNotification: status })),
//               clearAllToasts: () => set(state => ({ toast: [], isShowGlobalNotification: false })),
//        }));





export const useGlobalCallOutNotificationStore = create(
       (set) => ({
              isOffLine : false,
              isShowGlobalNotification: false,
              toast: [],
              setOffline: (status) => set(state => ({ isOffLine: true , isShowGlobalNotification: true })),
              showNotificationError: (title, message, tecnical_error) => set(state => (
                     {

                            toast: [
                                   ...state.toast, {
                                          title: title,
                                          text: message,
                                          tecnical_error: tecnical_error,
                                          color: 'danger',
                                          icon :ICON_GALLERY.ERROR
                                   }
                            ],
                            isShowGlobalNotification: true
                     }
              )),
              setShowNotification: (status) => set(state => ({ isShowGlobalNotification: status })),
              clearAllToasts: () => set(state => ({ toast: [], isShowGlobalNotification: false })),
       }));