import React from 'react'
import { useParams } from 'react-router-dom';
import AppToolBarSettings from './app-tool-bar-configuration';

function AppToolBar() {
  
    let parameters = useParams();
    const toolbar = parameters.toolbar;
    const app = parameters.app;
    const collection = parameters.collection;
    
  return (
    <>              
    {toolbar==="s" && <AppToolBarSettings app={app} collection={collection} />}
    </>
  )
}

export default AppToolBar