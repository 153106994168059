import { useGeneratedHtmlId, EuiContextMenu, EuiPopover, EuiIcon,  EuiButtonEmpty } from '@elastic/eui';
import React,{useState} from 'react';
import FilterBuilder from './FilterBuilder';


function FilterInTable() {

    
    const [isPopoverOpen, setPopover] = useState(false);

  
    const normalContextMenuPopoverId = useGeneratedHtmlId({
      prefix: 'normalContextMenuPopover',
    });
 
    const onButtonClick = () => {
      setPopover(!isPopoverOpen);
    };
  
    const closePopover = () => {
      setPopover(false);
    };
  
  

  

    const fitlerList = [
      { id: 0,
        title: 'Filtros disponibles',
        items: [
          {
            name: 'Personas mayores a 50 años',
            icon: <EuiIcon type="filter" size="m" color='primary' />,
            onClick: () => {
              closePopover();
            },
          },
          {
            isSeparator: true,
            key: 'sep',
          },
          {
            name: 'Solo mujeres',
            icon: <EuiIcon type="filter" size="m"  color='primary'/>,
            onClick: () => {
              closePopover();
            },
          },
          {
            isSeparator: true,
            key: 'sep',
          },
          {
            name: 'Agregar filtro',
            icon: 'plusInCircle',
            panel:1
           
          },
        ],
      }, 
      {
        id: 1,
        width: 400,
        title: 'Agregar filtro',
        content:<FilterBuilder/>,
      }
      
    ];
    
  
   
    // const typesView = [
    //   {
    //     id: 0,
    //     title: TitlePanel,
    //     hasFocus: false,
    //     items: [
  
    //       {
    //         name: i18n_title_card,
    //         icon: IcoCard,
    //         toolTipContent: 'Vista de Tarjetas',
    //         toolTipPosition: 'right',
    //         panel: 1,
    //         className: 'remove-focus',
  
  
    //       }        
    //     ],
    //   },
    //   {
    //     id: 1,
    //     title: <AddTitleForm icon={IcoCard} title={i18n_title_card} />,
    //     content: <AddViewForm icon='stop' type_view="card" title='' />,
    //   },
      
    // ];
 
    
    const button = (
      <EuiButtonEmpty
        iconType='filterInCircle'
        iconSide="left"
        onClick={onButtonClick}
        size="s"
        iconSize='m'
        color="text"
      >
      Filtros
      </EuiButtonEmpty>
    );
  
   



  return (
    <EuiPopover
    id={normalContextMenuPopoverId}
    button={button}
    isOpen={isPopoverOpen}
    closePopover={closePopover}
    panelPaddingSize="none"
    anchorPosition="upLeft"
  >
    <EuiContextMenu initialPanelId={0} panels={fitlerList} />
  </EuiPopover>
  )
}

export default FilterInTable;
