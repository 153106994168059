import { useEffect, useState } from 'react';
import { EuiPopover } from '@elastic/eui';

import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import EmojimartPicker from 'components/emoji/picker';
import EmojiMart from 'components/emoji/emoji';




export const EmojiPicker = ({ field, error, ...others }) => {

  init({ data })
  const { value } = field;
  const [icoEmoji, setIcoEmoji] = useState('pinata')
  const [isOpen, setIsOpen] = useState(false)

  const openPopupEmoji = () => setIsOpen(true)
  const closeEmoji = () => setIsOpen(false)

  const onSelectEmoji = (emoji) => {
    setIcoEmoji(emoji)
    closeEmoji()
  }

  useEffect(() => {
    if (value) {
      setIcoEmoji(value)

    }

  }, [value]);


  const openEmojiContexMenu = (
    <div style={{ cursor: 'pointer' }}>
  <EmojiMart id={icoEmoji} onClick={() => { openPopupEmoji() }} />
  </div>
  );

  return (
    <EuiPopover
      id="pickeremoji"
      ownFocus
      button={openEmojiContexMenu}
      isOpen={isOpen}
      anchorPosition="rightDown"
      closePopover={closeEmoji}>
      <EmojimartPicker
        onEmojiSelect={(emoji) => { onSelectEmoji(emoji.id); field.onChange(emoji.id) }}

      />

    </EuiPopover>
  )
};