import { EuiButtonIcon, EuiIcon, EuiPopover, EuiToolTip } from "@elastic/eui";
import IconList from "./components/icon-list";
import { useState } from "react";



function IconPicker({ onChange }) {


    const [isOpen, setIsOpen] = useState(false)
    const [iconSelected, setIconSelected] = useState('index');

    const button_icon = (

        <EuiButtonIcon iconType={iconSelected} title="Selecciona un icono" aria-label={'Seleccionar icono'} onClick={() => setIsOpen(true)} />

    );


    const onClickIcon = (icon) => {

        setIconSelected(icon)
        setIsOpen(false)
        onChange(icon)
    }

    return (
        <>
            <EuiPopover
                button={button_icon}
                isOpen={isOpen}
                closePopover={() => { setIsOpen(false) }}
                panelPaddingSize="none">

                <IconList onChangeIcon={onClickIcon} />

            </EuiPopover>
        </>
    )
}

export default IconPicker