import { EuiRadioGroup } from "@elastic/eui";
import { useState } from "react";




export const OptionField = ({ fieldSchema, error, fieldController }) => {

    const findLabelById = (id) => {
        const option = JSON.parse(fieldSchema.options).find((o) => o.id === id);
        return option ? option.label : null;
    };

    const findLabelByLabel = (label) => {
        const option = JSON.parse(fieldSchema.options).find((o) => o.label === label);
        return option ? option.id : null;
    };

    const list_options = () => {

        if (!fieldSchema.options) {
            return [];
        }

        return JSON.parse(fieldSchema.options).map((o) => {
            return { id: o.id, label: o.label }
        }
        );

    }



    const [selectedOptions, setSelectedOptions] = useState(fieldSchema?.options ? findLabelByLabel(fieldSchema?.options) : '');




    const onChange = (option) => {
        setSelectedOptions(option);
        const response_value = findLabelById(option);
        fieldController.onChange(response_value);
    }




    return (
        <>
            <EuiRadioGroup
                options={list_options()}
                idSelected={selectedOptions}
                onChange={onChange}
                name={'radio-' + fieldSchema.id}
                compressed

            />

        </>
    );
};


