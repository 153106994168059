// @ts-nocheck
import React, { Fragment, useState } from 'react'
import {
  EuiButtonIcon, EuiPopover,
  EuiContextMenu, EuiIcon,

} from '@elastic/eui';
import { nanoid } from 'nanoid'
import icoApp from 'assets/svg/space.svg'
import icoTable from 'assets/svg/ico-table.svg'
import icoFolder from 'assets/svg/ico-file-manager.svg'
import Wizard from 'assets/svg/wizard.svg'
import { GLOBLAL_KEY_NANO_ID_LENGT } from 'core/Constants';
import AddAppForm from './AddAppForm';


function NewApp() {
  const id = nanoid(GLOBLAL_KEY_NANO_ID_LENGT);
  const [openPopover, setOpenPopover] = useState(false);
  const closeMenu = () => { setOpenPopover(false) }
  const TitleIcoApp = (<Fragment><EuiIcon type={icoApp} size="xl" style={{ marginRight: 5 }} />Crear una app desde cero </Fragment>);

  const panels = [
    {
      id: 0,
      title: 'Opciones para crear una app',
      hasFocus: false,
      items: [

        {
          name: 'App desde cero',
          icon: <EuiIcon type={icoApp} size="xl" />,
          toolTipContent: 'Nueva app',
          toolTipPosition: 'right',
          panel: 1,
          className: 'remove-focus',

        },

        {
          name: 'Desde una plantilla',
          icon: <EuiIcon type={Wizard} size="xl" />,
          toolTipContent: 'Selecciona una app prediseñada para agilizar tu proyecto',
          toolTipPosition: 'right',
          panel: 1,
          className: 'remove-focus',

        },
        {
          name: 'Base de datos',
          icon: <EuiIcon type={icoTable} size="xl" />,
          toolTipContent: 'Podra agregar una o varias tablas para la gestion de tus apps',
          toolTipPosition: 'right',
          panel: 1,

        },
        {
          name: 'Archivos',
          icon: <EuiIcon type={icoFolder} size="xl" />,
          toolTipContent: 'Gestionar archivos',
          toolTipPosition: "right",
          panel: 1,

        },
      ],
    },
    {
      id: 1,
      title: TitleIcoApp,
      content: <AddAppForm onClose={closeMenu} />,      
      width: 300,
    },
  ];

  const buttonOpenNewApp = (
    <EuiButtonIcon
      id={id}
      iconType="plusInCircle"
      aria-label='Icon'
      color="text"
      size="xs"
      className='border-radius-50'
      onClick={() => { setOpenPopover(true); }}
    />
  );


  return (
    <EuiPopover
      id={id}
      button={buttonOpenNewApp}
      closePopover={closeMenu}
      isOpen={openPopover}
      anchorPosition="rightUp"
      panelPaddingSize="s" >
      <EuiContextMenu
        initialPanelId={0}
        panels={panels}
        size="s" />
    </EuiPopover>
  )
}

export default NewApp


