import React, { Fragment, useEffect, useState } from 'react'
import {
  EuiFilePicker, EuiFlexGroup, EuiForm, EuiFormRow, EuiSpacer, useEuiI18n, EuiImage, EuiCallOut, EuiSkeletonRectangle, EuiPageHeader
} from '@elastic/eui'

import SubscriptionApi from 'api/subscription-api';
import NotifyUpgrade from 'components/notifications/notify-upgrade';
import { CheckImgDimension } from 'hooks/useImage';
import toast from 'react-hot-toast';


function SuscriptionBrandingPage() {

  
  const [hasBranding, sethasBranding] = useState(true)

  const [logoUri, setlogo] = useState('')
  const [login, setlogin] = useState('')
  const [loadingImagesLogo, setLoadingImagesLogo] = useState(true)
  const [loadingImagesLoging, setLoadingImagesLogin] = useState(true)
  const [validLogo, setValidLogo] = useState(true)
  const [validLogin, setValidLogin] = useState(true)
  const [uploadingLogo, setUploadingLogo] = useState(false)
  const [uploadingLogin, setUploadingLogin] = useState(false)

  const i18n_logo = useEuiI18n('company.brandig.logo', 'Logo de la organización')
  const i18n_logo_subtitle = useEuiI18n('company.brandig.logo.subtitle', 'Es la imagen que se muestra en el inicio de sesión de la app. Se aceptan imágenes de 250 x 100 y no superior a un 1 MB')
  const i18n_logo_input = useEuiI18n('company.brandig.logo.input', 'Selecciona y sube tu imagen')

  const i18n_login_subtitle = useEuiI18n('company.brandig.logo.subtitle', 'Se aceptan imágenes de 1200 x 850 y no superior a un 2 MB')
  const i18n_login_input = useEuiI18n('company.brandig.login.input', 'Imágen de bienvenida')
  const i18n_title_succes = useEuiI18n('company.brandig.title.success', 'Se actualizo con éxito, los cambios se verán reflejados en el próximo inicio de sesión')

  const i18n_error_title = useEuiI18n('company.brandig.error.title', 'No fue posible subir la imágen')
  const i18n_error_logo = useEuiI18n('company.brandig.error.validate.logo', 'Valide que el tamaño del logo sea de 200 x 100 (px) y no superior a un 1MB.')
  const i18n_error_login = useEuiI18n('company.brandig.error.validate.login', 'Valide que el tamaño de la imágen de autenticación sea de 1200 x 800 (px) y no superior a un 2MB')



  const validateImage = async (files, width, height, size) => 
  {
    let isValid = false;
    if (files[0].size <= size) { //1000000
      isValid = await CheckImgDimension(files[0], width, height);
    }


    return isValid;
  }

  const setterLogin = async () => {
    setLoadingImagesLogin(true)
    
    const _image = await SubscriptionApi.GetSubDomainImage();

    if (_image.status) 
    {
      setlogin(_image.data)
      setLoadingImagesLogin(false)
    }


  }


  const setterLogo = async () => {
    setLoadingImagesLogo(true)
    
    const _logo = await SubscriptionApi.GetBrandingLogo();

    if (_logo.status) {

      setlogo(_logo.data)
      setLoadingImagesLogo(false)
    }


  }

  const UploadLogo = async (files) => {

    if (files.length > 0) 
    {
      if (validateImage(files, 250, 100, 1000000)) 
      {
        setUploadingLogo(true)
        const upload = await SubscriptionApi.UploadBrandingLogo(files);

        if (upload) {
          setUploadingLogo(false)
          setValidLogo(true)
          setterLogo();
          toast.success(i18n_title_succes);

        }

      } else {
        setValidLogo(false)
        setUploadingLogo(false)
      }
    }

  };


  const UploadLogin = async (files) => {

    if (files.length > 0) {
      if (validateImage(files, 1200, 800, 2000000)) {
        setUploadingLogin(true)
        const upload = await SubscriptionApi.UploadBrandingLogin(files);

        if (upload) {
          setUploadingLogin(false)
          setValidLogin(true)
          setterLogin();
          toast.success(i18n_title_succes);
        }

      } else {
        setValidLogin(false)
        setUploadingLogin(false)
      }
    }

  }



  useEffect(() => {

    const Run = async () => {
      
      setterLogin();
      setterLogo();
    };
    Run();

    return () => {
      setLoadingImagesLogo(false);
      setLoadingImagesLogin(false);
    }

  }, [])


  useEffect(() => {

    const Run = async () => {

      const response = await SubscriptionApi.GetHasBranding()

      if (response.data)
        sethasBranding(response.data);

    };
    Run();

  }, [])


  const messajeValidation = (
  <EuiCallOut title={i18n_error_title} color="warning" iconType="help">
    {i18n_error_logo}<br />
    {i18n_error_login}
  </EuiCallOut>);


  return (
    <Fragment>
      <EuiPageHeader
        pageTitle="Configuración de la marca"
        iconType={'gear'}
        description="Configuración de los logos de la organización y de la pagina de bienvenida de la plataforma."
        bottomBorder       
      />
      {
        hasBranding ? (
          <EuiForm component='form' noValidate>
            {(!validLogin || !validLogo) && messajeValidation}

            <EuiSpacer size="m" />        

        <EuiSkeletonRectangle
          width={200}
          height={45}
          borderRadius="m"
          isLoading={loadingImagesLogo}
          contentAriaLabel="Logo"
        >
          <EuiImage
                size="s"
                hasShadow
                allowFullScreen
                alt="Logo"
                src={logoUri}

              />
          </EuiSkeletonRectangle>

            <EuiSpacer size="m" />
            <EuiFormRow label={i18n_logo} helpText={i18n_logo_subtitle}>
              <EuiFilePicker
                id="logo"
                isInvalid={!validLogo}
                initialPromptText={i18n_logo_input}
                display="default"
                accept="image/*"
                multiple={false}
                isLoading={uploadingLogo}
                onChange={(files) => { UploadLogo(files) }}
              />


            </EuiFormRow>
            <EuiSpacer size="m" />
           
          <EuiSkeletonRectangle
          width={200}
          height={45}
          borderRadius="m"
          isLoading={loadingImagesLoging}
          contentAriaLabel="Logo"
        >
            <EuiImage
            size="m"
            hasShadow
            allowFullScreen
            alt="Logo"
            src={login}

          />

        </EuiSkeletonRectangle>


            <EuiSpacer size="m" />
            <EuiFormRow label={i18n_login_input} helpText={i18n_login_subtitle}>
              <EuiFilePicker
                id="login-image"
                initialPromptText={i18n_logo_input}
                display="default"
                accept="image/*"
                multiple={false}
                isInvalid={!validLogin}
                isLoading={uploadingLogin}
                onChange={(files) => { UploadLogin(files) }}
              />


            </EuiFormRow>
            <EuiFlexGroup>


            </EuiFlexGroup>
          </EuiForm>
        ) : (
          <NotifyUpgrade showModal={true} ></NotifyUpgrade>
        )
      }

    </Fragment>
  )
}

export default SuscriptionBrandingPage
