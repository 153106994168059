import { EuiColorPicker,useColorPickerState } from '@elastic/eui';
import { PALLETE_COLOR_WORKSPACE } from 'core/Colors';
import { useEffect } from 'react';


export const ColorPicker = ({field,error,...others}) => {

    const { name,value } = field;
    const [color, setColor] = useColorPickerState('');
       
        
    useEffect(() => {
      
        setColor(value,{isValid:true })
      
    }, [setColor,value]);
    
    return (
           <EuiColorPicker
            id={name}
            mode="swatch"
            color={color}            
            onChange={(text, { hex, isValid }) => { setColor(text, { hex, isValid }); field.onChange(text) }}
            isInvalid={!!error}
            swatches={PALLETE_COLOR_WORKSPACE}
            compressed={true}
            readOnly={false}
            fullWidth={false}
            prepend={null}
            append={null}
            autoComplete='off'
            maxLength={255}
            placeholder=""
            {...others}
        />)
    
};