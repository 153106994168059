import { Rating } from "@smastrom/react-rating"


const RatingRenderer = ({ value, row, column }) => {

    return( 
        <>
        {value &&
        <Rating
        readOnly 
        style={{ maxWidth: 120 }} 
        value={value}
        onChange={() => null} 
        />
        }
        </>
        )
}

export default RatingRenderer