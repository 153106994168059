
import { EuiPageSection} from '@elastic/eui';
import { useMatch } from 'react-router';
import { ShowWhen } from 'components/conditional-render/show-when';
import Demo from './demo';
import CalloutError from 'components/notifications/callout/callout-error';





function Home() {

  let match = useMatch("/home/:notify");
  let parameters = '';

  if (match)
    parameters = match.params.notify;



  return (
    <EuiPageSection>

      <ShowWhen condition={parameters === 'forbidden'}>

        <CalloutError title="Acceso denegado" description="No tienes permisos para ver este contenido." error={"Acceso denegado"} />
      </ShowWhen>
      {/* <Callout type="success" />
      <Notify type="success" description="se ha realizado con exito" ></Notify>
             <Callout type="alert" /> */}



      {/* <NotifyUpgrade showModal={true} /> */}

      {/* <RenderForm collection_id={1} /> */}
      <Demo />

    </EuiPageSection>
  )
}

export default Home
