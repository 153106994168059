import React, { useEffect, useState } from 'react';
import {

  EuiPage,
  EuiPageBody,
  EuiPageSidebar,

  } from '@elastic/eui';
import { Toaster } from 'react-hot-toast';
import { Outlet } from "react-router-dom";
import AppSideNav from 'pages/sidenav/Index';
import CalloutInformation from 'components/notifications/callout/callout-information';
import GlobalNotificationCallout from 'components/notifications/callout/global-notification-callout';
import NotificationPanel from 'packages/notifications';

import GlobalNotificationOffline from 'components/notifications/callout/callout-offline';


function MainLayout() {

  const sideBarOpenKey = 'sidebar-collapse';
  const defaultOpen = localStorage.getItem(sideBarOpenKey) === 'true';
  const [isCollpase, setIscollapse] = useState(defaultOpen ? true : false);


 


  const CollapseOnchange = () => {
    setIscollapse(!isCollpase)
    localStorage.setItem(sideBarOpenKey, JSON.stringify(!isCollpase));
  }



  return (

    <EuiPage paddingSize="none" style={{ minHeight: '100vh' }} >
      <EuiPageSidebar
        sticky
        className={isCollpase ? 'collapse' : ''}
        minWidth={isCollpase ? 50 : 240} >
        <AppSideNav
          CollapseOnchange={CollapseOnchange}
          iscollapse={isCollpase} />
      </EuiPageSidebar>
      <EuiPageBody
        paddingSize="none"
        restrictWidth={false}
        panelled={false}
        panelProps={{ 'hasShadow': false, 'grow': true }}>
            <Outlet />
        <Toaster toastOptions={{ success: { duration: 3000 } }} />
        {/* <CalloutInformation
          title='Configuración general'
          messaje='La información registrada se almacenará en mayusculas'
          side='right' /> */}
        {/* <QuestionHelper /> */}
        {/* {isShowGlobalNotification && <Callout type={toast.type} title={toast.title} description={toast.message} />} */}
        <GlobalNotificationCallout />
        <GlobalNotificationOffline />
        <NotificationPanel />

      </EuiPageBody>
    </EuiPage>

  )
}

export default MainLayout
