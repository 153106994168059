
import {
    EuiButtonIcon,
    EuiToolTip,
} from "@elastic/eui";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { DATAGRID_QUERY_KEYS } from "api/datagrid-api";
import { RecordsApiCommand } from "api/collections/records-api";
import CalloutError from "components/notifications/callout/callout-error";
import { useState } from "react";
import ModalNewRecord from "./components/modal-new-record";
import toast from 'react-hot-toast';
import { QUERY_KEYS_FIELDS } from "api/field-api";
import { useDataGridStore } from "packages/datagrid/store/use-datagrid-store";


function ButtonOpenModalNewRecord({ collection_id, ...iconProps }) {


    const [showModal, setShowModal] = useState(false);
    const [isLoadingSavingChanges, setIsLoadingSavingChanges] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setCurrentData] = useState({});
    const updateGrid = useDataGridStore((state) => state.updateGrid);

    const { InvalidateQuery } = useInvalidateQueryByKey([DATAGRID_QUERY_KEYS.GET_RECORDS_BY_VIEW, QUERY_KEYS_FIELDS.GET_FIELD_BY_FOREIGN_KEY]);


    const closeModal = () => setShowModal(false);

    const onFormChange = (data) => setCurrentData(data);
    const onSubmit = async (data) => {


        setIsLoadingSavingChanges(true);
        setShowError(false);


        const new_record = {
            hash_id: collection_id,
            data: data,
        }

        const response = await RecordsApiCommand.AddRecord(new_record);

        if (response.status) {
            
            //InvalidateQuery();
            setShowModal(false);
            setIsLoadingSavingChanges(false);
            toast.success('Registro creado exitosamente');
            setCurrentData({});
            updateGrid();

        } else {
            setIsLoadingSavingChanges(false);
            setShowError(true);
            setErrorMessage(response.message);
            setShowModal(false);

        }



    }




    return (
        <>

            {showModal &&
                <ModalNewRecord
                    closeModal={closeModal}
                    onFormChange={onFormChange}
                    onSubmit={onSubmit}
                    collection_id={collection_id}
                    isLoadingSavingChanges={isLoadingSavingChanges}
                    data={data} />}

            {showError &&
                <CalloutError
                    title="No fue posible crear el registro"
                    description="Ha ocurrido algo inesperado."
                    error={errorMessage}
                />
            }

            <EuiToolTip content='Agregar un nuevo registro'>
                <EuiButtonIcon
                    display='fill'
                    color='primary'
                    iconType='plus'
                    aria-label='add'
                    className='border-radius-50'
                    {...iconProps}
                    onClick={() => { setShowModal(true) }} />
            </EuiToolTip>

        </>
    )



}

export default ButtonOpenModalNewRecord;