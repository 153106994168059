import { useRef, useState } from 'react';
import { EuiConfirmModal } from '@elastic/eui';
import { FilePond, registerPlugin } from 'react-filepond';
import locale_es_co from 'filepond/locale/es-es.js';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

import cleanFileNameHelper from './component/file-name.helper';
import { useFilePondServer } from './component/useFilePondServer';


registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginGetFile,
    FilePondPluginFileRename,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
)



export const UploadAvatarField = ({ fieldSchema, error, fieldController }) => {


    let modalConfirmDelete;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleConfirmationRef = useRef(null);
    const fileSizeInMb = 1 * 1048576;

    const { files,
        setFiles,
        loadFile,
        processFile,
        revertFile,
        removeFile, } = useFilePondServer('images/upload/avatar', []);


    const onBeforeDeleteFile = (item) => {
        return new Promise((resolve) => {
            setIsModalVisible(true);
            handleConfirmationRef.current = (confirmed) => {

                setIsModalVisible(false)
                resolve(confirmed);
            };


        });
    }

    const onchange = (files) => {

        const list_files = files.map(file => file.filename);
        setFiles(files)
        fieldController.onChange(list_files);
    }






    if (isModalVisible) {
        modalConfirmDelete =
            (
                <EuiConfirmModal
                    title="¿Esta seguro de eliminar?"
                    onCancel={() => { handleConfirmationRef.current(false) }}
                    onConfirm={() => { handleConfirmationRef.current(true) }}
                    cancelButtonText="Cancelar"
                    confirmButtonText="Si"
                    defaultFocusedButton="confirm"
                    buttonColor='danger'
                    titleProps={{
                        size: 'xs'
                    }}
                >
                </EuiConfirmModal>
            );
    }




    return (
        <>
            {modalConfirmDelete}
            <div style={{ width: 200, height: 200, margin: 'auto', left: 'auto', display: 'block', textAlign: 'center', alignContent: 'center' }}>
                <FilePond
                    name={fieldSchema.id + ''}
                    credits={false}
                    files={files}
                    onupdatefiles={onchange}
                    labelButtonDownloadItem="Descargar"
                    beforeRemoveFile={onBeforeDeleteFile}
                    allowMultiple={false}
                    maxFiles={1}

                    maxTotalFileSize={fileSizeInMb}
                    acceptedFileTypes={'image/png, image/jpeg, image/gif'}
                    fileRenameFunction={
                        (file) => {
                            return cleanFileNameHelper(file.basename, file.extension, false);
                        }
                    }
                    server={{
                        load: loadFile,
                        process: processFile,
                        revert: revertFile,
                        remove: removeFile
                    }}
                    imagePreviewHeight={170}
                    imageCropAspectRatio={"1:1"}
                    imageResizeTargetWidth={200}
                    imageResizeTargetHeight={200}
                    stylePanelLayout='compact circle'
                    styleLoadIndicatorPosition='center bottom'
                    styleProgressIndicatorPosition='right bottom'
                    styleButtonRemoveItemPosition='left bottom'
                    styleButtonProcessItemPosition='right bottom'
                    {...locale_es_co}
                />

            </div>

        </>


    )
}
