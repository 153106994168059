import React from 'react';
import {
  
  EuiFlexGroup,
  
  EuiPanel,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiButton,

  
} from '@elastic/eui';
import ItemBuilder from './Builder/Item';
import AndOr from './Builder/AndOr';




  function FilterBuilder(){
  
 
  return (
   <>
   <EuiPanel>
  <EuiFlexGroup style={{ maxWidth: 800 }} direction='column' >
    <ItemBuilder/>
     <AndOr/>
    <ItemBuilder/>
    <AndOr/>
    <ItemBuilder/>
    <AndOr/>
    <EuiButtonEmpty onClick={() => {}} iconType="plusInCircleFilled" size="xs">
          Agregar condicion
        </EuiButtonEmpty>
    <EuiHorizontalRule/>

    <EuiFlexGroup justifyContent="spaceBetween">
    <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => {}} color="danger" size="s">
          Cancelar
        </EuiButtonEmpty></EuiFlexItem>
    <EuiFlexItem grow={false}><EuiButton onClick={() => {}} size="s">
          Guardar Filtro
        </EuiButton></EuiFlexItem>
  </EuiFlexGroup>
    
  </EuiFlexGroup>
  </EuiPanel>
   </>
  );
};

export default FilterBuilder;