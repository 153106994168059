
import React, { useState, useEffect } from 'react';
import { AppApiQuery } from 'api/app-api';
import { EuiBreadcrumbs, EuiHeaderSection, EuiHeaderSectionItem, EuiIcon, EuiPanel, EuiSkeletonRectangle } from '@elastic/eui';
import EmojiMart from 'components/emoji/emoji';



function AppEmoji({ app }) {


  const [emoji, setemoji] = useState('pinata')
  const [nameSpace, setnameSpace] = useState('')
  const { data, isLoading } = AppApiQuery.QueryGetAppByHas(app);

  const breadcrumbs = [
    {
      text: nameSpace,
      href: '#',
    },
    {
      text: 'Configuración',
      href: '#',
    },

  ];

  useEffect(() => {

    if (data) {
      setemoji(data.data.emoji)
      setnameSpace(data.data.name)
    }

  }, [app, data])




  return (
    <>
      <div style={{ width: '350px' }}>
        <EuiSkeletonRectangle
          width="100%"
          height={20}
          borderRadius="m"
          isLoading={isLoading}
          contentAriaLabel="Loading configuration"
        >
          <EuiHeaderSection>
            <EuiHeaderSectionItem><EmojiMart id={emoji} /></EuiHeaderSectionItem>
            <EuiHeaderSectionItem>
              <EuiBreadcrumbs
                max={4}
                breadcrumbs={breadcrumbs}
                aria-label="An example of EuiBreadcrumbs with specifying max prop"
              />
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
        </EuiSkeletonRectangle>
      </div>
    </>
  )
}

export default AppEmoji