import { EuiButtonEmpty, } from "@elastic/eui";


function DataGridToolBarTotalRecords({ totalRecords }) {

    return (
        <EuiButtonEmpty
            size="s"
            iconType="document"
            color="text"
            onClick={() => { }}
        >
            {totalRecords} registros
        </EuiButtonEmpty>
    )
}

export default DataGridToolBarTotalRecords