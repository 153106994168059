import React, { useEffect, useState } from 'react'
import { EuiDragDropContext, EuiDraggable, EuiDroppable, euiDragDropReorder } from '@elastic/eui';
import { useParams } from 'react-router-dom';
import RowField from './row-field';
import { ViewAttributeApiCommand, ViewAttributeApiQuery } from 'api/view-attributes-api';
import ProgressPanel from 'components/progress/progress-whit-panel';
import { useInvalidateQuery } from 'api/invalidate-querys/invalidate-current-views';
import { useGlobalStore } from 'store/global-store';


function ColumnsByView({onChange}) {

  let parameters = useParams();
  const view_id = parameters.view;
  const [rowsFields, setRowsFields] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const currentTheme = useGlobalStore(state => state.theme);
  const isDraggingOverClass = currentTheme === 'dark' ? 'isDraging-hover-dark' : 'isDraging-hover-ligth ';
  const { data, isLoading } = ViewAttributeApiQuery.GetAttributesByView(view_id);
  const { InvalidateCurrentView } = useInvalidateQuery();


  const onDragStart = () => setIsDragging(true);

  const onDragEnd = ({ source, destination }) => {

    if (source && destination) {

      const items = euiDragDropReorder(rowsFields, source.index, destination.index);
      updateOrder(items);
      setRowsFields(items);

    }

  };

  const updateOrder = async (items) => {

    const update_order =
    {
      view_id: view_id,
      keys_id: items.map(({ id }) => parseInt(id))
    }
    await ViewAttributeApiCommand.UpdateOrderAttributesByView(update_order);
    InvalidateCurrentView()


  }


  useEffect(() => {

    if (data) {
      setRowsFields(data.data)
    }

  }, [data])




  if (isLoading) {
    return <ProgressPanel />
  }


  return (
    <>
      <EuiDragDropContext onDragEnd={onDragEnd}   onBeforeDragStart={onDragStart} >
        <EuiDroppable droppableId="DROPPABLE_AREA" spacing="s"  className={isDragging ? isDraggingOverClass : ''}>
          {!isLoading && rowsFields.map((item, index) => (
            <EuiDraggable
              spacing="s"
              key={item.id}
              index={index}
              draggableId={'draggable-' + item.attribute_key_id}
              customDragHandle={true}
              hasInteractiveChildren={true}
             
            >
              {(provided, state) => (
                <RowField 
                {...provided} 
                is_visble={item.visible} 
                title={item.name} 
                idx={index} 
                id={item.id} 
                icon={item.data_type} 
                onChange={e => { }} />
              )}
            </EuiDraggable>
          )
          )}

        </EuiDroppable>
      </EuiDragDropContext>

    </>
  )

}

export default ColumnsByView