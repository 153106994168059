import { EuiCallOut, EuiConfirmModal, EuiFieldText, EuiFormRow, EuiSpacer } from "@elastic/eui";
import { QUERY_KEYS_APP, AppApiCommand } from "api/app-api";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { useAppParameters } from "hooks/useAppParameters";
import { useAppRouteNavigator } from "hooks/useAppRouteNavigator";
import { useState } from "react";





function ModalConfirmDelete({ closeModal }) {

    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { app } = useAppParameters();
    const navigate = useAppRouteNavigator();
    const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_APP.GET_LIST_APPS_BY_WORKSPACE, QUERY_KEYS_APP.GET_APP_BY_HASH]);

    const onCopyHandler = (event) => {
        event.preventDefault();
    }

    // Evitar que se corte
    const onCutHandler = (event) => {
        event.preventDefault();
    }

    // Evitar que se pegue
    const onPasteHandler = (event) => {
        event.preventDefault();
    }

    const onChange = e => setValue(e.target.value);

    const onConfirm = async () => {

        setIsLoading(true)
        const data = { hash_id: app };

        const response = await AppApiCommand.DeleteApp(data);

        if (response.status) {
            InvalidateQuery();
            closeModal();
            navigate.GotoHome();
        }

    }

    return (
        <EuiConfirmModal
            title="Borrar App"
            onCancel={() => { if (!isLoading) closeModal()    }}
            onConfirm={() => {onConfirm()}}
            confirmButtonText={isLoading ? 'Borrando...' : 'Borrar definitivamente'}
            cancelButtonText="Cancelar"
            buttonColor="danger"
            initialFocus="[name=delete]"
            confirmButtonDisabled={value.toLowerCase() !== 'borrar definitivamente'}
            isLoading={isLoading}
        >
            <EuiCallOut title="Procede con precaución!" color="danger" iconType="help" >
                <p>Este proceso borra toda la información relacionada con la app.<br /> Una vez borrada, no podrá recuperarla.</p>
            </EuiCallOut>
            <EuiSpacer />
            <EuiFormRow label="Escribe 'borrar definitivamente' para confirmar" fullWidth >
                <>

                    <EuiFieldText
                        compressed
                        name="delete"
                        fullWidth
                        isLoading={isLoading}
                        value={value}
                        maxLength={25}
                        onChange={onChange}
                        onCopy={onCopyHandler}
                        onCut={onCutHandler}
                        onPaste={onPasteHandler}
                    />
                </>
            </EuiFormRow>
        </EuiConfirmModal>
    )

}


export default ModalConfirmDelete