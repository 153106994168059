import {
  useNavigate, useParams
} from "react-router-dom";


export const useAppColumnManager= () => {

  const navigate = useNavigate()


  let parameters = useParams();
  const collection = parameters.collection;
  const view_type = parameters.view_type;
  const view_id = parameters.view;
  const view_name = parameters.name_view || null;


  const ShowColumnManager = () =>
  {
    if(view_name)
      navigate(`/collection/${collection}/${view_type}/${view_id}/${view_name}/column-manager`);
    else
      navigate(`/collection/${collection}/${view_type}/${view_id}/column-manager`);

  }

  const HideColumnManager = () =>{
    
    if(view_name)
      navigate(`/collection/${collection}/${view_type}/${view_id}/${view_name}`);
    else
      navigate(`/collection/${collection}/${view_type}/${view_id}/`);
  }


  


  const columnManager = {
    Show:ShowColumnManager,
    Hide:HideColumnManager
  }

  return columnManager;

};