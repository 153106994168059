import { EuiButtonIcon, EuiHeader, EuiIcon, EuiSkeletonText, EuiTitle } from "@elastic/eui";
import { ViewApiQuery } from "api/view-api";
import { useAppParameters } from "hooks/useAppParameters";
import ButtonOpenModalNewRecord from "packages/form/modal/new-record";
import { useEffect, useState } from "react";
import DataGridTools from "./tools";
import DataGridExloreDatabase from "../database-explorer";
import SearchInTable from "packages/search/components/SearchInTable";




function DataGridTopToolBar({ collection }) {


  const [name_view, setName] = useState('');
  const [, setEmoji] = useState('pinata');
  const { view_id } = useAppParameters();
  const { data, isLoading } = ViewApiQuery.GetViewByHash(view_id);


  useEffect(() => {

    if (data) {

      setName(data.data.name);
      setEmoji(data.data.icon);
    }
  }
    , [view_id, data])







  const sections = [
    {
      items: [
        <DataGridExloreDatabase />,
        <div style={{ paddingLeft: 5 }}>
          <EuiSkeletonText isLoading={isLoading}>
            <EuiTitle size="xs" >
              <h2>{name_view}</h2>
            </EuiTitle>
          </EuiSkeletonText>
        </div>,
        <EuiIcon type="empty" size="s" />,
        <EuiButtonIcon aria-label='informacion' aria-labelledby='favoritos' iconType="questionInCircle" color="text" />,
        <EuiButtonIcon aria-label='favoritos' aria-labelledby='favoritos' iconType="starEmpty" color="warning" />,
        <EuiIcon aria-labelledby='empty' aria-label='empty' type="empty" size="s" />,
        <SearchInTable />
      ]
    },
    {
      items: [
        <ButtonOpenModalNewRecord collection_id={collection} />,
         <EuiIcon type="empty" size="s" />,
        <DataGridTools />,
        <EuiIcon type="empty" size="s" />,
        <EuiIcon type="empty" size="s" />,
        <EuiIcon type="empty" size="s" />,

      ],
    },
  ];



  return (
    <EuiHeader className='remove-shadow' sections={sections} />
  );



}

export default DataGridTopToolBar
