// @ts-nocheck
import { EuiCard, EuiIcon, EuiI18n } from '@elastic/eui'
import React, { Fragment, useEffect, useState } from 'react'
import Sucess from 'assets/svg/ico-succes.svg'
import { motion } from 'framer-motion'
import Confetti from 'react-dom-confetti'
import { nanoid } from 'nanoid'



const NotifySucces = ({ title, description, conffeti }) => {

    const [isOpen, setIsOpen] = useState(false);
    const random_id = nanoid();
    let i18n_title = title;


    if (!title)
        i18n_title = (<EuiI18n token="global.notify.title.success" default="Realizado con exito" />);




    const configConffeti = {
        angle: 90,
        spread: 145,
        startVelocity: 40,
        elementCount: 60,
        dragFriction: 0.12,
        duration: 4000,
        stagger: 0,
        width: "10px",
        height: "10px",
        perspective: "1000px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };


    useEffect(() => {

        setIsOpen(true)

    }, [])

    const component=(
                <motion.div key={'sucess-notify'+random_id} initial={{ opacity: 0, y: 50, scale: 0.2 }} animate={{ opacity: 1, y: 0, scale: 1 }} >
                    <div key={random_id}>
                <EuiCard key={'sucess-notify-card'+random_id} icon={<EuiIcon type={Sucess} size="original" /> }
                    title={i18n_title}
                    display="plain"
                    description={description} />
                    </div>                    
            </motion.div>
    );

    return (
        <Fragment>
            {conffeti && <Confetti active={isOpen} config={configConffeti} />}
            {component}
        </Fragment>
    )
}


export default NotifySucces
