import { JsonHelper } from "core/Json";


const func = (formId, fieldId, rule, data, mutableSchema, pristineSchema) => {

  const pristineField = (pristineSchema || []).find(
    (f) => f.id === fieldId
  );
  const field = (mutableSchema || []).find((f) => f.id === fieldId);

  if (!field || !pristineField) return;

  const currentValue = (data[rule.form || formId] ?? {})[rule.field];
  

  const _rules = JsonHelper.ToJson(rule.values);
  const _pristineOptions = JsonHelper.ToJson(pristineField.options);


  field.options = !!currentValue
    ? _pristineOptions?.filter(
      (o) => _rules.find(values => values[currentValue]?.indexOf(o.id) >= 0)
    )
    : [{id: '',value: '',label: '',color: '',dependent: ''}];



};

export default func;
