import { EuiTextArea } from '@elastic/eui';

export const TextArea = ({field,error,...others}) => {

const { name,value } = field;
  
return( 
    <EuiTextArea
      name={name}
      value={value||''}
      onChange={(e) => field.onChange(e.target.value)}
      isInvalid={!!error}
      compressed={true}
      readOnly={false}
      fullWidth={false}         
      autoComplete='off'
      maxLength={255}
      {...others}
    />
)
};