import React, { Fragment, useEffect, useState } from 'react'
import { nanoid } from 'nanoid'

import icoWorkspace from 'assets/svg/workspace.svg';
import {
  EuiPopover, EuiAvatar, EuiSelectable, EuiPopoverTitle, EuiPopoverFooter, EuiButtonEmpty, EuiModal, EuiModalHeader,
  EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiI18n, EuiPortal, EuiOverlayMask, EuiSpacer, EuiIcon, EuiListGroup, EuiProgress, useEuiTheme, EuiTourStep, EuiText
} from '@elastic/eui';
import { useQuery } from '@tanstack/react-query'
import AddWorkspaceForm from './add/AddWorkspaceForm';
import WorkspaceApi from 'api/workspace-api';
import ButtonConfigurationWorkSpace from './edit/components/LinkToEditWorkspace';
import NotifyUpgrade from 'components/notifications/notify-upgrade';
import NotifySucces from 'components/notifications/notify-success';
import { useGlobalStore } from 'store/global-store';
import { css } from '@emotion/css';
import useSuscriptionLimit from 'hooks/useSuscriptionLimit';




const i18n_create_modal_title = (<EuiI18n token="workspace.create.title" default="Crear un espacio de trabajo" />)
const i18n_ws_title = (<EuiI18n token="workspace.list.title" default="Espacios de trabajo" />)
const i18n_modal_succes = (<EuiI18n token="workspace.create.succes" default="Ahora puedes visualizar el nuevo espacio" />)
const i18n_workspace_loading_messaje = (<EuiI18n token="workspace.loading.messaje" default="Cargando espacios de trabajo" />)
const i18n_workspace_empty = (<EuiI18n token="workspace.empty" default="No hay espacios de trabajo disponibles" />)


function AppUserWorkspace({ iscollapse }) {


  const id = nanoid();
  const spacesValues = [];
  const { data, isLoading } = useQuery(["GetListWorkspace"], () => WorkspaceApi.GetList());
  const setCurrentWorkspace = useGlobalStore(state => state.setWorkSpace);
  const selectedWorkspace = useGlobalStore((state) => state.workspace);
  const currentTheme = useGlobalStore(state => state.theme);

  const [spaces, setSpaces] = useState(spacesValues);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusEmptyWorkspace, setStatusEmptyWorkspace] = useState(false);


  const initialSpace = spaces.length ? spaces.filter((option) => option.checked)[0] : {};

  const [selectedSpace, setSelectedSpace] = useState(initialSpace);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSpaceName, setSelectedSpaceName] = useState('');
  const { euiTheme } = useEuiTheme();
  const [limitWorkspace, setLimitWorkspace] = useState(false);
  const { data: limitResponse, status: limitStatus } = useSuscriptionLimit("workspace");


  const closeModal = () => {
    setIsModalVisible(false);

  }

  const closeModalForm = (operation) => {

    setStatus(operation)
    if (!operation)
      setIsModalVisible(false);

  }
  const showAddWorkspaceModal = () => {
    setStatus(false)
    setIsOpen(false)
    setIsModalVisible(true)

  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);

  };


  const closePopover = () => {
    setIsOpen(false);


  };

  const onChangeWokspace = (options) => {


    const curentOptions = options.filter((option) => option.checked)[0];
    setSpaces(options);
    setSelectedSpace(curentOptions);
    setCurrentWorkspace(curentOptions.id);
    setIsOpen(false);
    setSelectedSpaceName(curentOptions.label)

    ///navigator.GotoHome();

  };

  const fetchWorkspace = async (data) => {


    let totalrows = 0;
    if (data) {
      const dataList = data.data;
      const spacesfecht = [];

      if (dataList.length > 0) {
        totalrows = dataList.length;

        let first = true;
        dataList.forEach(element => {


          if (element.state) {

            spacesfecht.push(
              {
                id: element.id,
                label: element.name,
                prepend: <EuiAvatar size='s' style={{ marginLeft: -2, marginRight: 5 }} initialsLength={2} name={element.name.toUpperCase()} color={element.color} className="eui-alignMiddle" />,
                append: <ButtonConfigurationWorkSpace workspace={element.hash_id} />,
                ...(first && { checked: 'on' })
              }
            );

          }
          if (first) {
            first = false;

          }

        });

        setSpaces(spacesfecht);

        if (selectedWorkspace > 0) 
        {


          let workspaceWithUpdateItem = spacesfecht.map(items => {

            if (items.id === selectedWorkspace)
              items.checked = 'on'
            else
              if (items.checked)
                delete items.checked

            return items;
          }
          );

          setSpaces(workspaceWithUpdateItem);


          setSelectedSpace(spacesfecht.filter((option) => option.id === selectedWorkspace)[0]);
          setSelectedSpaceName(spacesfecht.filter((option) => option.id === selectedWorkspace)[0].label)


        }
        else {
          //set first workspace
          setSelectedSpace(spacesfecht[0]);
          setCurrentWorkspace(spacesfecht[0].id);
          setSelectedSpaceName(spacesfecht[0].label)
        }





      } else {

        setIsOpen(true);

      }


    }

    totalrows > 0 ? setStatusEmptyWorkspace(false) : setStatusEmptyWorkspace(true);
    totalrows <= 0 && setCurrentWorkspace(0);


  };



  useEffect(() => {

    if (data) {
      const fetchData = async () => {
        fetchWorkspace(data);
      }

      fetchData();
      if (limitStatus === "success") {
        setLimitWorkspace(limitResponse.data);

      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);




  const modalSuccess = (
    <EuiModal onClose={closeModal}>
      <NotifySucces description={i18n_modal_succes} conffeti={true} title={undefined} />
    </EuiModal>);

  const modalForm = (
    limitWorkspace ?
      (<EuiModal onClose={closeModal}>
        <NotifyUpgrade showModal={false} />
      </EuiModal>) :
      (<EuiModal onClose={closeModal} initialFocus="[name=popfirst]" maxWidth={true}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <EuiIcon type={icoWorkspace} size="xl" />
            <span style={{ marginLeft: 5 }}>{i18n_create_modal_title}</span></EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiSpacer size="s" />
          <AddWorkspaceForm closeModal={closeModalForm} />
          <EuiSpacer size="s" />
        </EuiModalBody>
        <EuiModalFooter>
        </EuiModalFooter>
      </EuiModal>
      )
  );

  const modalAddWorkspace = (
    <EuiPortal>
      <EuiOverlayMask className={currentTheme === 'dark' ? "euiOverlayMask-dark" : "euiOverlayMask-light"} >
        {!status ? (modalForm) : modalSuccess}
      </EuiOverlayMask>
    </EuiPortal>
  );



  const buttonWorkspace = (
    <EuiListGroup
      color='text'
      gutterSize="none"
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Spaces"
      onClick={onMenuButtonClick}
      maxWidth="none"
      bordered={true}
      className={css`
      background-color:  ${currentTheme === 'dark' ? euiTheme.colors.lightestShade : euiTheme.colors.emptyShade};  
      border-radius: 6px;`
      }
      size="s"
      style={{ marginTop: -4, width: (iscollapse ? 40 : 225), marginLeft: iscollapse ? 4 : 4 }}
      listItems=  {
        [
        {
          label: iscollapse ? '' : selectedSpaceName,
          href: '#',
          icon: selectedSpace.prepend,
          extraAction:iscollapse ? null : {
            color: 'text',
            iconType: 'arrowDown',
            iconSize: 's',
            alwaysShow: true,
            'aria-label': 'Favorite link2',
            
          }        
        },
      ]}
    />
  );

  const buttonEmptyWorkspace = (
    <EuiTourStep
      content={
        <EuiText size='xs'>
          <p>No tienes espacios de trabajo creado, por favor crea uno y luego podrás crear apps.</p>
        </EuiText>
      }
      isStepOpen={statusEmptyWorkspace}
      maxWidth={300}
      onFinish={() => setStatusEmptyWorkspace(false)}
      step={1}
      stepsTotal={1}
      title="Espacios de trabajo"
      anchorPosition="rightUp"
    >
      <EuiListGroup
        color='text'
        gutterSize="none"
        aria-controls={id}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Spaces"
        onClick={showAddWorkspaceModal}
        maxWidth="none"
        bordered={true}
        className={css`
      background-color:  ${currentTheme === 'dark' ? euiTheme.colors.lightestShade : "#fff"};  
      border-radius: 6px;`
        }
        size="s"
        style={{ marginTop: -4, width: (iscollapse ? 40 : 225), marginLeft: iscollapse ? 4 : 4 }}
        listItems={[
          {
            label: iscollapse ? '' : 'Crear espacio de trabajo',
            href: '#',
            icon: <EuiIcon type="plusInCircle" size="m" />,
          },
        ]}
      />
    </EuiTourStep>
  );



  return (
    <Fragment>
      {isLoading && <EuiProgress size="xs" color="primary" position="absolute" />}
      {data && data.data.length > 0 ? (
        <EuiPopover
          id={id}
          ownFocus
          button={buttonWorkspace}
          isOpen={isOpen}
          anchorPosition="rightUp"
          closePopover={closePopover}
          hasArrow={false}
          panelPaddingSize="none">
          <EuiSelectable
            options={spaces}
            singleSelection="always"
            style={{ width: 300 }}
            onChange={onChangeWokspace}
            isLoading={isLoading}
            emptyMessage={i18n_workspace_empty}
            loadingMessage={i18n_workspace_loading_messaje}
            listProps={{
              rowHeight: 40,
              showIcons: false,
              onFocusBadge: false
            }}>
            {(list, search) => (
              <>
                <EuiPopoverTitle paddingSize="s">
                  {i18n_ws_title}
                </EuiPopoverTitle>
                {list}
                <EuiPopoverFooter paddingSize="s">
                  <EuiButtonEmpty color="text" iconSide="left" iconType="plusInCircle" size="s" onClick={showAddWorkspaceModal}>
                    {i18n_create_modal_title}
                  </EuiButtonEmpty>
                </EuiPopoverFooter>
              </>
            )}
          </EuiSelectable>
        </EuiPopover>
      )
        : (buttonEmptyWorkspace)
      } 
      {isModalVisible && modalAddWorkspace}
    </Fragment>
  )
}

export default AppUserWorkspace
