import { useEffect, useMemo } from 'react';
import useSchemasFormStore from 'packages/form/state/schemas-store';
import ruleFunctions from './rules';
import { shallow } from 'zustand/shallow';


const useRules = ({ formId, schema_field , data,setLoading }) => {
 
  const rules = useMemo(() => schema_field.rules || [], [schema_field.rules]);

    const { updateSchema } = useSchemasFormStore(
      (state) => ({
        updateSchema: state.updateSchema,
      }),
      shallow//comparer(formId, itemId, rules)
    );

  useEffect(() => 
  {
    updateSchema(formId, (s, us) => 
    {
      for (const rule of rules) 
      {
        
        const func = ruleFunctions[rule.type];
        
        if (!func) {
          console.warn('Regla no reconocida: ' + rule.type, rule);
        } else {
          setLoading(true);
          
          func(formId, schema_field.id, rule, data, s, us);
          setTimeout(() => setLoading(false), 500); 
        }
      }
    });
  }, [formId,schema_field.id,rules,data,updateSchema,setLoading]);
};

export default useRules;
