import { useQuery } from "@tanstack/react-query";
import ApiClient from "./api.client";


export const QUERY_KEYS_APP = {
  GET_APP_BY_HASH: "getappbyhash",
  GET_LIST_APPS_BY_WORKSPACE: "GetWorkSpaceApps",

}

async function CreateApp(app) {
  const response = await ApiClient.HttpPost("app/create", app);
  return response.data;
}

async function IsDuplicateApp(app) {
  const response = await ApiClient.HttpPost("app/check-name", app);
  return response.data;
}



async function GetAppById(id) {
  const response = await ApiClient.HttpGet(`app/${id}`);
  return response.data;
}


async function GetAppViewsByUser(hash) {

  const response = await ApiClient.HttpGet(`app/${hash}`);
  return response.data;

}



async function UpdateApp(data) {

  const response = await ApiClient.HttpPost('app/update', data);
  return response.data;

}

async function DeleteApp(data) {

  const response = await ApiClient.HttpPost(`app/delete`, data);
  return response.data;

}


export const AppApiCommand = {
  CreateApp,
  IsDuplicateApp,
  GetAppById,
  GetAppViewsByUser,
  UpdateApp,
  DeleteApp
}



async function GetAppByHashQuery(hash) {
  const response = await ApiClient.HttpGet(`app/${hash}`);
  return response.data;
}


function GetAppByHash(hash_id) {
  return GetAppByHashQuery(hash_id);
}


function QueryGetAppByHas(hash_id) {
  return useQuery([QUERY_KEYS_APP.GET_APP_BY_HASH, hash_id], () => GetAppByHashQuery(hash_id));

}



export const AppApiQuery = {
  GetAppByHash,
  QueryGetAppByHas
}


