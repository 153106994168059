import { EuiPanel, EuiPopover, EuiProgress, EuiSkeletonCircle, EuiToolTip } from "@elastic/eui";
import { QUERY_KEYS_APP, AppApiQuery } from "api/app-api";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { ViewApiCommand } from "api/view-api";
import EmojiMart from "components/emoji/emoji";
import EmojimartPicker from "components/emoji/picker";
import { useEffect, useState } from "react";




function EditAppEmoji({ app }) {


    const [isUpdatinEmoji, setIsUpdatinEmoji] = useState(false)
    const [isOpenPopoverEmoji, setIsOpenPopoverEmoji] = useState(false)
    const [appName, setAppName] = useState('');
    const [appEmoji, setAppEmoji] = useState('pinata');
    const [view_default, setViewDefault] = useState('');



    const openPopupEmoji = () => setIsOpenPopoverEmoji(true)
    const closeEmoji = () => setIsOpenPopoverEmoji(false)

    const { data, isLoading } = AppApiQuery.QueryGetAppByHas(app);


    const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_APP.GET_LIST_APPS_BY_WORKSPACE, QUERY_KEYS_APP.GET_APP_BY_HASH]);




    const onUpdateEmoji = async (updatedEmoji) => {
        closeEmoji()
        setIsUpdatinEmoji(true)
        setAppEmoji(updatedEmoji)

        const updated = { view_hash_id: view_default, view_emoji: updatedEmoji }
        const response = await ViewApiCommand.UpdateViewEmoji(updated);
        if (response.status) {
            setIsUpdatinEmoji(false)
            InvalidateQuery()
        }
    }

    const buttonEditEmoji = (
        <div style={{ textAlign: 'center', padding: 5, cursor: 'pointer' }}>
            <EuiToolTip content={'Cambiar emoji'} position='top' >
                <EmojiMart id={data && appEmoji ? appEmoji : 'pinata'} onClick={() => { openPopupEmoji() }} />
            </EuiToolTip>
        </div>
    );

    useEffect(() => {

        if (data) {
            setAppEmoji(data.data.emoji);
            setAppName(data.data.name)
            setViewDefault(data.data.default_view)
        }

    }, [app, data])





    return (
        <>
            <EuiSkeletonCircle size="s" isLoading={isLoading} contentAriaLabel='Emoji' >
                <EuiPanel paddingSize="xs" hasShadow={false} color="transparent">
                    <EuiPopover
                        id="pickeremoji"
                        ownFocus
                        button={buttonEditEmoji}
                        isOpen={isOpenPopoverEmoji}
                        anchorPosition="rightDown"
                        closePopover={closeEmoji}>
                        <EmojimartPicker onEmojiSelect={(emoji) => {
                            onUpdateEmoji(emoji.id);
                        }
                        } />
                    </EuiPopover>
                </EuiPanel>
            </EuiSkeletonCircle>

        </>
    )
}

export default EditAppEmoji