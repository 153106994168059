import React, { Fragment, useEffect, useState } from 'react'
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiPageHeader, EuiSkeletonTitle, EuiSpacer } from '@elastic/eui'
import { Controller, useForm } from 'react-hook-form';
import { CountriesOptions } from 'core/Country'
import ConfigurationApi from 'api/configuration-api'
import toast from 'react-hot-toast';
import { TextField } from 'packages/fields/text-field/text-field';
import i18n from './Company.i18n';
import { Select } from 'packages/fields/select/select';
import { useQuery } from '@tanstack/react-query'
import { RowSection } from 'packages/fields/row-section';
import { ICON_GALLERY } from 'components/icons/icons-gallery';


function SubscriptionCompanyPage() {

  const { control, handleSubmit, reset } = useForm()
  const [updating, setUpdating] = useState(false)
  const { data, isLoading } = useQuery(["GetConfiguration", 'company'], () => ConfigurationApi.GetConfiguration('company'))



  const onSubmit = async (data) => {

    setUpdating(true)
    const parameters =
    {
      option: 'company',
      data: JSON.stringify(data)
    };
    const response = await ConfigurationApi.UpdateConfiguration(parameters);

    if (response) {
      reset(data)
      toast.success(i18n.title_succes);
      //updatename(data.name)

    } else {

    }
    setUpdating(false);

  }



  useEffect(() => {

    const fecht = async () => {
      if (data) {
        if (data.status) {
          const response = JSON.parse(data.data);
         // updatename(response.name)
          reset(response)
        }
      }
    }

    fecht();

  }, [data, reset])



  return (
    <Fragment>
      <EuiPageHeader
        pageTitle="Información de la organización"
        iconType={ICON_GALLERY.INFO_CIRCLE}
        description="Datos de la organización"
        bottomBorder       
      />
      <EuiSpacer />
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} label={i18n.company_name} error={fieldState.error} extraHelpText={null} helpText={null} errorText={i18n.label_required} >
                <TextField
                  field={field}
                  error={fieldState.error} />
              </RowSection>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />


        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} label={i18n.company_country} error={fieldState.error} errorText={i18n.label_required} >
                <Select
                  field={field}
                  options={CountriesOptions}
                  error={fieldState.error} />
              </RowSection>

            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} label={i18n.company_email} error={fieldState.error} errorText={i18n.label_required} >
                <TextField
                  field={field}
                  readOnly={true}
                  error={fieldState.error} />
              </RowSection>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="phone"
            control={control}
            rules={{ required: false }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} label={i18n.company_phone} error={fieldState.error} errorText={i18n.label_required} >
                <TextField
                  field={field}
                  error={fieldState.error}
                />
              </RowSection>

            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="address"
            control={control}
            rules={{ required: false }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} label={i18n.company_address} error={fieldState.error} errorText={i18n.label_required} >
                <TextField
                  field={field}
                  maxLength={30}
                  error={fieldState.error} />
              </RowSection>

            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="postalcode"
            control={control}
            rules={{ required: false }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} label={i18n.company_postal_code} error={fieldState.error} errorText={i18n.label_required} >
                <TextField
                  field={field}
                  maxLength={8}
                  error={fieldState.error} />
              </RowSection>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size="s" />
        <EuiSpacer size="s" />

        <EuiFlexGroup>

          {!isLoading &&
            (<EuiFlexItem grow={false}>
              <EuiButton fill type="submit" size="s" isLoading={updating}>
                {i18n.button_save}
              </EuiButton>
            </EuiFlexItem>
            )
          }

        </EuiFlexGroup>
      </EuiForm>






    </Fragment>
  )
}

export default SubscriptionCompanyPage
