import ApiClient from "./api.client"

async function UploadSignature(formData) {


    var client = await ApiClient.axiosClient();

    var response = await client({
        method: 'POST',
        url: 'storage/upload/signature',
        data: formData
    });

    return response.data;
    
}

async function DeleteSignature(uniqueFileId) {

    var client = await ApiClient.axiosClient();
    var response = await client({
        method: 'DELETE',
        url: 'storage/'+uniqueFileId,
    })
    return response.data;

}





const UploadApi = {

    UploadSignature,
    DeleteSignature
}

export default UploadApi