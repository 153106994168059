import React, { useEffect, useState } from 'react'
import './components/auth.css'

import { EuiLoadingSpinner, EuiImage, EuiPage, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui';
import AuthPanel from './components/AuthPanel';
import { AuthManager } from 'api/auth-api';
import { getSubDomain } from 'core/Url';


function AuthV2() {

    const [imgIntro, setmainImage] = useState('')

    useEffect(() => {

        const Run = async () => {
            const subdomain = getSubDomain()
            const data = await AuthManager.GetBrandingLogin(subdomain)

            if (data?.status)
                setmainImage(data.data);

        }

        Run();

    }, [])


    return (
        <EuiPage paddingSize="none" grow={true}>

            <EuiFlexGroup justifyContent="spaceBetween" gutterSize='none'>
                <EuiFlexItem >
                    <EuiPanel hasShadow={false} style={{ paddingTop: 150 }}>
                        <AuthPanel />
                        <EuiSpacer />
                        <div style={{ textAlign: 'center' }} > v0.3.2</div>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem grow={9}>
                    <EuiPanel hasShadow={false} paddingSize='none' style={{ height: '100vh' }} >
                        {imgIntro ? (
                            <EuiImage
                                alt="arcoirisapp"
                                src={imgIntro}
                                size="fullWidth"
                                style={{ maxHeight: '100vh' }}
                            />) : (<div style={{ height: '100%' }}> <EuiLoadingSpinner className="loading-center green" size="l" /></div>)
                        }
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPage>
    )
}

export default AuthV2