import { EuiButton, EuiHeader, EuiIcon } from '@elastic/eui';
import ListOptionsSettings from './app-tool-bar-options';
import AppEmoji from './components/app-emoji';


function AppToolBarSettings({ app, collection }) {


  const sections_main = [
    {
      items: [<AppEmoji app={app} />],
    },
    {
      items: [<ListOptionsSettings app={app} collection={collection} />],
    },
    {
      items: [],
    },        

  ];

 

  return (
    <>
      <EuiHeader sections={sections_main} position="static" />


    </>
  );

}

export default AppToolBarSettings