import { htmlIdGenerator, EuiComboBox, EuiHealth, EuiHighlight, euiPaletteColorBlind, euiPaletteColorBlindBehindText } from "@elastic/eui";
import { useEffect, useState } from "react";

const makeId = htmlIdGenerator();

export const ComboBoxField = ({ fieldSchema, error, fieldController }) => {

  const options = () => {

    if (!fieldSchema.options) {
      return [];
    }

    return JSON.parse(fieldSchema.options).map((o) => {

      if (fieldSchema.props.enable_color_picker)
        return { id: o.id, label: o.label, value: o.value, color: o.color }
      else
        return { id: o.id, label: o.label, value: o.value }

    });

  }



  const [selectedOptions, setSelectedOptions] = useState([]);
  const visColors = euiPaletteColorBlind();
  const visColorsBehindText = euiPaletteColorBlindBehindText();

  const renderOption = (option, searchValue, contentClassName) => 
  {
    const { color, label } = option;
    const dotColor = visColors[visColorsBehindText.indexOf(color)];

    return (
      <EuiHealth color={dotColor || color}>
        <span className={contentClassName}>
          <EuiHighlight search={searchValue}>{label}</EuiHighlight>
        </span>
      </EuiHealth>
    );
  };





  const onChange = (option) => {

    if (option.length === 0) {
      setSelectedOptions([]);
      fieldController.onChange('');

    } else {
      setSelectedOptions(option);
      const response_value = option.map((o) => o.value);
      console.log("response_value", response_value)
      fieldController.onChange(response_value.join(','));
    }
  }

  const onCreateOption = (searchValue) => {
    const normalizedSearchValue = searchValue;


    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      id: makeId(),
      label: searchValue,
    };
    setSelectedOptions([newOption]);
    const response_value = [newOption].map((o) => o.label);
    fieldController.onChange(response_value.join(','));


  };



  useEffect(() => {

    if (!fieldController.value && fieldSchema.props?.default_value) {
      const default_value = options().filter((o) => o.id === fieldSchema.props?.default_value)
      setSelectedOptions(default_value)
    } else {

      const current_value = options().filter((o) => o.value === fieldController.value[0])
      setSelectedOptions(current_value)
    }
    // eslint-disable-next-line
  }, [])





  return (
    <>
      <EuiComboBox
        fullWidth
        placeholder="seleccione una opción"
        id={fieldSchema.id + ''}
        singleSelection={JSON.parse(fieldSchema.props?.multiple_selection) ? false : { asPlainText: true }}
        selectedOptions={selectedOptions}
        options={options()}
        onChange={(e) => onChange(e)}
        isInvalid={!!error}
        compressed
        prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
        append={fieldSchema.props?.append && fieldSchema.props?.append}
        renderOption={fieldSchema.props?.enable_color_picker ? renderOption : null}
        onCreateOption={fieldSchema.props?.enable_add_options ? onCreateOption : null}
        customOptionText="Adicionar {searchValue} como nueva opción"
      />
    </>
  );
};


