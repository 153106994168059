import { EuiSkeletonRectangle, EuiSwitch } from "@elastic/eui";
import { useInvalidateQuery } from "api/invalidate-querys/invalidate-current-views";

import { ViewAttributeApiCommand } from "api/view-attributes-api";
import { useState } from "react";



function SwitchStatusViewAttribute({ status, id, onChange }) {

  const { InvalidateCurrentView } = useInvalidateQuery();

  const [checked, setChecked] = useState(status);
  const [isSaving, setIsSaving] = useState(false);


  const onChangeStatus = async (e) => {
    setIsSaving(true);
    const response = await ViewAttributeApiCommand.UpdateVisibilityAttributesByView({ attribute_id: id, status: e.target.checked });

    if (response.status) {
      setChecked(e.target.checked);
      InvalidateCurrentView();
      onChange(e.target.checked);
      setIsSaving(false);
    }

  };




  return (
    <>
    <EuiSkeletonRectangle
        isLoading={isSaving}
        contentAriaLabel="update change status"
        width="28px"
        height="16px"
        borderRadius="s"
        color="prim"
      >
        <EuiSwitch
          showLabel={false}
          label="enable or disable"
          checked={checked}
          onChange={onChangeStatus}
          compressed
        />
      </EuiSkeletonRectangle>
    </>
  )
}

export default SwitchStatusViewAttribute;