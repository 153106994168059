import { EuiComboBox } from "@elastic/eui";
import { useEffect, useState } from "react";



export const UserField = ({ fieldSchema, error, fieldController }) => {

    const options = [];


    const [selectedOptions, setSelectedOptions] = useState([]);
  
  

    const onChange = (option) => {

        if (option.length === 0) {
            setSelectedOptions([]);
            fieldController.onChange('');

        } else {
            setSelectedOptions(option);
            const response_value = option.map((o) => o.value);
            fieldController.onChange(response_value);
        }
    }

  



    useEffect(() => {

        if (!fieldController.value && fieldSchema.props?.default_value) {
            const default_value = options.filter((o) => o.id === fieldSchema.props?.default_value)
            setSelectedOptions(default_value)
        } else {

            const current_value = options.filter((o) => o.value === fieldController.value[0])
            setSelectedOptions(current_value)
        }
        // eslint-disable-next-line
    }, [])


    console.log(JSON.parse(fieldSchema.options).multiple,"options")


    return (
        <>
            <EuiComboBox
                fullWidth
                placeholder="seleccione una opción"
                id={'user-field-'+fieldSchema.id + ''}
                singleSelection={JSON.parse(fieldSchema.options).multiple ? false : { asPlainText: true }}
                selectedOptions={selectedOptions}
                options={options}
                onChange={(e) => onChange(e)}
                isInvalid={!!error}
                compressed                
            />
        </>
    );
};


