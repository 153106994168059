import jwt_decode from 'jwt-decode'
import { LocalStoreHelper } from './LocalStore';
import { KEYS_LOCALSTORE } from './Keys';

const isValidToken = () => {

  const token = localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);

  if (token === 'undefined' || token === null || token === '') {
    clearToken();
    return false

  } else {
    const _token = localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);
    const exp = getExpirationDate(_token);

    if (isExpired(exp)) {
      clearToken();
      return false;

    } else {
      return true;
    }

  }


}

const clearToken = () => {
  LocalStoreHelper.delete(KEYS_LOCALSTORE.KEY_ACCOUNT_SETTINGS);
  LocalStoreHelper.delete(KEYS_LOCALSTORE.KEY_USERPROFILE);
  LocalStoreHelper.delete(KEYS_LOCALSTORE.KEY_TOKEN);
  LocalStoreHelper.delete(KEYS_LOCALSTORE.KEY_ONESPACE_STORE);

}

const setToken = (token, data) => {
  localStorage.setItem(token, data);
}


const getExpirationDate = (token) => {
  const jwt = jwt_decode(token);
  // @ts-ignore
  return jwt && jwt.exp;
};


const isExpired = (exp) => {
  exp = exp * 1000;
  return Date.now() > exp;
};


const GetToken = async () => {

  if (isValidToken)
    return localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);
  else
    return null;
}

const TokenWithBearer = 'Bearer ' + localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);


export {
  isValidToken,
  clearToken,
  setToken,
  GetToken,
  TokenWithBearer
}