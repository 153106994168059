import {
  EuiContextMenu,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiToolTip,
  EuiTextTruncate,
  EuiEmptyPrompt,

} from '@elastic/eui';
import { CollectionApiQuery } from 'api/collections/query-api';
import ProgressPanel from 'components/progress/progress-whit-panel';
import { useAppParameters } from 'hooks/useAppParameters';
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';
import CreateCollection from 'pages/collection/create-collection';




function Navigator() {

  const { collection } = useAppParameters();
  const { data, isLoading = true } = CollectionApiQuery.GetCollectionNavigator(collection);
  const navigator = useAppRouteNavigator();


  if (isLoading) {
    return <ProgressPanel paddingSize='m' />
  }



  const titleNavigator = (
    <EuiFlexGroup gutterSize="xs" responsive={false}>
      <EuiFlexItem grow={false} ><EuiIcon style={{ paddingTop: 1 }} size='l' className='eui-alignMiddle' type={'database'} /></EuiFlexItem>
      <EuiFlexItem grow={true} >
        <EuiTextTruncate text={data && data.data?.initialCollection?.name || 'Nueva colección'} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiToolTip position='right' content='Estas colecciones permiten ampliar el contenido de la base de datos, has click para saber más ' >
          <EuiButtonIcon iconType={'questionInCircle'} color='text' aria-label={'label'} />
        </EuiToolTip>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const navigateToView = (view_has_id, name) => {
    navigator.GotoViewWithName(collection, view_has_id,'database',name);

  }

  const panels = [
    {
      id: 0,
      title: titleNavigator,
      items: []
    },
    {
      id: 1,
      title: 'agregar una formulario',
      content: <>
        <CreateCollection />
      </>,

    }
  ];

  if (data?.data?.views?.length > 0) {

    for (let i = 0; i < data.data.views.length; i++) 
    {
     
      panels[0].items.push(
        {
          name: 
          <EuiTextTruncate 
          key={i} 
          style={{ cursor: 'pointer' }} 
          onClick={() =>
            navigateToView(data.data.views[i].view_hash_id,data.data.views[i].view_name)}
            text={data?.data?.views[i].view_name || ''}
          />,
          icon: data.data.views[i].view_icon,
        },
        {
          isSeparator: true,
          key: 'sep-' + i,
        }
      )
    }



  } else {
    panels[0].items.push(

      {
        name: 'No hay formularios',

        renderItem: () => (
          <EuiEmptyPrompt
            iconType="index"
            iconColor="default"
            title={<h2>No hay formularios</h2>}
            titleSize="xs"
            body={<p>Agrega una formularios para extender la base de datos</p>}
            actions={[]}
          />
        ),
      },
      {
        isSeparator: true,
        key: 'sep-' + 0,
      }
    )
  }


  panels[0].items.push(
    {
      name: 'Agregar una formulario',
      icon: 'plusInCircle',
      panel: 1
    },
  );



  return (    

    <EuiContextMenu
      initialPanelId={0}
      // @ts-ignore
      panels={panels}>
    </EuiContextMenu>

  );
};

export default Navigator;