import { EuiBadge, EuiBadgeGroup, EuiNotificationBadge, EuiPanel, keys } from "@elastic/eui";
import { GLOBAL_KEY_BASE_URL } from "core/Constants";
import { KEYS_LOCALSTORE } from "core/Keys";




const SignatureRenderer = ({ value, row, column }) => {

    const signature = value ? value : null;

    const openFile = (filename) => {

        const _token = localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);
        const url = GLOBAL_KEY_BASE_URL + `files/download?filename=${encodeURIComponent(filename)}&token=${_token}`;
        window.open(url, '_blank');

    }

    return (
        <>
            {signature && 
            (<EuiPanel hasBorder={false} hasShadow={false} color="transparent" paddingSize="none" style={{ maxWidth: 300 }}>
                <EuiBadge
                    iconType="popout"
                    iconSide="left"
                    iconOnClick={() => { openFile(signature) }}
                    iconOnClickAriaLabel="Descargar"
                    key={signature}
                    color="hollow">
                    {signature}
                </EuiBadge>
            </EuiPanel>
            )}
        </>
    )
}

export default SignatureRenderer