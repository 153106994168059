import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiIcon, EuiPanel,  EuiSpacer, } from "@elastic/eui"
import { QUERY_KEYS_COLLECTION } from "api/collections/query-api";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import { ViewApiCommand } from "api/view-api";
import NotifySucces from "components/notifications/notify-success";
import QuickMessageTooltip from "help/quick-messaje-tooltip";
import { useAppParameters } from "hooks/useAppParameters";
import { RowSection } from "packages/fields/row-section";
import { TextField } from "packages/fields/text-field/text-field";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";


function CreateTurboFormView() {

    const { control, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [icon,] = useState('pencil');
    const { collection } = useAppParameters();
    const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_COLLECTION.GET_COLLECTION_NAVIGATOR, collection]);


    const onSubmit = async (data) => {

        setIsLoading(true)

        const payload = {
            app_id : '',
            view_type : 'turbo-form',
            size :'xs',
            icon : icon,
            create_by : '',
            hash : '',
            description : '',
        }


        const response = await ViewApiCommand.AddView(payload);

        if (response.status) {
            InvalidateQuery()
            setIsSuccess(true)
            setIsLoading(false)
        }

    }

    return (
        <>
            <EuiPanel paddingSize="m"  >

                {isSuccess && <NotifySucces conffeti={false} title={'Componente creado con exito'} description={'Quedo vinculado a la base de datos'} />}
                {!isSuccess && (
                    <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate >
                        <>
                            <EuiFlexGroup gutterSize='xs' direction="column">
                                <EuiFlexItem>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) =>
                                            <RowSection
                                                labelAppend={
                                                    <QuickMessageTooltip text={''} position="right" titleToolTip="Agregar componente" descriptionToolTip="Este componente se mostrara en la base de datos " />
                                                }
                                                label={"Nombre del componente"} display="row" extraHelpText={null} error={fieldState.error} errorText={''} helpText={'Escribe el nombre del componente'} >
                                                <TextField
                                                    fullWidth
                                                    field={field}
                                                    maxLength={200}
                                                    error={fieldState.error}
                                                    prepend={<EuiIcon type={icon} size="m" />}
                                                />
                                            </RowSection>
                                        }
                                    />
                                </EuiFlexItem>                               
                            </EuiFlexGroup>

                        </>
                        <EuiSpacer />
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiButton fill size="s" type="submit" isLoading={isLoading}>
                                    Crear
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiForm>
                )}

            </EuiPanel>
        </>
    )

}

export default CreateTurboFormView

