import { EuiFieldNumber } from "@elastic/eui";
import { useGlobalConfiguration } from "hooks/useGlobalConfiguration";
import { NumericFormat, } from "react-number-format"


const NumberRenderer = ({ value, row, column }) => {

    const { separatorThousands, separatorDecimals } = useGlobalConfiguration();
    const decimal_scale = column.numeric_props.decimal_scale;

    return (
        <NumericFormat
            fixedDecimalScale
            thousandSeparator={separatorThousands}
            decimalSeparator={separatorDecimals}
            decimalScale={decimal_scale}
            displayType="text"
            value={value}
            prefix={column.numeric_props?.prefix || ''}

        />)
}

export default NumberRenderer;