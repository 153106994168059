import { useQuery } from "@tanstack/react-query";
import ApiClient from "./api.client";


export const QUERY_KEYS_ATTRIBUTE = {
      GET_ATTRIBUTE_BY_KEY: "GetAttributeByKey",

}



async function CreateAttribute(element) {
      const response = await ApiClient.HttpPost("collections/attribute/add", element);
      return response.data;
}

async function UpdateAttribute(element) {
      const response = await ApiClient.HttpPost("collections/attribute/update", element);
      return response.data;
}



async function DeleteAttribute(element) {
      const response = await ApiClient.HttpPost("collections/attribute/delete", element);
      return response.data;
}



export const AttributeApiCommand = {
      CreateAttribute,
      DeleteAttribute,
      UpdateAttribute
}


async function GetAttributeByKeyQuery(collection,key) {

      const response = await ApiClient.HttpGet(`collections/${collection}/attribute/${key}/key`);
      return response.data;
}


function GetAttributeByKey(collection,key) 
{
      return useQuery([QUERY_KEYS_ATTRIBUTE.GET_ATTRIBUTE_BY_KEY, key], () => GetAttributeByKeyQuery(collection,key));
}





async function BeUniqueAttributeName(name) {
      const response = await ApiClient.HttpPost("collections/attribute/check-unique-name", name);
      return response.data;
}


export const AttributeApiQuery = {
      BeUniqueAttributeName,
      GetAttributeByKey
}
