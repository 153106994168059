import { EuiAccordion, EuiButton, EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiPanel, EuiSelect, EuiSpacer, EuiSwitch, EuiText, EuiTextArea } from '@elastic/eui'
import ProgreessWhitPanel from 'components/progress/progress-whit-panel';
import { QUERY_KEYS_COLLECTION, CollectionApiQuery } from 'api/collections/query-api';
import React, { useEffect, useState } from 'react'
import { CollectionApiCommand } from 'api/collections/command-api';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

const optionsCallOut = [

    { value: 'primary', text: 'Azul ' },
    { value: 'success', text: 'Verde' },
    { value: 'warning', text: 'Amarillo' },
    { value: 'danger', text: 'Rojo' },


];

function FormInitialMessaje({ collection }) {

    const [typeCallOut, settypeCallOut] = useState(optionsCallOut[0].value);
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [statusMessage, setstatusMessage] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [message, setmessage] = useState({
        color: 'primary',
        title: '',
        content: '',
        status: false,
        layout: 'inline',
        is_clousable: true
    })
    const queryClient = useQueryClient();

    const { data, isLoading } = CollectionApiQuery.GetCollectionByHash(collection);

    const UpdateMessage = async () => {

        setIsSaving(true)
        message.status = statusMessage;

        const set_message = {
            type: 'form',
            hash_id: collection,
            message: JSON.stringify(message),
        }

        const response = await CollectionApiCommand.UpdateFormInitialMessage(set_message);


        if (response.data) {
            setIsSaving(false)
            toast.success('Mensaje de bienvenida actualizado');
            queryClient.invalidateQueries([QUERY_KEYS_COLLECTION.GET_COLLECTION_BY_HASH, collection])
        }
    }



    const onChangeOptions = (option, value) => {

        switch (option) {
            case 'color':
                settypeCallOut(value);
                break;
            case 'title':
                setTitle(value);
                break;
            case 'content':
                setContent(value);
                break;
            case 'status':
                setstatusMessage(value);
            default:
                break;
        }
        const options = {
            ...message,
            [option]: value,
        }

        setmessage(options)


    }



    useEffect(() => {

        if (data?.data.on_start_form_message) {
            const message = data?.data.on_start_form_message;
            setmessage(data?.data.on_start_form_message)
            settypeCallOut(message.color)
            setTitle(message.title)
            setContent(message.content)
            setstatusMessage(message.status)

        }
    }, [data])



    if (isLoading)
        return (<ProgreessWhitPanel />)

    return (
        <>
            <EuiPanel hasBorder={true} hasShadow={false} paddingSize='s' >
                <EuiAccordion
                    id={'message-onload-form'}
                    buttonContent="Mensaje de bienvenida del formulario"
                    paddingSize='s'
                    initialIsOpen={true}
                >
                    <EuiPanel hasBorder={false} hasShadow={false} color='subdued' paddingSize='m'>
                    {title && (
                        <>
                        <EuiText grow={false} size='s'>Asi se muestra el mensaje en el formulario</EuiText>
                        <EuiSpacer size="s" />                       
                        <EuiCallOut size="s"
                            title={title}
                            // @ts-ignore
                            color={typeCallOut}
                            style={{ maxWidth: 500 }}
                            iconType="pin">
                            {content}

                        </EuiCallOut>
                        </>
                        )}
                        <EuiHorizontalRule margin='xs' />
                        <EuiFlexGroup>
                            <EuiFlexItem grow={3}>
                                <EuiFormRow
                                    label="Color del mensaje"
                                >
                                    <EuiSelect
                                        id="file-type"
                                        options={optionsCallOut}
                                        value={typeCallOut}
                                        aria-label="type of colors"
                                        compressed
                                        onChange={(e) => onChangeOptions('color', e.target.value)}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem grow={7}>
                                <EuiFormRow
                                    label="Título del mensaje"
                                >
                                    <EuiFieldText
                                        placeholder="Escribe hasta 60 caracteres para el título"
                                        value={title}
                                        onChange={(e) => onChangeOptions('title', e.target.value)}
                                        compressed
                                        maxLength={60}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>

                        </EuiFlexGroup>
                        <EuiSpacer size="m" />
                        <EuiFlexGroup>
                            <EuiFlexItem>

                                <EuiFormRow fullWidth
                                    label="Contenido del mensaje"
                                    helpText='Puedes dejar el contenido en blanco y solo usar el titulo como un mensaje corto.'
                                >
                                    <EuiTextArea
                                        placeholder="Escribe hasta 180 caracteres de tu contenido"
                                        value={content}
                                        onChange={(e) => onChangeOptions('content', e.target.value)}
                                        compressed
                                        rows={2}
                                        maxLength={180}
                                        fullWidth
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>

                        </EuiFlexGroup>
                        <EuiSpacer />
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiFormRow
                                    label="Mostrar el mensaje"
                                    display="columnCompressedSwitch"
                                    helpText='Puede activar o desactivar para notificar un mensaje de inicio o una advertencia.'
                                >
                                    <EuiSwitch
                                        showLabel={false}
                                        label="Mostrar el mensaje"
                                        checked={statusMessage}
                                        onChange={() => setstatusMessage(!statusMessage)}
                                        compressed

                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiSpacer />
                            <EuiButton fullWidth size="s" fill isLoading={isSaving} onClick={UpdateMessage} >Guardar</EuiButton>
                        </EuiFlexItem>
                    </EuiPanel>
                </EuiAccordion>
            </EuiPanel>
        </>
    )
}

export default FormInitialMessaje