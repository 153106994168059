const { nanoid, customAlphabet }  = require("nanoid");

const cleanFileNameHelper = (fileName, extension,includeName=true) => 
{
  const cleanName = fileName.replace(/[^\w\s-]/g, '').trim().replace(/\s+/g, '-');
  const hyphen = cleanName.length > 0 ? '-' : '';
  const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 11);
  const randomId = nanoid();
  const cleanFileNameWithId = includeName ? `${cleanName}${hyphen}${randomId}${extension}` : `${randomId}${extension}`
  return cleanFileNameWithId.toLowerCase();
};

export default cleanFileNameHelper


