


const RichTextRenderer = ({ value,  row,column  } ) => 
{
    return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>
    )
}

export default RichTextRenderer