import { EuiToolTip, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTextTruncate } from "@elastic/eui";
import { useAppRouteNavigator } from "hooks/useAppRouteNavigator";



function Title({ data }) {


    const navigator = useAppRouteNavigator();

    return (
        //
        <EuiFlexGroup gutterSize="xs" responsive={false}>
            <EuiFlexItem grow={false} style={{ paddingTop: 3 }} >

                <EuiIcon size='m' type={'database'} color={'primary'}  />

            </EuiFlexItem>
            <EuiFlexItem>
            <EuiToolTip content={"Tabla principal de la base de datos"} position="right">
                <div style={{ cursor: 'pointer', }} onClick={() => {
                    navigator.GotoView(data.data?.initialCollection.collection_id, data.data?.initialCollection.view_hash_id, 'database');
                }}>
                    
                        <EuiTextTruncate style={{ fontWeight: 'bold' }} text={data.data?.initialCollection?.name || '?'} />
                    
                </div>
                </EuiToolTip>
                {/* <EuiInlineEditText
                    inputAriaLabel="Edit database name"
                    defaultValue={collectionName}
                    size="s"
                    id='edit-collection-title'
                    startWithEditOpen={false}
                    onSave={useUpdateNameView}
                    isReadOnly={true}

                    
                    editModeProps={{
                        inputProps: {
                            compressed: true,
                            maxLength: 30

                        },
                        saveButtonProps: {
                            display: 'empty',
                            size: 's',
                        },
                        cancelButtonProps: {
                            display: 'empty',
                            size: 's',
                            onClick: () => { }
                        },

                    }}

                /> */}
            </EuiFlexItem>
        </EuiFlexGroup>
        // </EuiToolTip>
    );
}

export default Title;