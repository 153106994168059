import { Outlet } from "react-router";
import { EuiHorizontalRule, EuiPage, EuiPageBody, EuiPageSection, EuiPageSidebar, EuiText } from "@elastic/eui";
import SideNavWorkspace from "./side-nav/side-nav";





function WorkSpacePageContainer() {


    const Tile = (<div><dl><dt>Espacios</dt><dd className="ws-subtitle-small">Configuracion de espacio de trabajo</dd> </dl></div>);

    return (
        <EuiPage paddingSize="none">
            <EuiPageSidebar paddingSize="none" style={{ backgroundColor: "#FFF" }} hasEmbellish>
                <EuiText size="s" style={{ marginBottom: 0, paddingTop: 17, paddingLeft: 15 }} >{Tile}</EuiText>
                <EuiHorizontalRule margin="s" />
                <SideNavWorkspace />
            </EuiPageSidebar>
            <EuiPageBody paddingSize="none" panelled={true} panelProps >
                <EuiPageSection>
                    <Outlet />
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>


    )
}

export default WorkSpacePageContainer