import React from 'react'
import { EuiHorizontalRule, EuiIcon, EuiPanel, EuiSkeletonText, EuiSpacer } from '@elastic/eui'
import { useAppParameters } from 'hooks/useAppParameters'
import { ViewApiQuery } from 'api/view-api'



function ColumManagerTitle() {

    const { view_id } = useAppParameters();
    const { data, isLoading } = ViewApiQuery.GetViewByHash(view_id);

    if (isLoading)
        return (<EuiSkeletonText lines={1} size="m" isLoading={isLoading} contentAriaLabel="Administración de columnas" />)

    return (
        <>
            <EuiPanel color="subdued" borderRadius="m" paddingSize='s'>
                {/* <EmojiMart id={data ? data.data.icon : 'pinata'} /> {data.data.name} 
                 */}
                <EuiIcon type={'database'} size='m' className='eui-alignMiddle' /> {data.data.name}
                <EuiIcon type="arrowRight" size='s' /> Administración de columnas
            </EuiPanel>
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin='none' />
        </>
    )
}

export default ColumManagerTitle