import { EuiButton } from '@elastic/eui'
import { ICON_GALLERY } from 'components/icons/icons-gallery'
import React from 'react'

function DangerButton({ text, ...rest }) {
    return (
        <EuiButton  size="s" fullWidth iconType={ICON_GALLERY.TRASH} iconSide='left' color="danger" {...rest}>
            {text}
        </EuiButton>
    )
}

export default DangerButton