
import { useState } from 'react'
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'

export const RatingField = ({ fieldSchema, error, fieldController }) => {
    const [rating, setRating] = useState(0) // Initial value

    const onChange = (value) => {
        setRating(value)
        fieldController.onChange(value)
    }
    return( 
    <>
    <Rating 
    style={{ maxWidth: 160 }} 
    value={rating}
    onChange={onChange} 
    />
    </>
    )
}

export default RatingField