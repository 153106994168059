import {  EuiButtonIcon, EuiHeader, EuiIcon, EuiPanel, EuiSkeletonText, EuiToolTip } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import BreadcrumbsCollectionDetails from "packages/breadcrumb/breadcrumb-collection";



function CollectionHeaderPage() {


    const panelControls = (
        <EuiPanel paddingSize="s" color="subdued" style={{ marginRight: '20px' }} >
            <EuiToolTip content='Ubicación' position="right"  >
                <EuiButtonIcon color="text" iconType={'mapMarker'} aria-label={'ubicación '} />
            </EuiToolTip>
            <EuiIcon type="empty" />
            <EuiToolTip content='Reportes disponibles' position="right"  >
                <EuiButtonIcon color="text" iconType={'visPie'} aria-label={'reportes'} />
            </EuiToolTip>
            <EuiIcon type="empty" />
            <EuiToolTip content='Plantilla de impresión' position="right" >
                <EuiButtonIcon color="text" iconType={ICON_GALLERY.PRINTER} aria-label={'Plantilla de impresión'} />
            </EuiToolTip>
            <EuiIcon type="empty" />
            <EuiToolTip content='Comentarios' position="right"  >
                <EuiButtonIcon color="text" iconType={ICON_GALLERY.COMMENTS} aria-label={'comentarios'} />
            </EuiToolTip>
            <EuiIcon type="empty" />
            <EuiToolTip content='Historial de actividad' position="right"  >
                <EuiButtonIcon color="text" iconType={ICON_GALLERY.CLOCK} aria-label={'actividad'} />
            </EuiToolTip>
            <EuiIcon type="empty" />
        </EuiPanel>
    )

    const sections = [
        {
            items: [<BreadcrumbsCollectionDetails />],

        },
        {
            items: [panelControls]
        }

    ];





    return (
        <div>
            <EuiSkeletonText lines={1} size="m" isLoading={false} contentAriaLabel="cargando barra de navegacion">
                <EuiHeader className='remove-shadow' sections={sections} />
            </EuiSkeletonText>
        </div>
    )
}

export default CollectionHeaderPage