import { Outlet } from "react-router"
import TurboFormPage from "."



function TurboFormContainer() {
    return (
        <>
        <TurboFormPage/>
            <Outlet />
        </>
    )
}
export default TurboFormContainer