import DangerButton from "components/buttons/danger-button";
import { useState } from "react";
import ModalDeleteWorkSpace from "./modal-delete-workspace";
import { EuiPortal } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";


function ButtonDeleteWorkspace() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const openModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };




    return (
        <>
            <DangerButton text={'Borrar completamente este espacio de trabajo'} size="s" fullWidth={false} iconType={ICON_GALLERY.DELETE} onClick={openModal} />
            {isModalVisible && 
            <EuiPortal>
            <ModalDeleteWorkSpace closeModal={closeModal} />
            </EuiPortal>
            }
        </>
    )
}

export default ButtonDeleteWorkspace