import { EuiButtonIcon, EuiPanel, EuiToolTip } from "@elastic/eui"
import UploadApi from "api/upload-api";
import { useEffect, useRef, useState } from "react";
import SignaturePad from 'react-signature-pad-wrapper'


export const SignatureField = ({ fieldSchema, error, fieldController }) => {

    const [isDisableCleanbutton, setIsDisableCleanbutton] = useState(false);
    const [saveisloading, setSaveisLoading] = useState(false);
    const [clearisloading, setClearisLoading] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('Guardar');
    const [signatureUniqueId, setSignatureUniqueId] = useState(fieldSchema.uniqueFileId || '');

    const signaturePadRef = useRef(null);



    const saveSignature = async () => {

        if (signaturePadRef.current.isEmpty()) {
            setTooltipContent('')
            return;
        }
        signaturePadRef.current.off();
        setSaveisLoading(true);
        setTimeout(async () => {

            const signature = signaturePadRef.current.toDataURL();
            const response = await UploadApi.UploadSignature({ signature: signature });
            const signature_id = response.data;
            setSignatureUniqueId(signature_id);
            fieldController.onChange(signature_id);
            setSaveisLoading(false);
            setTooltipContent('Guardado');


        }, 500)

        setTimeout(() => {
            setTooltipContent('');
        }, 2000);

    };



    const clearSignature = async () => {


        if (signatureUniqueId.length === 0) {
            return;
        }

        setIsDisableCleanbutton(true);
        setClearisLoading(true);
        signaturePadRef.current.off();
        const response = await UploadApi.DeleteSignature(signatureUniqueId);
        if (response.data)
            clear();


    }

    const clear = () => {
        signaturePadRef.current.clear();
        signaturePadRef.current.on();
        setIsDisableCleanbutton(false);
        setClearisLoading(false);
        setTooltipContent('Guardar');
        setSignatureUniqueId('');
        fieldController.onChange('');
    }


    useEffect(() => {


        if (!!signaturePadRef.current && !!signaturePadRef.current.signaturePad) {

            const handleEndStroke = () => {
                saveSignature()
            }

            const current = signaturePadRef.current;
            current.signaturePad.addEventListener(
                "endStroke",
                handleEndStroke,
                false
            );

            return function cleanup() {
                current.signaturePad.removeEventListener(
                    "endStroke",
                    handleEndStroke
                );
            };
        }

    }, [signaturePadRef]);




    return (
        <EuiPanel grow hasShadow={false} hasBorder={true} paddingSize="xs" style={{ backgroundColor: '#fff' }} >
            <div style={{ float: 'right' }}>
                <EuiToolTip content={tooltipContent}  >
                    <EuiButtonIcon
                        color="success"
                        display="base"
                        isLoading={saveisloading}
                        disabled={isDisableCleanbutton}
                        onClick={saveSignature}
                        iconType="check"
                        aria-label="check"
                    />
                </EuiToolTip>
            </div>
            <EuiToolTip content="Limpiar">
                <EuiButtonIcon
                    color="primary"
                    display="base"
                    disabled={isDisableCleanbutton}
                    onClick={clearSignature}
                    iconType="eraser"
                    aria-label="check"
                    isLoading={clearisloading}
                />
            </EuiToolTip>

            <SignaturePad
                ref={signaturePadRef}
                redrawOnResize={false}
                options={{ dotSize: 2, backgroundColor: '#fff' }}
            />
        </EuiPanel>
    )
}