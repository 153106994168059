
import { EuiListGroup, EuiListGroupItem, useEuiI18n } from "@elastic/eui";
import { ICON_GALLERY } from "components/icons/icons-gallery";
import { NavLink } from "react-router-dom";


function SideNavSuscription() {

    const i18n_company_data = useEuiI18n('company.menu.data', 'Organización')
    const i18n_company_configuration = useEuiI18n('company.menu.configuration', 'Configuración ')
    const i18n_company_branding = useEuiI18n('company.menu.branding', 'Marca')


    return (
        <>
            <EuiListGroup size="m" maxWidth={600} >
                <NavLink to={`/subscription/organization`} >
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'info-company'}
                            iconType={ICON_GALLERY.INFO_CIRCLE}
                            label={i18n_company_data}
                            onClick={() => { }}
                            isActive={isActive}
                        />

                    )}
                </NavLink>

                <NavLink to={`/subscription/configuration`} >
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'configuration-company'}
                            iconType="gear"
                            onClick={() => { }}
                            label={i18n_company_configuration}
                            isActive={isActive}

                        />
                    )}
                </NavLink>

                <NavLink to={`/subscription/branding`} >
                    {({ isActive }) => (
                        <EuiListGroupItem
                            id={'marketing-company'}
                            iconType={'image'}
                            onClick={() => { }}
                            label={i18n_company_branding}
                        />
                    )}
                </NavLink>
                <EuiListGroupItem
                    id={'subscription-users'}
                    onClick={() => { }}
                    iconType={ICON_GALLERY.USERS}
                    label="usuarios"

                />
                <EuiListGroupItem
                    id={'sucripton-security'}
                    onClick={() => { }}
                    iconType={'key'}
                    label="Seguridad"

                />

                <EuiListGroupItem
                    id={'subscription-invoices'}
                    iconType={ICON_GALLERY.MONEY}
                    onClick={() => { }}
                    label="Plan y facturación"

                />
                 <EuiListGroupItem
                    id={'subscription-consumption'}
                    iconType={ICON_GALLERY.MONEY}
                    onClick={() => { }}
                    label="Consumo"

                />

            </EuiListGroup></>
    )
}

export default SideNavSuscription