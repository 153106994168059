
import FormEditor from 'packages/form-desinger'
import React from 'react'

function AppPageForms() {
  return (

    <FormEditor />

  )
}

export default AppPageForms
