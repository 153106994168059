import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import { AuthManager } from 'api/auth-api';
import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiCallOut,
  EuiFieldPassword,
  EuiTextColor,
  EuiI18n,
  useEuiI18n,
  EuiPanel
} from '@elastic/eui';



const AppLogin = () => {

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, setisLoading] = useState(false)
  const [isAuthenticate, setisAuthenticate] = useState(false)




  const onSubmit = async (data) => {

    setisAuthenticate(false);
    setisLoading(true);
    data.method = 'web';

    const resultado = await AuthManager.Login(data);

    if (resultado) {


      setisLoading(false);
      navigate("/home")

    }
    else {
      setisAuthenticate(true);
      setisLoading(false);
    }

  }

  const login_callout_title = (<EuiI18n token="login.callout.invalid.title" default="usuario o contraseña incorrectos" />)
  const username_label = (<EuiI18n token="login.username" default="Dirección de email" />)
  const username_helptext = (<EuiI18n token="login.username.help" default="Escribe tu dirección de email" />)
  const username_placeholder = (useEuiI18n('login.username.placeholder', 'tucuenta@dominio'))
  const username_error = (<EuiI18n token="login.username.error" default="Escribe un email válido" />)
  const password_label = (<EuiI18n token="login.password" default="Contraseña" />)
  const password_helptext = (<EuiI18n token="login.password.help" default="Escribe tu contraseña" />)
  const password_error = (<EuiI18n token="login.password.error" default="Escribe una contraseña válida" />)
  const mensajerror = (<EuiCallOut size="s" title={login_callout_title} color="danger" iconType=""> {isAuthenticate && <EuiTextColor color="danger"><EuiI18n token="login.callout.error.text" default=" Verifica el usuario y la contraseña." /></EuiTextColor>}</EuiCallOut>);




  return (
    <>
      <EuiPanel hasShadow={false}>

        <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} invalidCallout="none" autoComplete="off" noValidate>

          {(errors.email || errors.password || isAuthenticate) && mensajerror}


          <EuiFormRow label={username_label} helpText={username_helptext} isInvalid={errors?.email ? true : false} error={[username_error]}>
            <Controller
              defaultValue='gerencia@arcoirisapp.co'
              name="email"
              control={control}
              rules={{
                required: true,
                maxLength: 80,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              }}
              render={({ field }) => <EuiFieldText
                name="email"
                autoComplete="off"
                isInvalid={errors?.email ? true : false}
                isLoading={isLoading}
                placeholder={username_placeholder}
                icon="user"
                compressed
                value='gerencia@arcoirisapp.co'
                aria-label='email'
              />}
            />
          </EuiFormRow>
          <EuiFormRow label={password_label} helpText={password_helptext} isInvalid={errors?.password ? true : false} error={[password_error]}>
            <Controller
              defaultValue='tYzUQ'
              name="password"
              control={control}
              rules={{ required: true, maxLength: 15 }}
              render={({ field }) => <EuiFieldPassword
                name="password"
                isInvalid={errors?.password ? true : false}
                placeholder=""
                type="dual"
                aria-label='password'
                compressed
              />}
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiFormRow>
            <EuiButton fullWidth={true} fill isLoading={isLoading} size="s" type="submit" >
              <EuiI18n token="login.submit" default="Iniciar sesion" />
            </EuiButton>
          </EuiFormRow>
        </EuiForm>
      </EuiPanel>
    </>
  )
}

export default AppLogin