import { useParams } from "react-router";

export const useAppParameters = () => {

    let parameters = useParams();
    const collection = parameters.collection;
    const view_type = parameters.view_type;
    const view_id = parameters.view;
    const app = parameters.app;
    const record = parameters.record;
    const workspace = parameters.workspace;
    const view_name = parameters.name_view || null;

    return {
        collection,
        view_type,
        view_id,
        view_name,
        app,
        record,
        workspace
    }

}