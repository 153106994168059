import { useState,useEffect } from 'react'
var status=false;

export const useProgressiveImage = src => {  
    const [sourceLoaded, setSourceLoaded] = useState(null)
  
    useEffect(() => {
      const img = new Image()
      img.src = src
      img.onload = () => setSourceLoaded(src)
    }, [src])
  
    return sourceLoaded 
  }

  

  export const useSubDomainImage = src => {  
    const [sourceLoaded, setSourceLoaded] = useState(null)
  
    useEffect(() => {
      const img = new Image()
      img.src = src
      img.onload = () => setSourceLoaded(src)
    }, [src])
  
    return sourceLoaded 
  }



  export const  CheckImgDimension = async (fileUpload,widthLimit, heightLimit)=>
  {
            
            var reader = new FileReader();
            reader.readAsDataURL(fileUpload);
            reader.onload = function (e) 
            {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () 
            {
                    var height = this.height;
                    var width = this.width;
                    if (height > heightLimit && width > widthLimit) 
                        status =  false;
                    else
                        status = true;                        
            };
                       
            }
            return status;
            
  }