import React from 'react'
import { EuiButtonEmpty } from '@elastic/eui';

function AddTitleForm({ icon, title }) {
    return (
            <div><EuiButtonEmpty 
            iconType={icon}
            aria-label="card"
            color="text"
            style={{ marginRight: 3 }}>
            {title}
          </EuiButtonEmpty>         
               
            </div>

    )
}

export default AddTitleForm
