import React, { Fragment, useState } from 'react'
import {
  EuiButtonIcon, EuiPopover,
  EuiContextMenu, EuiIcon, EuiI18n, EuiToolTip, 

} from '@elastic/eui';
import { nanoid } from 'nanoid'
import AddTitleForm from './Title';
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import CreateTurboFormView from 'pages/views/turbo-form/create-turbo-form';


function AppNavButtomAddComponent({ apphash, showAddButton }) {


  const navigator = useAppRouteNavigator()
  const id = nanoid();
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);

  const i18n_title_card = (<EuiI18n token="view.add.title.card" default="Tarjeta" />)
  const i18n_title_table = (<EuiI18n token="view.add.title.table" default="Tabla" />)
  const i18n_title_gantt = (<EuiI18n token="view.add.title.gantt" default="Gantt" />)
  const i18n_title_dashboard = (<EuiI18n token="view.add.title.dashboard" default="Tablero de control" />)
  const i18n_title_report = (<EuiI18n token="view.add.title.report" default="Reportes" />)
  const i18n_title_page = (<EuiI18n token="view.add.title.page" default="Pagina" />)
  const i18n_title_kanban = (<EuiI18n token="view.add.title.kanban" default="Kanban" />)
  const i18n_title_block = (<EuiI18n token="view.add.title.block" default="Panel de control" />)

  const TitlePanel = (<Fragment>Agrega una componente <EuiIcon type="questionInCircle" style={{ marginLeft: 5 }} size="m" /> </Fragment>);

  // const itemsViews = [


  //   {
  //     name: i18n_title_card,
  //     icon: 'image',
  //     toolTipContent: 'Vista de Tarjetas',
  //     toolTipPosition: 'right',
  //     panel: 10,
  //     className: 'remove-focus',

  //   },
  //   {
  //     name: i18n_title_gantt,
  //     icon: 'visTagCloud',
  //     toolTipContent: 'Gestionar archivos',
  //     toolTipPosition: 'right',
  //     panel: 3,

  //   },
  //   {
  //     name: i18n_title_dashboard,
  //     icon: 'visLine',
  //     toolTipContent: 'Gestionar archivos',
  //     toolTipPosition: 'right',
  //     panel: 4,

  //   },
  //   {
  //     name: i18n_title_report,
  //     icon: 'documents',
  //     toolTipContent: 'Gestionar archivos',
  //     toolTipPosition: 'right',
  //     panel: 5,

  //   },
  //   {
  //     name: i18n_title_page,
  //     icon: 'documentEdit',
  //     toolTipContent: 'Gestionar archivos',
  //     toolTipPosition: 'right',
  //     panel: 6,


  //   },
  //   {
  //     name: i18n_title_kanban,
  //     icon: <EuiIcon type={ICON_GALLERY.KANBAN} />,
  //     toolTipContent: 'Gestionar archivos',
  //     toolTipPosition: 'right',
  //     panel: 7,


  //   },
  //   {
  //     name: i18n_title_block,
  //     icon: 'visGauge',
  //     toolTipContent: 'Crear bloques de interactivos',
  //     toolTipPosition: 'right',
  //     panel: 8,


  //   },
  // ];

  const itemsOptionApp = [
    {
      name: 'Registro rapido',
      icon: 'launch',
      iconProps: { size: 'l' },
      toolTipContent: 'Agregar formulario de registro rapido',
      toolTipPosition: 'right',
      panel: 1,
      className: 'remove-focus',
    },
    {
      name: i18n_title_card,
      icon: 'image',
      toolTipContent: 'Vista de Tarjetas',
      toolTipPosition: 'right',
      panel: 10,
      className: 'remove-focus',
    },

    {
      name: i18n_title_gantt,
      icon: 'visTagCloud',
      toolTipContent: 'Gestionar archivos',
      toolTipPosition: 'right',
      panel: 3,

    },
    {
      name: i18n_title_dashboard,
      icon: 'stats',
      toolTipContent: 'Gestionar archivos',
      toolTipPosition: 'right',
      panel: 4,

    },
    {
      name: i18n_title_report,
      icon: 'documents',
      toolTipContent: 'Gestionar archivos',
      toolTipPosition: 'right',
      panel: 5,

    },
    {
      name: i18n_title_page,
      icon: 'article',
      toolTipContent: 'Gestionar archivos',
      toolTipPosition: 'right',
      panel: 6,


    },
    {
      name: i18n_title_kanban,
      icon: <EuiIcon type={ICON_GALLERY.KANBAN} />,
      toolTipContent: 'Gestionar archivos',
      toolTipPosition: 'right',
      panel: 7,


    },
    {
      name: i18n_title_block,
      icon: 'visGauge',
      toolTipContent: 'Crear bloques de interactivos',
      toolTipPosition: 'right',
      panel: 8,


    },

    {
      name: 'Vista de personalizada',
      icon: 'tableDensityNormal',

    },
    {
      name: 'Carpeta',
      icon: 'folderClosed',

    },

    {
      name: 'Proyectos',
      icon: 'users',

    },
    {
      name: 'Plugin',
      icon: 'package',

    },



    {
      isSeparator: true,
      key: 'sep',
    },
    {
      name: 'Configuración de la app',      
      icon: 'gear',
      onClick: () => {
        closeMenu()
        navigator.GotoSettings(apphash)
      }

    },
  ];

  const typesView = [
    {
      id: 0,
      title: TitlePanel,
      hasFocus: false,
      items: itemsOptionApp
    },
    {
      id: 1,
      title: 'Agregar registro rapido',
      content: <CreateTurboFormView />,


    },

    {
      id: 2,
      title: <AddTitleForm icon="tableDensityNormal" title={i18n_title_table} />,
      content: <></>,
    },
    {
      id: 3,
      title: <AddTitleForm icon="visTagCloud" title={i18n_title_gantt} />,
      content: <></>,
    },
    {
      id: 4,
      title: <AddTitleForm icon="visLine" title={i18n_title_dashboard} />,
      content: <></>,
    },
    {
      id: 5,
      title: <AddTitleForm icon="documents" title={i18n_title_report} />,
      content: <></>,
    },
    {
      id: 6,
      title: <AddTitleForm icon="documentEdit" title={i18n_title_page} />,
      content: <></>,
    },
    {
      id: 7,
      title: <AddTitleForm icon="keyboardShortcut" title={i18n_title_kanban} />,
      content: <></>,
    },
    {
      id: 8,
      title: <AddTitleForm icon="package" title={i18n_title_block} />,
      content: <></>,
    },

  ];

  const optionGroup =
    (<>
      {/* <EuiToolTip content="Configurar la app" position="top"  >
        <EuiButtonIcon
          iconType="gear"
          onClick={() =>  navigator.GotoSettings(apphash)}
          aria-label="more"
          color='text'
          id={id}
          display="fill"
          css={{ opacity: showAddButton ? 1 : 0 ,background:background_color_configuration_button}}
          style={{ marginRight: 12  }}
        />
      </EuiToolTip> */}

      <EuiToolTip content="Opciones de la app" position="right" >
        <EuiButtonIcon
          iconType="boxesVertical"
          onClick={() => setIsOpen(true)}
          aria-label="more"
          color='text'
          id={id}
          css={{ opacity: showAddButton ? 1 : 0 }}
          style={{ marginRight: 10 }}
        />
      </EuiToolTip>
    </>


    );


  return (
    <EuiPopover id={id} button={optionGroup} closePopover={closeMenu} isOpen={isOpen} anchorPosition="rightUp" panelPaddingSize="xs" >
      <EuiContextMenu initialPanelId={0}
        // @ts-ignore
        panels={typesView} size="m" />

    </EuiPopover>
  )
}





export default AppNavButtomAddComponent
