import { EuiButtonGroup, EuiPanel, EuiSpacer, } from "@elastic/eui"
import { CollectionApiQuery } from "api/collections/query-api";
import { useAppParameters } from "hooks/useAppParameters";
import { RowSection } from "packages/fields/row-section"
import { useState } from "react";



const FormLayoutConfiguration = ({ displayLayout , collection }) => {
    const emtpyLayout = {
        columns: '1',
        gutterSize: 's',
        displayForm: displayLayout || 'columnCompressed',
    }

    const [layout, setLayout] = useState(emtpyLayout);
    //const { collection } = useAppParameters();
    //const { data, isLoading } = CollectionApiQuery.GetCollectionLayout(collection);

    console.log('layout', layout);
    
    const handleChange = (name, value) => 
    {
        if(name === 'displayForm' && value === 'columnCompressed')
        {
            setLayout((prevLayout) => ({
                ...prevLayout,
                ['columns']: '1'
            }));
        }

        setLayout((prevLayout) => ({
            ...prevLayout,
            [name]: value
        }));

        

    };



    const numberColumns = [
        {
            id: '1',
            label: '1',
        },
        {
            id: '2',
            label: '2 ',
        },
        {
            id: '3',
            label: ' 3 ',
        },
        {
            id: '4',
            label: ' 4 ',
        },
    ];


    const displayForm = [
        {
            id: 'columnCompressed',
            label: 'Columnas',
        },
        {
            id: 'rowCompressed',
            label: 'Filas',
        },
    ];

    const gutterSize = [
        {
            id: 's',
            label: 'Pequeño',
        },
        {
            id: 'm',
            label: 'Mediano',
        },
        {
            id: 'xl',
            label: 'Grande',
        },

    ];

 



    return (

        <>
        <EuiPanel hasShadow={false} hasBorder={false} paddingSize="s">
            <RowSection
                label='Tipo de Diseño'
                error={null}
                errorText={''}
                display={'column'}
                extraHelpText={null}
                helpText={''}>
                <>
                    <EuiButtonGroup
                        legend=""
                        options={displayForm}
                        color="primary"
                        idSelected={layout.displayForm}
                        onChange={(value) => handleChange('displayForm',value)}
                    />
                </>
            </RowSection>
            {layout.displayForm === 'columnCompressed' && (
                <><RowSection
                    label={'Numero de columnas'}
                    display={'column'}
                    extraHelpText={null}
                    error={null}
                    errorText={'El campo es requerido'}
                    helpText={''} >
                    <EuiButtonGroup
                        isFullWidth
                        legend=""
                        options={numberColumns}
                        color="primary"
                        idSelected={layout.columns}
                        onChange={(value) => handleChange('columns',value)}

                    />
                </RowSection>
                    <EuiSpacer size="s" />
                </>)}

            <RowSection
                label='Espaciado entre preguntas'
                error={null}
                display={'column'}
                errorText={''}
                extraHelpText={null}
                helpText={''}>
                <EuiButtonGroup
                    legend=""
                    options={gutterSize}
                    color="primary"
                    idSelected={layout.gutterSize}
                    onChange={(value) => handleChange('gutterSize',value)}
                />
            </RowSection>

        </EuiPanel>

        </>



    )
}

export default FormLayoutConfiguration;