import axios from 'axios';
import {
  GLOBAL_KEY_BASE_URL
} from "../core/Constants";
import { TokenWithBearer } from 'core/Token';
import useConnectionStore from 'store/use-conection-store';

const client = axios.create({
  baseURL: GLOBAL_KEY_BASE_URL,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});

function isNetworkError(err) {
  return !!err.isAxiosError && !err.response;
}


client.interceptors.response.use(response => response,
  error => {

    if (!isNetworkError(error)) {
      const status = error.response ? error.response.status : null;

      if (status === 401) {

        console.log("Unauthorized access");
      } else if (status === 404) {

        console.log("Route not found");
      } else {

        console.error("An error occurred:", error);
      }

      return Promise.reject(error);
    } else {

      useConnectionStore.setState({ isConnected: false });
      return Promise.reject(error);
    }

  });


const HttpPost = async (url, parameters) => {

  const _token = 'Bearer ' + localStorage.getItem("user-token")
  client.defaults.headers.common['Authorization'] = _token;


  try {

    const response = client.post(url, parameters).then(x => new Promise(resolve => setTimeout(() => resolve(x), 800)));
    return response;

  } catch (error) {
    console.log("HttpPost-error", error);

  }

};

const HttpGet = async (url) => {

  const _token = 'Bearer ' + localStorage.getItem("user-token")
  client.defaults.headers.common['Authorization'] = _token;


  try {
    const response = await client.get(url);
    return response;

  } catch (error) {

    console.log("HttpGet-error", error);

  }


}


const HttpUpload = async (url, file, parameters) => {

  const _token = 'Bearer ' + localStorage.getItem("user-token")
  client.defaults.headers.common['Authorization'] = _token;

  const formData = new FormData();
  formData.append('file', file[0]);
  formData.append('parameters', JSON.stringify(parameters));
  const response = await client.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response
}



const axiosClient = async () => {

  const _token = TokenWithBearer;
  client.defaults.headers.common['Authorization'] = _token;
  return client;

}



const ApiClient = {
  HttpPost,
  HttpGet,
  HttpUpload,
  axiosClient
}

export default ApiClient;