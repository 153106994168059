import { EuiCallOut, EuiConfirmModal, EuiFieldText, EuiFormRow, EuiSpacer } from "@elastic/eui";
import { useInvalidateQueryByKey } from "api/invalidate-querys/invalidate-by-key";
import WorkspaceApi, { QUERY_KEYS_WORKSPACE } from "api/workspace-api";
import { useAppParameters } from "hooks/useAppParameters";
import { useAppRouteNavigator } from "hooks/useAppRouteNavigator";
import { useState } from "react";
import { useGlobalStore } from "store/global-store";



function ModalDeleteWorkSpace({ closeModal }) {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { workspace } = useAppParameters();
    const navigate = useAppRouteNavigator();
    const setCurrentWorkspace = useGlobalStore(state => state.setWorkSpace);
    const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_WORKSPACE.GET_LIST_WORKSPACE]);

    const onCopyHandler = (event) => {
        event.preventDefault();
    }

    // Evitar que se corte
    const onCutHandler = (event) => {
        event.preventDefault();
    }

    // Evitar que se pegue
    const onPasteHandler = (event) => {
        event.preventDefault();
    }

    const onChange = e => setValue(e.target.value);

    const onConfirm = async () => {

        setIsLoading(true)
        const data = { hash_id: workspace };

        const response = await WorkspaceApi.DeleteWorkspace(data);

        if (response.status) 
        {
            setCurrentWorkspace(0);
            InvalidateQuery();
            closeModal();
            setTimeout(() => {
                navigate.GotoHome();    
            },500)
            
        }

    }

    return (
        <EuiConfirmModal
            title="Borrar espacio de trabajo"
            onCancel={() => {
                if (!isLoading) 
                {
                    closeModal()
                }


            }}
            onConfirm={() => {
                
                onConfirm()

            }}
            confirmButtonText={isLoading ? 'Borrando...' : 'Borrar definitivamente'}
            cancelButtonText="Cancelar"
            buttonColor="danger"
            initialFocus="[name=delete]"
            confirmButtonDisabled={value.toLowerCase() !== 'borrar definitivamente'}
            isLoading={isLoading}
        >
            <EuiCallOut title="Procede con precaución!" color="danger" iconType="help" >
                <p>Este proceso borra toda la información relacionada con el espacio de trabajo.<br /> Una vez borrada, no podrá recuperarla.</p>
            </EuiCallOut>
            <EuiSpacer />
            <EuiFormRow label="Escribe 'borrar definitivamente' para confirmar" fullWidth >
                <>

                    <EuiFieldText
                        compressed
                        name="delete"
                        fullWidth
                        isLoading={isLoading}
                        value={value}
                        maxLength={25}
                        onChange={onChange}
                        onCopy={onCopyHandler}
                        onCut={onCutHandler}
                        onPaste={onPasteHandler}
                    />
                </>
            </EuiFormRow>
        </EuiConfirmModal>
    )
}

export default ModalDeleteWorkSpace