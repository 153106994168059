import React, { useEffect, useState } from 'react';
import {
    EuiPanel,
    EuiSkeletonText,
} from '@elastic/eui';
import DependentList from './component/dependent-list';
import FieldRowCascade from './component/field-row-cascade';
import QuickMessageAccordion from 'help/quick-message-accordion';
import { useAppParameters } from 'hooks/useAppParameters';

function FieldCascadeOptions({ OnUpdateOptions, OnUpdateRules, data }) {

    const [totalChanges, setTotalChanges] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [combinedList, setCombinedList] = useState([]);
    const [dependentList, setDependentListOptions] = useState([])
    const [dependentField, setDependentField] = useState(data?.rules[0]?.field || '');
    const { collection } = useAppParameters();

    const removeItemOption = (index) => {

        var array = [...combinedList];
        const removed = array.filter(el => el.id !== index);
        setCombinedList(removed)
        OnUpdateOptions(removed)
        SetRules()
    }

    

    const PrepareList = (list) => {
        const updatedList = combinedList.map((prevElement) => {
            const matchingElement = list.find((newElement) => newElement.id === prevElement.id);

            if (matchingElement) {
                return { ...prevElement, ...matchingElement };
            }

            return prevElement;
        });

        list.forEach((newElement) => {
            const exists = updatedList.some((prevElement) => prevElement.id === newElement.id);

            if (!exists) {
                updatedList.push(newElement);
            }
        });

        return updatedList;
    }


    const GetOrderList=(list)=>{
        
        const updatedList = [...list];

        // Agregar elementos de combinedList que no existen en list
        combinedList.forEach((element) => {
            if (!list.some((listElement) => listElement.id === element.id) && !updatedList.some((updatedElement) => updatedElement.id === element.id)) {
                updatedList.push(element);
            }
        });
    
        return updatedList;
        
    }
    const onChangeOptions = (nuevaLista) => {

        const updateList = PrepareList(nuevaLista)
        setCombinedList(updateList)
        OnUpdateOptions(updateList)
        SetRules()

    };

    const onReorder = (orderedList) => {
        const updateList = GetOrderList(orderedList)      
        setCombinedList(updateList)
        OnUpdateOptions(updateList)
        SetRules()
    }


    const getDependentList = (field) => {

        const options = field ? JSON.parse(field.options).filter(option => option.value.trim() !== "") : [];

        setDependentField(field.key)
        setDependentListOptions(options)
        //setCombinedList([]);/// ACA SE DEBE PROBAR CON LOS DATOS NUEVOS 
        setTotalChanges(totalChanges + 1)
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false)
        }, 800);

    }

    function prepareRules(originalArray) {
        const result = {};

        originalArray.forEach(element => {
            const { id, dependent } = element;

            if (!result[dependent]) {
                result[dependent] = ['', id];
            } else {
                result[dependent].push(id);
            }
        });


        const finalResult = Object.keys(result).map(dependent => ({
            [dependent]: result[dependent]
        }));

        return finalResult;
    }

    const SetRules = () => {
        const rules = [{
            type: 'cascade',
            field: dependentField,
            conditions: [],
            form: collection,
            values: '',
        }];

        rules[0].values = JSON.stringify(prepareRules(combinedList));
        OnUpdateRules(rules)
    }


    useEffect(() => {

        SetRules();

        if (data?.options) {

            setCombinedList(JSON.parse(data?.options))
            setDependentField(data?.rules[0]?.field || '')


        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dependentField]);

    //combinedList, GetOptions, OnUpdateRules, dependentField, collection
    return (
        <>
            <EuiPanel hasShadow={false} >
                <DependentList
                    OnChangeDependentList={getDependentList}
                    collection={collection}
                    selected={data?.rules[0]?.field || ''}


                />
            </EuiPanel>

            {dependentList.length > 0 && (
                <QuickMessageAccordion
                    title={"Respuestas de la lista dependiente seleccionada"}
                    description={'Podrá agregar opciones a cada respuesta de la lista dependiente'}
                    paddingSize='s'
                    link_url={null}
                    link_text={null}
                    style={{ width: '400px' }}

                />
            )}
            <EuiPanel hasShadow={false} hasBorder={false} paddingSize='xs' color={isLoading ? 'plain' : 'transparent'}  >
                <EuiSkeletonText lines={4} size="m" isLoading={isLoading} contentAriaLabel="cargando opciones">

                    {dependentList.map((item, index) => {
                        return (
                            <FieldRowCascade
                                key={'response-row-' + index}
                                GetOptions={onChangeOptions}
                                CurrentItem={item}
                                onRemove={removeItemOption}
                                onReorder={onReorder}
                                resetOptions={totalChanges}
                                data={data}
                            />
                        )

                    })}

                </EuiSkeletonText>
            </EuiPanel>
        </>
    )
}

export default FieldCascadeOptions

