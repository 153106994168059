import React from 'react';
import { EuiText, EuiLink } from '@elastic/eui';

const LabelAppend = ({ schema_field }) => {

  return (
    <>
      {schema_field.props?.enable_help_link && (
        <EuiText size="xs">
          <EuiLink target="_blank" href={'https://' + schema_field.props.help_link_url}>
            {schema_field.props.help_link_text}
          </EuiLink>
        </EuiText>
      )}
    </>
  );
};

export default LabelAppend;