export const PATTERN_NOT_SPECIAL_CHARACTERS =/[!@#$%^&*()_+\-=[\]{};¨'´:"\\|,<>/]+/;
export const PATTERN_EMAIL =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
export const PATTERN ={
    EMAIL: PATTERN_EMAIL,
    NOT_SPECIAL_CHARACTERS: PATTERN_NOT_SPECIAL_CHARACTERS,
    ONLY_NUMBER: /^[0-9]+$/,
    ONLY_LETTER: /^[a-zA-Z]+$/,
    ONLY_LETTER_AND_NUMBER: /^[a-zA-Z0-9]+$/,
    ALL_UPPER_CASE: /^[A-Z]+$/,
    ALL_LOWER_CASE: /^[a-z]+$/,
}


export const PATTERN_VALIDATOR = {
    EMAIL: (value) => PATTERN.EMAIL.test(value),
    NOT_SPECIAL_CHARACTERS: (value) => PATTERN.NOT_SPECIAL_CHARACTERS.test(value),
    ONLY_NUMBER: (value) => PATTERN.ONLY_NUMBER.test(value),
    ONLY_LETTER: (value) => PATTERN.ONLY_LETTER.test(value),
    ONLY_LETTER_AND_NUMBER: (value) => PATTERN.ONLY_LETTER_AND_NUMBER.test(value),
    ALL_UPPER_CASE: (value) => PATTERN.ALL_UPPER_CASE.test(value),
    ALL_LOWER_CASE: (value) => PATTERN.ALL_LOWER_CASE.test(value),
}

