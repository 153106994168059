import { EuiCallOut, EuiComboBox, EuiFormRow } from "@elastic/eui";
import { CollectionApiQuery } from "api/collections/query-api";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

function DependentList({ OnChangeDependentList, collection, selected }) {
   

    const [list, setList] = useState([]);
    const [value, setValue] = useState([]);
    const [helpMessage, setHelpMessage] = useState("Solo se muestran las listas desplegables de seleccion única");

    const id = 'list-' + nanoid();


    const { data } = CollectionApiQuery.GetAttributesByCollection(collection);

    const onChange = (e) => {
        if (e.length > 0) {

            setValue(e)
            const key = e[0].value;
            const optionField = data.data.find(item => item.key === key);
            OnChangeDependentList(optionField)
        }
    }

    useEffect(() => {

        if (data) {

            const filtered_list = data.data.filter(item => (item.data_type === 'select' || item.data_type === 'cascade') && item.props.multiple_selection === false);

            setList(filtered_list.map(({ key, name }) => ({ value: key, label: name })));

            if (selected.length > 0) {


                const item = filtered_list.filter(item => item.key === selected).map(({ key, name }) => ({ value: key, label: name }))
                setValue(item);
                const optionField = data.data.find(item => item.key === selected);
                OnChangeDependentList(optionField)
                setHelpMessage("La lista dependiente no se puede editar");

            }

        }

    }, [data])



    return (
        <EuiFormRow
            label="Seleccione la lista dependiente"
            helpText={helpMessage}
        ><>
                {list.length === 0 ? (
                    <EuiCallOut title="No hay listas de seleccion unica creadas" size="s" color="warning" iconType="warning" />
                ) : (
                    <EuiComboBox
                        id={id}
                        aria-label="dependent list"
                        placeholder="Seleccione una opción"
                        singleSelection={{ asPlainText: true }}
                        options={list}
                        selectedOptions={value}
                        isDisabled={selected.length > 0}
                        onChange={onChange}
                        compressed
                    />
                )}
            </>
        </EuiFormRow>
    )
}

export default DependentList;