import { useQuery } from "@tanstack/react-query";
import ApiClient from "./api.client";


export const QUERY_KEYS_IMAGE = {
    GET_AVATAR_BY_HASH: "getavatarbyhash",
}


async function GetAvatar(hash_id) {
    const response = await ApiClient.HttpGet(`images/thumbnail/${hash_id}`);
    return response.data;
}


function GetAvatarQuery(hash_id,is_enabled) {
    return useQuery([QUERY_KEYS_IMAGE.GET_AVATAR_BY_HASH, hash_id], () => GetAvatar(hash_id),
    {
        enabled: is_enabled
    });
}


export const ImagesApiQuery   = {
    GetAvatarQuery
}