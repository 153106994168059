import { EuiBeacon, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPopover, EuiPopoverTitle, EuiToolTip } from "@elastic/eui";
import { useAppParameters } from "hooks/useAppParameters";
import FormFieldCreator from "packages/field-creator";
import { useCallback, useState } from "react";


function AddQuickColumn({showBeacon = false}) {
  const [openQuickAdd, setOpenQuickAdd] = useState(false);
  const closePopover = useCallback(() => setOpenQuickAdd(false), []);
  const { collection } = useAppParameters();

  const buttonOpenQuickAdd = (
    <>
      <EuiToolTip position="left" content="Crear una columna" >
        <>
          {showBeacon && <EuiBeacon className="position-fixed" />} 
          <EuiButtonIcon onClick={() => setOpenQuickAdd(true)} color="text" iconType={'plusInCircleFilled'} iconSize="m" aria-label={'add column'} />
          
        </>
      </EuiToolTip>

    </>);


  return (
    <EuiFlexGroup style={{ paddingLeft: 21 }} gutterSize="none">
      <EuiFlexItem grow={false}>
        <EuiPopover
          hasDragDrop
          hasArrow={false}
          panelPaddingSize="s"
          anchorPosition="rightUp"
          button={buttonOpenQuickAdd}
          isOpen={openQuickAdd}
          closePopover={closePopover}>
          {/* <EuiPopoverTitle style={{ textAlign: 'center' }} > Crear una columna</EuiPopoverTitle> */}
          <FormFieldCreator collection={collection} action={'create'} data={null} compressed={true} />
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>)
}

export default AddQuickColumn;