import { EuiI18n } from "@elastic/eui"



const app_name = (<EuiI18n token="global.name" default="Nombre" />)
const app_helptext = (<EuiI18n token="space.create.name.helptext" default="Escriba el nombre que identifique a su app." />)
const app_description = (<EuiI18n token="space.description" default="Descripción corta de la app" />)
const app_emoji = (<EuiI18n token="'space.create.emoji'" default="Selecione un emoji para su app" />)

const save_modal = (<EuiI18n token="global.create" default="Crear" />)
const label_required = (<EuiI18n token="global.required" default="Escribe un nombre que identifique su app." />)
const general_error = (<EuiI18n token="global.server.error" default="No fue posible terminar la tarea, intentelo nuevamente." />)
const general_title_error = (<EuiI18n token="global.server.title.error" default="Ocurrió algo inesperado" />)
const app_name_duplicate = (<EuiI18n token="global.duplicate" default="Ya existe ese nombre, intenta nuevamente." />)

const modal_succes = (<EuiI18n token="space.create.succes" default="Ahora puedes agrega columnas y comenzar a registrar información." />)


export const i18n = { app_emoji, app_name, save_modal, label_required, general_error, general_title_error, app_name_duplicate, app_helptext, modal_succes, app_description }