import { EuiButtonIcon, EuiFieldText, EuiLink, EuiPanel, EuiToolTip } from '@elastic/eui';
import { StringHelper } from 'core/String';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

export const UrlField = ({ fieldSchema, error, fieldController }) => {

    const [editMode, setEditMode] = useState(false);
    const [showAddButton, setShowAddButton] = useState(true);
    const [textLink, setTextLink] = useState(fieldController.value || '');
    const idIcon = nanoid();
    const isUrl = word => {
        // eslint-disable-next-line
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
        return word.match(urlPattern)
    }


    const onChange = event => {

        fieldController.onChange(event.target.value)
        setTextLink(event.target.value)

    };

    const onSave = () => {
        if (isUrl(textLink))
            setEditMode(false)
    }


    useEffect(() => {
        if (textLink.length <= 0) {
            setEditMode(true)
        }

    }, [textLink.length])

    return (
        <>
            {editMode ? (
                <EuiFieldText
                    fullWidth
                    name={fieldSchema.id + ''}
                    id={'url-field-' + fieldSchema.id}
                    value={fieldController.value || ''}
                    isInvalid={!!error}
                    title='Solo se permiten https'
                    onChange={onChange}
                    compressed
                    prepend={
                        <EuiToolTip content="Guardar" position="top" >
                            <EuiButtonIcon
                                iconType="save"
                                onClick={onSave}
                                aria-label="more"
                                color='text'
                                id={'54838'}


                            />
                        </EuiToolTip>
                    }

                />) : (
                <>
                    <EuiPanel hasBorder={false} hasShadow={false} paddingSize="s" onMouseOver={() => setShowAddButton(true)} onMouseOut={() => setShowAddButton(false)}>
                        <EuiLink external href={'https://' + textLink} target='_blank' >{StringHelper.TruncateText(textLink, 50)}</EuiLink>
                        <EuiToolTip content="Editar" position="top" >
                            <EuiButtonIcon
                                iconType="pencil"
                                onClick={() => setEditMode(true)}
                                aria-label="edit url"
                                color='text'
                                id={idIcon}
                                iconSize="s"
                                css={{ opacity: showAddButton ? 1 : 0, marginLeft: '5px' }}

                            />
                        </EuiToolTip>
                    </EuiPanel>
                </>
            )
            }


        </>
    );
};
