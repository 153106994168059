import { createWithEqualityFn } from 'zustand/traditional';
import produce from 'immer';
import ApiClient from 'api/api.client';


const useSchemasFormStore = createWithEqualityFn((set, get) => ({
  pristine_schemas: {},
  schemas: {},
  data: {},
  refreshSchema: async (schemaId) => {
    const response = await ApiClient.HttpGet(`forms/${schemaId}`);
    const schema = response.data.data;

    set(
      produce((state) => {
        // @ts-ignore     
        state.schemas[schemaId] = schema;
        // @ts-ignore
        state.pristine_schemas[schemaId] = schema;
      })
    );
  },
  clearShema: (schemaId) => {
    set(
      produce((state) => {
        // @ts-ignore
        state.schemas[schemaId] = null;
      })
    );
  },
  fetch: async (schemaId) => {
    const response = await ApiClient.HttpGet(`forms/${schemaId}`);
    const schema = response.data.data;
   
    set(
      produce((state) => {
        // @ts-ignore
        state.pristine_schemas[schemaId] = schema;
        // @ts-ignore
        state.schemas[schemaId] = schema;
      })

    );

    const formsToLoad = [];
    for (const field of schema) {
      if (!field.rules) continue;
      const thisForms = field.rules.filter((r) => !!r.form).map((r) => r.form);
      for (const frm of thisForms) {
        if (formsToLoad.indexOf(frm) === -1)
          formsToLoad.push(frm);
      }
    }

    return schema;
  },
  updateSchema: (schemaId, fn) => {
    set(
      produce((state) => {
        // @ts-ignore
        fn(state.schemas[schemaId], get().pristine_schemas[schemaId]);
      })
    );
  }
}));

export default useSchemasFormStore;
