import { EuiFormRow, } from '@elastic/eui';
import Label from '../label/label';


export const RowSection = ({ label, display, errorText, helpText,extraHelpText, error ,hasEmptyLabelSpace=false,children,...props}) => {
  
  return(
   <EuiFormRow
    hasEmptyLabelSpace={hasEmptyLabelSpace}
    label={<Label  label={label} extraHelpText={extraHelpText} />}
    isInvalid={!!error}
    error={errorText}
    fullWidth
    display={display === 'column' ? 'columnCompressed' : 'rowCompressed'}
    helpText={helpText}  
    {...props}
    >
      {children}
    
  </EuiFormRow>
  )
  
};