import { EuiFieldText } from '@elastic/eui';

const ConsecutiveField = ({ fieldSchema, error, fieldController,formAction }) => { 
  return (
    <>
      <EuiFieldText
        fullWidth
        disabled={formAction === 'creation' ? false : true}
        name={fieldSchema.id+''}
        id={'consecutive-field-'+fieldSchema.id}
        value={fieldController.value || ''}
        onChange={(e) => fieldController.onChange(e.target.value)}
        isInvalid={!!error}
        compressed
        prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend }
        append={fieldSchema.props?.append && fieldSchema.props?.append}
      />
      </>
  );
};

export default ConsecutiveField;