import React, { useState } from 'react';
import { EuiSuperSelect } from '@elastic/eui';
import { FieldTypes } from './types/fields.type.json';
import Options from './options';



function FieldSelector(props) {

  const list_options = [];

  FieldTypes.forEach((el) => {

    const option =(<Options label={el.label} type={el.value} description={el.description} />);

    list_options.push(
      {
        value: el.value,
        inputDisplay: (option),
        dropdownDisplay: (option),
        disabled:el.disabled || false,  
      }
    )
  });

  const [value] = useState(list_options[1].value);



  return (
    <EuiSuperSelect       
      options={list_options}    
      valueOfSelected={value}
      {...props}
    />
  );

}

export default FieldSelector

