import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import AppToolBar from './toolbar'


function AppPageContainer() {
    return (
        <Fragment>
            <AppToolBar />
            <Outlet />
        </Fragment>
    )
}

export default AppPageContainer
