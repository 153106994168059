import React, { useState } from 'react'
import { EuiButtonEmpty, EuiButtonIcon, EuiCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiSpacer, EuiSplitPanel, EuiToken, EuiToolTip } from '@elastic/eui'
import IconFieldType from 'components/icons/icons-fields-type'
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import FormFieldCreator from 'packages/field-creator';
import AnimatePanelWrapper from 'components/animation/animate-panel-wrapper';
import DangerButton from 'components/buttons/danger-button';
import SwitchStatusAttribute from './switch-status-attribute';
import { useInvalidateQuery } from 'api/invalidate-querys/invalidate-current-views';
import { AttributeApiCommand } from 'api/attributes-api';
import { useAppParameters } from 'hooks/useAppParameters';
import CalloutError from 'components/notifications/callout/callout-error';
import TitleFieldEditForm from 'packages/field-creator/editor-field/title-field/title-field-form';




function RowFieldDrop({ title, icon, idx, remove, visible, id, isDragging, is_predefined, data, ...provided }) {


  const [isOpenEdit, setIsOpenEdit] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [textConfirmed, setTextConfirmed] = useState('')
  const [status, setStatus] = useState(visible);
  const [isDeleting, setIsDeleting] = useState(false);
  const { collection } = useAppParameters();
  const [showError, setShowError] = useState(false);
  const [errortecnical, setErrortecnical] = useState('');
  const exclude_columns = ['id', 'create_at', 'last_update', 'create_by', 'geolocalization', 'avatar','foreign_key'];

  const column_type = data?.data_type;


  const { InvalidateCurrentView } = useInvalidateQuery();

  const handleRemove = async key => {
    setIsDeleting(true)
    setShowError(false)

    const response = await AttributeApiCommand.DeleteAttribute({ attribute_key: key, collection: collection })

    if (response.status) {
      InvalidateCurrentView()
    } else {
      setShowError(true)
      setErrortecnical(response.message)
    }

    setIsDeleting(false)
  }


  console.log("icon", icon)




  const updateStatus = (status) => {

    setStatus(status)
  }


  const handleAction = (action) => {



    if (action === 'edit') {
      if (isOpenEdit) {
        setIsOpenEdit(false)
        setIsOpenDelete(false)
      } else {
        setIsOpenEdit(true)
        setIsOpenDelete(false)
      }

    } else {
      if (isOpenDelete) {
        setIsOpenEdit(false)
        setIsOpenDelete(false)


      } else {
        setIsOpenEdit(false)
        setIsOpenDelete(true)
      }

      setIsDeleting(false)

      setTextConfirmed('')
    }
  }

  type TypePanelsColors = 'primary' | 'danger' | 'plain';


  const PanelsColors = (): TypePanelsColors => {

    let colorBackground;
    if (status) {

      if (isOpenEdit === true && isOpenDelete === false)
        colorBackground = 'primary'

      if (isOpenEdit === false && isOpenDelete === true)
        colorBackground = 'danger'

      if (isOpenDelete === false && isOpenEdit === false)
        colorBackground = 'plain'

    } else {
      colorBackground = isDragging ? 'subdued' : 'transparent'
    }

    return colorBackground;


  }

  const confirmation_delete = (<>Este proceso borra toda la información de la columna.<br /> Una vez borrada, no podrá recuperarla.</>)

  const predefiedIcon = (
    <>
      <p>
        <EuiToolTip content="Predefinida en la colección, no se puede eliminar o editar.">
          <EuiToken className="eui-alignMiddle" iconType={ column_type ==="foreign_key" ? 'tokenRepo' : 'tokenParameter'} />
        </EuiToolTip> {title}</p>
    </>
  )

  return (
    <>
      {showError && (
        <CalloutError
          error={errortecnical}
          title="Columna no fue borrada"
          description="Ha ocurrido algo inesperado y no fue posible borrar la columna, intentalo nuevamente."
        />
      )}

      <EuiSplitPanel.Outer hasShadow={isDragging && status ? true : false} color={PanelsColors()} hasBorder={!status}>
        <EuiSplitPanel.Inner paddingSize="s" >
          <EuiFlexGroup gutterSize="xs" alignItems="center" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiPanel
                color="transparent"
                paddingSize="s"
                {...provided.dragHandleProps}
                aria-label="Drag Handle"
              >
                <EuiIcon type="grab" />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <IconFieldType iconType={icon} /> 
              </EuiFlexItem>
            <EuiFlexItem>{is_predefined ? predefiedIcon : title}</EuiFlexItem>
            { !['title', 'foreign_key'].includes(column_type)  && (
              <EuiFlexItem grow={false}>
                <EuiToolTip position="left" content={'Activar o desactivar la columna'}>
                  <SwitchStatusAttribute status={status} id={id} onChange={updateStatus} />
                </EuiToolTip>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              {!exclude_columns.includes(column_type) ? (
                <EuiButtonIcon
                  iconType='pencil'
                  aria-label="edit-form"
                  color='primary'
                  onClick={() => { handleAction('edit') }}
                  disabled={!status}
                />
              ) : null}

            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {!is_predefined && (
                <EuiButtonIcon
                  iconType={ICON_GALLERY.TRASH}
                  aria-label="Remove"
                  color='danger'
                  onClick={() => handleAction('delete')}
                  disabled={!status}
                />
              )}

            </EuiFlexItem>
          </EuiFlexGroup>

        </EuiSplitPanel.Inner>

        {isOpenEdit && (
          <EuiSplitPanel.Inner paddingSize='s' style={{ display: isOpenEdit ? 'block' : 'none' }} >
            <EuiPanel hasShadow={false} color='primary' paddingSize='s'>
              {column_type === 'title' ? (
                <>
                  <TitleFieldEditForm data={data && data} collection={collection} />
                </>) : (
                <>
                  <FormFieldCreator
                    collection={collection}
                    action='update'
                    data={data && data}
                    compressed={false} />
                </>)}

            </EuiPanel>
          </EuiSplitPanel.Inner>
        )}

        {isOpenDelete && (
          <EuiSplitPanel.Inner style={{ display: isOpenDelete ? 'block' : 'none' }} >
            <EuiPanel hasShadow={false} paddingSize='s' >
              <EuiCard
                layout="horizontal"
                hasBorder={false}
                display="transparent"
                titleSize='xs'
                icon={<EuiIcon size="xl" type={ICON_GALLERY.TRASH} color="danger" />}
                title={'Confirmación de borrado de la columna'}
                description={confirmation_delete}
              >
                <EuiFieldText placeholder="Escribe borrar definitivamente para confirmar" value={textConfirmed} onChange={(e) => setTextConfirmed(e.target.value)} />
                <EuiSpacer />
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <AnimatePanelWrapper>
                      <EuiButtonEmpty size="s" onClick={() => handleAction('delete')} >
                        Cancelar
                      </EuiButtonEmpty>
                    </AnimatePanelWrapper>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false} >
                    <AnimatePanelWrapper>
                      <DangerButton
                        text='Borrar'
                        isLoading={isDeleting}
                        onClick={() => handleRemove(idx)}
                        color={(textConfirmed.toLowerCase() !== 'borrar definitivamente') ? 'text' : 'danger'}
                        isDisabled={(textConfirmed.toLowerCase() !== 'borrar definitivamente')}
                      />
                    </AnimatePanelWrapper>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiCard>
            </EuiPanel>
          </EuiSplitPanel.Inner>
        )}
      </EuiSplitPanel.Outer>
    </>
  )
}

export default RowFieldDrop