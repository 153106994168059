export const OverridesTheme = 
{
    colors:{
        LIGHT: {
            //primary: "#ffdd00",
            // // primaryText:"#616161",
            // danger: "#ff5576",
            // dangerText: "#FF5576",
            accentText:"#ff5576",
        }
    },
    
}
