import { EuiSplitPanel } from '@elastic/eui'
import React from 'react'
import NavDragFields from './nav-drag-fields'
import NavFieldToolbar from './nav-toolbar'

function NavFieldsContainer({list1}) {
  return (
    <EuiSplitPanel.Outer>
    <EuiSplitPanel.Inner paddingSize='s' grow={false}>
       <NavFieldToolbar/>
    </EuiSplitPanel.Inner>
    <EuiSplitPanel.Inner paddingSize="none" grow={false}>
      <NavDragFields list1={list1}/>
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  )
}

export default NavFieldsContainer