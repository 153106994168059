import { EuiButton, EuiButtonEmpty, EuiHeaderLinks } from '@elastic/eui';
import { ICON_GALLERY } from 'components/icons/icons-gallery';

import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function ListOptionsSettings({ app, collection }) {

  let _location = useLocation();
  const _path = _location.pathname;


  const [activeLink, setactiveLink] = useState('')


  const configurationOptions = [
    {
      name: 'Configuración',
      icon: 'gear',
      page: 'configuration',
      id: 1

    },
    {
      name: 'Diseñador de datos',
      icon: 'database',
      page: 'form/',
      id: 2

    },

    // {
    //   name: 'Flujos',
    //   icon: 'logstashOutput',
    //   page:'flow',
    //   id:4

    // },
    {
      name: 'Automatización',
      icon: 'bolt',
      page: 'bot',
      id: 5

    },
    {
      name: 'Plantillas',
      icon: 'documents',
      page: 'template',
      id: 6

    },
    {
      name: 'Configuracion avanzada',
      icon: ICON_GALLERY.CODE_SLASH,
      page: 'template',
      id: 8

    },
    // {
    //   name: 'Email',
    //   icon: 'email',
    //   page:'mail',
    //   id:7

    // },
    // {
    //   name: 'Api',
    //   icon: 'online',
    //   page:'api',
    //   id:8

    // },
    // {
    //   name: 'transformacion de datos',
    //   icon: 'aggregate',
    //   page:'transformacion',
    //   id:8

    // }
  ];

  useEffect(() => {

    setactiveLink(_path)

  }, [_path]);


  return (
    <EuiHeaderLinks gutterSize="xs">
      {
        configurationOptions.map((options) => (
          <NavLink to={`/app/${app}/s/${options.page}`} key={options.id}>
            {activeLink.includes(options.page) ? 
            (<EuiButton
              color="primary"
              fill
              size='s'
              iconType={options.icon}
              onClick={() => {}}
            >
              {options.name}
            </EuiButton>) :
            ( <EuiButtonEmpty
              size="s"
              isSelected={activeLink.includes(options.page) ? true : false}
              className={activeLink.includes(options.page) ? 'active' : ''}
              color={activeLink.includes(options.page) ? 'primary' : 'text'}
              iconType={options.icon}
            >
              {options.name}
            </EuiButtonEmpty>) 
          }
            
          </NavLink>

        ))
      }

    </EuiHeaderLinks>

  );

}

export default ListOptionsSettings