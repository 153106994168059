import { EuiAccordion, EuiGlobalToastList, EuiPanel, EuiText } from '@elastic/eui'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import { nanoid } from 'nanoid';


type CalloutErrorProps = {
  title: string;
  description: string;
  error?: string | null;
};

function CalloutError({ title, description, error: tecnical_error = null }: CalloutErrorProps): JSX.Element {


  const [toasts, setToasts] = useState([]);

  const CloseAllToast = () => { setToasts([]) }
  const id_accordion = 'error-accordion' + nanoid();

  const title_technical_details = (
    <EuiText style={{paddingTop:5}} size='s'><h5>Detalle técnico</h5></EuiText>
  )

  const template = (
    <Fragment>
      {description}
      <EuiAccordion id={id_accordion} buttonContent={title_technical_details} arrowDisplay='none'>
        <EuiPanel color="subdued">
          {tecnical_error}
        </EuiPanel>
      </EuiAccordion>
    </Fragment>
  )

  useEffect(() => {

    setToasts([{
      id: 'notification-' + nanoid(),
      title: title,
      color: "danger",
      text: tecnical_error ? template : description,
      iconType: ICON_GALLERY.ERROR,


    }])
    // eslint-disable-next-line
  }, [])



  return (

    <EuiGlobalToastList toasts={toasts}
      toastLifeTimeMs={900000}
      dismissToast={CloseAllToast} >
    </EuiGlobalToastList>

  )
}

export default CalloutError
