import React, { Fragment, useEffect, useState } from 'react';
import {
  EuiForm, EuiFormRow, EuiI18n, EuiSpacer, EuiPageSection, EuiInlineEditText, EuiSkeletonText, EuiCallOut, EuiSwitch, EuiSkeletonRectangle, EuiHorizontalRule,
} from '@elastic/eui';
import { useAppParameters } from 'hooks/useAppParameters';
import { QUERY_KEYS_APP, AppApiCommand, AppApiQuery } from 'api/app-api';
import EditAppEmoji from './components/edit-app-emoji';
import CalloutError from 'components/notifications/callout/callout-error';
import { useInvalidateQueryByKey } from 'api/invalidate-querys/invalidate-by-key';
import ButtonDeleteApp from './components/button-delete-app';



const AppPageSettings = () => {


  const i18n_workspace_name = (<EuiI18n token="global.name" default="Nombre de la app" />)
  const i18n_label_required = (<EuiI18n token="global.required" default="Es requerido" />)
  const i18n_space_itemtype = (<EuiI18n token="space.create.item" default="Descripción" />)
  const i18n_space_itemtype_helptext = (<EuiI18n token="space.create.item.helptext" default="Describa la app en pocas palabras" />)
  const i18n_space_name_helptext = (<EuiI18n token="space.create.name.helptext" default="Escriba el nombre que identifique a su app." />)

  const { app } = useAppParameters();
  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [appStatus, setAppStatus] = useState(false);
  const [appStatusText, setAppStatusText] = useState('Inactiva');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isInvalidAppName, setIsInvalidAppName] = useState(false);

  const { InvalidateQuery } = useInvalidateQueryByKey([QUERY_KEYS_APP.GET_LIST_APPS_BY_WORKSPACE, QUERY_KEYS_APP.GET_APP_BY_HASH]);

  const getDataApp = async () => {
    setIsLoading(true)
    setShowError(false)
    const response = await AppApiQuery.GetAppByHash(app);
    if (response) {

      setAppName(response.data.name);
      setAppDescription(response.data.description);
      setAppStatus(response.data.status)


    } else {
      setErrorMessage(response.message)
    }

    setIsLoading(false)

  }




  const updateAttributeApp = async (attribute, value) => {

    setShowError(false)
    const update = { attribute: attribute, value: value, hash_id: app };

    const response = await AppApiCommand.UpdateApp(update);

    if (!response.status) {
      setErrorMessage(response.message)
      setShowError(true)
    }

    InvalidateQuery()
    setUpdatingStatus(false)
    
    if (attribute === 'name')
      setAppName(value)

    if (attribute === 'description')
      setAppDescription(value)




  }





  useEffect(() => {

    getDataApp();

  }, [app])


  const onChangeStatus = async (e) => {

    setUpdatingStatus(true)
    setAppStatus(e.target.checked)
    e.target.checked ? setAppStatusText('Activa') : setAppStatusText('Inactiva')
    const status = e.target.checked ? 'true' : 'false'
    updateAttributeApp('status', status)

  }


  return (
    <Fragment>
      {showError &&
        <CalloutError
          error={errorMessage}
          title="No se actualizo la app"
          description="Ha ocurrido algo inesperado y no fue posible actualizar la app, intentalo nuevamente o comunicate con el administrador de la plataforma."
        />}
      <EuiPageSection style={{ width: 600 }}>
        <EuiCallOut
          size="s"
          title="En esta sección puedes editar la información de la app"
          iconType="pin"
          style={{ borderRadius: 5 }}
        />
        <EuiSpacer></EuiSpacer>
        <EuiForm component="form" autoComplete="off" noValidate>

          <EuiFormRow label={i18n_workspace_name} error={[i18n_label_required]} helpText={i18n_space_name_helptext}>
            <>
              <EuiSkeletonText lines={1} isLoading={isLoading} size='relative' >
                <EuiInlineEditText
                  inputAriaLabel="Edit app name"
                  defaultValue={appName}
                  isInvalid={isInvalidAppName}
                  size="s"
                  id='edit-app-name'
                  startWithEditOpen={false}
                  onSave={(newInlineEditValue) => {
                    if (newInlineEditValue.length > 0) {
                      setIsInvalidAppName(false)
                      updateAttributeApp('name', newInlineEditValue)
                    }
                    else {
                      setIsInvalidAppName(true)
                      return false;
                    }

                  }}
                  editModeProps={{
                    inputProps: {
                      compressed: true,
                      maxLength: 35//max length of name
                    },
                    saveButtonProps: {
                      display: 'empty',
                      size: 's',
                    },
                    cancelButtonProps: {
                      display: 'empty',
                      size: 's',
                      onClick: () => { }
                    },

                  }}

                />
              </EuiSkeletonText>
            </>
          </EuiFormRow>

          <EuiFormRow label={i18n_space_itemtype} error={[i18n_label_required]} helpText={i18n_space_itemtype_helptext} >
            <>
              <EuiSkeletonText lines={1} isLoading={isLoading} size='relative' >
                <EuiInlineEditText
                  inputAriaLabel="Edit description"
                  defaultValue={appDescription || ''}
                  size="s"
                  placeholder='Escribe una descripción'
                  id='edit-app-description'
                  startWithEditOpen={false}
                  onSave={(newInlineEditValue) => {
                    updateAttributeApp('description', newInlineEditValue)
                  }}
                  editModeProps={{
                    inputProps: {
                      compressed: true,
                      maxLength: 250//max length of name

                    },
                    saveButtonProps: {
                      display: 'empty',
                      size: 's',
                    },
                    cancelButtonProps: {
                      display: 'empty',
                      size: 's',
                      onClick: () => { }
                    },

                  }}

                />
              </EuiSkeletonText>
            </>
          </EuiFormRow>
          <EuiFormRow label={'Emoji'} >
            <EditAppEmoji app={app} />
          </EuiFormRow>

          <EuiFormRow label={'Estado de la app'} >
          <EuiSkeletonText lines={1} isLoading={isLoading} size='relative' >
            <EuiSkeletonRectangle
              isLoading={updatingStatus}
              contentAriaLabel="update status"
              width="54.16px"
              height="20px"
              borderRadius="s"
            >
              <EuiSwitch
                id='app-status-switch'
                label={appStatusText}
                checked={appStatus}
                compressed
                onChange={(e) => onChangeStatus(e)}
              />
            </EuiSkeletonRectangle>
            </EuiSkeletonText>
          </EuiFormRow>
          <EuiSpacer />
          <EuiHorizontalRule margin="m" />
          <EuiSkeletonText lines={1} isLoading={isLoading} size='relative' >
            <ButtonDeleteApp app={app} />
          </EuiSkeletonText>
        </EuiForm>      
      </EuiPageSection>
    </Fragment>


  )
}

export default AppPageSettings