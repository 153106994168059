export const NumberHelper = {

    LimitNumberRange: (number, min, max) => {
        const MIN = min || 1;
        const MAX = max || 25;
        const parsed = parseInt(number)
        const result = Math.min(Math.max(parsed, MIN), MAX)
        return isNaN(result) ? 0 : result;
    },


};