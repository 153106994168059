
export const LocalStoreHelper = {
    get(key) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
  
    set(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
  
    update(key, updatedValue) {
      const existingValue = this.get(key);
      if (existingValue) {
        const updatedObject = { ...existingValue, ...updatedValue };
        this.set(key, updatedObject);
      }
    },
  
    delete(key) {
      localStorage.removeItem(key);
    },
  };