import { EuiFieldText } from '@elastic/eui';

const LastUpdateField = ({ fieldSchema, error, fieldController }) => {
    return (
        <>
            <EuiFieldText
                fullWidth
                disabled
                name={fieldSchema.id + ''}
                id={'last-update-field-' + fieldSchema.id}
                value={fieldController.value || ''}
                onChange={(e) => fieldController.onChange(e.target.value)}
                isInvalid={!!error}
                compressed
            />
        </>
    );
};

export default LastUpdateField;