import {  EuiSpacer } from '@elastic/eui';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const RichTextField = ({ fieldSchema, error, fieldController }) => {
    const [, setValue] = useState('');

    var toolbarOptions = [
        ['bold', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'header': [1, 2, 3, false] }],
        [{ 'color': [] }],
        [{ 'align': [] }],       

    ];
    //para la edicion 
    //<div dangerouslySetInnerHTML={{__html: value}}></div>

    const onChange = (e) => {
        setValue(e);
        fieldController.onChange(e)
    }


    return (
        <>
            <ReactQuill
                id={'rich-field-' + fieldSchema.id}
                theme="snow"
                value={fieldController.value || ''}
                style={{ height: '250px', paddingBottom: '10px', marginBottom: '30px' }}
                modules={{ toolbar: toolbarOptions }}
                onChange={onChange} />
        </>
    );
};

export default RichTextField;