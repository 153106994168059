import { EuiFieldText } from '@elastic/eui';
import { useEffect, useState } from 'react';

const GeoLocalizationField = ({ fieldSchema, error, fieldController }) => {

  const [loading, setLoading] = useState(false);
    const [localization, setLocalization] = useState('');



    useEffect(() => {
        setLoading(true)
        if (navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(function (position) {
                var latitud = position.coords.latitude;
                var longitude = position.coords.longitude;
                var localization = '(' + latitud + ',' + longitude+')';
                setLocalization(localization);
                fieldController.onChange(localization)
                setLoading(false)
            });
        }
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <EuiFieldText
                fullWidth
                disabled
                isLoading={loading}
                name={'geolocalization'}
                id={'geolocalization'}
                value={fieldController.value || localization}
                isInvalid={!!error}
                compressed
                prepend={fieldSchema.props?.prepend && fieldSchema.props?.prepend}
                append={fieldSchema.props?.append && fieldSchema.props?.append}
            />
        </>
    );
};

export default GeoLocalizationField;