import { EuiBadge, EuiBadgeGroup, EuiNotificationBadge, EuiPanel, keys } from "@elastic/eui";
import ApiClient from "api/api.client";
import { GLOBAL_KEY_BASE_URL } from "core/Constants";
import { KEYS_LOCALSTORE } from "core/Keys";
import { GetToken, TokenWithBearer } from "core/Token";



const FileRenderer = ({ value, row, column }) => {
   
    const fileList = value ? value : [];

    const openFile = (filename) => {

        const _token = localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN);
        const url = GLOBAL_KEY_BASE_URL + `files/download?filename=${encodeURIComponent(filename)}&token=${_token}`;
        window.open(url, '_blank');

    }

    return (
        <EuiPanel hasBorder={false} hasShadow={false} color="transparent" paddingSize="none" style={{ maxWidth: 300 }}>

            <EuiBadgeGroup gutterSize="s">
                {fileList && fileList.length > 1 ? <EuiNotificationBadge title="Número de archivos" color="subdued">{fileList.length}</EuiNotificationBadge> : null}
                {fileList && fileList.map((val, index) => (
                    <EuiBadge
                        iconType="popout"
                        iconSide="left"
                        iconOnClick={() => { openFile(val) }}
                        iconOnClickAriaLabel="Descargar"
                        key={index}
                        color="hollow">
                        {val}
                    </EuiBadge>
                ))}
            </EuiBadgeGroup>

        </EuiPanel>)
}

export default FileRenderer