import {
  EuiGlobalToastList,
  htmlIdGenerator,
} from '@elastic/eui';
import React from 'react';
import { useState } from 'react';
import { ICON_GALLERY } from 'components/icons/icons-gallery';

function CalloutInformation({ title, messaje, side }) {

  const idmaker = htmlIdGenerator();

  const _toast = [{
    id: `notification-${idmaker()}`,
    title: title,
    color: 'primary',
    text: messaje,
    iconType: ICON_GALLERY.INFO_CIRCLE
  }
  ];

  const [toasts, setToasts] = useState(_toast);

  const CloseAllToast = () => setToasts([]);


  return (
    <EuiGlobalToastList toasts={toasts} toastLifeTimeMs={9000} side={side || 'right'} dismissToast={CloseAllToast} >
    </EuiGlobalToastList>

  );
}
export default CalloutInformation;