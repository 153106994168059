import { EuiButtonIcon, EuiFieldText } from '@elastic/eui';



export const TextField = ({field,error,...others}) => {

  const { name, value , id   } = field;

   return (
    <EuiFieldText
      name={name}
      id={id}
      value={value || ''}
      onChange={(e) => field.onChange(e.target.value)}
      isInvalid={!!error}
      compressed={true}
      fullWidth={false}    
      autoComplete='off'
      maxLength={255}
      
      {...others}
    />
  )
};