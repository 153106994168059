import { EuiIcon, EuiToolTip } from "@elastic/eui";


function LabelDescription({ description }) {
    return (
      <EuiToolTip content={description}  >
        <>
        <EuiIcon type="questionInCircle"  color="subdued" />
        </>
        </EuiToolTip>
    )
  }

  export default LabelDescription