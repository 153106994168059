import {
    EuiButton,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
} from "@elastic/eui"
import { ScobarForm } from "packages/form"
import BreadCrumbsForm from "packages/form/breadcrumbs"


const ModalNewRecord = ({ closeModal, onFormChange, onSubmit, collection_id, data, isLoadingSavingChanges }) => {


    return (

        <EuiModal onClose={closeModal}>

            <BreadCrumbsForm
                hasShadow={false}
                hasBorder={true}
                collection={collection_id}
                buttonClose={
                    <EuiButtonIcon
                        aria-labelledby="close"
                        style={{ marginRight: 6 }}
                        iconType="cross" color="danger"
                        onClick={closeModal} />
                }
            />
            <EuiModalBody style={{ paddingRight: 'initial' }}>
                <ScobarForm
                    formId={collection_id}
                    data={data}
                    onChange={onFormChange}
                    onSubmit={onSubmit}
                    showSubmitButton={false}
                    style={{ width: 450 }}
                    formAction='create'
                />

            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Cancelar</EuiButtonEmpty>
                <
                    // @ts-ignore
                    EuiButton form={`form-${collection_id}`} size="s" color="primary" fill type="submit" isLoading={isLoadingSavingChanges} >Guardar</EuiButton>
            </EuiModalFooter>
        </EuiModal>

    )
}

export default ModalNewRecord;

