export const FieldTypes = [
  {
    id: 'field-type-text',
    value: 'text',
    label: 'Texto corto',
    description: 'Escribe contenido en una linea',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-longtext',
    value: 'longtext',
    label: 'Texto largo',
    description: 'Escribe contenido en varias lineas',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-rich_text',
    value: 'richtext',
    label: 'Texto Entiquecido',
    description: 'Escribe contenido enriquecido con imagenes,texto, tablas y mucho más.',
    web: true,
    mobile: false,
    disabled: false

  },
  {
    id: 'field-type-date',
    value: 'date',
    label: 'Fecha',
    description: 'Selecciona una fecha desde un calendario',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-numeric',
    value: 'numeric',
    label: 'Numérico',
    description: 'Escribe solo números, con formato de moneda o porcentajes',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-select',
    value: 'select',
    label: 'Lista desplegable',
    description: 'Crea un listado de opciones con selección única o múltiple, además podrás agregar opciones  y seleccionar colores de respuesta',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-cascade',
    value: 'cascade',
    label: 'Listas dependientes',
    description: 'Permite crear un listado manual de opciones de selección unica, que dependan de respuesta de otra lista',
    disabled: false,

  },
  {
    id: 'field-type-checklist',
    value: 'checkbox',
    label: 'Casilla de chequeo',
    description: 'Crea una casilla de chequeo de selección unica',
    web: true,
    mobile: true,
    disabled: false

  },{
    id: 'field-type-options',
    value: 'options',
    label: 'Listado de opciones',
    description: 'Listado de botones de opción de selección unica, ideal para 2 o maximo 6 respuestas',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-upload',
    value: 'file',
    label: 'Archivo adjunto',
    description: 'Permite subir archivos de diferentes formatos, 5Mb por defecto y solo 1 archivo en móvil ',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-gallery',
    value: 'gallery',
    label: 'Galería de imagenes',
    description: 'Permite subir diferentes formatos y visualizarlos como una galeria de imagenes, en la app móvil solo permite 1 imagen',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-qr',
    value: 'qr',
    label: 'Codigo QR',
    description: 'Representa un codigo QR',
    web: true,
    mobile: false,
    disabled: true
  },
  {
    id: 'field-signature',
    value: 'signature',
    label: 'Firma',
    description: 'Firma',
    web: true,
    mobile: false,
    disabled: false

  },


  {
    id: 'field-type-email',
    value: 'email',
    label: 'Correo electrónico',
    description: 'Valida un correo electrónico',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-type-url',
    value: 'url',
    label: 'Hipervinculo',
    description: 'Registra una dirección de internet o URl para abrir desde un navegador',
    web: true,
    mobile: true,
    disabled: false

  },

  // {
  //   id: 'field-type-search-place',
  //   label: 'Geolocalización',
  //   value: 'searchplace',
  //   description: 'Busqueda de lugares o direcciones usando de google maps',
  //   web: true,
  //   mobile: true,
  //   disabled: true

  // },
  // {
  //   id: 'field-type-search-place',
  //   label: 'Direcciones',
  //   value: 'searchplace',
  //   description: 'Busqueda de lugares o direcciones usando servicio de mapas',
  //   disabled: true

  // },
  // {
  //   id: 'field-type-search-place',
  //   label: 'Pais, estado y ciudad',
  //   value: 'country_state_city',
  //   description: 'Listado de paises con sus estados y ciudades ',
  //   disabled: true

  // },
  // {
  //   id: 'field-type-avatar',
  //   value: 'avatar',
  //   label: 'Avatar',
  //   description: 'Identifica mediante una imagen ó foto cada registros de esta app',
  //   web: true,
  //   mobile: true,
  //   disabled: false

  // },
  {
    id: 'field-type-rating',
    value: 'rating',
    label: 'Calificación',
    description: 'Califica de 1 hasta 5 estrellas',
    web: true,
    mobile: false,
    disabled: false

  },
  {
    id: 'field-type-consecutive',
    value: 'consecutive',
    label: 'Consecutivo',
    description: 'Crea un consecutivo de forma automática iniciando desde el número 1 y puedes agregar prefijos: TEC-1',
    web: true,
    mobile: true,
    disabled: false

  },

  {
    id: 'field-type-user',
    value: 'user',
    label: 'Usuario',
    description: 'Listados de usuarios de la plataforma',
    web: true,
    mobile: true,
    disabled: false

  },
  {
    id: 'field-button',
    value: 'button',
    label: 'Botón',
    description: 'Muestra un botón que puedes configurar acciones en el formulario',
    web: true,
    mobile: true,
    disabled: true

  },
  {
    id: 'field-type-relationapp',
    value: 'linktoapp',
    label: 'Conexión a una App',
    description: 'Muestra una lista desplegable de un campo de otras apps para mostrar datos realacionados',
    web: true,
    mobile: false,
    disabled: true

  },
  {
    id: 'field-type-relationdb',
    value: 'linktodb',
    label: 'Conexión a Base de datos',
    description: 'Muestra una lista desplegable de un campo de una base de datos para mostrar datos realacionados',
    web: true,
    mobile: false,
    disabled: true

  }


];

