import { EuiLink } from "@elastic/eui"



const UrlRenderer = ({ value, row, column }) => {

    return (<>
        {value && 
        <EuiLink
            external
            href={'https://' + { value }}
            target='_blank' >
            {value}
        </EuiLink>
        }
    </>)
}

export default UrlRenderer