import { Outlet } from "react-router";
import SideNavSuscription from "./components/side-nav";
import { EuiHorizontalRule, EuiPage, EuiPageBody, EuiPageSection, EuiPageSidebar, EuiText } from "@elastic/eui";





function SuscriptionPageContainer() {


    const Tile = (<div><dl><dt>SUSCRIPCIÓN</dt><dd className="ws-subtitle-small">Configuracion de la cuenta</dd> </dl></div>);

    return (
        <EuiPage paddingSize="none">
            <EuiPageSidebar paddingSize="none" style={{ backgroundColor: "#FFF" }} hasEmbellish>
                <EuiText size="s" style={{ marginBottom: 0, paddingTop: 17, paddingLeft: 15 }} >{Tile}</EuiText>
                <EuiHorizontalRule margin="s"/>
                <SideNavSuscription />
            </EuiPageSidebar>
            <EuiPageBody paddingSize="none" panelled={true}  panelProps >
                <EuiPageSection>
                    <Outlet />
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>


    )
}

export default SuscriptionPageContainer