import React, { useState } from 'react';

import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  euiDragDropReorder,
  htmlIdGenerator,
  EuiButtonEmpty,
} from '@elastic/eui';
import ItemOption from './components/item-options';
import { useGlobalStore } from 'store/global-store';
import AnimatePanelWrapper from 'components/animation/animate-panel-wrapper';

const makeId = htmlIdGenerator();



function SuperSelectWithColorOptions({ data, GetOptions, showColorPicker, showEditorValues }) {


  const [list, setList] = useState( data || [{ value: '', id: makeId() }]);
  const currentTheme = useGlobalStore(state => state.theme);
  const isDraggingOverClass = currentTheme === 'dark' ? 'euiDroppable--isDraggingOver dark' : 'euiDroppable--isDraggingOver light';

  
  const onDragEnd = ({ source, destination }) => {


    if (source && destination) {
      let order = [...list];
      const ordered = euiDragDropReorder(order, source.index, destination.index);
      setList(ordered);
      GetOptions(ordered)

    }

  };


  const addItemOption = () => {
    let add = [...list];
    add.push({ value: '', id: makeId() })
    setList(add)
    GetOptions(add)
  }



  const updateItemOption = (item, add) => {


    let newList = list.map(el => (
      el.id === item.id ? { ...el, value: item.value, label: item.label, color: item.color } : el
    ))

    if (add) {
      const isBlankValueExists = list.some((el) => el.value === '');
      if (!isBlankValueExists)
        newList.push({ value: '', id: makeId() })
    }

    setList(newList)
    GetOptions(newList)

  }

  const removeItemOption = (index) => {
    var array = [...list];
    const removed = array.filter(el => el.id !== index);
    setList(removed)
    GetOptions(removed);

  }




  return (
    <>

      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable
          droppableId="DROPPABLE_AREA_2"
          spacing="none"
          className={isDraggingOverClass}
        >
          {list.length > 0 && list.map((content, idx) => (
            <AnimatePanelWrapper key={'option-' + content.id}>
              <EuiDraggable
                spacing="m"
                key={content.id}
                index={idx}
                draggableId={content.id}
                hasInteractiveChildren={true}
              >
                {(provided) => (
                  <EuiPanel paddingSize="none" color="subdued" {...provided.dragHandleProps} key={'option-' + content.id} >
                    <EuiFlexGroup alignItems="center" gutterSize="none">
                      <EuiFlexItem grow={false}>
                        <EuiPanel
                          color="transparent"
                          paddingSize="none"
                          aria-label="Drag Handle"
                        >
                          <EuiIcon type="grab" />
                        </EuiPanel>
                      </EuiFlexItem>
                      <EuiFlexItem >
                        <ItemOption 
                          item = {content}
                          showColorPicker={showColorPicker}
                          OnUpdate={updateItemOption}
                          OnRemove={removeItemOption}
                          showEditorValues={showEditorValues}
                          currentList={list} />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                )}
              </EuiDraggable>
            </AnimatePanelWrapper>
          ))}
        </EuiDroppable>
      </EuiDragDropContext>
      {list.length === 0 &&
        <EuiPanel
          color="subdued"
          style={{ position: 'sticky', bottom: 0, zIndex: 1 }}
          paddingSize="s">
          <EuiButtonEmpty
            size="xs"
            iconType="plusInCircle"
            style={{ width: '100%' }}
            onClick={() => { addItemOption() }}
          >
            Agregar una opción de respuesta
          </EuiButtonEmpty>
        </EuiPanel>
      }
    </>
  )
}

export default SuperSelectWithColorOptions

