import React from "react";
import { createRoot } from 'react-dom/client';
import './App.css';
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import App from './App';


TimeAgo.addDefaultLocale(es)
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
