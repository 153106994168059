import React, { useState } from 'react'

import { useForm, Controller } from 'react-hook-form';
import { AuthManager } from 'api/auth-api';

import iconSendRecovery from 'assets/img/send-recovery.png';

import { motion } from 'framer-motion'
import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiI18n, useEuiI18n, EuiSpacer, EuiCallOut, EuiTextColor, EuiIcon, EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui';

function AppForgot() {

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      username: '',   
    }
  });
  const [isLoading, setisLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const [Send, setSend] = useState(false)

  const onSubmit = async (data) => {


    setIsValid(false)
    setisLoading(true)
    setSend(false)
    
    const resultado = await AuthManager.RecoveryPassWord(data.username);

    if (resultado) {

      setisLoading(false);
      setSend(true);


    }
    else {
      setIsValid(true)
      setisLoading(false);

    }

  };


  const sendIcon = (<motion.div  initial={{ opacity: 0, y: 50, scale: 0.2 }}
    animate={{ opacity: 1, y: 0, scale: 1 }} >
    <EuiSpacer />
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={false}><EuiIcon type={iconSendRecovery} size="original" style={{ height: "auto", width: 78 }} /></EuiFlexItem>
    </EuiFlexGroup>
  </motion.div>
  );

  const label_email_helptext = (<EuiI18n token="forgot.label.email.help" default="Escribe tu dirección de email." />)
  const label_email = (useEuiI18n('forgot.label.email', 'Dirección de email.'))
  const label_email_required = ((useEuiI18n('forgot.label.email.required', 'Escribe un email válido.')))
  const label_email_placeholder = ((useEuiI18n('forgot.label.email.placeholder', 'tucuenta@dominio')))
  const error_title = (useEuiI18n('forgot.callout.error.title', 'Email inválido'))




  const messajeError = (<EuiCallOut size="s" title={error_title} color="danger" iconType="alert"> {isValid && <EuiTextColor color="danger"><p> <EuiI18n token="forgot.callout.error.text" default="No se ha podido encontrar tu cuenta." /><br /><EuiI18n token="forgot.callout.error.subtext" default="Verifica que el email sea válido." /></p></EuiTextColor>}</EuiCallOut>);
  const messajeSend = (<motion.div  initial={{ opacity: 0, y: 50, scale: 0.2 }} animate={{ opacity: 1, y: 0, scale: 1 }} >
    <EuiCallOut style={{ padding: 5 }} size="s" title="Revisa tu cuenta de correo." color="success" iconType="email">
      {Send && <EuiTextColor color="secondary"><EuiI18n token="forgot.callout.success.text" default="Se ha enviado un email con los datos de recuperación." /></EuiTextColor>}</EuiCallOut>
  </motion.div>
  );


  return (
    <>
      <EuiPanel hasShadow={false}>
        <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} invalidCallout="none" autoComplete="off" >

          {Send && messajeSend}
          {Send && sendIcon}
          {(errors.username || isValid) && messajeError}
          <EuiSpacer />
          {!Send &&
            <EuiFormRow label={label_email} helpText={label_email_helptext}  error={[label_email_required]}>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: false,                 
                }}
                render={({ field: { onChange, onBlur, value } }) => <EuiFieldText
                 
                  autoComplete="off"                 
                  isLoading={isLoading}
                  placeholder={label_email_placeholder}
                  icon="user"
                  aria-label='username'
                  compressed
                  value={value}
                  onChange={onChange}
                />
                }
              />
            </EuiFormRow>
          }

          <EuiSpacer />
          {!Send && <EuiFormRow><EuiButton fill={true} fullWidth={true} isLoading={isLoading} size="s" type="submit">
            <EuiI18n token="forgot.button.submit.text" default="Enviar nueva contraseña" />
          </EuiButton>
          </EuiFormRow>}


        </EuiForm>
      </EuiPanel>
    </>
  )
}

export default AppForgot
