
import { Outlet } from "react-router-dom";
import DataBaseView from "./Index";

function DataBaseViewContainer() {
  return (
    <> 
      <DataBaseView/>
      <Outlet />
    </>
  );
}

export default DataBaseViewContainer;
