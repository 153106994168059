import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiPanel, useColorPickerState, euiPaletteColorBlindBehindText, EuiFormRow, } from '@elastic/eui'
import { ICON_GALLERY } from 'components/icons/icons-gallery';


import React, { useEffect, useState } from 'react'

function ItemCascadeOption({ index, item, OnUpdate, OnRemove, dependentItem, currentList }) {

 
  const retornarValorAleatorio = () => Math.floor(Math.random() * 10);
  const visColorsBehindText = euiPaletteColorBlindBehindText();
  const [color,] = useColorPickerState(visColorsBehindText[retornarValorAleatorio()]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isInvalidMessage, setIsInvalidMessage] = useState('');
  const [listOptions, setListOptions] = useState(currentList || []);
  const [value, setvalue] = useState(item ? item.value : '');
  const [, setCurrentItem] = useState(item || { id: '', value: '', label: '', color: '', dependent: '' });

  const OnChangueInput = (value, is_enter) => {
    setIsInvalid(false);
    setvalue(value);
    if (value.trim() !== "") 
    {


      const isDuplicate = currentList.some((el) =>
        el.value.toLowerCase() === value.trim().toLowerCase() && el.id !== index);

      if (isDuplicate) {
        setIsInvalid(true);
        setIsInvalidMessage('La opción ya existe');
        return;
      }
      const item = { id: index, value: value, label: value, color: color, dependent: dependentItem };
      setCurrentItem(item);
      setListOptions([...listOptions, item]);
      OnUpdate(item, is_enter);



    }
  };

  const clickOnremove = () => OnRemove(index)


  const onKeyDownHandler = (event) => {

    const keyCode = event.keyCode || event.which;

    if (keyCode === 8 || keyCode === 192) return;//backspace and ñ por alguna razon
    if (keyCode === 110 || keyCode === 107 || keyCode === 186) event.preventDefault();

    const isSpecialCharacter = /[^\w\s]/.test(String.fromCharCode(keyCode));

    if (isSpecialCharacter)
      event.preventDefault();

    if (keyCode === 13) {//enter
      event.preventDefault();
      OnChangueInput(event.target.value, true);
    }

  };



  useEffect(() => {

    // if (currentList.length > 0) {
    //  // setCurrentItem(item);
    //  // setListOptions([...listOptions, item]);
//    OnUpdate(item, false);
    // }

  }, [])


  return (
    <EuiFlexGroup alignItems="center" gutterSize="xs">
      <EuiFlexItem grow={false}>
        <EuiPanel
          color="plain"
          paddingSize="none"
          hasShadow={false}
          hasBorder={true}
          aria-label="Drag Handle"
        >
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem >
        <EuiFormRow
          style={{ marginTop: 0 }}
          hasEmptyLabelSpace
          isInvalid={isInvalid}
          error={isInvalidMessage}
          display='rowCompressed'
        >

          <EuiFieldText
            autoFocus
            title='Presione Enter para agregar un opción y máximo 200 caracteres'
            style={{ minWidth: 230 }}
            placeholder="Escriba una opción"
            onKeyDown={onKeyDownHandler}
            onChange={(e) => { OnChangueInput(e.target.value) }}
            compressed
            isInvalid={isInvalid}
            maxLength={200}
            value={value}

          />

        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          iconType={ICON_GALLERY.DELETE}
          aria-label="delete"
          onClick={() => { clickOnremove() }}
          color="accent" />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default ItemCascadeOption