import React from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel } from '@elastic/eui'
import IconFieldType from 'components/icons/icons-fields-type'
import SwitchStatusViewAttribute from './switch-status-view-attribute'
import { StringHelper } from 'core/String'

function RowField({ title, icon, idx, id, is_visble, onChange, ...provided }) {


    const truncate_text  = StringHelper.TruncateText(title, 20)


    return (
        <EuiPanel paddingSize='xs' color='subdued'  >
            <EuiFlexGroup gutterSize="xs" alignItems="center"  {...provided.dragHandleProps}>
                <EuiFlexItem grow={false} title='Mostrar o ocultar la columna'>
                    <SwitchStatusViewAttribute status={is_visble} id={id} onChange={onChange} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}><IconFieldType iconType={icon} /> </EuiFlexItem>
                <EuiFlexItem title={title}>{truncate_text}</EuiFlexItem>
                <EuiFlexItem grow={false} title='Arraste para ordenar la columna'>
                    <EuiPanel
                        color="transparent"
                        paddingSize="s"
                        {...provided.dragHandleProps}
                        aria-label="Drag Handle"
                    >
                        <EuiIcon type="grab" />
                    </EuiPanel>                 
                    </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>


    )
}

export default RowField