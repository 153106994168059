

/**
 * Converts the input object or JSON string into a JSON object.
 *
 * @param {any} obj - The object or JSON string to be converted.
 * @return {object} - The converted JSON object. If the input is not a valid JSON
 * object or string, an empty object is returned.
 */
const ToJson = (obj) => {

    try {
        if (typeof obj === "string") {
            const objetoJSON = JSON.parse(obj);
            if (typeof objetoJSON === "object" && objetoJSON !== null) {
                return objetoJSON;
            }
        } else if (typeof obj === "object" && obj !== null) {
            return obj;
        }
    } catch (error) {
        
        return {};
    }
    
    return {};

}


export const JsonHelper = {
    ToJson
}

