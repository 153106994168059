import ApiClient from "../api.client";

async function UpdateStatusAttributes(attribute_status) {
    const response = await ApiClient.HttpPost("collections/attributes/status", attribute_status);
    return response.data;
}


async function UpdateOrderAttributes(attributes_order) {
    const response = await ApiClient.HttpPost("collections/attributes/order", attributes_order);
    return response.data;
}

async function UpdateFormLayout( layout) {
    const response = await ApiClient.HttpPost("collections/settings/layout/update", layout);
    return response.data;
}


async function UpdateFormInitialMessage(message) {
    const response = await ApiClient.HttpPost("collections/settings/message/update", message);
    return response.data;
}



function CreateSubCollection(collection) {
    return ApiClient.HttpPost("collections/create/subcollection", collection);
}


export const CollectionApiCommand = {

    UpdateStatusAttributes,
    UpdateOrderAttributes,
    UpdateFormLayout,
    UpdateFormInitialMessage,
    CreateSubCollection

}