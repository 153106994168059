import React from 'react';
import {
  EuiCollapsibleNavGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiFlexGroup,
  EuiErrorBoundary,
} from '@elastic/eui';

import { useNavigate } from 'react-router-dom';
import AppSideNavAddApp from './nav-add-app';
import AppSideNavApps from './nav-apps';
import AppUserWorkspace from '../workspace';
import AppTopNav from './nav-top';
import AppNavFooter from './nav-footer';
import { useQuery } from '@tanstack/react-query';
import WorkspaceApi from 'api/workspace-api';


function AppSideNav({ iscollapse, CollapseOnchange }) {


  const navigate = useNavigate();
  const { data } = useQuery(["GetListWorkspace"], () => WorkspaceApi.GetList());



  const onCollapse = () => {
    CollapseOnchange()
  }

  return (
    <>
      <EuiFlexGroup direction='column' className='border-nav-bar' gutterSize='none' style={{ position: 'fixed', top: 0, bottom: 0, width: (iscollapse ? 50 : 240), clear: 'both' }}>
        <EuiFlexItem grow={false} style={{ flexShrink: 0, paddingTop: 11, paddingLeft: (iscollapse ? 1 : 3) }} className="euiHeader">
          <EuiErrorBoundary>
            <AppUserWorkspace iscollapse={iscollapse} />
          </EuiErrorBoundary>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ flexShrink: 0 }} >
          <EuiCollapsibleNavGroup >
            <EuiErrorBoundary>
              <AppTopNav iscollapse={iscollapse} navigate={navigate} />
            </EuiErrorBoundary>
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
        <EuiHorizontalRule margin="none" />
        <EuiFlexItem grow={false}  >
          <EuiCollapsibleNavGroup>

            {data && data.data.length > 0 &&
              <EuiErrorBoundary>
                <AppSideNavAddApp iscollapse={iscollapse} />
              </EuiErrorBoundary>
            }
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
        <EuiFlexItem className="eui-yScroll" style={{ maxWidth: 250 }}>
          <EuiErrorBoundary>
            <AppSideNavApps iscollapse={iscollapse} selectApp={''} />
          </EuiErrorBoundary>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiHorizontalRule margin="none" />
          <AppNavFooter iscollapse={iscollapse} onCollapse={onCollapse} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}
export default AppSideNav;

