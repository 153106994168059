import React, { useEffect, useState } from "react";
import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormControlLayoutDelimited,
  EuiFormRow,
  EuiI18n,
  EuiPopover,
  EuiSuperSelect,
  EuiToolTip,
} from "@elastic/eui";
import { PATTERN, PATTERN_EMAIL } from "core/Patterns";
import AnimatePanelWrapper from "components/animation/animate-panel-wrapper";
import { ShowWhen } from "components/conditional-render/show-when";
import { StringHelper } from "core/String";





const validator_title_type = (<EuiI18n token="form.validator.title.type" default="Tipo de validación" />);
const validator_option_none = (<EuiI18n token="form.validator.option.none" default="Ninguna" />);
const validator_option_email = (<EuiI18n token="form.validator.option.email" default="Correo electrónico" />);
const validator_option_email_tooltip = (<EuiI18n token="form.validator.option.email.tooltip" default="Valida que un correo electronico este bien escrito" />);
const validator_option_rangeChar = (<EuiI18n token="form.validator.option.rangeChar" default="Limite de caracteres" />);
const validator_option_rangeChar_tooltip = (<EuiI18n token="form.validator.option.rangeChar.tooltip" default="Limita el texto a un número de caracteres" />);
const validator_option_rangeNumber = (<EuiI18n token="form.validator.option.rangeNumber" default="Rango de números" />);
const validator_option_rangeNumber_tooltip = (<EuiI18n token="form.validator.option.rangeNumber.tooltip" default="Registra un valor mínimo y máximo" />);
const validator_option_custom = (<EuiI18n token="form.validator.option.custom" default="Expresión regular" />);
const validator_option_custom_tooltip = (<EuiI18n token="form.validator.option.custom.tooltip" default="Puedes usar su propia regla de validación mediante una expresión regular personalizada." />);
const validator_option_function = (<EuiI18n token="form.validator.option.funcion" default="Formula de javascript" />);
const validator_option_function_tooltip = (<EuiI18n token="form.validator.option.custom.tooltip" default="Puedes usar un conjunto de instrucciones javscript para crear tu propia validación." />);

function FieldValidator({ TypeFielSelected, OnChangeFieldValidator, SetData }) {



  const emptyValidatorRules = {
    type: 'none',
    min: 0,
    max: 0,
    min_length: 1,
    max_length: 0,
    pattern: '',
    validate: '',
    message: ''
  };
  const limit_input_length = TypeFielSelected === 'text' ? 3 : 9;
  const [selectedPattern, setSeletedPattern] = useState(SetData?.type || "none");
  const [max, setMax] = useState(SetData?.max || '');
  const [min, setMin] = useState(SetData?.min || '');
  const [maxLength, setMaxLength] = useState(SetData?.max_length || '');
  const [minLength, setMinLength] = useState(SetData?.min_length || '');
  const [customPattern, setCustomPattern] = useState(SetData?.pattern || '');
  const [validatorRules, setValidatorRules] = useState(SetData);
  const [message, setMessage] = useState(SetData?.message || '');
  const [listOptions, setListOptions] = useState([]);




  const patterns_for_text = [
    {
      value: "email",
      inputDisplay: (
        <EuiToolTip position="left" content={validator_option_email_tooltip}>
          {validator_option_email}
        </EuiToolTip>
      ),
    },
    {
      value: "range_char",
      inputDisplay: (
        <EuiToolTip
          position="left"
          content={validator_option_rangeChar_tooltip}
        >
          {validator_option_rangeChar}
        </EuiToolTip>
      ),
    },
    {
      value: "only_number",
      inputDisplay: "Solo números",
    },
    {
      value: "only_letter",
      inputDisplay: "Solo letras",
    },
    {
      value: "only_uppercase",
      inputDisplay: "Solo letras en mayúsculas",
    }
  ];


  const patterns_for_number = [
    {
      value: "range_number",
      inputDisplay: (
        <EuiToolTip
          position="left"
          content={validator_option_rangeNumber_tooltip}
        >
          {validator_option_rangeNumber}
        </EuiToolTip>
      ),
    }
  ]


  let validatorsPatterns = [
    {
      value: "none",
      inputDisplay: validator_option_none,
    },
    {
      value: "regular_expression",
      inputDisplay: (
        <EuiToolTip position="left" content={validator_option_custom_tooltip}>
          {validator_option_custom}
        </EuiToolTip>
      ),
    },
    {
      value: "function",
      inputDisplay: (
        <EuiToolTip position="left" content={validator_option_function_tooltip}>
          {validator_option_function}
        </EuiToolTip>
      ),
      disabled: true,
    },
  ];



  const resetValidators = () => {
    setMax('');
    setMin('');
    setCustomPattern("");
    setMaxLength('')
    setMinLength('')
    setMessage('')

  };

  const OnChangeTypeValidator = (option) => {


    if (!SetData)
      resetValidators()

    setValidatorRules(validatorRules => ({
      ...emptyValidatorRules
    }))

    if (option === "none") {
      resetValidators()
      OnChangeFieldValidator(emptyValidatorRules)
    }


    if (option === 'email') {

      const updated =
      {
        ...validatorRules,
        type: 'email',
        pattern: '' + PATTERN_EMAIL + '',
        message: message

      }


      setValidatorRules(updated)
      OnChangeFieldValidator(updated)

    }
    if (option === 'only_number') {

      const updated =
      {
        ...validatorRules,
        type: 'only_number',
        pattern: '' + PATTERN.ONLY_NUMBER + '',
        message: message

      }

      setValidatorRules(updated)
      OnChangeFieldValidator(updated)

    } if (option === 'only_letter') {

      const updated =
      {
        ...validatorRules,
        type: 'only_letter',
        pattern: '' + PATTERN.ONLY_LETTER + '',
        message: message

      }

      setValidatorRules(updated)
      OnChangeFieldValidator(updated)

    } 
    if (option === 'only_uppercase') 
    {

      const updated =
      {
        ...validatorRules,
        type: 'only_uppercase',
        pattern: '' + PATTERN.ALL_UPPER_CASE + '',
        message: message

      }

      setValidatorRules(updated)
      OnChangeFieldValidator(updated)

    }


    
    setSeletedPattern(option)

  };


  const onChangeCustomPattern = (value) => {

    const updated =
    {
      ...validatorRules,
      type: 'regular_expression',
      pattern: '' + value + '',
      message: message

    }

    setValidatorRules(updated)
    OnChangeFieldValidator(updated)
    setCustomPattern(value)
  }


  const handleMaxMixChange = (value, key) => {

    const valueLimited = StringHelper.LimitLength(value, limit_input_length)
    const type = getTypeValitator(key)

    if (valueLimited){

      const updateValdators = {
        ...validatorRules,
        type: type,
        [key]: valueLimited,
      };


      setValues(key, valueLimited)
      setValidatorRules(updateValdators)
      OnChangeFieldValidator(updateValdators)

    }
  };


  const handleSetMessage = (value) => {

    const updateValdators = {
      ...validatorRules,
      message: value
    };

    setMessage(value)
    setValidatorRules(updateValdators)
    OnChangeFieldValidator(updateValdators)

  }

  const setValues = (key, value) => {


    switch (key) {
      case 'max':
        setMax(value)
        break;

      case 'min':
        setMin(value)
        break;

      case 'max_length':
        setMaxLength(value)
        break;

      case 'min_length':
        setMinLength(value)
        break;


      default:
        break;
    }

  }


  const getTypeValitator = (key) => {

    let type = 'none';

    switch (key) {
      case 'max':
        type = 'range_number'
        break;

      case 'min':
        type = 'range_number'
        break;

      case 'max_length':
        type = 'range_char'
        break;

      case 'min_length':
        type = 'range_char'
        break;

      default:
        type = 'none'
        break;
    }

    return type;

  }





  useEffect(() => {



    if (TypeFielSelected) {



      const RunSetter = () => {

        setListOptions(validatorsPatterns)//add

        if (TypeFielSelected === 'numeric')//add
          setListOptions([...validatorsPatterns, ...patterns_for_number])

        if (TypeFielSelected === 'text' || TypeFielSelected === 'email')
          setListOptions([...validatorsPatterns, ...patterns_for_text])


        if (TypeFielSelected === 'email')
          OnChangeTypeValidator(TypeFielSelected)
        else
          OnChangeTypeValidator(SetData.type ? SetData.type : 'none')

      }

      RunSetter()

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <EuiFlexGroup direction="column" >
      <EuiFlexItem>
        <EuiFormRow
          label={validator_title_type}
          fullWidth
          display="rowCompressed"
        >
          <EuiSuperSelect
            fullWidth
            options={listOptions}
            valueOfSelected={selectedPattern}
            disabled={selectedPattern === "email"}
            onChange={(value) => OnChangeTypeValidator(value)}
            compressed
          />
        </EuiFormRow>

      </EuiFlexItem>
      <EuiFlexItem grow={false} >
        <ShowWhen condition={selectedPattern === "regular_expression"}>
          <AnimatePanelWrapper>
            <EuiFormRow
              label={validator_option_custom}
              fullWidth
              display="rowCompressed"
            >
              <EuiFieldText
                placeholder="/[!@#$%^&*()_+\-=[\]{};¨'´]/"
                value={customPattern}
                onChange={(e) =>onChangeCustomPattern(e.target.value)}
                aria-label="Use expression regular"
                compressed
                prepend={
                  <EuiPopover
                    button={
                      <EuiButtonEmpty size="xs" iconType="arrowDown" iconSide="right">
                        Plantillas
                      </EuiButtonEmpty>
                    }
                    closePopover={() => { }}
                  />
                }
              />
            </EuiFormRow>
          </AnimatePanelWrapper>
        </ShowWhen>
        <ShowWhen condition={selectedPattern === "range_number"}>
          <AnimatePanelWrapper>
            <EuiFormRow
              label={validator_option_rangeNumber}
              fullWidth
              display="rowCompressed">
              <EuiFormControlLayoutDelimited
                compressed                
                startControl={
                  <input
                    type="number"
                    min="0"
                    placeholder="Valor mínimo"
                    className="euiFieldNumber"
                    aria-label="minimal value"
                    title="valor mínimo solo enteros positivos"
                    onChange={(e) => handleMaxMixChange(e.target.value, 'min')}
                    value={min}
                  />
                }
                endControl={
                  <input
                    type="number"
                    placeholder="Valor máximo"
                    className="euiFieldNumber"
                    aria-label="max value"
                    title="valor máximo solo enteros positivos"
                    min="0"
                    onChange={(e) => handleMaxMixChange(e.target.value, 'max')}
                    value={max}
                  />
                }
              />
            </EuiFormRow>
          </AnimatePanelWrapper>
        </ShowWhen>
        <ShowWhen condition={selectedPattern === "range_char"}>
          <AnimatePanelWrapper>
            <EuiFormRow
              label={validator_option_rangeChar}
              fullWidth
              display="rowCompressed"
            >
              <EuiFormControlLayoutDelimited
                compressed
                startControl={
                  <input
                    type="number"
                    placeholder="Valor mínimo"
                    title="valor mínimo"
                    className="euiFieldNumber"
                    min="0"
                    aria-label="minimal value"
                    onChange={(e) => handleMaxMixChange(e.target.value, 'min_length')}
                    value={minLength}
                  />
                }
                endControl={
                  <input
                    type="number"
                    placeholder="Valor máximo"
                    className="euiFieldNumber"
                    aria-label="max value"
                    max={500}
                    onChange={(e) => handleMaxMixChange(e.target.value, 'max_length')}
                    value={maxLength}
                    title="Valor máximo"
                    min="0"

                  />
                }
              />
            </EuiFormRow>
          </AnimatePanelWrapper>

        </ShowWhen>

        <ShowWhen condition={selectedPattern !== "none"}>
          <EuiToolTip content='Este mensaje se mostrará cuando no cumpla la validación' position="right" delay="long" >
            <AnimatePanelWrapper>
              <EuiFormRow
                style={{ marginTop: (['email', 'only_number', 'only_letter', 'only_uppercase']).includes(selectedPattern) ? '-10px' : '18px' }}
                hasEmptyLabelSpace
                fullWidth
                display="rowCompressed"

              >

                <EuiFieldText
                  fullWidth
                  isInvalid={selectedPattern !== "none" ? true : false}
                  placeholder={'Escribe un mensaje de validación'}
                  name="validation-messaje-1"
                  compressed
                  value={message}
                  onChange={(e) => handleSetMessage(e.target.value)}
                  maxLength={50}
                  spellCheck={true}

                />

              </EuiFormRow>

            </AnimatePanelWrapper>
          </EuiToolTip>
        </ShowWhen>

      </EuiFlexItem>
    </EuiFlexGroup>


  );
}

export default FieldValidator;
