
import React from 'react'


function Demo() {


//

// useEffect(() => {

//   const  GetConfiguration = async (prop) => {

//     const _token = localStorage.getItem("user-token")
//     console.log(_token)
//     const options = {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json;charset=utf-8',
//         'Authorization': 'Bearer ' + _token
//       }
//     }
//     const url = "https://localhost:44347/v1/workspace/list";
//     const res =  await fetch(url,options).then(res => res.json());
//     console.log("response",res)
// }
 
//     GetConfiguration('company-config');

 
// }, [])





  return (
     
    //  <ScobarForm formId="1"/>
    <></>
    
  )
}

export default Demo
  