import { EuiI18n } from "@elastic/eui"

const workspace_name=(<EuiI18n token="workspace.name" default="Nombre del espacio de trabajo" />)
const workspace_color=(<EuiI18n token="workspace.color" default="Color" />)
const save_modal=(<EuiI18n token="global.save" default="Guardar" />)
const label_required=(<EuiI18n token="global.required" default="Es requerido" />)
const general_error=(<EuiI18n token="global.server.error" default="No fue posible terminar la tarea, intentelo nuevamente." />)
const general_title_error=(<EuiI18n token="global.server.title.error" default="Ocurrió algo inesperado" />)
const workspace_duplicate=(<EuiI18n token="workspace.duplicate" default="Ya existe un espacio de trabajo" />)
const workspace_description=(<EuiI18n token="workspace.description" default="Describa su espacio de trabajo" />)
const updated_title_succes = (<EuiI18n token="workspace.update" default="Se actualizo el espacio de trabajo" />)


const i18n={ workspace_name,workspace_color,save_modal,label_required,general_error,general_title_error,workspace_duplicate,workspace_description,updated_title_succes}

export default i18n;