import { EuiComboBox, EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiIcon, EuiLink, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui'
import AnimatePanelWrapper from 'components/animation/animate-panel-wrapper'
import { NumberHelper } from 'core/Number';
import React, { useEffect, useState } from 'react'

function FieldValidatorUpload({ OnChangFieldValidatorUpload, data }) {


    const options = [
        { value: '*', text: 'Todos los archivos', label: 'Todos los archivos' },
        { value: 'image/*', text: 'Todos las imagenes', label: 'Todas las imagenes' },
        { value: 'image/jpg', text: 'Imagen JPG', label: 'Imagen JPG' },
        { value: 'image/png', text: 'Imagen PNG', label: 'Imagen PNG' },
        { value: 'image/jpeg', text: 'Imagen JPEG', label: 'Imagen JPEG' },
        { value: 'image/gif', text: 'Imagen GIF', label: 'Imagen GIF' },
        { value: 'image/bmp', text: 'Imagen BMP', label: 'Imagen BMP' },
        { value: 'image/webp', text: 'Imagen WEBP', label: 'Imagen WEBP' },
        { value: 'application/pdf', text: 'Archivo PDF', label: 'Archivo PDF' },
        { value: 'application/msword', text: 'Archivo Word (DOC)', label: 'Archivo Word (DOC)' },
        { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', text: 'Archivo Word (DOCX)', label: 'Archivo Word (DOCX)' },
        { value: 'application/vnd.ms-excel', text: 'Archivo Excel (XLS)', label: 'Archivo Excel (XLS)' },
        { value: 'application/vnd.ms-excel', text: 'Archivo Excel  (XLSX)', label: 'Archivo Excel  (XLSX)' },
        { value: 'application/vnd.ms-powerpoint', text: 'Archivo PowerPoint (PPT)', label: 'Archivo PowerPoint (PPT)' },
        { value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', text: 'Archivo PowerPoint (PPTX)', label: 'Archivo PowerPoint (PPTX)' },
        { value: 'audio/mpeg', text: 'Archivo Audio (MP3)', label: 'Archivo Audio (MP3)' },
        { value: 'video/mp4', text: 'Archivo Video (MP4)', label: 'Archivo Video (MP4)' },
        { value: 'application/vnd.rar', text: 'Archivo comprimidos (RAR)', label: 'Archivo comprimidos (RAR)' },
        { value: 'application/zip', text: 'Archivo comprimidos (RAR)', label: 'Archivo comprimidos (ZIP)' },
        { value: 'text/plain', text: 'Archivo de texto (TXT)', label: 'Archivo de texto (TXT)' },
        { value: 'application/rtf', text: 'Archivo de texto (RTF)', label: 'Archivo de texto (RTF)' },
        { value: 'image/svg+xml', text: 'Archivo SVG', label: 'Archivo SVG' },
        { value: 'audio/wav', text: 'Archivo Audio (WAV)', label: 'Archivo Audio (WAV)' },
        { value: 'text/html', text: 'Archivo HTML', label: 'Archivo HTML' },
        { value: 'text/csv', text: 'Archivo separado por comas (CSV)', label: 'Archivo separado por comas (CSV)' },
        { value: 'text/xml', text: 'Archivo XML', label: 'Archivo XML' },
        { value: 'application/json', text: 'Archivo JSON', label: 'Archivo JSON' },

    ];



    const emptyOptions = {
        max_files: 1,
        max_file_size: 5,
        allowed_extensions: ['image/*']
    };

    const [enableFileSize,] = useState(true)
    const [,] = useState(options[0].value);
    const [uploadOptions, setUploadOptions] = useState(emptyOptions);
    const [maxFile, setMaxFile] = useState(data?.upload_props?.max_files || 1)
    const [maxSize, setMaxSize] = useState(data?.upload_props?.max_file_size || 5)
    const [, setCustomExtension] = useState('')
    const [isCustomExtension, setIsCustomExtension] = useState(true)
    const [selectedOptions, setSelected] = useState([options[0]]);


    const setOptions = (option, value) => {

        let value_limit = 1;


        if (option === 'max_files') {
            value_limit = NumberHelper.LimitNumberRange(value, 1, 5);
            setMaxFile(value_limit)

        }

        if (option === 'max_file_size') {
            value_limit = NumberHelper.LimitNumberRange(value, 1, 25);
            setMaxSize(value_limit)

        }
        let update = true;

        if (option === 'type_file') {
            //setSelectOption(value)
            value_limit = value;
            setSelected([])
            setCustomExtension('')

            if (value === "custom") {
                setIsCustomExtension(true)
            }
            else {


                setIsCustomExtension(false)
                CreateAndClearOption(value)
                update = false;
            }

        }

        if (update) {
            const options_updated = {
                ...uploadOptions,
                [option]: value_limit
            }

            setUploadOptions(options_updated)
            OnChangFieldValidatorUpload(options_updated)

        }
    }



    // const onCreateOption = (new_value) => {

    //     console.log("new_value", new_value)
    //     const newOption = { value: new_value, text: new_value, label: new_value };

    //     const addedOptions = [...selectedOptions, newOption];
    //     updateOptions(addedOptions);


    // };

    const CreateAndClearOption = (new_value) => {
        const newOption = [{ label: new_value }];
        updateOptions(newOption);


    };

    const ResetOptions = () => {
        const newOption = [{ label: '' }];
        updateOptions(newOption);


    };


    const onChangeRemove = (item_option) => {

        updateOptions(item_option);
    };

    const updateOptions = (options, action) => {

        setSelected(options);

        let concatenatedOptions = options.map((str) => str.value);

        setCustomExtension(concatenatedOptions)

        const options_updated = {
            ...uploadOptions,
            allowed_extensions: concatenatedOptions
        }

        setUploadOptions(options_updated)
        OnChangFieldValidatorUpload(options_updated)
    }


    useEffect(() => {

        if (data) {
            setSelected([])
            const list_options = data?.upload_props.allowed_extensions;
            const selected = options.filter((option) => list_options.includes(option.value));
            setSelected(previous => [...previous, ...selected])
        }
    }, [])


    return (
        <EuiFormRow
            fullWidth
            display="rowCompressed">
            <>
                {enableFileSize && (
                    <AnimatePanelWrapper>
                        <EuiFlexGroup gutterSize='s'>
                            <EuiFlexItem>
                                <EuiFormRow label={
                                    <EuiToolTip content='Número máximo de archivos que se pueden subir: 5 en la web y  1 en la móvil.'>
                                        <>Limite de archivos <EuiIcon type="iInCircle" /></>
                                    </EuiToolTip>}
                                >
                                    <EuiFieldNumber
                                        placeholder=""
                                        onChange={(e) => setOptions('max_files', e.target.value)}
                                        compressed
                                        style={{ maxWidth: 130 }}
                                        value={maxFile}
                                        max={10}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow label={
                                    <EuiToolTip content='Tamaño máximo de cada archivo en Megabytes, 25 MB como máximo por archivo'>
                                        <>
                                            Limite de tamaño <EuiIcon type="iInCircle" />
                                        </>
                                    </EuiToolTip>}
                                >
                                    <EuiFieldNumber
                                        placeholder=""
                                        onChange={(e) => setOptions('max_file_size', e.target.value)}
                                        append="MB"
                                        style={{ maxWidth: 100 }}
                                        compressed
                                        value={maxSize}

                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        {isCustomExtension && (
                            <EuiFlexGroup>
                                <EuiFlexItem style={{ maxWidth: 500 }}>
                                    <EuiSpacer size='s' />
                                    <EuiFormRow hasChildLabel label="Extensiones permitidas" labelAppend={
                                        <EuiText size="xs">
                                            <EuiLink external target='_blank' href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types">Tipos de datos</EuiLink>
                                        </EuiText>
                                    }>
                                        <EuiComboBox
                                            compressed
                                            placeholder="Selecciona una o varias, tambien puedes agregar nuevas."
                                            selectedOptions={selectedOptions}
                                            onChange={onChangeRemove}
                                            onReset={ResetOptions}
                                            options={options}
                                            fullWidth

                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )}


                    </AnimatePanelWrapper>
                )}
            </>
        </EuiFormRow>
    )
}

export default FieldValidatorUpload