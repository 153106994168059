export const StringHelper = {

  Capitalize: (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  LimitLength: (value, limit_input_length) => {
    return value ? parseInt(value.slice(0, limit_input_length)) : 0;
  },
  TruncateText:(text, maxLength) => 
  {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }
  
  

};


export const TextFormater = {

  Transform: (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },

};

