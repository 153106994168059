import {
    EuiIcon,     EuiBreadcrumb, EuiHeader, EuiHeaderSections
} from "@elastic/eui";
import { CollectionApiQuery } from "api/collections/query-api";
import ProgressPanel from "components/progress/progress-whit-panel";



function BreadCrumbsForm({ collection, buttonClose, hasShadow, hasBorder }) 
{

    const { data, isLoading } = CollectionApiQuery.GetCollectionByHash(collection);


    if (isLoading)
        return <ProgressPanel />


    const breadcrumbs: EuiBreadcrumb[] = [

        {
            text: <EuiIcon type={'database'} />

        },
        {
            text: data?.data?.name || '',
        },
        {
            text: 'Nuevo registro',

        },

    ];

    const sections: EuiHeaderSections[] = [
        {
            items: [
            ],
            breadcrumbs: breadcrumbs,
            breadcrumbProps: {
                'aria-label': 'Header sections breadcrumbs',
                'type': 'page',
                'lastBreadcrumbIsCurrentPage': false
            },
        },
        {
            items: [buttonClose],
        }


    ];

    return (
        <EuiHeader
        
            position="static"
            style={{ padding: 0, margin: 0, borderRadius: '6px 6px 0px 0px' , minHeight: '47px'}}
            className={hasShadow ? ' ' : 'remove-shadow ' + (hasBorder ? ' ' : 'remove-bottom-border')}
            sections={sections} />)
}

export default BreadCrumbsForm