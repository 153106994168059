import React, { Fragment, useEffect, useState } from 'react'
import {
  EuiButton,
  EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer, useEuiI18n, EuiI18n, EuiSkeletonTitle, EuiPageHeader
} from '@elastic/eui'
import { Controller, useForm } from 'react-hook-form';
import { TimeZoneOptions } from 'core/TimeZone';
import { FormatDate } from 'core/FormatDate';
import { FormatNumber } from 'core/FormatNumber';
import { LangOptions } from 'core/Lang';
import ConfigurationApi from 'api/configuration-api'
import toast from 'react-hot-toast';
import { Select } from 'packages/fields/select/select';
import { RowSection } from 'packages/fields/row-section';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LocalStoreHelper } from 'core/LocalStore';
import { KEYS_LOCALSTORE } from 'core/Keys';



const i18n_company_cofiguration_save = (<EuiI18n token="global.save" default="Guardar" />)
const i18n_company_cofiguration_timezone = (<EuiI18n token="company.configuration.title.timezone" default="Zona horaria" />)
const i18n_company_cofiguration_formatdate = (<EuiI18n token="company.configuration.title.formatdate" default="Formato de la fecha" />)
const i18n_company_cofiguration_formatnumber = (<EuiI18n token="company.configuration.title.formatnumber" default="Formato de los números" />)
const i18n_company_cofiguration_lang = (<EuiI18n token="company.configuration.title.lang" default="Idioma de la plataforma" />)



function SuscriptionConfigurationPage() {


  const { data, isLoading } = useQuery(["GetConfiguration", 'settings'], () => ConfigurationApi.GetConfiguration('settings'))
  const { handleSubmit, control, reset } = useForm()
  // const  [config, setconfig] = useState({})
  const queryClient = useQueryClient();
  const i18n_title_succes = useEuiI18n('company.configuration.title.success', 'Se actualizo la configuración')
  const [save, setSave] = useState(false)


  const setFormatThousandSeparator = (data) => {
    const decimalSeparator = data.decimal_separator;

    if (decimalSeparator === ".")
      data.thousand_separator = ","

    if (decimalSeparator === ",")
      data.thousand_separator = "."

    return data;
  }

  const onSubmit = async (data) => {

    const update = setFormatThousandSeparator(data)
    setSave(true)

    const response = await ConfigurationApi.UpdateConfiguration({ option: 'settings', data: JSON.stringify(update) });

    if (response) {
      toast.success(i18n_title_succes);
      reset(update)
      queryClient.invalidateQueries(["GetConfiguration", 'settings'])
      LocalStoreHelper.update(KEYS_LOCALSTORE.KEY_ACCOUNT_SETTINGS, update);

    }

    setSave(false)
  }

  useEffect(() => {

    const fecht = async () => {

      if (data) {
        const response = JSON.parse(data.data);
        reset(response)
        //  setconfig(response)
      }

    }
    fecht();

  }, [data, reset])


  return (
    <Fragment>
      <EuiPageHeader
        pageTitle="Configuración de la plataforma"
        iconType={'gear'}
        description="Configuración global de la plataforma de los formatos de las fechas, zona horaria, números e idioma."
        bottomBorder
      />

      <EuiSpacer />
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="lang"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection label={i18n_company_cofiguration_lang} error={fieldState.error} display={'row'} extraHelpText={null} helpText={null} errorText={null} >
                <Select
                  field={field}
                  options={LangOptions}
                  error={fieldState.error} />
              </RowSection>

            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size='s' />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="timezone"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection display={'row'} extraHelpText={null} helpText={null} errorText={null} label={i18n_company_cofiguration_timezone} error={fieldState.error}  >
                <Select
                  field={field}
                  options={TimeZoneOptions}
                  error={fieldState.error} />
              </RowSection>

            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size='s' />

        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="date_format"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection label={i18n_company_cofiguration_formatdate} error={fieldState.error} display={'row'} extraHelpText={null} helpText={null} errorText={null}  >
                <Select
                  field={field}
                  options={FormatDate}
                  error={fieldState.error} />
              </RowSection>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size='s' />
        <EuiSkeletonTitle size="xxs" isLoading={isLoading} contentAriaLabel="">
          <Controller
            name="decimal_separator"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) =>
              <RowSection label={i18n_company_cofiguration_formatnumber} error={fieldState.error} display={'row'} extraHelpText={null} helpText={null} errorText={null} >
                <Select
                  field={field}
                  options={FormatNumber}
                  error={fieldState.error} />
              </RowSection>
            }
          />
        </EuiSkeletonTitle>
        <EuiSpacer size='s' />


        <EuiSpacer />
        <EuiFlexGroup>

          {!isLoading &&
            (<EuiFlexItem grow={false}>
              <EuiButton fill type="submit" size="s" isLoading={save}>
                {i18n_company_cofiguration_save}
              </EuiButton>
            </EuiFlexItem>
            )
          }

        </EuiFlexGroup>
      </EuiForm>
    </Fragment>
  )
}

export default SuscriptionConfigurationPage
