import { useState } from 'react';
import axios from "axios";
import { GLOBAL_KEY_BASE_URL } from "core/Constants";
import { KEYS_LOCALSTORE } from 'core/Keys';

export const useFilePondServer = (endpoint,preloadfiles) => {

    const client = axios.create({
        baseURL: GLOBAL_KEY_BASE_URL,
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
        
    });

    const _token ='Bearer ' + localStorage.getItem(KEYS_LOCALSTORE.KEY_TOKEN); 
    client.defaults.headers.common['Authorization'] = _token;
    const endPoint = GLOBAL_KEY_BASE_URL + endpoint;

    const [files, setFiles] = useState(preloadfiles ? preloadfiles : []);


    const loadFile = (source, load) => {
        var myRequest = new Request(source);
        fetch(myRequest).then(function (response) {
            response.blob().then(function (myBlob) {
                load(myBlob);
            });
        });

    };

    const processFile = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        
        const formData = new FormData();        
        const fileName = file.name;
        formData.append("file", file,fileName,);


        client({
            method: 'POST',
            url: endPoint,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;'
            },
            cancelToken: source.token,
            onUploadProgress: (e) => 
            {
                progress(true, e.loaded, e.total);
            }
            }).then(response => {
                
                load(fileName);

            }).catch((thrown) => {
                if (axios.isCancel(thrown)) {

                } else {

                }
            });

        return {
            abort: () => {
                source.cancel('');
                abort();
            }
        };
    };

    const revertFile = (uniqueFileId, load, error) => {

        client({
            method: 'DELETE',
            url: endPoint + '/' + uniqueFileId
        })
            .then(response => {

            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {

                }
            });

        load();
    };

    const removeFile = (source, load, error) => {
        const updatedFiles = files.filter(file => file.source !== source);
        setFiles(updatedFiles);
        load();
    };

    return {
        files,
        setFiles,
        loadFile,
        processFile,
        revertFile,
        removeFile,
    };
};
