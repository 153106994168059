export const LangOptions = [
    {
        value : "es",
        text : "Español"
    },
    {
        value : "es-us",
        text : "Español (Estado Unidos)"
    },
    {
        value : "en",
        text : "Ingles (Estado Unidos)"
    }

];