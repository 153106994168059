import { EuiBetaBadge } from "@elastic/eui";



function LabelRequiredBetaBadge() {

    return (
        <EuiBetaBadge label="R" title='Requerido' size='s' className='required-icon-form' />

    )
}

export default LabelRequiredBetaBadge