import { EuiAvatar, EuiComboBox } from '@elastic/eui';
import { FieldApiQuery } from 'api/field-api';
import ButtonOpenModalNewRecord from 'packages/form/modal/new-record';
import { useEffect, useState } from 'react';

const ForeignKeyField = ({ fieldSchema, error, fieldController }) => {


    const collection = fieldSchema.options;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { data, isLoading } = FieldApiQuery.GeDataFromForeignFieldQuery(collection);
    const [options, setOptions] = useState([]);
    const onChange = (selectedOptions) => {
        
        fieldController.onChange(parseInt(selectedOptions[0].value));
        setSelectedOptions(selectedOptions);
    }

    useEffect(() => 
    {
        if (data) {
            
            const update = data.data.map(item => ({
                ...item,
                prepend: <EuiAvatar name={item.label || '?'} size="s" />
            }))

            setOptions(update);


        }

    }, [data])





    return (
        <>

            <EuiComboBox
                isLoading={isLoading}
                id={fieldSchema.id + ''}
                fullWidth
                aria-label="Combo box demo with option prepend/append nodes"
                options={options}
                onChange={onChange}
                singleSelection={{ asPlainText: true }}
                isInvalid={!!error}
                selectedOptions={selectedOptions}
                compressed
                isClearable
                append={
                    <ButtonOpenModalNewRecord
                        collection_id={collection}
                        display='base'
                        iconType='plusInCircle'

                    />}

            />
        </>
    );
};

export default ForeignKeyField;