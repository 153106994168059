import { EuiTitle } from '@elastic/eui'
import React from 'react'


function NavFieldToolbar() {
 

  return (
  <EuiTitle size='xs'>
    <h5>Tipos de campos</h5>
  </EuiTitle>
  
  )
  

}

export default NavFieldToolbar