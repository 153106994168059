import { useQuery } from "@tanstack/react-query";
import ApiClient from "api/api.client";


export const QUERY_KEYS_VIEW = {
    UPDATE_NAME_QUERY_KEY: "UpdateViewNameQuery",
    GET_VIEW: "GetViewByHash",
}

async function GetViewByHashQuery(view_id) {

    const response = await ApiClient.HttpGet(`view/${view_id}`);
    return response.data;
}



function GetViewByHash(hash_id) {
    return useQuery([QUERY_KEYS_VIEW.GET_VIEW, hash_id], () => GetViewByHashQuery(hash_id));
}







export const ViewApiQuery = {
    GetViewByHash

}



async function UpdateViewName(view) {
    const response = await ApiClient.HttpPost("view/name/update", view);
    return response.data;
}

async function UpdateViewEmoji(view) {
    const response = await ApiClient.HttpPost("view/emoji/update", view);
    return response.data;
}


async function AddView(view) {

    const response = await ApiClient.HttpPost("view/create", view);
    return response.data;

}



export const ViewApiCommand = {

    AddView,
    UpdateViewName,
    UpdateViewEmoji,

}














