import {
  EuiButton, EuiFlexGroup, EuiSpacer, EuiFlexItem, EuiForm,
} from '@elastic/eui'
import React, { Fragment, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form';
import { i18n } from './AddApp.i18n';
import { RowSection } from 'packages/fields/row-section';
import { TextField } from 'packages/fields/text-field/text-field';
import { EmojiPicker } from 'packages/fields/emoji-picker/emoji-picker';
import NotifySucces from 'components/notifications/notify-success';
import { AppApiCommand } from 'api/app-api';
import { useGlobalStore } from 'store/global-store';
import { useGlobalCallOutNotificationStore } from 'store/global-callout-notification-store';



function AddAppForm({ onClose }) {

  const selectedWorkspace = useGlobalStore((state) => state.workspace);
  const showNotificationError = useGlobalCallOutNotificationStore(state => state.showNotificationError);

  const [Loading, setLoading] = useState(false)
  const [isSucces, setIsSucces] = useState(false)
  const [validationText, setValidationText] = useState(undefined)


  const { control, handleSubmit, clearErrors, setError } = useForm();
  const queryClient = useQueryClient()

  const onSubmit = async (data) => {

    setLoading(true);
    clearErrors('name');
    setValidationText(i18n.label_required)

    if (!data.emoji)
      data.emoji = 'pinata'

    data.workspace = selectedWorkspace;
    data.app_type = 'database';

    const duplicate = await AppApiCommand.IsDuplicateApp(data);

    if (!duplicate.data) {

      const response = await AppApiCommand.CreateApp(data)



      if (response.data) {

        setLoading(false)
        setIsSucces(true)
        queryClient.invalidateQueries(['GetWorkSpaceApps', selectedWorkspace])

        setTimeout(() => {
          onClose()
        }, 500);




      } else {
        showNotificationError('No se creo la app', 'Ha ocurrido algo inesperado y no se pudo crear la app', response.message)
        setValidationText('No fue posible crear la app')
        setError('name', {})
        setLoading(false)
        setIsSucces(false)

      }

    } else {

      setError('name', { message: data.name + ' ya existe, intenta otra nombre.' })
      setValidationText(i18n.app_name_duplicate)
      setLoading(false)


    }

  }



  return (
    <Fragment>
      <EuiSpacer size="m" />
      {!isSucces ?
        (
          <EuiForm component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
            <>
              <EuiFlexGroup gutterSize='xs'>
                <EuiFlexItem>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) =>
                      <RowSection label={i18n.app_name} display="row" extraHelpText={null} error={fieldState.error} errorText={validationText} helpText={i18n.app_helptext} >
                        <TextField
                          field={field}
                          maxLength={35}
                          error={fieldState.error} />
                      </RowSection>
                    }
                  />
                  </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Controller
                    name="emoji"
                    control={control}
                    render={({ field, fieldState }) =>
                      <RowSection hasEmptyLabelSpace={false} label='Emoji' error={fieldState.error} display={'row'} errorText={fieldState.error} extraHelpText={null} helpText={null}>
                        <EmojiPicker
                          ariaLabel={i18n.app_emoji}
                          field={field}
                          error={fieldState.error} />
                      </RowSection>
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>

            </>
            <EuiSpacer />
            <Controller
              name="description"
              control={control}
              rules={{ required: false }}
              render={({ field, fieldState }) =>
                <RowSection label={i18n.app_description} display="row" extraHelpText={null} error={fieldState.error} errorText={validationText} helpText={null} >
                  <TextField
                    field={field}
                    maxLength={200}
                    error={fieldState.error} />
                </RowSection>
              }
            />
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton fill type="submit" isLoading={Loading}>
                  {i18n.save_modal}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>) :
        (<NotifySucces title='App creada con exito' description={<>{i18n.modal_succes}</>} conffeti={false} />)
      }
    </Fragment>
  )
}

export default AddAppForm
