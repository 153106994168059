import React from 'react'
import { EuiIcon,  EuiToolTip  } from '@elastic/eui';
import { useEuiI18n } from '@elastic/eui';
import { useAppRouteNavigator } from 'hooks/useAppRouteNavigator';


function ButtonConfigurationWorkSpace({workspace}) {

  const i18n_configuration = useEuiI18n('global.configuration', 'Configuración')
  const navigator = useAppRouteNavigator();

    return (
            <EuiToolTip content={i18n_configuration}>
            <EuiIcon
            aria-label="Warning"
            type="gear"
            color="subdued"
            aria-labelledby='navigate to workspace settings'
            onClick={() => navigator.GotoWorkSpaceSettings(workspace)}
            />
            </EuiToolTip>
            
    )
}

export default ButtonConfigurationWorkSpace
