import { EuiDatePicker } from '@elastic/eui';
import { KEYS_DATE_FORMAT_ISO } from 'core/Date';
import { useGlobalConfiguration } from 'hooks/useGlobalConfiguration';
import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
export const DateField = ({ fieldSchema, error, fieldController }) => {

  const [selectedDate, setSelectedDate] = useState(null);
  const  { dateFormat,lang } = useGlobalConfiguration();

 

  const onChange = (date) =>
  {
    
    setSelectedDate(date);
    fieldController.onChange(moment(date).format(KEYS_DATE_FORMAT_ISO));

  }

  useEffect(() => {
    if (fieldController.value) 
      setSelectedDate(moment(fieldController.value));
        
  },[fieldController.value])


  return (
    <>
      <EuiDatePicker
        fullWidth
        name={fieldSchema.id+''}
        id={fieldSchema.id+''}
        selected={selectedDate}  
        onChange={(e) => onChange(e)}
        isInvalid={!!error}
        compressed
        dateFormat={dateFormat}
        locale={lang}      
      />       
      </>
  );
};


