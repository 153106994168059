import React from 'react'
import HelpIconToolTip from './label-help-icon-tooltip'

type LabelProps = {
  extraHelpText: Boolean,
  label: String 

}

function Label({extraHelpText,label}:LabelProps) {
  return (
    <>
    {extraHelpText ? (<HelpIconToolTip  label={label} extraHelpText={extraHelpText} />) : label }
    </>
  )
}

export default Label