import React from 'react'
import { EuiButton, EuiEmptyPrompt, EuiImage, EuiLink, EuiProgress, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import AppNavItemApp from './nav-app-item';
import { useQuery } from '@tanstack/react-query';
import WorkspaceApi from 'api/workspace-api';
import { useGlobalStore } from 'store/global-store';
import emptyappslight from 'assets/svg/empty-state/empty-apps-light.svg'
import emptyappsdark from 'assets/svg/empty-state/empty-apps-dark.svg'

import { QUERY_KEYS_APP } from 'api/app-api';



function AppSideNavApps({ iscollapse, selectApp }) {


  const selectedWorkspace = useGlobalStore((state) => state.workspace);
  const currentTheme = useGlobalStore((state) => state.theme);


  const { data, isLoading } = useQuery([QUERY_KEYS_APP.GET_LIST_APPS_BY_WORKSPACE, selectedWorkspace], () => WorkspaceApi.GetWorkSpaceApps(selectedWorkspace));

  if (isLoading)
    return <EuiProgress size="xs" color="primary" position="static" />


  const EmtpyApps = (
    <EuiEmptyPrompt
      id='empty-apps'
      titleSize="xs"
      color='transparent'
      icon={<EuiImage
        size="s"
        src={currentTheme === 'light' ? emptyappslight : emptyappsdark}
        alt='empty apps'
      />}
      body={<EuiText size="s">{selectedWorkspace > 0 ? 'Aun no tienes apps ' : 'Debes crea un espacio de trabajo'}
        {selectedWorkspace > 0 &&
          <EuiButton
            color="primary"
            size='s'
            iconSize='s'
            iconType={"plusInCircle"}
            onClick={() => { }}
          >
            Crear una app
          </EuiButton>
        }
      </EuiText>}
      footer={
        <>
          <EuiTitle size="xxs">
            <h3>Guia para crear una app</h3>
          </EuiTitle>
            <EuiSpacer size='xs'/>
          <EuiLink href="#"  target="_blank">
            Centro de ayuda
          </EuiLink>          
        </>
      }

    />

  )

  return data && data.data.length > 0 ? (
    data.data.map(element => {
      return <AppNavItemApp key={'apps-id-' + element.id} app={element} iscollapse={iscollapse} />
    })
  ) : (!iscollapse && EmtpyApps)

}

export default AppSideNavApps