import { EuiDraggable, EuiDroppable, EuiFlexGroup, EuiFlexItem, EuiPanel, } from '@elastic/eui';
import IconFieldType from 'components/icons/icons-fields-type';

import React from 'react'


function NavDragFields({ list1 }) {


  return (
    <>

      <EuiDroppable
        droppableId="DROPPABLE_AREA_COPY_1"
        cloneDraggables={true}
        spacing="none">
        {list1.map(({ content, id, icon }, idx) => (
          <EuiDraggable key={id} index={idx} draggableId={id} spacing="s">
            {(provided) => (
              <>
                <EuiPanel paddingSize="s" color="subdued" {...provided.dragHandleProps}>
                  <EuiFlexGroup gutterSize="none">
                    <EuiFlexItem grow={false}><IconFieldType iconType={icon} /> </EuiFlexItem>
                    <EuiFlexItem>{content}</EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </>
            )}
          </EuiDraggable>
        ))}
      </EuiDroppable>

    </>
  )
}

export default NavDragFields