import { useQuery } from "@tanstack/react-query";
import ApiClient from "../api.client";

export const QUERY_KEYS_COLLECTION = {
    GET_COLLECTION_BY_HASH: "GetCollectionByHashQuery",
    GET_ATTRIBUTES_BY_COLLECTION: "GetAttributesByCollectionQuery",
    GET_COLLECTION_LAYOUT: "GetCollectionLayoutQuery",
    GET_COLLECTION_INITIAL_FORM: "GetCollectionInitialFormQuery",
    GET_COLLECTION_NAVIGATOR: "GetCollectionNavigatorQuery"
}


async function GetCollectionByHashQuery(collection) {

    const response = await ApiClient.HttpGet(`collections/${collection}`);
    return response.data;
}
function GetCollectionByHash(collection) {
    return useQuery([QUERY_KEYS_COLLECTION.GET_COLLECTION_BY_HASH, collection], () => GetCollectionByHashQuery(collection));
}

async function GetCollectionLayoutQuery(collection) {

    const response = await ApiClient.HttpGet(`collections/${collection}/layout`);
    return response.data;
}

function GetCollectionLayout(collection) {

    return useQuery([QUERY_KEYS_COLLECTION.GET_COLLECTION_LAYOUT, collection], () => GetCollectionLayoutQuery(collection));
}




async function GetAttributesByCollectionQuery(collection) {
    const response = await ApiClient.HttpGet(`collections/${collection}/attributes`);
    return response.data;

}


function GetAttributesByCollection(view_id) {
    return useQuery([QUERY_KEYS_COLLECTION.GET_ATTRIBUTES_BY_COLLECTION, view_id], () => GetAttributesByCollectionQuery(view_id));
}


async function GetCollectionInitialFormQuery(collection) {
    const response = await ApiClient.HttpGet(`collections/${collection}/message/form`);
   
    return response.data;
}

function GetCollectionInitialForm(collection) {
    return useQuery([QUERY_KEYS_COLLECTION.GET_COLLECTION_INITIAL_FORM, collection], () => GetCollectionInitialFormQuery(collection));
}

async function GetCollectionNavigatorQuery(collection) {
    const response = await ApiClient.HttpGet(`collections/${collection}/navigator`);
    return response.data;
}

function GetCollectionNavigator(collection) {
    return useQuery([QUERY_KEYS_COLLECTION.GET_COLLECTION_NAVIGATOR, collection], () => GetCollectionNavigatorQuery(collection));
}


export const CollectionApiQuery = {
    GetCollectionByHash,
    GetAttributesByCollection,
    GetCollectionLayout,
    GetCollectionInitialForm,
    GetCollectionNavigator

}









