import React from 'react'
import { EuiText,EuiIcon,EuiContextMenu,EuiI18n } from '@elastic/eui';
import AppForgot from './Forgot'
import AppLogin from './Login';



const  AuthPanel =(props)=> {


 const welcomeTitle =(
 <EuiText size="xs"><h3><EuiI18n token="login.title.welcome" default="Bienvenido/a" /></h3> <p><EuiI18n token="login.subtitle" default="Seleccione una opción para continuar." /></p></EuiText> 
); 
 const login_email=(<EuiI18n token="login.with.email" default="Iniciar sesión con Email" />)
 const login_sso=(<EuiI18n token="login.with.sso" default="Iniciar sesión con SSO" />)
 const login_forgot=(<EuiI18n token="login.with.fogot" default="Recuperar contraseña" />)
 const login_return=(<EuiI18n token="login.return" default="Regresar" />)


const panels = [
  {
    id: 0,
    title: welcomeTitle ,
    hasFocus:false,
    items: [
      {
        name: login_email,
        icon: <EuiIcon type="user" size="m" />,
        panel:2
      },
      {
        name: login_sso,
        icon: <EuiIcon type="users" size="m" />,
        panel:3
      },
      {
        name: login_forgot,
        icon: <EuiIcon type="email" size="m" />,
        panel:3
      }
          ],
  },
  {
    id: 2,
    title: login_return,
    content:<AppLogin/>
  },
  {
    id: 3,
    title: login_return,
    content:<AppForgot/>
    
  },
];


    return (
      <EuiContextMenu initialPanelId={0} panels={panels}/>
    )
}

export default AuthPanel