import { EuiFlexItem, EuiFormRow } from '@elastic/eui';
import LabelAppend from './components/label-append';
import Label from './components/label';



const FormRowSection = ({ schema_field,  display, errorText,  error, hasEmptyLabelSpace = false,formAction, children }) => {


  
  let customError = errorText;
  const type_error = error?.type || '';

  switch (type_error) {
    case 'required':
      customError = errorText;
      break;
    case 'pattern':
    case 'maxLength':
    case 'minLength':
      customError = error.message;
      break;

    default:
      customError = errorText
      break;
  }

  const style_is_visible = schema_field.is_visible_in_form ? 'block' : 'none';
 
  return (
    <EuiFlexItem>
    <EuiFormRow
      fullWidth
      style={{display:style_is_visible }}
      hasEmptyLabelSpace={hasEmptyLabelSpace}
      label={<Label schema_field={schema_field}  />}
      isInvalid={!!error}
      error={customError}
      labelAppend={<LabelAppend schema_field={schema_field} />}
      //display={display === 'column' ? 'columnCompressed' : 'rowCompressed'}
      display="rowCompressed"
      helpText={schema_field.props?.help_text}>
      {children}
    </EuiFormRow>
    </EuiFlexItem>
  )

};

export default FormRowSection