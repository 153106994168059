import { useGeneratedHtmlId, EuiIcon,  EuiButtonEmpty, EuiPopover, EuiFieldSearch, EuiHorizontalRule,  EuiTitle, EuiHealth, EuiSpacer, EuiLink } from '@elastic/eui';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import React,{useState} from 'react';

function SearchInTable() {
  
   
  const [isPopoverOpen, setPopover] = useState(false);

  
  const normalContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'normalContextMenuPopover',
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };
 



  
  const button = (
    <EuiButtonEmpty
      iconType={ICON_GALLERY.SEARCH}
      iconSide="left"
      onClick={onButtonClick}
      size="s"
      color="text"
    >
    Buscar
    </EuiButtonEmpty>
  );


    



return (
  <EuiPopover
  id={normalContextMenuPopoverId}
  button={button}
  isOpen={isPopoverOpen}
  closePopover={closePopover}
  panelPaddingSize="s"
  anchorPosition="downCenter"
>
<EuiFieldSearch
        compressed={true}
        append={[
          <EuiButtonEmpty
            role="button"
            size="xs"
            aria-label="Calendar dropdown"
          >
            <EuiIcon type="list" />
          </EuiButtonEmpty>
          ]}
        placeholder=""
        value={value}
        onChange={(e) => onChange(e)}
        isClearable={true}
        aria-label="Use aria labels when no actual label is in use"
      />
      <EuiHorizontalRule margin='s' />
      <EuiTitle size="xxxs">
      <h6>Búsquedas recientes</h6>
    </EuiTitle>
    <EuiSpacer size='xs' />
    <EuiHealth color="primary"><EuiLink href="#">Tomas Anderson</EuiLink></EuiHealth>
    <EuiSpacer size='xs' />
    <EuiHealth color="success">Rebert Anderson</EuiHealth>
 
</EuiPopover>
)
}

export default SearchInTable;
