import { useGlobalConfiguration } from "hooks/useGlobalConfiguration";
import moment from "moment";


const DateRenderer = ({ value, row,column } ) => {

    const  { dateFormat} = useGlobalConfiguration();

    const dateFomated =  value ?  moment(value).format(dateFormat) : '';
    
    return (<>{dateFomated}</>)
}

export default DateRenderer;