import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHeaderLinks, EuiSpacer } from '@elastic/eui';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import React from 'react';
import { NavLink, useParams, } from 'react-router-dom';


function AppFormsToolBar() {

  let parameters = useParams();


  const formsType = [


    // {
    //   name: 'Diseño',
    //   icon: ICON_GALLERY.PENCIL1,
    //   page: 'desing',
    //   id: 0
    // },
    {
      name: 'Formularios',
      icon: ICON_GALLERY.PENCIL1,
      page: '',
      id: 2
    },
    // {
    //   name: 'Resumen',
    //   icon: ICON_GALLERY.RESUME,
    //   page: 'resume',
    //   id: 3
    // },
    {
      name: 'Fases',
      icon: 'timeline',
      page: 'phase',
      id: 3
    },
    {
      name: 'Reglas y condiciones de los campos',
      icon: 'branch',
      page: 'flow',
      id: 4
    },
    // {
    //   name: 'Esquema',
    //   icon: 'indexMapping',
    //   page: 'design?sidebar=true',
    //   id: 1
    // }
  ];



  return (
    <>
      <EuiFlexGroup justifyContent="spaceEvenly">
        <EuiHeaderLinks gutterSize="xs">
          {
            formsType.map((items) => (
              <EuiFlexItem grow={false}>
                <NavLink to={`/app/${parameters.app}/s/form/${items.page}`} >
                  {({ isActive }) => (
                    <EuiButtonEmpty isSelected={isActive ? true : false} className={isActive ? 'active' : ''} color={isActive ? 'primary' : 'text'} size='s' iconType={items.icon}>
                      {items.name}
                    </EuiButtonEmpty>
                  )}
                </NavLink>
              </EuiFlexItem>

            ))
          }

        </EuiHeaderLinks>
      </EuiFlexGroup>
      <EuiSpacer /></>
  );
}

export default AppFormsToolBar;
