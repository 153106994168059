import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui'
import QuickMessageAccordion from 'help/quick-message-accordion'
import React, { useEffect, useState } from 'react'

function FieldConsecutive({ onChangeConsecutiveOptions, SetOptions }) {

    console.log("SetOptions", SetOptions)

    const emptyConsecutiveOptions = {
        prefix: 'CON-',
        sufix: ''
    }
    const [prefix, setPrefix] = useState(SetOptions?.prefix || '')
    const [sufix, setSufix] = useState(SetOptions?.sufix || '')
    const [consecutiveOptions, setConsecutiveOptions] = useState(emptyConsecutiveOptions)


    const onChangePrefix = (type, value) => {

        const text = value.toUpperCase();
        const update_options = {
            ...consecutiveOptions,
            [type]: text
        }
        if (type === 'prefix')
            setPrefix(text)
        else
            setSufix(text)


        setConsecutiveOptions(update_options)
        onChangeConsecutiveOptions(update_options)

    }

    useEffect(() => {

        if (SetOptions)
            setConsecutiveOptions(SetOptions)
        else
            onChangeConsecutiveOptions(emptyConsecutiveOptions)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SetOptions])




    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <QuickMessageAccordion
                        title={"Este campo no estará habilitado en el formulario de creación."}
                        description={null}
                        paddingSize='s'
                        link_url={null}
                        link_text={null}
                        style={{ width: '450px' }}

                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label='Prefijo' helpText={prefix && prefix + '1'} >
                        <EuiFieldText
                            name="prepend"
                            compressed
                            title='Texto antes'
                            placeholder="Texto antes"
                            value={prefix}
                            onChange={(e) => onChangePrefix("prefix", e.target.value)}
                            maxLength={10}

                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label='Sufijo' helpText={prefix && prefix + '1' + sufix} >
                        <EuiFieldText
                            name="append"
                            title='Texto despues'
                            compressed
                            placeholder="Texto despues"
                            value={sufix}
                            onChange={(e) => onChangePrefix("sufix", e.target.value)}
                            maxLength={10}

                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

        </>

    )
}

export default FieldConsecutive