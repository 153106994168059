import React, { useState } from 'react';
import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiTab,
    EuiTabs,
    EuiIcon,
    EuiCallOut,
    EuiLink,
    EuiI18n,
    EuiToolTip,    

} from '@elastic/eui';
import { ShowWhen } from 'components/conditional-render/show-when';
import AnimatePanelWrapper from 'components/animation/animate-panel-wrapper';
import FormFieldCreator from 'packages/field-creator';
import ColumManagerTitle from './title';
import { useAppColumnManager } from 'hooks/useAppColumnManager';
import { ICON_GALLERY } from 'components/icons/icons-gallery';
import ColumnListManager from 'packages/column-manager';
import QuickMessageAccordion from 'help/quick-message-accordion';
import FormLayoutConfiguration from 'packages/form/layout/form-layout-configuration';
import FormInitialMessaje from 'packages/form/layout/form-initial-messaje';
import { useAppParameters } from 'hooks/useAppParameters';


const i18n_columns_available = (<EuiI18n token="form.editor.sidebar.column.available" default="Columnas" />)
const i18n_columns_add = (<EuiI18n token="form.editor.sidebar.column.add" default="Crear" />)
const i18n_columns_rule_title = (<EuiI18n token="form.editor.sidebar.column.rule.title'" default="Administrar reglas y condiciones de las columnas" />)


function ColumnManager() {


    const [selectedTabId, setSelectedTabId] = useState('1');
    const [showFlyout, setShowFlyout] = useState(true);
    const [showHelpColumnManager,] = useState(true);
    const columnManager = useAppColumnManager();
    const { collection } = useAppParameters();


    const tabs = [
        {
            id: '1',
            name:
                <EuiToolTip content="Listado de columnas de la colección">
                    {i18n_columns_available}
                </EuiToolTip>,
            prepend: <EuiIcon type="editorUnorderedList" />,
        },
        {
            id: '2',
            prepend: <EuiIcon type="plusInCircle" />,
            name:
                <EuiToolTip content="Crear una nueva columna">
                    {i18n_columns_add}
                </EuiToolTip>,
        },
        {
            id: '3',
            prepend: <EuiIcon type={ICON_GALLERY.LAYOUT} />,
            name: <EuiToolTip content="Diseño de columnas del formulario">
                <>
                    Diseño
                </>
            </EuiToolTip>,
        },
        {
            id: '4',
            prepend: <EuiIcon type={'editorComment'} />,
            name: <EuiToolTip content="Mensaje de bienvenida del formulario"><>Mensajes</></EuiToolTip>,
        },
    ];

    const closeFlyout = () => {
        setShowFlyout(false)
        columnManager.Hide();

    }

    const onSelectedTabChanged = (id) => setSelectedTabId(id);

    const renderTabs = tabs.map((tab, index) => (
        <EuiTab
            onClick={() => onSelectedTabChanged(tab.id)}
            isSelected={tab.id === selectedTabId}
            prepend={tab.prepend}
            key={index}
        >
            {tab.name}
        </EuiTab>
    ));



    let flyout;

    if (showFlyout) {
        flyout = (
            <EuiFlyout
                
                closeButtonPosition='outside'
                size={560}
                onClose={closeFlyout}
                paddingSize='s'
                closeButtonProps={
                    {
                        color: "primary",
                    }
                }
                aria-labelledby='sidebar-editor-fields'
            >
                <EuiFlyoutHeader
                    hasBorder >
                    <ColumManagerTitle />
                    <EuiCallOut size="s" title=
                        {
                            <EuiLink>
                                {i18n_columns_rule_title}
                            </EuiLink>
                        }
                        iconType={ICON_GALLERY.MAGIC} />

                    <EuiTabs style={{ marginBottom: '-9px' }}>{renderTabs}</EuiTabs>
                </EuiFlyoutHeader>
                <EuiFlyoutBody color='subdued'>
                    <ShowWhen condition={selectedTabId === '1'}>
                        <ShowWhen condition={showHelpColumnManager === true}>
                            <QuickMessageAccordion
                                title="Administración de columas de la colección"
                                description={'Puede personalizar el orden de las columnas, estas se mostraran en la creación y edición del formulario'}
                                paddingSize='s'
                                link_url={'/help/column-manager'}
                                link_text={'Ayuda'}
                                style={null}
                                initialIsOpen={true}

                            />
                        </ShowWhen>
                        <ColumnListManager collection={collection} />

                    </ShowWhen>
                    <ShowWhen condition={selectedTabId === '2'}>
                        <AnimatePanelWrapper>
                            <FormFieldCreator collection={collection} compressed={false} action='create' data={null} />
                        </AnimatePanelWrapper>
                    </ShowWhen>
                    <ShowWhen condition={selectedTabId === '3'}>
                        <AnimatePanelWrapper>
                            <FormLayoutConfiguration collection={collection} displayLayout='column' />
                        </AnimatePanelWrapper>
                    </ShowWhen>
                    <ShowWhen condition={selectedTabId === '4'}>
                        <AnimatePanelWrapper>
                            <FormInitialMessaje collection={collection} />
                            {/* <EuiSpacer size="s" />
                            <CollectionInitialMessaje collection={collection} /> */}
                        </AnimatePanelWrapper>
                    </ShowWhen>
                </EuiFlyoutBody>
            </EuiFlyout>
        );
    }

    return (

        <>
        <p> Column Manager</p>
        {flyout}
        </>
    );
};

export default ColumnManager;