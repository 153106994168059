import { useQuery } from "@tanstack/react-query";
import ApiClient from "api/api.client";



export const DATAGRID_QUERY_KEYS = {
    GET_RECORDS_BY_VIEW: "getrecordsbydatagrid",

}
async function GetRecordsByDataGridQuery(parameters) {

    const response = await ApiClient.HttpPost(`records/datagrid`, parameters);
    return response.data;
}

function GetRecordsByDataGrid(parameters) 
{
    console.log("parameters", parameters)
    return useQuery([DATAGRID_QUERY_KEYS.GET_RECORDS_BY_VIEW, parameters], 
        () => GetRecordsByDataGridQuery(parameters)
    );
}



export const DataGridViewApiQuery = {
    GetRecordsByDataGrid,
    GetRecordsByDataGridQuery

}







