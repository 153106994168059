import { EuiButtonIcon, EuiToolTip } from "@elastic/eui";
import ReactTimeAgo from "react-time-ago";



function DataGridToolBarRefresh({ onRefreshGrid, lastRefresh }) {

    const textLastRefresh = (<>Actualizado <ReactTimeAgo date={lastRefresh} /></>);

    return (
        <EuiToolTip
            title={textLastRefresh}
            content="Click para refrescar datos"
            position="left"
        >
            <EuiButtonIcon
                aria-label="Refresh grid data"
                size="xs"
                color="text"
                iconType="refresh"
                onClick={onRefreshGrid}
            />
        </EuiToolTip>
    )
}
export default DataGridToolBarRefresh