import {  EuiPopover, EuiText, EuiTextTruncate } from "@elastic/eui"
import TextCellEditor from "packages/datagrid/cell-editor/text/text";
import { useState } from "react"


const TextRenderer = ({ value, row, column, onChangeValue }) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [valueUpdate, setValueUpdate] = useState((value && value.toString()) || '');


    const onButtonClick = () => {
        setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    }

    const onTextChange = (value) => {

        setValueUpdate(value)
        onChangeValue(value)
        closePopover()
    }

    const closePopover = () => {
        setIsPopoverOpen(false);
        onChangeValue(value)
    }

    const TextCellButton = (
        <EuiText
            style={{ cursor: 'pointer' }}
            onDoubleClick={onButtonClick}
            grow={false}
            size="s"
            title={valueUpdate}

        >
        {valueUpdate ? <EuiTextTruncate text={valueUpdate || ''} /> : <p>&nbsp;</p>} 
        </EuiText>
    );





    return (<>
        <EuiPopover
            button={TextCellButton}
            initialFocus={"#cell-popover-editor-" + column.id}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
            display="block"
        >
            <TextCellEditor
                value={value}
                row={row}
                column={column}
                UpdateCellValue={onTextChange}

            />
        </EuiPopover>


    </>)
}

export default TextRenderer